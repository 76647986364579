import React, { useContext, useEffect, useRef, useState, useMemo } from 'react';
import { Heading, LearningContentCard } from '@atlassian/learning-components';
import { css } from '@compiled/react';
import { media } from '@atlaskit/primitives/responsive';
import { N800 } from '@atlaskit/theme/colors';
import { useLoaderData, useLocation } from 'react-router-dom';
import { token } from '@atlaskit/tokens';
import { throttle, times } from 'lodash';

import { Sidebar } from 'src/pages/CatalogPage/children/Sidebar';
import { CatalogLoaderProps, getCatalogResources, getFiltersFromUrl } from 'src/pages/CatalogPage/loader';
import { useFilters } from 'src/pages/CatalogPage/hooks/useFilters';
import { NoResults } from 'src/pages/CatalogPage/children/NoResults';
import { useAtlassianUser } from 'src/hooks/useAtlassianUser';
import { sendScreenEvent, sendUIEvent } from 'src/utils/analytics';
import { MobileSidebar } from 'src/pages/CatalogPage/children/MobileSidebar';
import { HelmetContext } from 'src/components/Helmet';

import { countAllResources, TaxonomyCount } from './utils/taxonomy';
import { CardsList } from './children/CardsList';

export const MIN_TOTAL_ENTITIES_PER_PAGE = 12;
export const PAGE_BOTTOM_THRESHOLD = 700;
export const MAX_ENTITIES_PER_PAGE = 9;

export const CatalogPage: React.FC = () => {
  const location = useLocation();
  const { resources, taxonomy } = useLoaderData() as CatalogLoaderProps;
  const { user, isLoading: isLoadingAtlassianUser, hasError } = useAtlassianUser();
  const hasInteractedWithFilter = useRef(false);
  const helmet = useContext(HelmetContext);

  const handleFilterInteraction = (): void => {
    hasInteractedWithFilter.current = true;
  };

  const [numberEntitiesPerTaxonomy, setNumberEntitiesPerTaxonomy] = useState<TaxonomyCount>({});
  const [totalResources, setTotalResources] = useState<number | null>(null);

  const [isLoadingResources, setIsLoadingResources] = useState(false);
  const [allResources, setAllResources] = useState(resources);
  const { filteredResources, selectedFiltersLength } = useFilters(allResources);

  const pageRef = useRef(0);

  const urlFilterParameters = useMemo(
    () => getFiltersFromUrl(globalThis?.location?.origin + location.search),
    [location.search],
  );

  const isAllResourcesLoaded = typeof totalResources === 'number' && totalResources === filteredResources.length;
  const isLoadingClientSideFilters =
    filteredResources.length < MIN_TOTAL_ENTITIES_PER_PAGE && pageRef.current !== -1 && !isAllResourcesLoaded;

  helmet.setHelmet({
    title: 'Learning - Atlassian Community',
    seoTags: {},
  });

  useEffect(() => {
    setAllResources(resources);
    pageRef.current = 0;
  }, [resources]);

  const handleCardClick = (slug: string, type: string): void => {
    sendUIEvent(
      'catalogScreen',
      'card',
      'clicked',
      'catalogCard',
      {
        hasInteractedWithFilters: hasInteractedWithFilter.current,
        areFiltersApplied: !!selectedFiltersLength,
        resourceSlug: slug,
        type,
      },
      user?.account_id,
    );
  };

  useEffect(() => {
    if (!isLoadingAtlassianUser && !hasError && resources.length > 0) {
      sendScreenEvent('catalogScreen', {}, user?.account_id);
    }
  }, [user, isLoadingAtlassianUser, hasError, resources]);

  const fetchNextPage = async (): Promise<void> => {
    setIsLoadingResources(true);
    pageRef.current++;
    const nextResources = await getCatalogResources(
      false,
      urlFilterParameters,
      pageRef.current * MAX_ENTITIES_PER_PAGE,
    );

    if (!nextResources.length) {
      pageRef.current = -1;
    }

    setAllResources((prevResources) => {
      const prevResourceIds = new Set(prevResources.map((resource) => resource.slug));
      const uniqueNextResources = nextResources.filter((resource) => !prevResourceIds.has(resource.slug));

      return [...prevResources, ...uniqueNextResources];
    });

    setIsLoadingResources(false);
  };

  const handleScroll = throttle((): void => {
    const { scrollTop, clientHeight, scrollHeight } = document.documentElement;

    const isNearBottom = scrollTop + clientHeight >= scrollHeight - PAGE_BOTTOM_THRESHOLD;
    const hasMorePages = pageRef.current >= 0;

    const shouldFetchNextPage = isNearBottom && !isLoadingResources && hasMorePages && !isAllResourcesLoaded;

    if (shouldFetchNextPage) {
      fetchNextPage();
    }
  }, 500);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return (): void => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [totalResources, filteredResources]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isLoadingClientSideFilters) {
      fetchNextPage();
    }
  }, [filteredResources]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const getCount = async (): Promise<void> => {
      // Exclude collectionProduct from the urlFilterParameters
      // as it does not affect the counters query and is needed only for the BE query
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
      const { collectionProduct, ...urlFilterParametersWithoutCollectionProduct } = urlFilterParameters;
      const resourceCount = await countAllResources(urlFilterParametersWithoutCollectionProduct);

      setNumberEntitiesPerTaxonomy(resourceCount.taxonomyCount);
      setTotalResources(resourceCount.totalResources);
    };

    getCount();
  }, [urlFilterParameters]);

  const isLoading = isLoadingResources || isLoadingClientSideFilters;

  return (
    <div css={catalogSectionStyles} id="catalog-body" data-testid="catalog-page">
      <div css={sidebarContainerStyles}>
        <Sidebar
          taxonomy={taxonomy}
          numberEntitiesPerTaxonomy={numberEntitiesPerTaxonomy}
          onFilterInteraction={handleFilterInteraction}
          isLoadingCount={totalResources === null}
        />
      </div>
      <main data-testid="main-catalog-page" css={mainStyles} role="main">
        <div css={contentContainerStyles}>
          <Heading level={2}>Learning catalog</Heading>
          <div css={resultsContainerStyles} data-testid="catalog-total-container">
            <span css={filteredResourcesCounterWrapperStyles} data-testid="catalog-total-text">
              <span css={filteredResourcesCounterStyles}>
                {typeof totalResources === 'number' ? totalResources : ' '}
              </span>
              <span css={resultStyles}>{typeof totalResources === 'number' ? 'results' : ' '}</span>
            </span>
            <div css={mobileSidebarContainerStyles}>
              <MobileSidebar
                selectedFiltersLength={selectedFiltersLength}
                taxonomy={taxonomy}
                numberEntitiesPerTaxonomy={numberEntitiesPerTaxonomy}
                onFilterInteraction={handleFilterInteraction}
                isLoadingCount={totalResources === null}
              />
            </div>
          </div>
        </div>
        {filteredResources.length > 0 || isLoading ? (
          <div css={courseListStyles}>
            <CardsList resources={filteredResources} handleCardClick={handleCardClick} />
            {isLoading &&
              times(MIN_TOTAL_ENTITIES_PER_PAGE / 2, (i) => (
                <LearningContentCard
                  key={`loading-tile-${i}`}
                  title=""
                  link=""
                  tags={[]}
                  onClick={() => {}}
                  isLoading
                />
              ))}
          </div>
        ) : (
          <NoResults />
        )}
      </main>
    </div>
  );
};

const catalogSectionStyles = css({
  display: 'flex',
  maxWidth: '1254px',
  padding: '0 16px',
  margin: '172px auto 100px',
  justifyContent: 'space-between',
  gap: '32px',

  '@media (min-width: 768px) and (max-width: 1279px)': {
    margin: '48px auto 100px',
  },

  '@media (max-width: 767px)': {
    gap: 0,
    margin: '48px auto 100px',
  },
});

const sidebarContainerStyles = css({
  position: 'relative',
  flexShrink: 0,
  flexGrow: 0,

  [media.below.md]: {
    display: 'none',
  },
});

const mainStyles = css({
  position: 'relative',
  flexGrow: 1,
});

const contentContainerStyles = css({
  marginBottom: token('space.400'),
});

const mobileSidebarContainerStyles = css({
  display: 'none',

  [media.below.md]: {
    display: 'block',
  },
});

const resultsContainerStyles = css({
  color: N800,
  fontFamily: 'Charlie Display, sans-serif',
  fontSize: '20px',
  fontWeight: 600,
  lineHeight: '28px',
});

const resultStyles = css({
  marginLeft: '3px',
  color: token('color.text.subtlest'),

  [media.below.md]: {
    fontSize: '16px',
    lineHeight: '22px',
  },
});

const filteredResourcesCounterStyles = css({
  [media.below.md]: {
    fontSize: '16px',
    lineHeight: '22px',
  },
});

const filteredResourcesCounterWrapperStyles = css({
  display: 'block',
});

const courseListStyles = css({
  display: 'grid',
  gap: '16px',
  gridTemplateColumns: 'repeat(auto-fill, minmax(292px, 1fr))',

  [media.above.lg]: {
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
  },
});
