import React from 'react';
import { LearningContentCard, LearningContentCardProps } from '@atlassian/learning-components';

import { ResourceType } from 'src/constants';
import { getAssetUrl } from 'src/services/http/user';

export interface ResourceCardProps extends Omit<LearningContentCardProps, 'backgroundPosition' | 'imageUrl'> {
  contentType: ResourceType;
  product?: string;
}

export const ResourceCard: React.FC<ResourceCardProps> = ({
  minHeight,
  tags,
  onClick,
  target,
  link,
  title,
  description,
  onGeneratePdf,
  learningProgress,
  shouldDisplayBookmark,
  shouldDisplayShareButton,
  product = 'atlassian',
  contentType,
}) => {
  const productIcon = getAssetUrl(`/images/logos/${product}.svg`);
  const backgroundDecorationImage = getAssetUrl(`/images/contentCards/${contentType}/${product}.svg`);

  const backgroundColorByContent = {
    [ResourceType.Collection]: '#AF59E0',
    [ResourceType.Certification]: '#123263',
    [ResourceType.LearningPath]: '#6A9A23',
    [ResourceType.SmCourse]: '#1868DB',
    [ResourceType.SmLesson]: '#FCA700',
    [ResourceType.InstructorLedTraining]: '#6C6F77',
  };

  return (
    <LearningContentCard
      title={title}
      link={link}
      onClick={onClick}
      target={target}
      tags={tags}
      imageUrl={backgroundDecorationImage}
      productIcon={productIcon}
      backgroundColor={backgroundColorByContent[contentType]}
      minHeight={minHeight}
      description={description}
      onGeneratePdf={onGeneratePdf}
      learningProgress={learningProgress}
      shouldDisplayBookmark={shouldDisplayBookmark}
      shouldDisplayShareButton={shouldDisplayShareButton}
    />
  );
};
