import React, { useState } from 'react';
import { css } from '@compiled/react';
import { N800 } from '@atlaskit/theme/colors';
import { media } from '@atlaskit/primitives/responsive';
import { token } from '@atlaskit/tokens';
import { Button, ButtonAppearance, ButtonSize, Heading, Text } from '@atlassian/learning-components';

import { ConfirmationMessage } from 'src/pages/HomePage/children/ConfirmationMessage';
import { sendUIEvent } from 'src/utils/analytics';
import { getAssetUrl } from 'src/services/http/user';

export const EmailSubscription: React.FC = () => {
  const [inputValue, setInputValue] = useState('');
  const [isConfirmationMessage, setIsConfirmationMessage] = useState(false);

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>): void => {
    setInputValue(e.currentTarget.value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    setIsConfirmationMessage(true);
    sendUIEvent('homeScreen', 'button', 'clicked', 'signUpEmailButton');
  };

  return (
    <section aria-labelledby="lets-keep-in-touch-heading" data-testid="email-subscription-section" css={sectionStyles}>
      {isConfirmationMessage ? (
        <ConfirmationMessage />
      ) : (
        <div css={emailSubscriptionContainerStyles}>
          <div css={emailSubscriptionFormStyles}>
            <Heading level={3} id="lets-keep-in-touch-heading">
              Let’s keep in touch!
            </Heading>
            <span css={emailSubscriptionParagraphStyles}>
              <Text size="large">
                Be the first to hear about new learning content and other announcements you won’t want to miss
              </Text>
            </span>
            <form onSubmit={handleSubmit} css={subscriptionFormStyles}>
              <div css={emailSubscriptionFormFieldsStyles}>
                <div>
                  <Text color="#44546F" size="small">
                    Email address
                  </Text>
                  <input
                    css={emailSubscriptionInputStyles}
                    type="email"
                    placeholder="yourname@email.com"
                    value={inputValue}
                    onChange={handleInputChange}
                  />
                </div>
                <span css={buttonStyles}>
                  <Button
                    label="Sign up"
                    type="submit"
                    appearance={ButtonAppearance.PRIMARY}
                    size={ButtonSize.LARGE}
                    isResponsive={true}
                  />
                </span>
              </div>
            </form>
          </div>
          <img
            css={emailSubscriptionImgStyles}
            src={getAssetUrl('/images/signupIllustration.svg')}
            alt="Signup illustration"
          />
        </div>
      )}
    </section>
  );
};

const sectionStyles = css({
  padding: `${token('space.1000')} ${token('space.300')}`,

  [media.below.md]: {
    paddingTop: token('space.500'),
  },

  [media.below.sm]: {
    padding: `${token('space.500')} ${token('space.200')}`,
  },
});

const emailSubscriptionContainerStyles = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  maxWidth: '918px',

  [media.below.md]: {
    maxWidth: '500px',
    flexDirection: 'column-reverse',
  },

  [media.below.sm]: {
    maxWidth: '343px !important',
    gap: token('space.200'),
  },
});

const emailSubscriptionFormStyles = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: token('space.300'),
  alignItems: 'flex-start',
  maxWidth: '500px',

  [media.below.md]: {
    width: '100%',
    gap: `${token('space.200')} !important`,
  },
});

const subscriptionFormStyles = css({
  width: '100%',
  marginTop: 0,
});

const emailSubscriptionParagraphStyles = css({
  marginTop: 0,
  marginBottom: 0,
});

const emailSubscriptionFormFieldsStyles = css({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'flex-end',

  [media.below.sm]: {
    flexDirection: 'column',
  },
});

const emailSubscriptionInputStyles = css({
  width: '403px',
  height: '40px',
  padding: '0 0 0 12px',
  border: '1px solid rgba(9, 30, 66, 0.14) !important',
  borderRadius: '4px !important',
  color: N800,
  fontSize: '16px',
  marginTop: '4px',

  '&::placeholder': {
    color: '#626F86',
    fontFamily: 'Inter, sans-serif',
  },

  [media.below.sm]: {
    maxWidth: '343px',
  },
});

const buttonStyles = css({
  marginTop: 0,
  button: {
    width: '85px',
    height: '40px',
  },

  [media.below.sm]: {
    width: '100%',
    button: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    marginTop: token('space.200'),
  },
});

const emailSubscriptionImgStyles = css({
  width: '275px',

  [media.below.md]: {
    marginBottom: '10px',
  },

  [media.below.sm]: {
    width: '181px',
    height: '154px',
    marginBottom: token('space.300'),
  },
});
