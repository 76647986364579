export const SECONDS_IN_MIN = 60;
export const SECONDS_IN_HR = SECONDS_IN_MIN * 60;
export const SECONDS_IN_DAY = SECONDS_IN_HR * 24;

export const timeConverter = (isoDate: Date): string => {
  const date = new Date(isoDate);

  const secondsSinceUpdate = Math.floor((Date.now() - date.getTime()) / 1000);

  const totalHours = Math.floor(secondsSinceUpdate / SECONDS_IN_HR);
  const totalMinutes = Math.floor(secondsSinceUpdate / SECONDS_IN_MIN);
  const totalDays = Math.floor(secondsSinceUpdate / SECONDS_IN_DAY);

  if (totalMinutes < 1) {
    return 'Just now';
  }

  if (totalMinutes >= 1 && totalMinutes < 60) {
    return `${totalMinutes} min ago`;
  }

  if (totalHours >= 1 && totalHours < 24) {
    return `${totalHours} hr ago`;
  }

  if (totalDays <= 31) {
    return `${totalDays} days ago`;
  }

  return new Date(isoDate).toISOString().slice(0, 10);
};
