import { css } from '@compiled/react';
import React, { useContext, useEffect, useState } from 'react';
import { media } from '@atlaskit/primitives/responsive';
import { N800 } from '@atlaskit/theme/colors';
import { useLoaderData, useLocation } from 'react-router-dom';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import { ContentType, formatMinutes, Metadata, useDeviceSize } from '@atlassian/learning-components';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { token } from '@atlaskit/tokens';

import { RelatedContentSection } from 'src/pages/CoursePage/children/RelatedContentSection';
import { LearningPathHeader } from 'src/pages/LearningPathPage/children/LearningPathHeader';
import { useAtlassianUser } from 'src/hooks/useAtlassianUser';
import { getAssetUrl, loginUser } from 'src/services/http/user';
import { HelmetContext } from 'src/components/Helmet';
import { sendScreenEvent } from 'src/utils/analytics';
import { getOptions } from 'src/pages/LessonPage/children/utils/getOptions';
import { getProgressForCourses, ProgressForCourses } from 'src/services/http/progress';
import { useSaveContent } from 'src/hooks/useSaveContent';
import { useSaveRecentlyViewed } from 'src/hooks/useSaveRecentlyViewed';
import { ContentHubType } from 'src/constants';
import { BreadcrumbsContainer } from 'src/components/BreadcrumbsContainer';

import { CourseList } from './children/CourseList';
import { LearningPathLoaderProps } from './loader';
import { getDuration, remapLearningPathTaxonomy } from './children/utils/learningPathUtils';

export const LearningPathPage: React.FC = () => {
  const { learningPath } = useLoaderData() as LearningPathLoaderProps;
  const inspectorProps = useContentfulInspectorMode();
  const { user, isLoading, hasError } = useAtlassianUser();
  const { isContentSaved, saveContent } = useSaveContent({
    contentId: learningPath?.sys.id ?? '',
    contentType: ContentType.LEARNING_PATH,
    user,
  });

  const helmet = useContext(HelmetContext);
  const deviceWidth = useDeviceSize();
  const location = useLocation();

  const [progress, setProgress] = useState<ProgressForCourses | undefined>();
  const [isLoadingProgress, setIsLoadingProgress] = useState<boolean>(false);
  const [isBreadcrumbVisible, setIsBreadcrumbVisible] = useState(false);

  helmet.setHelmet({
    title: `${learningPath.title} | Learning - Atlassian Community`,
    seoTags: learningPath.seo,
  });

  const learningPathDuration = formatMinutes(
    learningPath?.coursesCollection.items.reduce((acc, course) => acc + getDuration(course), 0),
  );

  const isAllLearningPathsHubIncluded = location.pathname?.includes(`hub/${ContentHubType.LEARNING_PATHS}`);

  useEffect(() => {
    if (!isLoading && !hasError && learningPath) {
      sendScreenEvent(
        'learningPathScreen',
        {
          learningPathSlug: learningPath.slug,
        },
        user?.account_id,
      );
    }
  }, [user, isLoading, hasError, learningPath]);

  useEffect(() => {
    const fetchProgressForCourses = async (): Promise<void> => {
      if (user) {
        setIsLoadingProgress(true);
        setProgress(await getProgressForCourses(learningPath.coursesCollection.items.map((c) => c.sys.id)));
        setIsLoadingProgress(false);
      }
    };

    fetchProgressForCourses();
  }, [learningPath, user]);

  useEffect(() => {
    setIsBreadcrumbVisible(isAllLearningPathsHubIncluded);
  }, [location.pathname, isAllLearningPathsHubIncluded]);

  useSaveRecentlyViewed(learningPath?.sys.id, ContentType.LEARNING_PATH);

  return (
    <>
      <main css={learningPathMainSectionStyles} id="learning-path-body" data-testid="learning-path-page">
        {isBreadcrumbVisible && <BreadcrumbsContainer learningPath={learningPath} course={null} lessonSlug={null} />}
        <div
          css={[
            headerContainerStyles,
            isBreadcrumbVisible && breadCrumbsStyles,
            !isBreadcrumbVisible && navigationMarginStyles,
          ]}
          style={
            {
              '--desktop-image': `url(${getAssetUrl('/images/contentBanners/learningPathBackgroundImage.svg')})`,
            } as Record<string, string>
          }
        >
          <div css={sharedContainerStyles}>
            <LearningPathHeader
              learningPath={learningPath}
              user={user}
              isLoading={isLoading}
              onLoginUser={loginUser}
              onSave={saveContent}
              isLearningPathSaved={isContentSaved}
              progress={progress}
              isLoadingProgress={isLoadingProgress}
            />
          </div>
          <div css={[learningPathContainerStyles, css({ marginBottom: '-64px' })]}>
            <Metadata fields={remapLearningPathTaxonomy(learningPath, learningPathDuration)} />
          </div>
        </div>
        <div css={learningPathContainerStyles}>
          {learningPath?.learningPathSummary && (
            <section
              {...inspectorProps({ entryId: learningPath.sys.id, fieldId: 'overview' })}
              data-testid="learning-path-summary"
              css={learningPathOverviewStyles}
            >
              <div css={markdownContainerStyles}>
                {documentToReactComponents(
                  learningPath.learningPathSummary.json,
                  getOptions(learningPath.learningPathSummary, deviceWidth),
                )}
              </div>
            </section>
          )}
          <CourseList
            learningPath={learningPath}
            user={user}
            isLoading={isLoading}
            progress={progress}
            isLoadingProgress={isLoadingProgress}
          />
        </div>
        <RelatedContentSection relatedContent={learningPath?.relatedContent} />
      </main>
    </>
  );
};

const learningPathMainSectionStyles = css({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const navigationMarginStyles = css({
  marginTop: '136px',

  '@media (max-width: 1279px)': {
    marginTop: '0',
  },

  '@media (max-width: 768px)': {
    marginTop: '0',
  },
});

const breadCrumbsStyles = css({
  marginTop: '192px',

  '@media (max-width: 1279px)': {
    marginTop: '68px',
  },

  '@media (max-width: 768px)': {
    marginTop: '56px',
  },
});

const headerContainerStyles = css({
  backgroundColor: '#D3F1A7',
  marginBottom: '64px',
  width: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundPositionX: 'right',
  backgroundImage: 'var(--desktop-image)',

  [media.below.md]: {
    backgroundImage: 'none',
  },
});

const sharedContainerStyles = css({
  maxWidth: '846px',
  width: '100%',
  margin: '0 auto',

  [media.below.lg]: {
    maxWidth: 'unset',
    paddingTop: '32px',
    paddingLeft: '24px',
    paddingRight: '24px',
  },

  [media.below.sm]: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
});

const learningPathContainerStyles = css({
  maxWidth: '846px',
  width: '100%',
  margin: '0 auto',

  [media.below.lg]: {
    maxWidth: 'unset',
    paddingLeft: '24px',
    paddingRight: '24px',
  },

  [media.below.sm]: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
});

const learningPathOverviewStyles = css({
  width: '100%',
  marginTop: '64px',
  marginBottom: '56px',
  color: N800,

  p: {
    margin: '16px 0',
    fontFamily: 'Inter, sans-serif',
    fontWeight: '400',
    fontSize: '20px',
    lineHeight: '32px',
    color: N800,
  },
  ul: {
    fontFamily: 'Inter, sans-serif',
    listStyleType: 'disc',
    paddingLeft: '30px !important',
    color: N800,
  },
  'ul li': {
    fontSize: '20px',
    marginTop: '4px',
    lineHeight: '32px',

    '&:first-child': {
      marginTop: 0,
    },
  },
  'ol li': {
    fontSize: '20px',
    lineHeight: '32px',
    marginTop: '4px',

    '&:first-child': {
      marginTop: 0,
    },
  },
  'ul ul': {
    listStyleType: 'circle',
  },
  'ul ul ul': {
    listStyleType: 'square',
  },
  ol: {
    listStyleType: 'decimal',
    paddingLeft: '30px !important',
    color: N800,
  },
  'ol ol': {
    listStyleType: 'lower-alpha',
  },
  'ol ol ol': {
    listStyleType: 'lower-roman',
  },
  a: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '20px',
    color: token('color.text.accent.blue'),
    textUnderlineOffset: '4px',
    textDecorationThickness: '2px',
    textDecorationColor: token('color.link'),
    textDecorationLine: 'none',
    cursor: 'pointer',
    ':hover': {
      color: token('color.link'),
      textDecorationLine: 'underline',
    },
  },

  [media.below.sm]: {
    p: {
      fontSize: '16px',
      lineHeight: '24px',
    },
    ul: {
      paddingLeft: '20px !important',
    },
    ol: {
      paddingLeft: '20px !important',
    },
    'ul li': {
      fontSize: '14px',
      lineHeight: '20px',
    },
    'ol li': {
      fontSize: '14px',
      lineHeight: '20px',
    },
  },

  h3: {
    fontFamily: 'Charlie Display, sans-serif',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '32px',
    color: N800,
    margin: 0,

    [media.above.sm]: {
      fontSize: '32px',
      lineHeight: '40px',
    },
  },

  hr: {
    margin: '16px 0',
  },
});

const markdownContainerStyles = css({
  maxWidth: '846px',
  margin: '0 auto',

  [media.below.lg]: {
    maxWidth: 'unset',
  },
});
