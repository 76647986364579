import { isProduction } from 'src/services/http/user';

export enum Opinion {
  SATISFIED = 'Satisfied',
  NOT_SATISFIED = 'Not satisfied',
}

export const QUICK_FEEDBACK_URL =
  'https://jsd-widget.atlassian.com/api/embeddable/67d937a3-0a8c-4eea-b8dd-a1c5f16c9ee8/request?requestTypeId=11';
export const QUICK_FEEDBACK_URL_DEV =
  'https://jsd-widget.atlassian.com/api/embeddable/298dd788-f8ab-43d2-916c-4ac084fb6125/request?requestTypeId=21';

interface SubmitQuickFeedbackArgs {
  opinion: string;
  thoughts?: string;
  selectedOption?: string;
  lessonSlug: string;
  learningPathSlug?: string;
  courseSlug: string;
}
export const submitQuickFeedback = (feedback: SubmitQuickFeedbackArgs): Promise<Response> => {
  const fields = [
    {
      id: 'summary',
      value: `${feedback.opinion}`,
    },
    {
      id: 'customfield_10049',
      value: `${feedback.opinion === Opinion.SATISFIED ? Opinion.SATISFIED : Opinion.NOT_SATISFIED}`,
    },
    feedback.selectedOption && {
      id: 'customfield_10051',
      value: feedback.selectedOption,
    },
    {
      id: 'customfield_10052',
      value: feedback.thoughts,
    },
    {
      id: 'customfield_10053',
      value: feedback.lessonSlug,
    },
    {
      id: 'customfield_10055',
      value: feedback.courseSlug,
    },
    {
      id: 'customfield_10056',
      value: feedback.learningPathSlug,
    },
    {
      id: 'email',
      value: 'do-not-reply@atlassian.com',
    },
  ].filter(Boolean);

  const quickFeedbackObj = { fields };

  const feedbackObjJSON = JSON.stringify(quickFeedbackObj);

  const url = isProduction() ? QUICK_FEEDBACK_URL : QUICK_FEEDBACK_URL_DEV;

  return fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: '*/*',
      'Content-Type': 'application/json',
    },
    body: feedbackObjJSON,
  });
};
