import React from 'react';
import { css } from '@compiled/react';
import { FlatContentCard, FlatContentCardType, Heading } from '@atlassian/learning-components';
import { useNavigate } from 'react-router-dom';
import { media } from '@atlaskit/primitives/responsive';
import { N0 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import { useAtlassianUser } from 'src/hooks/useAtlassianUser';
import { sendUIEvent } from 'src/utils/analytics';
import { getAssetUrl, getUrlPrefix } from 'src/services/http/user';

export const CoursesByProductSection: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAtlassianUser();

  const onCardLinkClick = (cardTitle: string): void => {
    sendUIEvent(
      'homeScreen',
      'link',
      'clicked',
      'cardLink',
      {
        contentBlockTitle: 'Focused learning, quick results',
        cardTitle,
      },
      user?.account_id,
    );
  };

  return (
    <section data-testid="courses-by-product-section" css={sectionContainerStyles}>
      <span css={headingStyles}>
        <Heading level={2}>Learning by product</Heading>
      </span>
      <div data-testid="courses-by-product-section-cards" css={cardsContainerStyles}>
        <FlatContentCard
          bgColor={N0}
          image={{ src: getAssetUrl('/images/homePage/jira.svg'), alt: 'jira icon' }}
          title="Jira"
          link={`${getUrlPrefix()}/catalog?product=Jira`}
          type={FlatContentCardType.SQUARE}
          onClick={(title) => {
            onCardLinkClick(title);
            navigate('/catalog?product=Jira');
          }}
        />
        <FlatContentCard
          bgColor={N0}
          image={{ src: getAssetUrl('/images/homePage/confluence.svg'), alt: 'confluence icon' }}
          title="Confluence"
          link={`${getUrlPrefix()}/catalog?product=Confluence`}
          type={FlatContentCardType.SQUARE}
          onClick={(title) => {
            onCardLinkClick(title);
            navigate('/catalog?product=Confluence');
          }}
        />
        <FlatContentCard
          bgColor={N0}
          image={{
            src: getAssetUrl('/images/homePage/jiraServiceManagement.svg'),
            alt: 'jira service management icon',
          }}
          title="Jira Service Management"
          link={`${getUrlPrefix()}/catalog?product=Jira+Service+Management`}
          type={FlatContentCardType.SQUARE}
          onClick={(title) => {
            onCardLinkClick(title);
            navigate('/catalog?product=Jira+Service+Management');
          }}
        />
      </div>
    </section>
  );
};

const sectionContainerStyles = css({
  width: '100%',
  backgroundColor: '#F8F8F8',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: `${token('space.1000')} ${token('space.300')}`,

  [media.below.sm]: {
    padding: `56px ${token('space.200')}`,
  },
});

const headingStyles = css({
  marginBottom: `${token('space.500')}`,

  [media.below.sm]: {
    marginBottom: `${token('space.300')}`,
  },
});

const cardsContainerStyles = css({
  width: '100%',
  maxWidth: '1022px',
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  columnGap: token('space.300'),
  rowGap: '0',

  [media.below.md]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
    rowGap: token('space.300'),
  },

  [media.below.sm]: {
    gridTemplateColumns: 'auto !important',
  },
});
