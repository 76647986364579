import { gql } from 'graphql-tag';

import { GetAllMyLearningEntriesQuery } from 'src/graphql/generated/graphql';

export const GET_ALL_MY_LEARNING_ENTRIES = gql`
  query GetAllMyLearningEntries($contentfulIds: [String]!) {
    learningPathCollection(where: { OR: [{ sys: { id_in: $contentfulIds } }] }) {
      items {
        sys {
          id
        }
        title
        slug
        product
      }
    }
    smCourseCollection(where: { OR: [{ sys: { id_in: $contentfulIds } }] }) {
      items {
        sys {
          id
        }
        title
        slug
        reward {
          badge
          badgeCdnUrl
        }
        product
      }
    }
    smLessonCollection(where: { OR: [{ sys: { id_in: $contentfulIds } }] }) {
      items {
        sys {
          id
        }
        title
        slug
      }
    }
  }
`;

export type LearningPathEntry = GetAllMyLearningEntriesQuery['learningPathCollection']['items'][0];
export type CourseEntry = GetAllMyLearningEntriesQuery['smCourseCollection']['items'][0];
export type LessonEntry = GetAllMyLearningEntriesQuery['smLessonCollection']['items'][0];
export type CollectedDataItem = LearningPathEntry | CourseEntry | LessonEntry;
export type MatchingDataItem = LearningPathEntry | CourseEntry;
