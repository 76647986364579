import React, { useContext, useEffect } from 'react';
import { css } from '@compiled/react';
import { media } from '@atlaskit/primitives/responsive';
import { token } from '@atlaskit/tokens';
import { useLoaderData } from 'react-router-dom';

import { sendScreenEvent } from 'src/utils/analytics';
import { useAtlassianUser } from 'src/hooks/useAtlassianUser';
import { HelmetContext } from 'src/components/Helmet';
import { HeroSection } from 'src/pages/hubs/RoleBasedPage/children/HeroSection';
import { ContentBlock } from 'src/pages/hubs/RoleBasedPage/children/ContentBlock';
import { RoleBasedHubLoaderProps } from 'src/pages/hubs/RoleBasedPage/loader';
import { ContentBlockData } from 'src/graphql/queries/GetContentHub';

export const RoleBasedPage: React.FC = () => {
  const { user, isLoading, hasError } = useAtlassianUser();
  const helmet = useContext(HelmetContext);
  const { roleBasedHubData } = useLoaderData() as RoleBasedHubLoaderProps;
  const contentBlockData: ContentBlockData = roleBasedHubData.linkedContentBlockCollection.items?.length
    ? roleBasedHubData.linkedContentBlockCollection.items
    : [];

  helmet.setHelmet({
    title: `${roleBasedHubData.titleExternal} | Learning - Atlassian Community`,
    seoTags: roleBasedHubData.seo,
  });

  useEffect(() => {
    if (!isLoading && !hasError) {
      sendScreenEvent('roleBasedHubScreen', {}, user?.account_id);
    }
  }, [user, isLoading, hasError]);

  return (
    <main data-testid="role-based-hub" css={pageContainerStyles}>
      <HeroSection
        title={roleBasedHubData.hero.titleExternal}
        subtitle={roleBasedHubData.hero.subtitle}
        eyebrowText={roleBasedHubData.hero.eyebrowText}
      />
      <div css={pageContentContainerStyles}>
        {!!roleBasedHubData.linkedContentBlockCollection.items.length && (
          <ContentBlock
            key={contentBlockData[0].sys.id}
            title={contentBlockData[0].titleExternal}
            associatedContent={contentBlockData[0].associatedContentResourcesCollection.items}
          />
        )}
      </div>
    </main>
  );
};

const pageContainerStyles = css({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '136px',

  '@media (max-width: 1279px)': {
    marginTop: '0',
  },
});

const pageContentContainerStyles = css({
  width: '100%',
  padding: `0 ${token('space.300')}`,
  backgroundColor: '#F8F8F8',

  [media.below.xs]: {
    padding: `0 ${token('space.200')}`,
  },
});
