import { gql } from 'graphql-tag';

export const AT_IMAGE_FRAGMENT = gql`
  fragment AtImageFragment on AtImage {
    __typename
    sys {
      id
    }
    name
    image {
      contentType
      url
      size
    }
    center
    caption
    format
    width
    height
    brandfolderUrl
    cdnUrl
    altText
  }
`;

export const OR_VIDEO_FRAGMENT = gql`
  fragment OrVideoFragment on OrVideo {
    __typename
    name
    brandfolderUrl
    cdnUrl
    captionCdnUrl
    youtubeUrl
    vimeoUrl
    captionFile
    description
    videoHeight: height
    videoWidth: width
    chaptersCollection {
      items {
        chapterTitle
        timeStamp
      }
    }
  }
`;

export const ML_NAVTAB_FRAGMENT = gql`
  fragment MlNavtabFragment on MlNavtab {
    __typename
    sys {
      id
    }
    name
    tabsLabel
    size
    contentCollection(limit: 20) {
      items {
        ...MlLayoutContentFragment
      }
    }
  }
`;

export const ML_ACCORDION_FRAGMENT = gql`
  fragment MlAccordionFragment on MlAccordion {
    name
    size
    tabsLabel
    shouldSingleTabBeOpen
    tabsContentCollection(limit: 20) {
      items {
        ...MlLayoutContentFragment
      }
    }
  }
`;

export const ML_LAYOUT_CONTENT_FRAGMENT = gql`
  fragment MlLayoutContentFragment on MlLayoutContent {
    __typename
    sys {
      id
    }
    name
    content {
      __typename
      json
      links {
        entries {
          inline {
            ...EntryLayoutFragment
          }
          block {
            ...EntryLayoutFragment
          }
        }
      }
    }
  }
`;

export const ENTRY_LAYOUT_FRAGMENT = gql`
  fragment EntryLayoutFragment on Entry {
    __typename
    sys {
      id
    }

    ... on MlSectionMessage {
      description
      appearance
    }

    ... on OrVideo {
      name
      brandfolderUrl
      youtubeUrl
      vimeoUrl
      cdnUrl
      captionCdnUrl
      captionFile
      description
      videoHeight: height
      videoWidth: width
      chaptersCollection {
        items {
          chapterTitle
          timeStamp
        }
      }
    }

    ... on AtImage {
      ...AtImageFragment
    }
    ... on MlLozenge {
      text
      appearance
      isBold
    }
    ... on AtIcon {
      name
      type
      size
      color
      alt
      rotation
    }
    ... on MlInlineCode {
      name
      code
    }
  }
`;

export const ENTRY_FRAGMENT = gql`
  fragment EntryFragment on Entry {
    __typename
    sys {
      id
    }
    ... on AtImage {
      ...AtImageFragment
    }

    ... on MlSectionMessage {
      description
      appearance
    }

    ... on MlLozenge {
      text
      appearance
      isBold
    }
    ... on MlNavtab {
      ...MlNavtabFragment
    }
    ... on MlAccordion {
      ...MlAccordionFragment
    }
    ... on AtIcon {
      name
      type
      size
      color
      alt
      rotation
    }
    ... on MlInlineCode {
      name
      code
    }
    ... on OrLayout {
      name
      columns
      columnContentCollection(limit: 4) {
        items {
          ...MlLayoutContentFragment
        }
      }
    }
    ... on OrLabelledGraphic {
      name
      image {
        ...AtImageFragment
      }
      dataMarkers
      pulseAnimationColor
    }
    ... on OrVideo {
      name
      brandfolderUrl
      captionCdnUrl
      youtubeUrl
      vimeoUrl
      cdnUrl
      captionFile
      description
      videoHeight: height
      videoWidth: width
      chaptersCollection {
        items {
          chapterTitle
          timeStamp
        }
      }
    }
  }
`;

export const OVERVIEW_ENTRY_BLOCK_FRAGMENT = gql`
  fragment OverviewEntryBlockFragment on Entry {
    __typename
    sys {
      id
    }
    ... on AtImage {
      ...AtImageFragment
    }
    ... on OrVideo {
      ...OrVideoFragment
    }
  }
`;

export const OVERVIEW_ENTRY_INLINE_FRAGMENT = gql`
  fragment OverviewEntryInlineFragment on Entry {
    __typename
    sys {
      id
    }
    ... on AtImage {
      ...AtImageFragment
    }
  }
`;

export const OR_LESSON_SECTION_CONTAINER_FRAGMENT = gql`
  fragment OrLessonSectionContainerFragment on OrLessonSectionContainer {
    __typename
    sys {
      id
    }
    backgroundColor
    title
    id
    centeredSection
    className
    children {
      __typename
      json
      links {
        assets {
          hyperlink {
            sys {
              id
            }
            title
            description
            fileName
            url
          }
        }
        entries {
          block {
            ...EntryFragment
          }
          inline {
            ...EntryFragment
          }
        }
      }
    }
  }
`;

export const OR_QUIZ_FRAGMENT = gql`
  fragment SmQuizFragment on SmQuiz {
    __typename
    sys {
      id
    }
    title
    # TODO: add banner
    questionsCollection(limit: 100) {
      items {
        __typename
        sys {
          id
        }
        # TODO: download a real schema and generate types based on it after a migration
        title {
          json
        }
        image {
          ...AtImageFragment
        }
        type
        feedbackMessage {
          json
        }
        answersCollection(limit: 10) {
          items {
            __typename
            sys {
              id
            }
            title
            feedback
            correct
            matchingAnswer {
              __typename
              sys {
                id
              }
              title
            }
            # TODO: add matching answer
          }
        }
      }
    }
  }
`;

export const RELATED_CONTENT = gql`
  fragment RelatedContentFragment on RelatedContent {
    __typename
    sys {
      id
    }
    relatedTitle
    relatedContentDescription
    recommendedResourcesCollection {
      items {
        ... on SmCourse {
          __typename
          title
          slug
          sys {
            id
          }
          product
        }
        ... on SmLesson {
          __typename
          title
          slug
          sys {
            id
          }
          products
          linkedFrom {
            smCourseCollection {
              items {
                sys {
                  id
                }
                title
                slug
              }
            }
          }
        }
        ... on LearningPath {
          __typename
          title
          slug
          sys {
            id
          }
          product
        }
      }
    }
  }
`;

export const SEO_FRAGMENT = gql`
  fragment SeoFragment on Seo {
    __typename
    sys {
      id
    }
    description
    robots
    canonicalUrl
    openGraphImage {
      title
      description
      contentType
      url
      size
    }
    openGraphType
    structuredData
  }
`;

export const GET_ALL_COURSES_SLUGS_FRAGMENT = gql`
  fragment GetAllSlugsCoursesFragment on SmCourse {
    slug
    seo {
      canonicalUrl
      robots
    }
    lessonsCollection(where: { sys: { id_exists: true } }) {
      items {
        slug
        name
        seo {
          canonicalUrl
          robots
        }
      }
    }
  }
`;

export const HERO_FRAGMENT = gql`
  fragment GetHeroFragment on MarketingHero {
    __typename
    sys {
      id
    }
    nameInternal
    titleExternal
    subtitle
    eyebrowText
    asset {
      ...AtImageFragment
    }
  }
`;

export const ASSOCIATED_CONTENT_RESOURCES_COLLECTION_FRAGMENT = gql`
  fragment GetAssociatedContentResourcesCollectionFragment on MarketingContentBlockAssociatedContentResourcesCollection {
    __typename
    items {
      sys {
        id
      }
      nameInternal
      titleExternal
      description
      asset {
        ...AtImageFragment
      }
      link {
        ... on AtLink {
          sys {
            id
          }
          label
          target
          link
          icon {
            ... on AtIcon {
              name
              type
              size
              color
              alt
              rotation
            }
          }
          style
        }
      }
    }
  }
`;

export const MARKETING_CONTENT_BLOCK_FRAGMENT = gql`
  fragment GetMarketingContentBlockFragment on MarketingContentBlock {
    __typename
    sys {
      id
    }
    nameInternal
    titleExternal
    subtitle
    asset {
      ...AtImageFragment
    }
    associatedContentResourcesCollection(limit: 100, preview: $preview, where: { sys: { id_exists: true } }) {
      ...GetAssociatedContentResourcesCollectionFragment
    }
  }
`;

export const LINKED_CONTENT_BLOCK_COLLECTION_FRAGMENT = gql`
  fragment GetLinkedContentBlockCollectionFragment on ContentHubLinkedContentBlockCollection {
    __typename
    items {
      ...GetMarketingContentBlockFragment
    }
  }
`;

export const BENEFIT_FRAGMENT = gql`
  fragment GetBenefitFragment on Benefit {
    __typename
    sys {
      id
    }
    nameInternal
    titleExternal
    description {
      json
      links {
        entries {
          inline {
            ...EntryLayoutFragment
          }
          block {
            ...EntryLayoutFragment
          }
        }
      }
    }
    asset {
      ...AtImageFragment
    }
  }
`;

export const BENEFITS_CONTAINER_FRAGMENT = gql`
  fragment GetBenefitsContainerFragment on BenefitsContainer {
    __typename
    sys {
      id
    }
    nameInternal
    titleExternal
    benefitsCollection(limit: 100, preview: $preview, where: { sys: { id_exists: true } }) {
      items {
        ...GetBenefitFragment
      }
    }
  }
`;

export const STATISTIC_FRAGMENT = gql`
  fragment GetStatisticFragment on Statistic {
    __typename
    sys {
      id
    }
    nameInternal
    titleExternal
    stat
    statDescription {
      json
      links {
        entries {
          inline {
            ...EntryLayoutFragment
          }
          block {
            ...EntryLayoutFragment
          }
        }
      }
    }
    asset {
      ...AtImageFragment
    }
  }
`;

export const FAQ_CONTAINER_FRAGMENT = gql`
  fragment GetFAQContainerFragment on FaqContainer {
    __typename
    sys {
      id
    }
    nameInternal
    titleExternal
    questionsCollection(limit: 100, preview: $preview, where: { sys: { id_exists: true } }) {
      items {
        ...GetFAQFragment
      }
    }
  }
`;

export const FAQ_FRAGMENT = gql`
  fragment GetFAQFragment on Faq {
    __typename
    sys {
      id
    }
    nameInternal
    titleExternal
    answer {
      json
      links {
        entries {
          inline {
            ...EntryLayoutFragment
          }
          block {
            ...EntryLayoutFragment
          }
        }
      }
    }
  }
`;

export const RESOURCE_FRAGMENT = gql`
  fragment GetResourceFragment on Resource {
    __typename
    sys {
      id
    }
    nameInternal
    titleExternal
    description
    link
    ctaText
  }
`;

export const SOCIAL_PROOF_FRAGMENT = gql`
  fragment GetSocialProofFragment on SocialProof {
    __typename
    sys {
      id
    }
    nameInternal
    titleExternal
    customerName
    customerRole
    quote {
      json
      links {
        entries {
          inline {
            ...EntryLayoutFragment
          }
          block {
            ...EntryLayoutFragment
          }
        }
      }
    }
    customerHeadshot {
      ...AtImageFragment
    }
  }
`;

export const SALARY_FRAGMENT = gql`
  fragment GetSalaryFragment on RoleSalary {
    __typename
    sys {
      id
    }
    nameInternal
    salary
    referenceText
    reference {
      ... on AtLink {
        sys {
          id
        }
        label
        target
        link
        icon {
          ... on AtIcon {
            name
            type
            size
            color
            alt
            rotation
          }
        }
        style
      }
    }
  }
`;

export const REUSABLE_CONTENT_SECTION_FRAGMENT = gql`
  fragment GetReusableContentSectionFragment on ReusableContentSection {
    __typename
    sys {
      id
    }
    contentSectionNameInternal
    contentSectionTitle {
      contentSectionTitleNameInternal
      contentSectionTitleExternal
      contentSectionSubtitle
      asset {
        ...AtImageFragment
      }
    }
    contentLinksCollection(limit: 100, preview: $preview, where: { sys: { id_exists: true } }) {
      items {
        ... on AssociatedContent {
          __typename
          sys {
            id
          }
          nameInternal
          titleExternal
          description
          link {
            ... on AtLink {
              sys {
                id
              }
              label
              target
              link
              icon {
                ... on AtIcon {
                  name
                  type
                  size
                  color
                  alt
                  rotation
                }
              }
              style
            }
          }
          asset {
            ...AtImageFragment
          }
        }
      }
    }
  }
`;
