import { gql } from 'graphql-tag';

import {
  AT_IMAGE_FRAGMENT,
  SALARY_FRAGMENT,
  SEO_FRAGMENT,
  REUSABLE_CONTENT_SECTION_FRAGMENT,
} from 'src/graphql/fragments/fragments';
import { GetRoleCollectionQuery } from 'src/graphql/generated/graphql';

export const GET_ROLE_COLLECTION = gql`
  ${AT_IMAGE_FRAGMENT}
  ${SALARY_FRAGMENT}
  ${SEO_FRAGMENT}
  ${REUSABLE_CONTENT_SECTION_FRAGMENT}
  query getRoleCollection($slug: String!, $preview: Boolean!) {
    roleCollection(preview: $preview, limit: 1, where: { slug: $slug }) {
      __typename
      items {
        nameInternal
        titleExternal
        slug
        icon {
          ...AtImageFragment
        }
        description
        similarRoleTitles
        salary {
          ...GetSalaryFragment
        }
        onDemandContentCollection {
          items {
            ...GetReusableContentSectionFragment
          }
        }
        iltContent {
          ...GetReusableContentSectionFragment
        }
        certificationContent {
          ...GetReusableContentSectionFragment
        }
        groupContent {
          ...GetReusableContentSectionFragment
        }
        eventContent {
          ...GetReusableContentSectionFragment
        }
        contentOverrideContainerCollection {
          items {
            ...GetReusableContentSectionFragment
          }
        }
        contentSectionOrder
        seo {
          ...SeoFragment
        }
      }
    }
  }
`;

export type GetRoleCollectionResponse = GetRoleCollectionQuery;
export type ContentfulRole = GetRoleCollectionQuery['roleCollection']['items'][0];
export type Asset = ContentfulRole['icon'];
export type ContentSectionOrder = ContentfulRole['contentSectionOrder'];
