import React from 'react';
import { css } from '@compiled/react';
import { media } from '@atlaskit/primitives/responsive';
import { token } from '@atlaskit/tokens';
import { Button, ButtonAppearance, ButtonSize, Heading, Text } from '@atlassian/learning-components';
import { useNavigate } from 'react-router-dom';
import { N0 } from '@atlaskit/theme/colors';

import { LandingType } from 'src/constants';
import { useAtlassianUser } from 'src/hooks/useAtlassianUser';
import { sendUIEvent } from 'src/utils/analytics';
import { getAssetUrl } from 'src/services/http/user';

export const LiveTrainingSection: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAtlassianUser();

  const onButtonClick = (): void => {
    navigate(`/landing/${LandingType.LIVE_TRAINING}`);
    sendUIEvent('homeScreen', 'button', 'clicked', 'findOutHowButton', {}, user?.account_id);
  };

  return (
    <section
      data-testid="live-training-section"
      style={
        {
          '--desktop-bg': `url(${getAssetUrl('/images/homePage/liveTrainingSectionImage.png')})`,
        } as Record<string, string>
      }
      css={sectionContainerStyles}
    >
      <img
        data-testid="live-training-section-image"
        css={imageContainerStyles}
        src={getAssetUrl('/images/homePage/liveTrainingIcon.svg')}
        alt="live training icon"
      />
      <span css={headingStyles}>
        <Heading level={2} color={N0}>
          Live training
        </Heading>
      </span>
      <span css={textStyles}>
        <Text size="large" color={N0}>
          Train with your team, enroll in public classes, or join community-led classes with like-minded peers.
        </Text>
      </span>
      <span css={buttonStyles}>
        <Button
          label="Explore live training"
          size={ButtonSize.LARGE}
          appearance={ButtonAppearance.PRIMARY}
          isResponsive={true}
          onClick={onButtonClick}
        />
      </span>
    </section>
  );
};

const sectionContainerStyles = css({
  width: '100%',
  height: '504px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  backgroundPosition: 'bottom',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundImage: 'var(--desktop-bg)',
  padding: `0 ${token('space.300')}`,

  [media.below.sm]: {
    padding: `0 ${token('space.200')}`,
  },
});

const imageContainerStyles = css({
  width: '120px',
  height: '120px',
  marginBottom: token('space.100'),
});

const headingStyles = css({
  marginBottom: token('space.200'),
});

const textStyles = css({
  marginBottom: token('space.300'),
  maxWidth: '720px',
});

const buttonStyles = css({
  color: N0,
});
