import React from 'react';
import { Divider, FlatContentCard, FlatContentCardProps, FlatContentCardStyle } from '@atlassian/learning-components';
import { token } from '@atlaskit/tokens';
import { css } from '@compiled/react';
import { media } from '@atlaskit/primitives/responsive';

type ContentCardProps = FlatContentCardProps & {
  showTopDivider?: boolean;
};

export const ContentCard: React.FC<ContentCardProps> = ({
  showTopDivider = true,
  style = FlatContentCardStyle.TALL,
  ...props
}) => (
  <div css={containerStyles}>
    {showTopDivider && (
      <div css={topDividerWrapperStyles}>
        <Divider color={token('color.border')} />
      </div>
    )}
    <FlatContentCard style={style} {...props} />
    <Divider color={token('color.border')} />
  </div>
);

const containerStyles = css({
  width: '100%',
});

const topDividerWrapperStyles = css({
  [media.below.lg]: {
    display: 'none',
  },
});
