import React, { useContext, useEffect, useState } from 'react';
import { useLoaderData, useLocation } from 'react-router-dom';
import { ContentType, Divider, formatMinutes, Metadata, useDeviceSize } from '@atlassian/learning-components';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import { css } from '@compiled/react';
import { N50, N800 } from '@atlaskit/theme/colors';
import { media } from '@atlaskit/primitives/responsive';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { token } from '@atlaskit/tokens';

import { HelmetContext } from 'src/components/Helmet';
import { useAtlassianUser } from 'src/hooks/useAtlassianUser';
import { sendScreenEvent } from 'src/utils/analytics';
import { getAssetUrl, loginUser } from 'src/services/http/user';
import { getDuration } from 'src/pages/LearningPathPage/children/utils/learningPathUtils';
import { getOptions } from 'src/pages/LessonPage/children/utils/getOptions';
import { useSaveRecentlyViewed } from 'src/hooks/useSaveRecentlyViewed';
import { getProgressForLessons, ProgressForLessons } from 'src/services/http/progress';
import { useSaveContent } from 'src/hooks/useSaveContent';
import { ContentHubType } from 'src/constants';
import { BreadcrumbsContainer } from 'src/components/BreadcrumbsContainer';

import { CourseHeader } from './children/CourseHeader';
import { LessonList } from './children/LessonList';
import { RelatedContentSection } from './children/RelatedContentSection';
import { CourseLoaderProps } from './loader';
import { remapCourseTaxonomy } from './children/utils/courseUtils';

export const CoursePage: React.FC = () => {
  const { course, learningPath } = useLoaderData() as CourseLoaderProps;
  const { user, isLoading, hasError } = useAtlassianUser();
  const inspectorProps = useContentfulInspectorMode();
  const deviceWidth = useDeviceSize();
  const location = useLocation();

  const helmet = useContext(HelmetContext);

  const [isBreadcrumbVisible, setIsBreadcrumbVisible] = useState(false);
  const [progress, setProgress] = useState<ProgressForLessons | undefined>();
  const [isLoadingProgress, setIsLoadingProgress] = useState<boolean>(false);
  const { isContentSaved, saveContent } = useSaveContent({
    contentId: course?.sys.id,
    contentType: ContentType.COURSE,
    learningPathId: learningPath?.sys.id,
    user,
  });

  helmet.setHelmet({
    title: `${course.title} | Learning - Atlassian Community`,
    seoTags: course.seo,
  });

  const isAllLearningPathsHubIncluded = location.pathname?.includes(`hub/${ContentHubType.LEARNING_PATHS}`);
  const isLearningPathIncluded = location.pathname?.includes('path');

  useEffect(() => {
    if (!isLoading && !hasError && course) {
      sendScreenEvent('courseScreen', { courseSlug: course.slug }, user?.account_id);
    }
  }, [user, isLoading, hasError, course]);

  useEffect(() => {
    const fetchProgressForLessons = async (): Promise<void> => {
      if (user) {
        setIsLoadingProgress(true);
        setProgress(await getProgressForLessons(course.lessonsCollection.items.map((l) => l.sys.id)));
        setIsLoadingProgress(false);
      }
    };

    fetchProgressForLessons();
  }, [course, user]);

  useEffect(() => {
    setIsBreadcrumbVisible(isAllLearningPathsHubIncluded || isLearningPathIncluded);
  }, [location.pathname, isAllLearningPathsHubIncluded, isLearningPathIncluded]);

  // Send a request to add the course to the list of recently viewed section when the user is redirected or has left the page
  useSaveRecentlyViewed(course?.sys.id, ContentType.COURSE, learningPath?.sys.id);

  const courseDuration = formatMinutes(getDuration(course));

  return (
    <>
      <main css={mainSectionStyles} id="course-body" data-testid="course-page">
        {isBreadcrumbVisible && (
          <BreadcrumbsContainer learningPath={learningPath ? learningPath : null} course={course} lessonSlug={null} />
        )}
        <div
          css={[headerStyles, isBreadcrumbVisible && breadCrumbsStyles, !isBreadcrumbVisible && navigationMarginStyles]}
          style={
            {
              '--desktop-image': `url(${getAssetUrl('/images/contentBanners/courseBackgroundImage.svg')})`,
            } as Record<string, string>
          }
        >
          <div css={[sharedContainerStyles]} data-testid="course-header">
            <CourseHeader
              course={course}
              user={user}
              onLoginUser={loginUser}
              isLoading={isLoading}
              onSave={saveContent}
              isCourseSaved={isContentSaved}
              progress={progress}
              isLoadingProgress={isLoadingProgress}
            />
          </div>
          <div css={[sharedContainerStyles, css({ marginBottom: '-64px' })]}>
            <Metadata fields={remapCourseTaxonomy(course, courseDuration)} />
          </div>
        </div>
        <div css={sharedContainerStyles}>
          {course?.overview && (
            <section
              {...inspectorProps({ entryId: course.sys.id, fieldId: 'overview' })}
              data-testid="course-overview"
              css={courseOverviewStyles}
            >
              <div css={markdownContainerStyles}>
                {documentToReactComponents(course.overview.json, getOptions(course.overview, deviceWidth))}
              </div>
            </section>
          )}
          <LessonList
            course={course}
            user={user}
            isLoading={isLoading}
            progress={progress}
            isLoadingProgress={isLoadingProgress}
          />
          {course?.relatedContent && (
            <div css={spacingStyles}>
              <Divider color={N50} />
            </div>
          )}
        </div>
        <RelatedContentSection relatedContent={course?.relatedContent} />
      </main>
    </>
  );
};

const mainSectionStyles = css({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const navigationMarginStyles = css({
  marginTop: '136px !important',

  '@media (max-width: 1279px)': {
    marginTop: '0 !important',
  },

  '@media (max-width: 768px)': {
    marginTop: '0 !important',
  },
});

const breadCrumbsStyles = css({
  marginTop: '192px',

  '@media (max-width: 1279px)': {
    marginTop: '68px',
  },

  '@media (max-width: 768px)': {
    marginTop: '56px',
  },
});

const headerStyles = css({
  backgroundColor: '#E9F2FE',
  marginBottom: '64px',
  width: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundPositionX: 'right',
  backgroundImage: 'var(--desktop-image)',

  [media.below.md]: {
    backgroundImage: 'none',
  },
});

const sharedContainerStyles = css({
  maxWidth: '846px',
  width: '100%',
  margin: '0 auto',

  [media.below.lg]: {
    maxWidth: 'unset',
    paddingLeft: '24px',
    paddingRight: '24px',
  },

  [media.below.sm]: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
});

const courseOverviewStyles = css({
  width: '100%',
  marginTop: '64px',
  marginBottom: '56px',
  color: N800,

  p: {
    margin: '16px 0',
    fontFamily: 'Inter, sans-serif',
    fontWeight: '400',
    fontSize: '20px',
    lineHeight: '32px',
    color: N800,
  },
  span: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '20px',
  },
  ul: {
    fontFamily: 'Inter, sans-serif',
    listStyleType: 'disc',
    paddingLeft: '30px !important',
    color: N800,
  },
  a: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '20px',
    color: token('color.text.accent.blue'),
    textUnderlineOffset: '4px',
    textDecorationThickness: '2px',
    textDecorationColor: token('color.link'),
    textDecorationLine: 'none',
    cursor: 'pointer',
    ':hover': {
      color: token('color.link'),
      textDecorationLine: 'underline',
    },
  },
  'ul li': {
    fontSize: '20px',
    marginTop: '4px',
    lineHeight: '32px',

    span: {
      fontSize: '20px',
    },

    '&:first-child': {
      marginTop: 0,
    },
  },
  'ul ul': {
    listStyleType: 'circle',
  },
  'ul ul ul': {
    listStyleType: 'square',
  },
  'ol li': {
    fontSize: '20px',
    lineHeight: '32px',
    marginTop: '4px',

    span: {
      fontSize: '20px',
    },

    '&:first-child': {
      marginTop: 0,
    },
  },
  ol: {
    listStyleType: 'decimal',
    paddingLeft: '30px !important',
    color: N800,
  },
  'ol ol': {
    listStyleType: 'lower-alpha',
  },
  'ol ol ol': {
    listStyleType: 'lower-roman',
  },

  [media.below.sm]: {
    span: {
      fontSize: '16px',
      lineHeight: '24px',
    },
    p: {
      fontSize: '16px',
      lineHeight: '24px',
    },
    ul: {
      paddingLeft: '20px !important',
    },
    ol: {
      paddingLeft: '20px !important',
    },
    'ul li': {
      fontSize: '14px',
      lineHeight: '20px',

      span: {
        fontSize: '16px',
      },
    },
    'ol li': {
      fontSize: '16px',
      lineHeight: '20px',

      span: {
        fontSize: '16px',
      },
    },
  },

  h3: {
    fontFamily: '"Charlie Display", sans-serif',
    fontWeight: 700,
    fontSize: '1.5rem',
    lineHeight: '2rem',
    color: N800,
    margin: 0,

    [media.above.sm]: {
      fontSize: '2rem',
      lineHeight: '2.5rem',
    },
  },

  hr: {
    margin: '16px 0',
    paddingTop: 0,
    paddingBottom: 0,
  },
});

const markdownContainerStyles = css({
  maxWidth: '846px',
  margin: '0 auto',

  [media.below.lg]: {
    maxWidth: 'unset',
  },
  hr: {
    height: '1px',
    border: `0 solid  ${token('color.border')}`,
    margin: 0,
    backgroundColor: N50,
  },
});

const spacingStyles = css({
  marginTop: '2rem',
  marginBottom: '2rem',
});
