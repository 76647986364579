import React, { useEffect } from 'react';
import { css } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { media } from '@atlaskit/primitives/responsive';
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs';
import { useNavigate } from 'react-router-dom';

import { generateBreadcrumbLinks } from 'src/components/utils/generateBreadcrumbLinks';

interface BreadcrumbsLink {
  label: string;
  link: string;
}

interface BreadcrumbsProps {
  links?: BreadcrumbsLink[];
  learningPath: { title: string; slug: string } | null;
  course: { title: string; slug: string } | null;
  lessonSlug: string | null;
}

export const BreadcrumbsContainer = ({ learningPath, course, lessonSlug }: BreadcrumbsProps): React.ReactElement => {
  const navigate = useNavigate();
  const [scrollProgress, setScrollProgress] = React.useState(0);

  useEffect(() => {
    const handleScroll = (): void => {
      setScrollProgress(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const breadcrumbLinks = generateBreadcrumbLinks(learningPath, course, lessonSlug);

  const dynamicStyles = {
    transform: `translateY(-${Math.min(scrollProgress, 68)}px)`,
  };

  return (
    <div
      data-testid="breadcrumbs"
      css={[breadcrumbsContainerStyles, lessonSlug && lessonBreadcrumbsContainerStyles, disableDynamicStylesOnTablet]}
      style={dynamicStyles}
    >
      <Breadcrumbs itemsBeforeCollapse={0} itemsAfterCollapse={1}>
        {breadcrumbLinks.map((link) => (
          <BreadcrumbsItem
            key={link.label}
            text={link.label}
            href={link.link}
            onClick={(e) => {
              e.preventDefault();
              navigate(link.link);
            }}
          />
        ))}
      </Breadcrumbs>
    </div>
  );
};

const disableDynamicStylesOnTablet = css({
  '@media (max-width: 1279px)': {
    transform: 'unset !important',
  },
});

const breadcrumbsContainerStyles = css({
  position: 'fixed',
  display: 'flex',
  justifyContent: 'center',
  height: '68px',
  width: '100%',
  borderTop: `1px solid ${token('color.border')}`,
  padding: '0 16px',
  alignItems: 'center',
  backgroundColor: token('color.background.input'),
  fontSize: '14px',
  fontFamily: 'Inter, san-serif',
  lineHeight: '20px',
  color: token('color.text.subtle'),
  zIndex: 4,

  '@media (min-width: 1279px)': {
    top: '136px',
  },

  '& > nav': {
    maxWidth: '1232px',
    width: '100%',
  },

  span: {
    fontWeight: '400 !important',
  },

  li: {
    alignItems: 'center',
  },

  [media.below.sm]: {
    height: '56px',
    padding: `${token('space.200')}`,

    li: {
      '&:not(:last-child)': {
        display: 'none',
      },
      '&::before': {
        content: '"/"',
        padding: `0 ${token('space.100')}`,
      },
    },
  },
});

const lessonBreadcrumbsContainerStyles = css({
  padding: '0 36px',
  justifyContent: 'flex-start',
  width: '100%',

  [media.below.sm]: {
    borderTop: 'none',
    top: '104px',
  },
});
