import React, { useCallback, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import {
  ContentOverviewCard,
  ContentOverviewCardState,
  Button,
  formatMinutes,
  ButtonAppearance,
  ContentType,
} from '@atlassian/learning-components';
import { css } from '@compiled/react';
import { N800 } from '@atlaskit/theme/colors';

import { User } from 'src/types';
import { getInspectModeData } from 'src/utils/contentful';
import { ContentfulSmCourse } from 'src/graphql/queries/GetSmCourse';
import { LearningProgressStatus, ProgressForLessons } from 'src/services/http/progress';
import { constructLessonUrl } from 'src/utils/constructUrl';
import { sendTrackEvent } from 'src/utils/analytics';

interface LessonListProps {
  course: ContentfulSmCourse | null;
  user?: User | null;
  numLessonsShown?: number;
  isLoading?: boolean;
  progress?: ProgressForLessons;
  isLoadingProgress?: boolean;
}

export const MAX_LESSONS_SHOWN = 6;

export const LessonList: React.FC<LessonListProps> = ({
  course,
  user,
  numLessonsShown = MAX_LESSONS_SHOWN,
  isLoading = false,
  progress,
  isLoadingProgress,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [shouldShowAllLessons, setShouldShowAllLessons] = useState<boolean>(false);

  const getLessonState = (lessonId: string): ContentOverviewCardState => {
    if (isLoading || isLoadingProgress) {
      return ContentOverviewCardState.LOADING;
    }

    if (!progress || !user) {
      return ContentOverviewCardState.DEFAULT;
    }

    const lessonProgress = progress.lessons.find((p) => p.lessonId === lessonId);

    if (lessonProgress?.status === LearningProgressStatus.IN_PROGRESS) {
      return ContentOverviewCardState.IN_PROGRESS;
    }

    if (lessonProgress?.status === LearningProgressStatus.COMPLETED) {
      return ContentOverviewCardState.FINISHED;
    }

    return ContentOverviewCardState.DEFAULT;
  };

  const onCardClick = useCallback(
    (lesson: ContentfulSmCourse['lessonsCollection']['items'][0]): void => {
      if (course) {
        const url = constructLessonUrl(course.slug, lesson.slug, params?.pathId, location.pathname);

        const hasNotStartedCourse =
          !progress?.overallStatus || progress.overallStatus === LearningProgressStatus.NOT_STARTED;

        if (hasNotStartedCourse) {
          sendTrackEvent(
            'learningApp',
            'course',
            'started',
            {
              courseSlug: course?.slug,
            },
            user?.account_id,
          );
        }

        navigate(url);
      }
    },
    [course, navigate, params?.pathId, location.pathname, progress, user],
  );

  const showAllLessons = useCallback(() => {
    setShouldShowAllLessons(true);
  }, [setShouldShowAllLessons]);

  if (!course?.lessonsCollection.items || course.lessonsCollection.items.length === 0) {
    return null;
  }

  const lessonsToShow = shouldShowAllLessons
    ? course.lessonsCollection.items
    : course.lessonsCollection.items.slice(0, numLessonsShown);

  return (
    <section data-testid="lesson-list" css={sectionStyles}>
      <div css={containerStyles}>
        <p data-testid="lesson-quantity-title" css={headerStyles}>
          {course.lessonsCollection.items.length}{' '}
          {`${course.lessonsCollection.items.length > 1 ? 'Lessons' : 'Lesson'} in this course`}
        </p>
        {lessonsToShow.map((lesson) => (
          <ContentOverviewCard
            inspectMode={getInspectModeData(lesson, ['title', 'description', 'sectionList'])}
            key={lesson.sys.id} // to support duplicated lessons for testing purposes
            tags={[{ label: formatMinutes(lesson.duration), inspectMode: getInspectModeData(lesson, ['duration']) }]}
            heading={lesson.title}
            subheading={lesson?.teaser || lesson.description}
            collapsedSectionTitle="Sections"
            collapsedSectionItems={lesson.sectionListCollection.items
              .filter((section) => section.title)
              .map((section) => section.title)}
            state={getLessonState(lesson.sys.id)}
            onClickButton={() => onCardClick(lesson)}
            contentType={ContentType.COURSE}
          />
        ))}
      </div>
      <div css={css({ marginTop: '1rem' })}>
        {lessonsToShow.length < course.lessonsCollection.items.length && (
          <Button label="See All Lessons" appearance={ButtonAppearance.LINK} onClick={showAllLessons} />
        )}
      </div>
    </section>
  );
};

const sectionStyles = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  rowGap: '1rem',
  marginTop: '1.5rem',
  width: '100%',
});

const containerStyles = css({
  width: '100%',
  gap: '1rem',
  display: 'flex',
  flexDirection: 'column',
});

const headerStyles = css({
  fontFamily: 'Charlie Display, sans-serif',
  fontSize: '1.5rem',
  lineHeight: '28px',
  fontWeight: 600,
  color: N800,
  marginTop: 0,
  marginBottom: '1rem',
});
