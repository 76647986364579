import React from 'react';
import { Components } from 'react-markdown';

export const CustomLink: Components['a'] = (props) => {
  const { href, children } = props;

  if (!href) {
    return <>{children}</>;
  }

  const currentHost = window.location.host;
  const linkHost = new URL(href).host;

  // Open in new tab if link is external (different host)
  const shouldOpenInNewTab = linkHost !== currentHost;

  return (
    <a
      href={href}
      target={shouldOpenInNewTab ? '_blank' : '_self'}
      rel={shouldOpenInNewTab ? 'noopener noreferrer' : undefined}
      {...props}
    >
      {children}
    </a>
  );
};
