import React, { useEffect } from 'react';
import { useRouteError, isRouteErrorResponse } from 'react-router-dom';
import EmptyState from '@atlaskit/empty-state';
import * as Sentry from '@sentry/react';

import { sendOperationalEvent } from 'src/utils/analytics';
import { useAtlassianUser } from 'src/hooks/useAtlassianUser';
import { detectPage } from 'src/utils/detectPage';

export const ErrorPage: React.FC = () => {
  const error = useRouteError();
  const { user } = useAtlassianUser();
  const errorMessage = (isRouteErrorResponse(error) && error.statusText) || (error as Error).message;

  React.useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  useEffect(() => {
    sendOperationalEvent(
      'learningApp',
      'app',
      'failed',
      {
        errorCode: isRouteErrorResponse(error) && error.status,
        errorMessage,
        location: detectPage(location.pathname),
      },
      user?.account_id,
    );
    // eslint-disable-next-line
  }, []);

  return <EmptyState header="Sorry, an unexpected error has occurred" description={errorMessage} />;
};
