import { FC, useContext, useEffect, useRef, useState } from 'react';
import {
  Button,
  ButtonAppearance,
  ButtonSize,
  Divider,
  Eyebrow,
  Heading,
  Icon,
  Link,
  LinkStyle,
} from '@atlassian/learning-components';
import { css } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { media } from '@atlaskit/primitives/responsive';
import { useLoaderData, useNavigate } from 'react-router-dom';

import { HelmetContext } from 'src/components/Helmet';
import { useAtlassianUser } from 'src/hooks/useAtlassianUser';
import { sendScreenEvent, sendUIEvent } from 'src/utils/analytics';
import { AssociatedContent } from 'src/graphql/generated/graphql';
import { getAssetUrl } from 'src/services/http/user';
import { LandingType } from 'src/constants';
import { useNavigateByHost } from 'src/hooks/useNavigateByHost';
import { getImageAttrs } from 'src/utils/contentful';
import { MarkdownWrapper } from 'src/components/MarkdownWrapper';

import { CertificationDetailLoaderProps } from './loader';

export const CertificationDetailPage: FC = () => {
  const helmet = useContext(HelmetContext);
  const { user, isLoading, hasError } = useAtlassianUser();
  const [isOpen, setIsOpen] = useState(false);
  const { certDetailData } = useLoaderData() as CertificationDetailLoaderProps;

  const navigate = useNavigate();
  const navigateByHost = useNavigateByHost();

  const sectionRef = useRef<HTMLDivElement | null>(null);

  const marginTop = 200;

  const handleDropdownOpen = (): void => {
    const newIsOpen = !isOpen;

    setIsOpen(newIsOpen);

    if (sectionRef.current && isOpen) {
      window.scrollTo({
        top: sectionRef.current.offsetTop - marginTop,
        behavior: 'smooth',
      });
    }

    sendUIEvent(
      'certificationDetailScreen',
      'button',
      'clicked',
      'examDetailsDropdown',
      { isOpen: newIsOpen },
      user?.account_id,
    );
  };

  useEffect(() => {
    if (!isLoading && !hasError && certDetailData) {
      sendScreenEvent(
        'certificationDetailScreen',
        {
          slug: certDetailData.slug,
        },
        user?.account_id,
      );
    }
  }, [user, isLoading, hasError, certDetailData]);

  helmet.setHelmet({
    title: 'Certification Detail | Learning - Atlassian Community',
    seoTags: certDetailData.seo,
  });
  const relatedCertificationPrep = certDetailData.relatedCertificationPrep?.contentLinksCollection
    ?.items[0] as unknown as AssociatedContent;
  const scheduleExam = certDetailData.scheduleExam?.contentLinksCollection?.items[0] as unknown as AssociatedContent;
  const communityGroup = certDetailData.communityGroup?.contentLinksCollection
    ?.items[0] as unknown as AssociatedContent;
  const contactUs = certDetailData.contactUs?.contentLinksCollection?.items[0] as unknown as AssociatedContent;

  const handleNavigation = (link?: string, sectionTitle?: string, buttonLabel?: string): void => {
    link && navigateByHost(link);
    sendUIEvent(
      'certificationDetailScreen',
      'button',
      'clicked',
      'certificationDetailButton',
      {
        sectionTitle,
        link: communityGroup?.link?.link,
        buttonLabel: buttonLabel || '',
      },
      user?.account_id,
    );
  };

  return (
    <main css={mainStyles} data-testid="certification-detail-page" role="main">
      <section css={sectionStyles}>
        <div css={heroContainerStyles}>
          <div css={eyebrowStyles}>
            <Eyebrow fontWeight="semibold" size="medium" color={token('color.text.subtle')}>
              {certDetailData.certificationNameInternal}
            </Eyebrow>
          </div>
          <div css={headerStyles}>
            <Heading level={0}>{certDetailData.certificationTitleExternal}</Heading>
          </div>
          <p css={descriptionStyles} data-testid="description">
            {certDetailData.certificationDescription}
          </p>
        </div>
      </section>
      <div css={imageSectionStyles} data-testid="image-section">
        <img
          src={getAssetUrl('/images/certificationDetail/heroBg.png')}
          alt="hero banner background"
          css={heroBgStyles}
        />
      </div>
      <section css={[sectionStyles, marginBottomStyles]} ref={sectionRef}>
        <div css={dropdownStyles} data-testid="dropdown-section">
          <Heading level={2} data-testid="dropdown-heading">
            {certDetailData.certificationNameInternal}
          </Heading>
          <div css={dividerStyles}>
            <Divider color={token('color.border')} />
          </div>
          <div css={dropdownContainerStyles} data-testid="dropdown-container">
            <div data-testid="dropdown-item-exam-details">
              <div css={dropdownRow}>
                <div css={css({ alignSelf: 'start' })}>
                  <h3 css={dropdownHeadingStyles}>Exam details</h3>
                </div>
                <div css={descriptionContainerStyles}>
                  <MarkdownWrapper>{certDetailData.examDetails}</MarkdownWrapper>
                  {isOpen && (
                    <>
                      <MarkdownWrapper>
                        {certDetailData.certificateOutcomesCollection.items[0].outcomesTitleExternal}
                      </MarkdownWrapper>
                      <MarkdownWrapper>
                        {certDetailData.certificateOutcomesCollection.items[0].outcomes}
                      </MarkdownWrapper>
                    </>
                  )}
                </div>
              </div>
              <div css={dividerStyles}>
                <Divider color={token('color.border')} />
              </div>
            </div>
            {isOpen && (
              <>
                <div data-testid="dropdown-item-designed-for">
                  <div css={dropdownRow}>
                    <div css={css({ alignSelf: 'start' })}>
                      <h3 css={dropdownHeadingStyles}>Designed for</h3>
                    </div>
                    <div css={descriptionContainerStyles}>
                      {certDetailData.designedForCollection.items.map((item) => (
                        <li key={item.sys.id}>{item.certificationRoleTitleExternal}</li>
                      ))}
                    </div>
                  </div>
                  <div css={dividerStyles}>
                    <Divider color={token('color.border')} />
                  </div>
                </div>
                <div data-testid="dropdown-item-exam-topics">
                  <div css={dropdownRow}>
                    <div css={css({ alignSelf: 'start' })}>
                      <h3 css={dropdownHeadingStyles}>Exam topics</h3>
                    </div>
                    <div css={descriptionContainerStyles}>
                      {certDetailData.examTopics.map((item) => (
                        <li key={item}>{item}</li>
                      ))}
                    </div>
                  </div>
                  <div css={dividerStyles}>
                    <Divider color={token('color.border')} />
                  </div>
                </div>
                {!!certDetailData.detailedExamTopics && (
                  <div data-testid="dropdown-item-detailed-exam-topics">
                    <div css={dropdownRow}>
                      <div css={css({ alignSelf: 'start' })}>
                        <h3 css={dropdownHeadingStyles}>Detailed exam topics</h3>
                      </div>
                      <div css={[descriptionContainerStyles, css({ fontWeight: 600 })]}>
                        <Link
                          link={certDetailData.detailedExamTopics.url}
                          label={certDetailData.detailedExamTopics.title}
                          icon="arrow-right"
                          onClick={() =>
                            window.open(certDetailData.detailedExamTopics?.url ?? '', '_blank', 'noopener')
                          }
                        />
                      </div>
                    </div>
                    <div css={dividerStyles}>
                      <Divider color={token('color.border')} />
                    </div>
                  </div>
                )}
                <div data-testid="dropdown-item-exam-specifics">
                  <div css={dropdownRow}>
                    <div css={css({ alignSelf: 'start' })}>
                      <h3 css={dropdownHeadingStyles}>Exam specifics</h3>
                    </div>
                    <div css={descriptionContainerStyles}>
                      <li>
                        <b>Price: </b>
                        {certDetailData.examSpecifics.price}
                      </li>
                      <li>
                        <b>Exam time: </b>
                        {certDetailData.examSpecifics.examTime}
                      </li>
                      <li>
                        <b>Questions: </b>
                        {certDetailData.examSpecifics.questions}
                      </li>
                      <li>
                        <b>Passing score: </b>
                        {certDetailData.examSpecifics.passingScore}
                      </li>
                      {!!certDetailData.examSpecifics?.productsCovered && (
                        <li>
                          <b>Products covered: </b>
                          {certDetailData.examSpecifics.productsCovered}
                        </li>
                      )}
                      <li>
                        <b>Location: </b>
                        {certDetailData.examSpecifics.location}
                      </li>
                      <li>
                        <b>Valid for: </b>
                        {certDetailData.examSpecifics.validFor}
                      </li>
                    </div>
                  </div>
                  <div css={dividerStyles}>
                    <Divider color={token('color.border')} />
                  </div>
                </div>
                <div data-testid="dropdown-item-exam-scheduling">
                  <div css={dropdownRow}>
                    <div css={css({ alignSelf: 'start' })}>
                      <h3 css={dropdownHeadingStyles}>Exam scheduling</h3>
                    </div>
                    <div css={descriptionContainerStyles}>
                      <MarkdownWrapper>
                        {certDetailData.examSchedulingCollection.items[0].schedulingDirections}
                      </MarkdownWrapper>
                    </div>
                  </div>
                  <div css={dividerStyles}>
                    <Divider color={token('color.border')} />
                  </div>
                </div>
                <div data-testid="dropdown-item-Certification">
                  <div css={dropdownRow}>
                    <div css={css({ alignSelf: 'start' })}>
                      <h3 css={dropdownHeadingStyles}>Certification renewals</h3>
                    </div>
                    <div css={descriptionContainerStyles}>
                      <MarkdownWrapper>{certDetailData.certificationRenewals}</MarkdownWrapper>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          {isOpen && (
            <div css={dividerStyles}>
              <Divider color={token('color.border')} />
            </div>
          )}
          <button onClick={handleDropdownOpen} css={expanderStyles} data-testid="dropdown-expander">
            {isOpen ? 'Close full exam details' : 'Open full exam details'}{' '}
            <Icon type={isOpen ? 'chevron-up' : 'chevron-down'} color={token('color.text')} size="large" />
          </button>
        </div>
      </section>
      <section css={[sectionStyles, marginBottomStyles]}>
        <div css={benefitsContainerStyles}>
          <div css={aboutHeadingStyles}>
            <Heading level={2}>{certDetailData.certificationType.certificationTypeTitleExternal}</Heading>
          </div>
          <p css={yourExperienceStyles}>{certDetailData.certificationType.certificationTypeDescription}</p>
          <div css={benefitsStyles}>
            {certDetailData.certificationType.benefitsContainerCollection.items.map((item) => (
              <div css={benefitContainerStyles} key={item.sys.id}>
                <img css={benefitImgStyles} src={getImageAttrs(item.asset)?.src} alt={item.asset?.altText} />
                <p css={benefitTitleStyles}>{item.titleExternal}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section css={marginBottomStyles}>
        <div css={sectionContainerStyles}>
          <img
            src={getAssetUrl('/images/certificationDetail/notesIcon.svg')}
            alt="Notes icon"
            css={imgRecommendedStyles}
          />
          <div css={recommendedHeadingStyles}>
            <Heading level={2}>
              {certDetailData.relatedCertificationPrep.contentSectionTitle.contentSectionTitleExternal}
            </Heading>
          </div>
          <p css={recommendedTextStyles}>
            {certDetailData.relatedCertificationPrep.contentSectionTitle.contentSectionSubtitle}
          </p>
          <div css={css({ button: { fontSize: '20px' } })}>
            <Button
              size={ButtonSize.LARGE}
              label={relatedCertificationPrep?.titleExternal || ''}
              onClick={() => {
                relatedCertificationPrep?.link?.link && navigateByHost(relatedCertificationPrep.link.link);
                sendUIEvent(
                  'certificationDetailScreen',
                  'button',
                  'clicked',
                  'certificationDetailButton',
                  {
                    sectionTitle:
                      certDetailData.relatedCertificationPrep.contentSectionTitle.contentSectionTitleExternal,
                    link: relatedCertificationPrep?.link?.link,
                    buttonLabel: relatedCertificationPrep?.titleExternal || '',
                  },
                  user?.account_id,
                );
              }}
              appearance={ButtonAppearance.PRIMARY}
              isResponsive={true}
            />
          </div>
          <div css={decorStyles}></div>
        </div>
      </section>
      <section
        css={readySectionStyles}
        style={
          { '--schedule-exam-decor': `url(${getAssetUrl('/images/certificationDetail/decor.svg')})` } as Record<
            string,
            string
          >
        }
      >
        <div css={readyGridStyles}>
          <div css={readyTextContainerStyles}>
            <div css={readyHeadingStyles}>
              <Heading level={2} color={token('color.text.inverse')}>
                {certDetailData.scheduleExam.contentSectionTitle.contentSectionTitleExternal}
              </Heading>
            </div>
            <p css={readyTextStyles}>{certDetailData.scheduleExam.contentSectionTitle.contentSectionSubtitle}</p>
            <button
              onClick={() => {
                scheduleExam?.link?.link && navigateByHost(scheduleExam.link.link);
                sendUIEvent(
                  'certificationDetailScreen',
                  'button',
                  'clicked',
                  'certificationDetailButton',
                  {
                    sectionTitle: certDetailData.scheduleExam.contentSectionTitle.contentSectionTitleExternal,
                    link: scheduleExam?.link?.link,
                    buttonLabel: scheduleExam?.titleExternal || '',
                  },
                  user?.account_id,
                );
              }}
              css={readyButtonStyles}
            >
              {scheduleExam?.titleExternal || ''}
            </button>
          </div>
          <img
            src={getAssetUrl('/images/certificationDetail/logoBadge.svg')}
            alt="Atlassian logo badge"
            css={logoBadgeStyles}
          />
        </div>
      </section>
      <section css={[studySectionStyles, marginBottomStyles]}>
        <div css={studyContainerStyles}>
          <img src={getAssetUrl('/images/certificationDetail/community.png')} alt="community" css={studyImgStyles} />
          <div css={studyTextContainerStyles}>
            <div css={studyHeadingStyles}>
              <Heading level={2}>
                {certDetailData.communityGroup.contentSectionTitle.contentSectionTitleExternal}
              </Heading>
            </div>
            <p css={studyTextStyles}>{certDetailData.communityGroup.contentSectionTitle.contentSectionSubtitle}</p>
            <div css={linkFontStyles}>
              <Link
                style={LinkStyle.SEMIBOLD}
                link={communityGroup?.link?.link}
                label={communityGroup?.titleExternal || ''}
                icon="arrow-right"
                onClick={() =>
                  handleNavigation(
                    communityGroup?.link?.link,
                    certDetailData.communityGroup.contentSectionTitle.contentSectionTitleExternal,
                    communityGroup?.titleExternal,
                  )
                }
              />
            </div>
          </div>
        </div>
      </section>
      <section css={[contactSectionStyles, marginBottomStyles]}>
        <div css={contactContainerStyles}>
          <div css={contactTextContainerStyles}>
            <div css={contactHeadingStyles}>
              <Heading level={2}>{certDetailData.contactUs.contentSectionTitle.contentSectionTitleExternal}</Heading>
            </div>
            <p css={contactTextStyles}>{certDetailData.contactUs.contentSectionTitle.contentSectionSubtitle}</p>
            <div css={linkFontStyles}>
              <Link
                data-testid="contact-us-button"
                link={contactUs?.link?.link}
                style={LinkStyle.SEMIBOLD}
                label={contactUs?.titleExternal || ''}
                icon="arrow-right"
                onClick={() =>
                  handleNavigation(
                    contactUs?.link?.link,
                    certDetailData.contactUs.contentSectionTitle.contentSectionTitleExternal,
                    contactUs?.titleExternal,
                  )
                }
              />
            </div>
          </div>
          <img src={getAssetUrl('/images/certificationDetail/message.svg')} alt="message icon" css={contactImgStyles} />
        </div>
      </section>
      <section>
        <div css={relatedSection}>
          <img
            src={getAssetUrl('/images/certificationDetail/compassIcon.svg')}
            alt="Related credentials icon"
            css={imgRecommendedStyles}
          />
          <div css={[recommendedHeadingStyles, recommendedHeadingMarginStyles]}>
            <Heading level={2}>Related credentials</Heading>
          </div>
          <div css={recommendedButtonsStyles}>
            {certDetailData.relatedCredentialsCollection.items.map((item) => (
              <div css={linkFontStyles} key={item.slug}>
                <Link
                  label={item.certificationTitleExternal}
                  style={LinkStyle.SEMIBOLD}
                  icon="arrow-right"
                  link={`/landing/${LandingType.CERTIFICATION_DETAIL}/${item.slug}`}
                  onClick={() => {
                    navigate(`/landing/${LandingType.CERTIFICATION_DETAIL}/${item.slug}`);
                    sendUIEvent(
                      'certificationDetailScreen',
                      'link',
                      'clicked',
                      'certificationDetailLink',
                      {
                        sectionTitle: 'Related credentials',
                        slug: item.slug,
                      },
                      user?.account_id,
                    );
                  }}
                />
              </div>
            ))}
          </div>
          <div css={relatedDecor}></div>
        </div>
      </section>
    </main>
  );
};

const mainStyles = css({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: token('space.600'),
  overflowX: 'hidden',
  paddingTop: '136px',

  '@media (max-width: 1279px)': {
    paddingTop: '0',
  },
});

const linkFontStyles = css({
  a: {
    fontSize: '20px',
  },
});

const sectionStyles = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  maxWidth: '1020px',
  width: '100%',
  padding: `0 ${token('space.200')}`,
});

const marginBottomStyles = css({
  marginBottom: token('space.1000'),

  [media.below.sm]: {
    marginBottom: token('space.500'),
  },
});

const heroContainerStyles = css({
  maxWidth: '720px',
  marginBottom: token('space.1000'),

  [media.below.sm]: {
    marginBottom: token('space.500'),
  },
});

const headerStyles = css({
  textAlign: 'center',
  margin: '0 auto',
  marginBottom: token('space.200'),
});

const eyebrowStyles = css({
  textAlign: 'center',
  marginBottom: token('space.200'),
});

const descriptionStyles = css({
  color: '#000',
  textAlign: 'center',
  fontFamily: 'Inter, sans-serif',
  fontSize: '20px',
  fontWeight: 400,
  lineHeight: '32px',
});

const imageSectionStyles = css({
  height: '350px',
  width: '100vw',
  marginBottom: token('space.1000'),
  objectFit: 'cover',

  [media.below.md]: {
    height: '260px',
  },

  [media.below.sm]: {
    marginBottom: token('space.500'),
  },

  [media.below.xs]: {
    height: '181px',
  },
});

const heroBgStyles = css({
  objectFit: 'cover',
  objectPosition: 'left',
  width: '100%',
  height: '100%',

  [media.below.sm]: {
    objectPosition: '12%',
  },
});

const dropdownStyles = css({
  width: '100%',
  background: '#FBE49A',
  padding: token('space.1000'),

  [media.below.md]: {
    padding: token('space.500'),
    maxWidth: '720px',
  },

  [media.below.sm]: {
    padding: `${token('space.300')} !important`,
    maxWidth: '343px !important',
  },
});

const descriptionContainerStyles = css({
  color: '#000',
  fontFamily: 'Inter, sans-serif',
  fontSize: '20px',
  fontWeight: 400,

  '& blockquote': {
    paddingLeft: '9px !important',
  },

  '& ul': {
    paddingLeft: '19px !important',
  },

  '& ol': {
    paddingLeft: '23px !important',
  },

  [media.below.sm]: {
    fontSize: '16px',
    lineHeight: '24px',
  },
});

const dividerStyles = css({
  margin: `${token('space.500')} auto`,
});

const dropdownContainerStyles = css({
  height: 'auto',
  overflow: 'hidden',
});

const dropdownRow = css({
  display: 'grid',
  gap: '65px',
  gridTemplateColumns: '273px auto',
  justifyContent: 'start',
  alignItems: 'center',
  width: '100%',

  [media.below.md]: {
    gap: token('space.200'),
    maxWidth: '720px',
    gridTemplateColumns: 'none',
    gridTemplateRows: 'auto auto',
  },

  [media.below.sm]: {
    maxWidth: '343px',
  },
});

const dropdownHeadingStyles = css({
  color: '#000',
  fontFamily: 'Charlie Text, sans-serif',
  fontWeight: 700,
  fontSize: '32px',
  lineHeight: '40px',

  [media.below.sm]: {
    fontSize: '24px',
    lineHeight: '32px',
  },
});

const expanderStyles = css({
  display: 'flex',
  alignItems: 'center',
  color: token('color.text'),
  textAlign: 'center',
  fontFamily: 'Charlie Text, sans-serif',
  fontSize: '20px',
  fontWeight: 600,
  lineHeight: '24px',
  background: 'none',
  border: 'none',
  cursor: 'pointer',

  [media.below.sm]: {
    fontSize: '16px',
    lineHeight: '20px',
  },
});

const benefitsContainerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const aboutHeadingStyles = css({
  textAlign: 'center',
  maxWidth: '720px',

  [media.below.sm]: {
    maxWidth: '343px',
  },
});

const yourExperienceStyles = css({
  color: '#000',
  textAlign: 'center',
  fontFamily: 'Inter, sans-serif',
  fontSize: '20px',
  fontWeight: 400,
  lineHeight: '32px',
  marginBottom: token('space.200'),
});

const benefitsStyles = css({
  display: 'grid',
  gridTemplateColumns: 'auto auto auto auto',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'start',
  marginTop: token('space.600'),

  [media.below.sm]: {
    gridTemplateColumns: 'auto auto',
    gap: token('space.300'),
  },
});

const benefitContainerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  maxWidth: '150px',
  margin: '0 28px',

  [media.below.md]: {
    margin: '0 15px',
  },
});

const benefitImgStyles = css({
  width: 'auto',
  maxWidth: '100%',
  maxHeight: '80px',
  marginBottom: token('space.200'),
});

const benefitTitleStyles = css({
  color: '#000',
  textAlign: 'center',
  fontFamily: 'Inter, sans-serif',
  fontSize: '16px',
  fontWeight: 400,
  marginTop: 0,
  lineHeight: '24px',
});

const sectionContainerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  width: '1020px',
  height: '576px',

  [media.below.md]: {
    width: '720px',
    height: '656px',
  },

  [media.below.sm]: {
    width: '343px !important',
    padding: `0 ${token('space.300')}`,
  },
});

const recommendedHeadingStyles = css({
  textAlign: 'center',
  marginBottom: token('space.200'),

  [media.below.md]: {
    maxWidth: '600px',
  },

  [media.below.sm]: {
    h2: {
      fontSize: '32px',
      lineHeight: '40px',
    },
  },
});

const recommendedHeadingMarginStyles = css({
  marginBottom: token('space.600'),

  [media.below.sm]: {
    marginBottom: token('space.300'),
  },
});

const imgRecommendedStyles = css({
  width: 'auto',
  maxWidth: '100%',
  height: '80px',
  marginBottom: token('space.600'),

  [media.below.sm]: {
    marginBottom: token('space.300'),
  },
});

const decorStyles = css({
  position: 'absolute',
  zIndex: -1,
  width: '100%',
  height: '100%',
  backgroundColor: '#F7C849',
  clipPath: 'polygon(0 40px, 347px 40px, 347px 0, 100% 40px, calc(100% - 20px) 100%, 20px 100%)',

  [media.below.sm]: {
    clipPath: 'polygon(0 100%, 100% 100%, 100% 0, 0 0)',
  },
});

const recommendedTextStyles = css({
  color: '#000',
  textAlign: 'center',
  fontFamily: 'Inter, sans-serif',
  fontSize: '20px',
  fontWeight: 400,
  lineHeight: '32px',
  maxWidth: '720px',
  marginTop: 0,
  marginBottom: token('space.500'),

  [media.below.md]: {
    maxWidth: '600px',
  },

  [media.below.sm]: {
    fontSize: '16px',
    lineHeight: '24px',
  },
});

const readySectionStyles = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '408px',
  backgroundColor: token('color.background.accent.blue.bolder'),
  backgroundImage: 'var(--schedule-exam-decor)',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'bottom right',
  backgroundSize: 'auto 60%',

  [media.below.md]: {
    height: '641px',
  },
});

const readyGridStyles = css({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '70px',
  width: '980px',

  [media.below.md]: {
    gridTemplateColumns: '1fr',
    gap: token('space.600'),
    width: '720px',
  },

  [media.below.sm]: {
    gap: `${token('space.300')} !important`,
    width: '343px !important',
  },
});

const readyTextContainerStyles = css({
  width: '720px',

  [media.below.md]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [media.below.sm]: {
    width: '343px',
  },
});

const readyHeadingStyles = css({
  marginBottom: token('space.200'),

  [media.below.md]: {
    textAlign: 'center',
  },

  [media.below.sm]: {
    h2: {
      fontSize: '32px',
      lineHeight: '40px',
    },
  },
});

const readyTextStyles = css({
  color: token('color.text.inverse'),
  fontFamily: 'Inter, sans-serif',
  fontSize: '20px',
  fontWeight: 400,
  lineHeight: '32px',
  marginTop: 0,
  marginBottom: token('space.500'),

  [media.below.md]: {
    textAlign: 'center',
  },

  [media.below.sm]: {
    fontSize: '16px',
    lineHeight: '24px',
  },
});

const readyButtonStyles = css({
  backgroundColor: token('color.background.information.pressed'),
  color: token('color.text'),
  fontFamily: 'Charlie Text, sans-serif',
  fontSize: '20px',
  fontWeight: 600,
  lineHeight: '24px',
  border: 'none',
  padding: `${token('space.150')} ${token('space.200')}`,
  borderRadius: '4px',
  cursor: 'pointer',
});

const logoBadgeStyles = css({
  marginLeft: 'auto',
  width: 'auto',
  maxWidth: '100%',
  height: '280px',

  [media.below.md]: {
    margin: '0 auto',
    order: -1,
  },
});

const studySectionStyles = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#D2E1FB',
  width: '100%',
  height: '648px',

  [media.below.md]: {
    height: '888px',
    padding: token('space.600'),
  },

  [media.below.sm]: {
    height: '714px !important',
  },
});

const studyContainerStyles = css({
  display: 'grid',
  gridTemplateColumns: '442px 480px',
  alignItems: 'center',
  justifyContent: 'center',
  gap: token('space.1000'),

  [media.below.md]: {
    gridTemplateColumns: '1fr',
    gap: token('space.600'),
    maxWidth: '720px',
  },

  [media.below.sm]: {
    gap: `${token('space.300')} !important`,
  },
});

const studyImgStyles = css({
  width: 'auto',
  maxWidth: '100%',
  height: '460px',

  [media.below.md]: {
    margin: '0 auto',
  },

  [media.below.sm]: {
    width: '343px',
    height: '378px',
  },
});

const studyTextContainerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  justifyContent: 'center',

  [media.below.md]: {
    alignItems: 'center',
  },
});

const studyHeadingStyles = css({
  marginBottom: token('space.200'),
  textAlign: 'left',

  [media.below.md]: {
    textAlign: 'center',
  },
});

const studyTextStyles = css({
  color: token('color.text'),
  fontFamily: 'Inter, sans-serif',
  fontSize: '20px',
  fontWeight: 400,
  lineHeight: '32px',
  marginBottom: token('space.500'),

  [media.below.md]: {
    textAlign: 'center',
  },

  [media.below.sm]: {
    fontSize: '16px',
    lineHeight: '24px',
  },
});

const contactSectionStyles = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
});

const contactContainerStyles = css({
  display: 'grid',
  gridTemplateColumns: '500px 440px',
  alignItems: 'center',
  justifyContent: 'center',
  gap: token('space.1000'),

  [media.below.md]: {
    gridTemplateColumns: '1fr',
    gap: token('space.600'),
    maxWidth: '720px',
  },

  [media.below.sm]: {
    gap: `${token('space.300')} !important`,
  },
});

const contactImgStyles = css({
  width: '442px',
  height: 'auto',

  [media.below.md]: {
    margin: '0 auto',
    order: -1,
  },

  [media.below.sm]: {
    width: '343px',
    height: '215px',
  },
});

const contactTextContainerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  justifyContent: 'center',

  [media.below.md]: {
    alignItems: 'center',
  },
});

const contactHeadingStyles = css({
  marginBottom: token('space.200'),
});

const contactTextStyles = css({
  color: token('color.text'),
  fontFamily: 'Inter, sans-serif',
  fontSize: '20px',
  fontWeight: 400,
  lineHeight: '32px',
  marginBottom: token('space.500'),
  marginTop: 0,

  [media.below.md]: {
    textAlign: 'center',
  },

  [media.below.sm]: {
    fontSize: '16px',
    lineHeight: '24px',
  },
});

const relatedSection = css({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '1020px',
  height: '584px',

  [media.below.md]: {
    width: '720px',
  },

  [media.below.sm]: {
    width: '343px !important',
    height: '435px',
    padding: token('space.300'),
  },
});

const relatedDecor = css({
  position: 'absolute',
  zIndex: -1,
  width: '100%',
  height: '100%',
  backgroundColor: '#FEF5D8',
  clipPath:
    'polygon(0 40px, 673px 0, 673px 40px, 100% 40px, 100% 100%, calc(100% - 673px) calc(100% - 20px), calc(100% - 673px) calc(100% - 40px), 20px calc(100% - 40px))',

  [media.below.md]: {
    clipPath:
      'polygon(0 40px, 420px 0, 420px 40px, 100% 40px, 100% 100%, calc(100% - 420px) calc(100% - 20px), calc(100% - 420px) calc(100% - 40px), 20px calc(100% - 40px))',
  },

  [media.below.sm]: {
    clipPath: 'polygon(0 100%, 100% 100%, 100% 0, 0 0) !important',
  },
});

const recommendedButtonsStyles = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: token('space.500'),

  [media.below.sm]: {
    gap: token('space.300'),
  },
});
