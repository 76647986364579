import { MyLearningData } from 'src/services/http/myLearning';
import { MyLearningResource, MyLearningResourceData } from 'src/pages/MyLearningPage';

interface GenerateCSVFileProps {
  userName: string;
  fullTranscriptData: MyLearningData;
  myLearningData: MyLearningResourceData;
}

interface CSVItem {
  'User Name': string;
  'Content Type': string;
  Title: string;
  'Started Date'?: string;
  'Completed Date'?: string;
}

const downloadCSVFile = (data: string, userName: string): void => {
  const downloadDate = new Date().toISOString().split('T')[0];
  const fileName = `${userName}-${downloadDate}.csv`;

  const blob = new Blob([data], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);

  const a = document.createElement('a');

  a.href = url;
  a.download = fileName;

  a.click();
};

const createCSVData = (data: CSVItem[]): string => {
  const csvRows = [];
  const headers = Object.keys(data[0] ?? '');

  csvRows.push(headers.join(','));

  data.forEach((el) => {
    const values = headers.map((key) => el[key as keyof CSVItem]);

    csvRows.push(values.join(','));
  });

  return csvRows.join('\n');
};

const mapContentfulData = (
  fullTranscriptData: MyLearningData,
  myLearningData: MyLearningResourceData,
): MyLearningResourceData => {
  const mappedCourses = myLearningData.courses
    ? myLearningData.courses.map((course) => {
      const courseResult = fullTranscriptData.courses.find(
        (courseData) => course.contentfulId == courseData.contentfulId,
      );

      if (courseResult) {
        return { ...course, startedDate: courseResult.startedDate };
      }

      return course;
    })
    : [];
  const mappedLearningPaths = myLearningData.learningPaths
    ? myLearningData.learningPaths.map((learningPath) => {
      const learningPathResult = fullTranscriptData.learningPath.find(
        (learningPathData) => learningPath.contentfulId == learningPathData.contentfulId,
      );

      if (learningPathResult) {
        return { ...learningPath, startedDate: learningPathResult.startedDate };
      }

      return learningPath;
    })
    : [];

  return {
    learningPaths: [...mappedLearningPaths],
    courses: [...mappedCourses],
  };
};

const transformDate = (fullDate?: string): string => (fullDate ? fullDate.split('T')[0] : '');

const transformData = (data: MyLearningResourceData, userName: string): CSVItem[] => {
  const transformedLearningPathData = data.learningPaths
    ? data.learningPaths.map(({ completedDate, startedDate, title }) => ({
      'User Name': userName,
      'Content Type': 'Learning Path',
      Title: title,
      'Started Date': transformDate(startedDate),
      'Completed Date': transformDate(completedDate),
    }))
    : [];

  const transformedCourseData = data.courses
    ? data.courses.map(({ completedDate, startedDate, title }) => ({
      'User Name': userName,
      'Content Type': 'Course',
      Title: title,
      'Started Date': transformDate(startedDate),
      'Completed Date': transformDate(completedDate),
    }))
    : [];

  return [...transformedLearningPathData, ...transformedCourseData];
};

const convertIntellumData = (fullTranscriptData: MyLearningData): MyLearningResourceData => {
  const intellumLearningPaths = fullTranscriptData.learningPath.filter((learningPath) => learningPath.isFromIntellum);
  const intellumCourses = fullTranscriptData.courses.filter((course) => course.isFromIntellum);

  const convertedLearningPaths = intellumLearningPaths.map((learningPath) => ({
    ...learningPath,
    title: learningPath.contentfulId,
  }));

  const convertedCourses = intellumCourses.map((course) => ({ ...course, title: course.contentfulId }));

  return {
    courses: [...convertedCourses],
    learningPaths: [...convertedLearningPaths],
  };
};

export const generateCSV = ({ userName, fullTranscriptData, myLearningData }: GenerateCSVFileProps): void => {
  const intellumDataResult: MyLearningResourceData = convertIntellumData(fullTranscriptData);

  const contentfulDataResult: MyLearningResourceData = mapContentfulData(fullTranscriptData, myLearningData);

  const resultData = {
    learningPaths: [
      ...(contentfulDataResult.learningPaths as MyLearningResource[]),
      ...(intellumDataResult.learningPaths as MyLearningResource[]),
    ],
    courses: [
      ...(contentfulDataResult.courses as MyLearningResource[]),
      ...(intellumDataResult.courses as MyLearningResource[]),
    ],
  };

  const transformedData = transformData(resultData, userName);

  const CSVdata = createCSVData(transformedData);

  downloadCSVFile(CSVdata, userName);
};
