import { LoaderFunction } from 'react-router-dom';

import {
  ContentfulGetCertifications,
  ContentfulGetCertPreps,
  ContentfulGetCourses,
  ContentfulGetCatalogResourcesResponse,
  ContentfulGetLearningPaths,
  ContentfulGetRoles,
  ContentfulGetTopics,
  GET_CATALOG_RESOURCES,
} from 'src/graphql/queries/GetCatalogResources';
import { fetchGraphQL } from 'src/services/contentful/client';
import { GetCatalogResourcesQueryVariables } from 'src/graphql/generated/GetCatalogResources';
import { GetAllTaxonomyQueryVariables } from 'src/graphql/generated/GetAllTaxonomy';
import { ContentfulGetAllTaxonomyResponse, GET_ALL_TAXONOMY } from 'src/graphql/queries/GetAllTaxonomy';
import { sanitizeTaxonomy } from 'src/pages/CatalogPage/utils/loaderUtils';

import { getCourseDuration, getLearningPathDuration, Resource, ResourceWithDurationAndType } from './utils/taxonomy';

export interface Taxonomy {
  duration: string[];
  learningLevel: string[];
  product: string[];
  role: string[];
  deployment: string[];
}

export interface CatalogLoaderProps {
  resources: ResourceWithDurationAndType[];
  taxonomy: Taxonomy;
}

const compareByTitle = (resourceA: Resource, resourceB: Resource): number =>
  resourceA.title.localeCompare(resourceB.title, undefined, { sensitivity: 'base' });

export const getFiltersFromUrl = (url: string): Record<string, string[]> => {
  const queryParams: Record<string, string[]> = {};
  const urlObj = new URL(url);
  const searchParams = new URLSearchParams(urlObj.search);

  searchParams.forEach((value, key) => {
    queryParams[key] = value.split(',');
  });

  // product is the only valid BE filter for collection,
  // for all other selected filters or filter intersections (e. g. paramsLength > 1)
  // we pass NOT_MATCHED as a value to collectionProduct to exclude collections from the results
  const paramsLength = Object.keys(queryParams).length;
  const shouldExcludeCollections = !queryParams.product && (!queryParams.type || paramsLength > 1) && paramsLength > 0;

  if (shouldExcludeCollections) {
    queryParams.collectionProduct = ['NOT_MATCHED'];
  } else {
    queryParams.collectionProduct = queryParams.product;
  }

  return queryParams;
};

export const loader: LoaderFunction = async ({ request }): Promise<CatalogLoaderProps> => {
  const preview = request.url.includes('preview=true');
  const queryParams: Record<string, string[]> = getFiltersFromUrl(request.url);

  return {
    resources: await getCatalogResources(preview, queryParams),
    taxonomy: await getAllTaxonomy(preview),
  };
};

export const getCatalogResources = async (
  preview: boolean,
  filters: Record<string, string[]>,
  skip: number = 0,
): Promise<ResourceWithDurationAndType[]> => {
  const response = await fetchGraphQL<ContentfulGetCatalogResourcesResponse, GetCatalogResourcesQueryVariables>(
    GET_CATALOG_RESOURCES,
    {
      preview,
      skip,
      ...filters,
    },
  );

  const coursesWithDuration = response.smCourseCollection?.items.map((c: ContentfulGetCourses) => ({
    ...c,
    duration: getCourseDuration(c),
    type: c.__typename,
  }));

  const learningPathsWithDuration = response.learningPathCollection?.items.map((lp: ContentfulGetLearningPaths) => ({
    ...lp,
    duration: getLearningPathDuration(lp),
    type: lp.__typename,
  }));

  const certifications = response.certificationCollection?.items.map((cert: ContentfulGetCertifications) => ({
    ...cert,
    type: cert.__typename,
  }));

  const roles = response.roleCollection?.items.map((role: ContentfulGetRoles) => ({
    ...role,
    type: role.__typename,
  }));

  const topics = response.topicCollection?.items.map((topic: ContentfulGetTopics) => ({
    ...topic,
    type: topic.__typename,
  }));

  const certPreps = response.certificationPrepCollection?.items.map((certPrep: ContentfulGetCertPreps) => ({
    ...certPrep,
    type: certPrep.__typename,
  }));

  const instructorLedTrainingWithType = response.instructorLedTrainingCollection.items.map((ilt) => {
    // duration is optional for instructorLedTraining on contentful
    // if duration is null we exclude field to exclude resource
    // from filtering results when filtering by duration
    const { duration, ...iltWithoutDuration } = ilt;

    return {
      ...(duration ? ilt : iltWithoutDuration),
      type: ilt.__typename,
    };
  });

  return [
    ...coursesWithDuration,
    ...learningPathsWithDuration,
    ...certifications,
    ...roles,
    ...topics,
    ...certPreps,
    ...instructorLedTrainingWithType,
  ].sort((a, b) => compareByTitle(a as Resource, b as Resource)) as ResourceWithDurationAndType[];
};

const getAllTaxonomy = async (preview: boolean): Promise<Taxonomy> => {
  const response = await fetchGraphQL<ContentfulGetAllTaxonomyResponse, GetAllTaxonomyQueryVariables>(
    GET_ALL_TAXONOMY,
    { preview },
  );

  return sanitizeTaxonomy(response);
};
