import { LoaderFunction } from 'react-router-dom';

import { ContentfulSmCourse, ContentfulSmCourseResponse, GET_SM_COURSE } from 'src/graphql/queries/GetSmCourse';
import { GetCourseQueryVariables } from 'src/graphql/generated/GetSmCourse';
import { GetLearningPathIdQueryVariables, GetLearningPathIdQuery } from 'src/graphql/generated/GetLearningPathId';
import { fetchGraphQL } from 'src/services/contentful/client';
import { GET_LEARNING_PATH_ID, GetLearningPathId } from 'src/graphql/queries/GetLearningPathId';

export const loader: LoaderFunction = async ({ params, request }): Promise<CourseLoaderProps> => {
  const courseId = params.courseId ?? '';
  const pathId = params.pathId ?? '';
  const preview = request.url.includes('preview=true');

  const course = await getCourse(courseId, preview);
  const learningPath = await getLearningPathId(pathId, preview);

  if (!course) {
    throw new Error('Course was not found');
  }

  return { course, learningPath };
};

const getCourse = async (courseId: string, preview: boolean): Promise<ContentfulSmCourse | undefined> => {
  const response = await fetchGraphQL<ContentfulSmCourseResponse, GetCourseQueryVariables>(GET_SM_COURSE, {
    slug: courseId,
    preview,
  });

  return response.smCourseCollection.items[0];
};

const getLearningPathId = async (courseId: string, preview: boolean): Promise<GetLearningPathId | undefined> => {
  const response = await fetchGraphQL<GetLearningPathIdQuery, GetLearningPathIdQueryVariables>(GET_LEARNING_PATH_ID, {
    slug: courseId,
    preview,
  });

  return response.learningPathCollection.items[0];
};

export interface CourseLoaderProps {
  course: ContentfulSmCourse;
  learningPath?: GetLearningPathId;
}
