import { Environment } from '@atlassian/learning-components';

import { User } from 'src/types/index';

import type { Request } from '@atlassian/tesseract-runtime-globals';

export const isLocalhost = (): boolean =>
  !!process.env.BITBUCKET_DEPLOYMENT_ENVIRONMENT &&
  process.env.BITBUCKET_DEPLOYMENT_ENVIRONMENT.indexOf('localhost') !== -1;

export const isDevelopment = (): boolean =>
  !!process.env.BITBUCKET_DEPLOYMENT_ENVIRONMENT && process.env.BITBUCKET_DEPLOYMENT_ENVIRONMENT.indexOf('dev') !== -1;

export const isStaging = (): boolean =>
  !!process.env.BITBUCKET_DEPLOYMENT_ENVIRONMENT && process.env.BITBUCKET_DEPLOYMENT_ENVIRONMENT.indexOf('stg') !== -1;

export const isProduction = (): boolean => !isStaging() && !isDevelopment() && !isLocalhost();

export const getUrlPrefix = (): string => '/learning';
export const getAssetUrl = (url: string): string => `/assets/learning${url}`;

export const getEnvironment = (): Environment => {
  if (isStaging()) {
    return 'staging' as Environment;
  }

  if (isDevelopment() || isLocalhost()) {
    return 'development' as Environment;
  }

  return 'production' as Environment;
};

const getIdentityUrl = (): string =>
  isProduction() ? 'https://id.atlassian.com' : 'https://id.stg.internal.atlassian.com';

export const prepareUrlForAuth = (): string => {
  const url = new URL(globalThis.location.href.replace('/index.html', ''));
  const learningPrefix = getUrlPrefix();

  // Adds learning prefix to the pathname if it doesn't exist in URL
  if (learningPrefix && !url.pathname.includes(learningPrefix)) {
    url.pathname = `${learningPrefix}${url.pathname}`;
  }

  // Removes `?` symbol from the end of URL if it exists
  if (/\?$/.test(url.href)) {
    return url.href.replace(/\?$/, '');
  }

  return url.href;
};

export const getLoginUrl = (): string =>
  `${getIdentityUrl()}/login?application=community--entry-auth&continue=${encodeURIComponent(prepareUrlForAuth())}`;

export const getLogoutUrl = (): string => {
  if (globalThis.location.href.includes('/my-learning/')) {
    return `${getIdentityUrl()}/logout?application=community--entry-auth&continue=${encodeURIComponent(
      globalThis.location.origin + getUrlPrefix(),
    )}`;
  }

  return `${getIdentityUrl()}/logout?application=community--entry-auth&continue=${encodeURIComponent(
    globalThis.location.href.replace('/index.html', ''),
  )}`;
};

export const getSignUpUrl = (): string =>
  `${getIdentityUrl()}/signup?application=community--entry-auth&continue=${encodeURIComponent(prepareUrlForAuth())}`;

export const getLoginUrlWithHomePageRedirect = (): string => {
  // Redirect to the home page after login to avoid redirecting to the same page
  const homePageUrl = encodeURIComponent(globalThis.location.origin + getUrlPrefix());

  return `${getIdentityUrl()}/login?application=community--entry-auth&continue=${homePageUrl}`;
};

export const getAtlassianUser = async (): Promise<User | null> => {
  const typedGlobalThis = globalThis as typeof globalThis & { __COOKIES__: Request['headers']['cookie'] };

  const res = await fetch(`${globalThis?.location?.origin}/gateway/api/me`, {
    credentials: 'include',
    headers: {
      ...(typedGlobalThis.__COOKIES__ ? { cookie: typedGlobalThis.__COOKIES__ } : {}),
    },
  });

  if (res.status !== 200) {
    return null;
  }

  return res.json();
};

export const loginUser = (): void => {
  window.location.href = getLoginUrl();
};

export const logoutUser = (): void => {
  window.location.href = getLogoutUrl();
};
