import React from 'react';
import { RouteObject } from 'react-router-dom';

import { CommunityLogin } from './pages/CommunityLogin/CommunityLogin';
import { App } from './pages/App';
import { ErrorPage } from './pages/ErrorPage';
import { CoursePage, loader as courseLoader } from './pages/CoursePage';
import { LessonPage, loader as lessonLoader } from './pages/LessonPage';
import { HomePage } from './pages/HomePage';
import { CatalogPage, loader as catalogLoader } from './pages/CatalogPage';
import { LearningPathPage, loader as learningPathLoader } from './pages/LearningPathPage';
import { FeedbackPage } from './pages/App/FeedbackPage';
import { MyLearningPage, loader as myLearningLoader } from './pages/MyLearningPage';
import { loader as certPrepLoader, CertificatePreparationPage } from './pages/hubs/CertificatePreparationPage';
import {
  loader as learningPathsHubLoader,
  LearningPathPage as LearningPathsHubPage,
} from './pages/hubs/LearningPathPage';
import { RoleBasedPage, loader as roleBasedHubLoader } from './pages/hubs/RoleBasedPage';
import { CertificationPage, loader as certificationLoader } from './pages/hubs/CertificationPage';
import { LiveTrainingPage } from './pages/landings/LiveTrainingPage';
import { RelatedTopicCollectionPage, loader as topicLoader } from './pages/collections/RelatedTopicCollectionPage';
import { CertificationDetailPage, loader as certDetailLoader } from './pages/CertificationDetailPage';
import { RoleBasedCollectionPage, loader as roleLoader } from './pages/collections/RoleBasedCollectionPage';
import {
  loader as certPrepCollectionLoader,
  CertificatePreparationCollectionPage,
} from './pages/collections/CertificationPreparation';
import { CollectionType, ContentHubType, LandingType } from './constants';

export const routes: RouteObject[] = [
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <HomePage />,
      },
      {
        path: '/community-login',
        element: <CommunityLogin />,
      },
      {
        path: '/catalog',
        element: <CatalogPage />,
        loader: catalogLoader,
      },
      {
        path: 'course/:courseId',
        element: <CoursePage />,
        loader: courseLoader,
      },
      {
        path: 'path/:pathId/course/:courseId',
        element: <CoursePage />,
        loader: courseLoader,
      },
      {
        path: `hub/${ContentHubType.LEARNING_PATHS}/path/:pathId/course/:courseId/`,
        element: <CoursePage />,
        loader: courseLoader,
      },
      {
        path: 'path/:pathId/course/:courseId/lesson/:lessonId',
        element: <LessonPage />,
        loader: lessonLoader,
      },
      {
        path: `hub/${ContentHubType.LEARNING_PATHS}/path/:pathId/course/:courseId/lesson/:lessonId`,
        element: <LessonPage />,
        loader: lessonLoader,
      },
      {
        path: 'course/:courseId/lesson/:lessonId',
        element: <LessonPage />,
        loader: lessonLoader,
      },
      {
        path: 'course/:courseId/survey',
        element: <FeedbackPage />,
      },
      {
        path: 'path/:pathId/course/:courseId/survey',
        element: <FeedbackPage />,
      },
      {
        path: 'path/:pathId',
        element: <LearningPathPage />,
        loader: learningPathLoader,
      },
      {
        path: `hub/${ContentHubType.LEARNING_PATHS}/path/:pathId`,
        element: <LearningPathPage />,
        loader: learningPathLoader,
      },
      {
        path: 'my-learning/:status',
        element: <MyLearningPage />,
        loader: myLearningLoader,
      },
      {
        path: `hub/${ContentHubType.CERT_PREP}`,
        element: <CertificatePreparationPage />,
        loader: certPrepLoader,
      },
      {
        path: `hub/${ContentHubType.LEARNING_PATHS}`,
        element: <LearningPathsHubPage />,
        loader: learningPathsHubLoader,
      },
      {
        path: `hub/${ContentHubType.ROLE_BASED}`,
        element: <RoleBasedPage />,
        loader: roleBasedHubLoader,
      },
      {
        path: `hub/${ContentHubType.CERTIFICATIONS}`,
        element: <CertificationPage />,
        loader: certificationLoader,
      },
      {
        path: `landing/${LandingType.LIVE_TRAINING}`,
        element: <LiveTrainingPage />,
      },
      {
        path: `landing/${LandingType.CERTIFICATION_DETAIL}/:certificationId`,
        element: <CertificationDetailPage />,
        loader: certDetailLoader,
      },
      {
        path: `collection/${CollectionType.TOPIC}/:topicId`,
        element: <RelatedTopicCollectionPage />,
        loader: topicLoader,
      },
      {
        path: `collection/${CollectionType.ROLE_BASED}/:roleId`,
        element: <RoleBasedCollectionPage />,
        loader: roleLoader,
      },
      {
        path: `collection/${CollectionType.CERT_PREP}/:certPrepId`,
        element: <CertificatePreparationCollectionPage />,
        loader: certPrepCollectionLoader,
      },
    ],
  },
];
