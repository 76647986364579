import React from 'react';
import { css } from '@compiled/react';
import { media } from '@atlaskit/primitives/responsive';
import { token } from '@atlaskit/tokens';
import { Heading, Text } from '@atlassian/learning-components';

import { getAssetUrl } from 'src/services/http/user';

export const Header: React.FC = () => (
  <section
    data-testid="home-page-heading"
    aria-labelledby="home-page-heading"
    css={containerStyles}
    style={
      {
        '--desktop-hero-bg': `url(${getAssetUrl('/images/homePage/heroDesktopBackground.png')})`,
      } as Record<string, string>
    }
  >
    <div css={textContentContainerStyles}>
      <div css={logoContainerStyles}>
        <img
          css={css({ marginTop: 0, display: 'flex' })}
          src={getAssetUrl('/images/logos/atlassian-full-logo.svg')}
          alt="atlassian logo"
        />
        <span css={css({ marginLeft: '5px' })}>Learning</span>
      </div>
      <div css={headerContainerStyles}>
        <Heading level={0}>Build skills to work smarter</Heading>
      </div>
      <Text size="large">
        Learn Atlassian products quickly, build skills and confidence, and earn globally recognized certifications.
      </Text>
    </div>
  </section>
);

const containerStyles = css({
  width: '100%',
  height: '496px',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  textAlign: 'center',
  backgroundPosition: 'bottom',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundImage: 'var(--desktop-hero-bg)',

  [media.below.md]: {
    backgroundImage: 'none',
    height: 'auto',
  },
});

const textContentContainerStyles = css({
  maxWidth: '720px',
  boxSizing: 'content-box',
  padding: `${token('space.1000')} ${token('space.300')} 0`,

  [media.below.md]: {
    padding: `${token('space.600')} ${token('space.300')} 0`,
  },

  [media.below.sm]: {
    padding: `${token('space.600')} ${token('space.200')} 0 !important`,
  },
});

const logoContainerStyles = css({
  fontFamily: 'Charlie Display, sans-serif',
  height: '44px',
  fontSize: '16px',
  lineHeight: '16px',
  fontWeight: 400,
  color: '#000000',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  marginBottom: `${token('space.100')}`,
});

const headerContainerStyles = css({
  marginBottom: 0,

  [media.below.sm]: {
    marginBottom: `${token('space.200')}`,
  },
});
