import React, { FC, useCallback, useEffect, useState } from 'react';
import { ModalTransition } from '@atlaskit/modal-dialog';
import { ReportIssueModal } from '@atlassian/learning-components';
import { useLocation } from 'react-router-dom';

import { reportProblem } from 'src/services/http/reportProblem';
import { useAtlassianUser } from 'src/hooks/useAtlassianUser';
import { sendUIEvent } from 'src/utils/analytics';
import { detectPage } from 'src/utils/detectPage';

interface ReportProblemModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

const options = [
  { label: 'Incorrect content', value: '10020' },
  { label: 'Technical issue', value: '10021' },
  { label: 'Broken link or image', value: '10022' },
  { label: 'Other', value: '10023' },
];

export const ReportProblemModal: FC<ReportProblemModalProps> = ({ isOpen, closeModal }) => {
  const { user } = useAtlassianUser();
  const [isDisabled, setIsDisabled] = useState(true);
  const [consentToContact, setConsentToContact] = useState(false);
  const [isParticipatingInResearch, setIsParticipatingInResearch] = useState(false);
  const [selectValue, setSelectValue] = useState<{ label: string; value: string } | undefined>();
  const [textAreaValue, setTextAreaValue] = useState<string | undefined>();
  const location = useLocation();

  const isHomePage = location.pathname === '/';

  const handleSubmit = useCallback(() => {
    if (isDisabled || !selectValue || !textAreaValue) {
      return;
    }

    reportProblem({
      problemType: selectValue.value,
      description: textAreaValue,
      sourcePage: isHomePage ? 'Home page' : location.pathname,
      canBeContacted: consentToContact,
      canBeResearched: isParticipatingInResearch,
      atlassianID: user?.account_id,
    });

    sendUIEvent(
      detectPage(location.pathname),
      'button',
      'clicked',
      'submitReportProblemButton',
      {
        problemType: selectValue.label,
        canBeContacted: consentToContact,
        canBeResearched: isParticipatingInResearch,
        isEmpty: false,
      },
      user?.account_id,
    );

    closeModal();
    setConsentToContact(false);
    setIsParticipatingInResearch(false);
    setSelectValue(undefined);
    setTextAreaValue(undefined);
  }, [
    closeModal,
    consentToContact,
    isDisabled,
    isParticipatingInResearch,
    location.pathname,
    selectValue,
    textAreaValue,
    user?.account_id,
    isHomePage,
  ]);

  const handleClose = useCallback(() => {
    if (!selectValue || !textAreaValue) {
      sendUIEvent(
        detectPage(location.pathname),
        'button',
        'clicked',
        'closeReportProblemButton',
        { isEmpty: true },
        user?.account_id,
      );
    }

    closeModal();
    setConsentToContact(false);
    setIsParticipatingInResearch(false);
    setSelectValue(undefined);
    setTextAreaValue(undefined);
  }, [closeModal, location.pathname, selectValue, textAreaValue, user?.account_id]);

  useEffect(() => {
    if (selectValue && textAreaValue) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [selectValue, textAreaValue]);

  if (!isOpen) {
    return null;
  }

  return (
    <ModalTransition>
      <ReportIssueModal
        heading="Report a problem with Atlassian Learning"
        options={options}
        isDisabled={isDisabled}
        selectValue={selectValue}
        onSelectChange={(e): void => setSelectValue(e)}
        onTextChange={(e): void => setTextAreaValue(e.target.value)}
        onParticipatingInResearch={(): void => setIsParticipatingInResearch(!isParticipatingInResearch)}
        onConsentToContact={(): void => setConsentToContact(!consentToContact)}
        isParticipatingInResearch={isParticipatingInResearch}
        consentToContact={consentToContact}
        textAreaValues={textAreaValue}
        closeModal={handleClose}
        submit={handleSubmit}
      />
    </ModalTransition>
  );
};
