import { gql } from 'graphql-tag';

import {
  AT_IMAGE_FRAGMENT,
  SEO_FRAGMENT,
  BENEFIT_FRAGMENT,
  BENEFITS_CONTAINER_FRAGMENT,
  FAQ_CONTAINER_FRAGMENT,
  FAQ_FRAGMENT,
  RESOURCE_FRAGMENT,
  SOCIAL_PROOF_FRAGMENT,
  STATISTIC_FRAGMENT,
  OR_VIDEO_FRAGMENT,
  MARKETING_CONTENT_BLOCK_FRAGMENT,
  HERO_FRAGMENT,
  ENTRY_LAYOUT_FRAGMENT,
  ASSOCIATED_CONTENT_RESOURCES_COLLECTION_FRAGMENT,
} from 'src/graphql/fragments/fragments';
import { GetFlexibleContentHubCollectionQuery } from 'src/graphql/generated/graphql';

export const GET_FLEXIBLE_CONTENT_HUB_COLLECTION = gql`
  ${SEO_FRAGMENT}
  ${AT_IMAGE_FRAGMENT}
  ${BENEFIT_FRAGMENT}
  ${BENEFITS_CONTAINER_FRAGMENT}
  ${STATISTIC_FRAGMENT}
  ${FAQ_CONTAINER_FRAGMENT}
  ${FAQ_FRAGMENT}
  ${RESOURCE_FRAGMENT}
  ${SOCIAL_PROOF_FRAGMENT}
  ${OR_VIDEO_FRAGMENT}
  ${MARKETING_CONTENT_BLOCK_FRAGMENT}
  ${ENTRY_LAYOUT_FRAGMENT}
  ${HERO_FRAGMENT}
  ${ASSOCIATED_CONTENT_RESOURCES_COLLECTION_FRAGMENT}
  query getFlexibleContentHubCollection($preview: Boolean!, $type: String!) {
    flexibleContentHubCollection(limit: 1, preview: $preview, where: { type: $type }) {
      __typename
      items {
        sys {
          id
        }
        nameInternal
        titleExternal
        type
        hero {
          ...GetHeroFragment
        }
        contentContainerCollection(limit: 100, preview: $preview, where: { sys: { id_exists: true } }) {
          items {
            ... on BenefitsContainer {
              ...GetBenefitsContainerFragment
            }
            ... on Statistic {
              ...GetStatisticFragment
            }
            ... on MarketingContentBlock {
              ...GetMarketingContentBlockFragment
            }
            ... on SocialProof {
              ...GetSocialProofFragment
            }
            ... on FaqContainer {
              ...GetFAQContainerFragment
            }
            ... on OrVideo {
              sys {
                id
              }
              ...OrVideoFragment
            }
            ... on Resource {
              ...GetResourceFragment
            }
          }
        }
        seo {
          ...SeoFragment
        }
      }
    }
  }
`;

export type GetFlexibleContentHubCollectionResponse = GetFlexibleContentHubCollectionQuery;
export type ContentfulFlexibleContentHub =
  GetFlexibleContentHubCollectionQuery['flexibleContentHubCollection']['items'][0];
export type ContentContainersCollection = ContentfulFlexibleContentHub['contentContainerCollection']['items'];
