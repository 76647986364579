import React from 'react';
import { Link, SectionMessage, SectionMessageAppearance } from '@atlassian/learning-components';
import { css } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { media } from '@atlaskit/primitives/responsive';
import { Fragment } from '@compiled/react/jsx-runtime';

import { sendUIEvent } from 'src/utils/analytics';
import { useAtlassianUser } from 'src/hooks/useAtlassianUser';

const LINKS = [
  {
    label: 'Scheduled Classes',
    url: 'https://university.atlassian.com/student/my_events',
  },
  {
    label: 'Live Training History',
    url: 'https://university.atlassian.com/student/history',
  },
];

export const LiveTrainingNotice: React.FC = () => {
  const { user } = useAtlassianUser();

  const onLinkClick = (link: string): void => {
    sendUIEvent('myLearningScreen', 'link', 'clicked', 'sectionMessageLink', { link }, user?.account_id);

    window.open(link, '_blank');
  };

  return (
    <div css={containerStyles}>
      <SectionMessage appearance={SectionMessageAppearance.INFORMATION_WHITE} fullWidth>
        <span css={headingStyles}>Looking for training and classes?</span>
        <span css={messageStyles}>You can access your live training history through your old profile for now.</span>
        <div css={linkContainerStyles}>
          {LINKS.map((link, idx, arr) => (
            <Fragment key={link.label}>
              <Link onClick={() => onLinkClick(link.url)} label={link.label} link={link.url} />
              {idx < arr.length - 1 && <div css={dividerStyles} />}
            </Fragment>
          ))}
        </div>
      </SectionMessage>
    </div>
  );
};

const containerStyles = css({
  marginTop: token('space.300'),

  '& > div': {
    padding: `${token('space.200')} ${token('space.300')}`,

    [media.below.sm]: {
      padding: `${token('space.150')} ${token('space.100')}`,
    },
  },
});

const headingStyles = css({
  display: 'block',
  color: token('color.text'),
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: '600',
});

const messageStyles = css({
  fontSize: '16px',
  lineHeight: '24px',
  paddingTop: token('space.100'),

  [media.below.sm]: {
    fontSize: '14px',
    lineHeight: '22px',
  },
});

const linkContainerStyles = css({
  display: 'flex',
  alignItems: 'center',
  marginTop: token('space.100'),

  a: {
    fontSize: '14px!important',
    lineHeight: '20px!important',
    fontFamily: 'SF Pro, sans-serif',
    fontWeight: 500,
    color: token('color.link'),

    [media.below.sm]: {
      lineHeight: '22px!important',
      fontWeight: 400,
    },
  },

  [media.below.sm]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: token('space.100'),
  },
});

const dividerStyles = css({
  width: '2px',
  height: '2px',
  borderRadius: '50%',
  margin: `0 ${token('space.100')}`,
  backgroundColor: token('color.text.subtle'),

  [media.below.sm]: {
    display: 'none',
  },
});
