import { css } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { media } from '@atlaskit/primitives/responsive';
import {
  AppButton,
  Button,
  ButtonAppearance,
  ButtonSize,
  CommunityApp,
  FlatContentCard,
  FlatContentCardStyle,
  FlatContentCardType,
  getAppButtons,
} from '@atlassian/learning-components';
import { Fragment } from '@compiled/react/jsx-runtime';

import { ContentfulRole } from 'src/graphql/queries/GetRoleCollection';
import { AssociatedContent } from 'src/graphql/generated/graphql';
import { getAssetUrl } from 'src/services/http/user';
import { getImageAttrs } from 'src/utils/contentful';
import { sendUIEvent } from 'src/utils/analytics';
import { useAtlassianUser } from 'src/hooks/useAtlassianUser';
import { handleCardLinkClick } from 'src/pages/collections/RoleBasedCollectionPage/utils/utils';
import { useNavigateByHost } from 'src/hooks/useNavigateByHost';

import { SectionWrapper } from './SectionWrapper';
import { ContentCard } from './ContentCard';

export enum CollectionType {
  ON_DEMAND = 'On Demand',
  ILT = 'ILT',
  CERTIFICATION = 'Certification',
  GROUP = 'Group',
  EVENT = 'Event',
}

interface SectionProps {
  roleData: ContentfulRole;
  collectionType: CollectionType;
}

// fix double casting and typename checking for the contentLinksCollection when parsing of multiple types will be available

export const Section: React.FC<SectionProps> = ({ roleData, collectionType }) => {
  const { user } = useAtlassianUser();
  const navigateByHost = useNavigateByHost();

  const { onDemandContentCollection, iltContent, certificationContent, groupContent, eventContent } = roleData;

  if (collectionType === CollectionType.ON_DEMAND && onDemandContentCollection.items.length) {
    return (
      <>
        {onDemandContentCollection.items.map((item, idx) => (
          <SectionWrapper
            key={item.contentSectionTitle?.contentSectionTitleExternal + item.sys.id}
            title={item.contentSectionTitle?.contentSectionTitleExternal}
            subtitle={item.contentSectionTitle?.contentSectionSubtitle}
            image={getImageAttrs(item.contentSectionTitle?.asset)}
            bgColor={idx % 2 === 0 ? '#F8F8F8' : undefined}
          >
            <div css={cardsContainerStyles}>
              {(item.contentLinksCollection.items as unknown as AssociatedContent[]).map(
                ({ sys, link, titleExternal, description, __typename }) =>
                  __typename === 'AssociatedContent' && (
                    <ContentCard
                      key={titleExternal + sys.id}
                      link={link?.link}
                      title={titleExternal}
                      text={description}
                      linkLabel={link?.label}
                      onClick={(title) => {
                        link?.link && navigateByHost(link.link);
                        handleCardLinkClick(
                          title,
                          item.contentSectionTitle?.contentSectionTitleExternal,
                          user?.account_id,
                        );
                      }}
                    />
                  ),
              )}
            </div>
          </SectionWrapper>
        ))}
      </>
    );
  }

  if (collectionType === CollectionType.ILT && iltContent) {
    return (
      <SectionWrapper
        title={iltContent.contentSectionTitle?.contentSectionTitleExternal}
        subtitle={iltContent.contentSectionTitle?.contentSectionSubtitle}
        image={getImageAttrs(iltContent.contentSectionTitle?.asset)}
        bgColor="#101214"
        textColor={token('color.text.inverse')}
      >
        <div css={blackSectionCardWrapperStyles}>
          {(iltContent.contentLinksCollection.items as unknown as AssociatedContent[]).map(
            ({ sys, link, titleExternal, description, __typename }) =>
              __typename === 'AssociatedContent' && (
                <FlatContentCard
                  key={titleExternal + sys.id}
                  link={link?.link}
                  title={titleExternal}
                  text={description}
                  onClick={(title) => {
                    link?.link && navigateByHost(link.link);
                    handleCardLinkClick(
                      title,
                      iltContent.contentSectionTitle?.contentSectionTitleExternal,
                      user?.account_id,
                    );
                  }}
                />
              ),
          )}
        </div>
      </SectionWrapper>
    );
  }

  if (collectionType === CollectionType.CERTIFICATION && certificationContent) {
    return (
      <SectionWrapper
        title={certificationContent.contentSectionTitle?.contentSectionTitleExternal}
        subtitle={certificationContent.contentSectionTitle?.contentSectionSubtitle}
      >
        <div css={proveYourSkillsCardsStyles}>
          {(certificationContent.contentLinksCollection.items as unknown as AssociatedContent[]).map(
            ({ sys, link, titleExternal, description, asset, __typename }) =>
              __typename === 'AssociatedContent' && (
                <Fragment key={titleExternal + sys.id}>
                  <FlatContentCard
                    link={link?.link}
                    style={FlatContentCardStyle.FULL}
                    title={titleExternal}
                    text={description}
                    image={getImageAttrs(asset)}
                    onClick={(title) => {
                      link?.link && navigateByHost(link.link);
                      handleCardLinkClick(
                        title,
                        certificationContent.contentSectionTitle?.contentSectionTitleExternal,
                        user?.account_id,
                      );
                    }}
                  />
                  <div css={verticalDividerStyles} />
                </Fragment>
              ),
          )}
        </div>
      </SectionWrapper>
    );
  }

  if (collectionType === CollectionType.GROUP && groupContent) {
    const imageAttrs = getImageAttrs(groupContent.contentSectionTitle?.asset);

    const communityApp = (getAppButtons(user?.email).find((item) => item.app === CommunityApp.FORUMS) as AppButton)
      .href;

    const onButtonClick = (): void => {
      window.location.href = user ? communityApp.loggedIn : communityApp.loggedOut;

      sendUIEvent(
        'roleBasedCollectionScreen',
        'button',
        'clicked',
        'seeAllCommunityGroupsButton',
        {},
        user?.account_id,
      );
    };

    return (
      <SectionWrapper
        title={groupContent.contentSectionTitle?.contentSectionTitleExternal}
        subtitle={groupContent.contentSectionTitle?.contentSectionSubtitle}
        image={{
          src: imageAttrs?.src || getAssetUrl('/images/collections/groupBanner.svg'),
          alt: imageAttrs?.alt || 'group banner image',
          size: 'large',
        }}
        bgColor="#CFE1FD"
      >
        <div css={communityGroupsCardsStyles}>
          {(groupContent.contentLinksCollection.items as unknown as AssociatedContent[]).map(
            ({ sys, link, titleExternal, description, asset, __typename }) =>
              __typename === 'AssociatedContent' && (
                <FlatContentCard
                  key={titleExternal + sys.id}
                  link={link?.link}
                  type={FlatContentCardType.SQUARE}
                  title={titleExternal}
                  text={description}
                  headingLevel={4}
                  image={getImageAttrs(asset)}
                  bgColor={token('color.background.input')}
                  linkLabel={link?.label}
                  onClick={(title) => {
                    link?.link && navigateByHost(link.link);
                    handleCardLinkClick(
                      title,
                      groupContent.contentSectionTitle?.contentSectionTitleExternal,
                      user?.account_id,
                    );
                  }}
                />
              ),
          )}
        </div>
        <div css={communityGroupsButtonWrapperStyles}>
          <div css={desktopButtonStyles}>
            <Button
              appearance={ButtonAppearance.PRIMARY}
              size={ButtonSize.LARGE}
              isResponsive={true}
              onClick={onButtonClick}
              label="See all Community groups"
            />
          </div>
          <div css={mobileButtonStyles}>
            <Button
              appearance={ButtonAppearance.PRIMARY}
              size={ButtonSize.LARGE}
              onClick={onButtonClick}
              isResponsive={true}
              label="See all Community groups"
            />
          </div>
        </div>
      </SectionWrapper>
    );
  }

  if (collectionType === CollectionType.EVENT && eventContent) {
    return (
      <SectionWrapper
        title={eventContent.contentSectionTitle?.contentSectionTitleExternal}
        subtitle={eventContent.contentSectionTitle?.contentSectionSubtitle}
        image={getImageAttrs(eventContent.contentSectionTitle?.asset)}
        bgColor="#F8F8F8"
      >
        <div css={cardsContainerStyles}>
          {(eventContent.contentLinksCollection.items as unknown as AssociatedContent[]).map(
            ({ sys, link, titleExternal, description, __typename }, idx) =>
              __typename === 'AssociatedContent' && (
                <ContentCard
                  key={titleExternal + sys.id}
                  link={link?.link}
                  title={titleExternal}
                  text={description}
                  showTopDivider={idx < 2}
                  onClick={(title) => {
                    link?.link && navigateByHost(link.link);
                    handleCardLinkClick(
                      title,
                      eventContent.contentSectionTitle?.contentSectionTitleExternal,
                      user?.account_id,
                    );
                  }}
                />
              ),
          )}
        </div>
      </SectionWrapper>
    );
  }

  return null;
};

const cardsContainerStyles = css({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  columnGap: '56px',

  [media.below.lg]: {
    display: 'flex',
    flexDirection: 'column',
    borderTop: `1px solid ${token('color.border')}`,
  },
});

const blackSectionCardWrapperStyles = css({
  padding: `${token('space.100')} ${token('space.500')}`,
  backgroundColor: token('color.background.input'),
});

const proveYourSkillsCardsStyles = css({
  display: 'flex',
  margin: `${token('space.negative.400')} 0`,

  [media.below.lg]: {
    flexDirection: 'column',
    padding: `0 92px`,
  },

  [media.below.sm]: {
    padding: `0 ${token('space.400')}`,
  },
});

const verticalDividerStyles = css({
  flexGrow: 1,
  width: '1px',
  backgroundColor: token('color.border'),
  margin: `${token('space.400')} ${token('space.300')}`,

  [media.below.lg]: {
    display: 'none',
  },

  '&:last-of-type': {
    display: 'none',
  },
});

const communityGroupsCardsStyles = css({
  display: 'flex',
  gap: token('space.300'),
  marginBottom: token('space.600'),

  [media.below.lg]: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },

  [media.below.sm]: {
    gridTemplateColumns: '1fr',
    marginBottom: token('space.400'),
  },
});

const communityGroupsButtonWrapperStyles = css({
  width: '100%',
  display: 'flex',
  justifySelf: 'center',
});

const desktopButtonStyles = css({
  margin: '0 auto',

  [media.below.sm]: {
    display: 'none',
  },
});

const mobileButtonStyles = css({
  display: 'none',
  margin: '0 auto',
  marginBottom: token('space.negative.100'),

  [media.below.sm]: {
    display: 'block',
  },
});
