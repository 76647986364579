import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Outlet, ScrollRestoration, useLocation } from 'react-router-dom';
import { ContentfulLivePreviewProvider } from '@contentful/live-preview/react';
import '@atlassian/learning-components/dist/styles/index.css';
import '@contentful/live-preview/style.css';
import { css } from '@compiled/react';
import { SharedFooter } from '@atlassian/learning-components';
import { setGlobalTheme } from '@atlaskit/tokens';
import { media } from '@atlaskit/primitives/responsive';

import { AppNavigation } from 'src/pages/App/children/GlobalAppNavigation';
import { usePreviewMode } from 'src/hooks/usePreviewMode';
import 'src/styles/global.css';
import { sendOperationalEvent, sendUIEvent } from 'src/utils/analytics';
import { useAtlassianUser } from 'src/hooks/useAtlassianUser';
import { SubNavbar } from 'src/pages/App/children/SubNavbar';
import { detectPage } from 'src/utils/detectPage';
import { useSidebarVisibility } from 'src/context/SidebarVisibilityContext';
import { LEARNING_LICENSE_DATA } from 'src/constants/licenses';

import { ReportProblemModal } from './children/ReportProblemModal';

export const App: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const preview = usePreviewMode();
  const { user, isLoading, hasError } = useAtlassianUser();
  const isLessonPage = useLocation().pathname.includes('lesson');

  const { isSidebarVisible } = useSidebarVisibility();

  const openModal = useCallback(() => {
    sendUIEvent(
      detectPage(window.location.pathname),
      'button',
      'clicked',
      'openReportProblemButton',
      {},
      userRef.current?.account_id,
    );

    setIsOpen(true);
  }, []);
  const closeModal = useCallback(() => setIsOpen(false), []);

  setGlobalTheme({
    colorMode: 'light',
  });

  const sessionStartTime = useRef(Date.now());
  const userRef = useRef(user);

  useEffect(() => {
    const handleUnload = (): void => {
      sendOperationalEvent(
        'learningApp',
        'session',
        'tracked',
        {
          sessionDuration: Date.now() - sessionStartTime.current, // in milliseconds
        },
        userRef.current?.account_id,
      );
    };

    window.addEventListener('beforeunload', handleUnload);

    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, []);

  useEffect(() => {
    const lastVisitedDate = localStorage.getItem('lastDateVisited');
    const isUserDataLoaded = !isLoading && !hasError;

    if (!isUserDataLoaded) {
      return;
    }

    const isFirstTimeVisiting = !lastVisitedDate;
    const isReturningVisitor = lastVisitedDate
      ? (new Date().getTime() - new Date(lastVisitedDate).getTime()) / (1000 * 60) >= 30
      : false;

    sendOperationalEvent(
      'learningApp',
      'app',
      'visited',
      {
        isFirstTimeVisiting,
        isReturningVisitor,
      },
      user?.account_id,
    );

    localStorage.setItem('lastDateVisited', new Date().toString());
  }, [isLoading, hasError, user]);

  return (
    <ContentfulLivePreviewProvider
      locale="en-US" // Required: allows you to set the locale once and have it reused throughout the preview.
      enableInspectorMode={preview} // Optional: allows you to toggle inspector mode which is on by default.
      enableLiveUpdates={false} // Optional: allows you to toggle live updates which are on by default.
      debugMode={false} // Optional: allows you to toggle debug mode which is off by default.
    >
      <div css={appContainerStyles}>
        <AppNavigation />
        <SubNavbar />
        <div css={outletContainerStyles}>
          <Outlet />
        </div>
        <ScrollRestoration />
        <footer css={footerMarginStyles}>
          <div css={isLessonPage && isSidebarVisible && footerWithMenuStyles}>
            <SharedFooter
              email={user?.email}
              isLoggedIn={!!user?.account_id}
              reportAProblemCallback={openModal}
              licenseData={LEARNING_LICENSE_DATA}
            />
          </div>
        </footer>
      </div>
      <ReportProblemModal isOpen={isOpen} closeModal={closeModal} />
    </ContentfulLivePreviewProvider>
  );
};

const appContainerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

const outletContainerStyles = css({
  '@media (max-width: 1279px)': {
    paddingTop: '56px',
  },
});

const footerMarginStyles = css({
  paddingTop: '50px',
});

const footerWithMenuStyles = css({
  marginLeft: '420px',

  [media.below.md]: {
    marginLeft: 0,
  },
});
