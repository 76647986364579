import React, { ReactElement, useContext, useEffect } from 'react';
import { css } from '@compiled/react';
import { Eyebrow, Heading, Text } from '@atlassian/learning-components';
import { token } from '@atlaskit/tokens';
import { media } from '@atlaskit/primitives/responsive';
import { useLoaderData } from 'react-router-dom';

import { useAtlassianUser } from 'src/hooks/useAtlassianUser';
import { sendScreenEvent } from 'src/utils/analytics';
import { HelmetContext } from 'src/components/Helmet';
import { TopicLoaderProps } from 'src/pages/collections/RelatedTopicCollectionPage/loader';
import { ContentBlock } from 'src/pages/collections/RelatedTopicCollectionPage/children/ContentBlock';
import { getImageAttrs } from 'src/utils/contentful';

export const RelatedTopicCollectionPage = (): ReactElement => {
  const { user, isLoading, hasError } = useAtlassianUser();
  const helmet = useContext(HelmetContext);
  const { topicData } = useLoaderData() as TopicLoaderProps;

  helmet.setHelmet({
    title: `${topicData.topicTitleExternal} | Learning - Atlassian Community`,
    seoTags: topicData.seo,
  });

  useEffect(() => {
    if (!isLoading && !hasError && topicData) {
      sendScreenEvent(
        'relatedTopicCollectionScreen',
        {
          collectionSlug: topicData.slug,
        },
        user?.account_id,
      );
    }
  }, [user, isLoading, hasError, topicData]);

  return (
    <main data-testid="related-topic-collection" css={mainStyles}>
      <section data-testid="related-topic-hero" aria-labelledby="main-heading">
        <div css={containerStyles}>
          <div css={heroContentStyles}>
            <Eyebrow fontWeight="semibold" size="medium" color={token('color.text.subtle')}>
              {topicData.topicHero.eyebrowText}
            </Eyebrow>
            <div data-testid="related-topic-hero-heading">
              <Heading level={0}>{topicData.topicHero.titleExternal}</Heading>
            </div>
            <Text size="large">{topicData.topicHero.subtitle}</Text>
          </div>
        </div>
      </section>
      <>
        {!!topicData.contentContainerCollection.items.length &&
          topicData.contentContainerCollection.items.map((contentBlock, index) => {
            const isEvenBlock = index % 2 === 1;

            return (
              <section
                data-testid="content-block"
                key={contentBlock.sys.id}
                css={[contentBlockContainerStyles, isEvenBlock && evenBlockStyles]}
              >
                <ContentBlock
                  imageAttrs={getImageAttrs(contentBlock.asset)}
                  title={contentBlock.titleExternal}
                  subtitle={contentBlock.subtitle}
                  associatedContent={contentBlock.associatedContentResourcesCollection.items}
                />
              </section>
            );
          })}
      </>
    </main>
  );
};

const mainStyles = css({
  marginTop: '54px',
  paddingTop: '136px',

  '@media (max-width: 1279px)': {
    paddingTop: '0',
  },
});

const containerStyles = css({
  maxWidth: '1440px',
  width: '100%',
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',

  [media.below.lg]: {
    padding: `0 ${token('space.300')}`,
  },

  [media.below.sm]: {
    padding: `0 ${token('space.200')} !important`,
  },
});

const heroContentStyles = css({
  maxWidth: '720px',
  width: '100%',
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  gap: token('space.200'),
  textAlign: 'center',
});

const contentBlockContainerStyles = css({
  paddingTop: token('space.1000'),
  paddingBottom: `${token('space.1000')}`,

  [media.below.sm]: {
    paddingTop: '40px',
    paddingBottom: '40px',
  },

  '&:first-of-type': {
    paddingTop: `${token('space.800')}`,
    paddingBottom: `${token('space.1000')}`,

    [media.below.sm]: {
      paddingTop: '48px',
      paddingBottom: '40px',
    },
  },
});

const evenBlockStyles = css({
  backgroundColor: '#F8F8F8',
});
