import { useState, FormEvent, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import FeatureGates from '@atlaskit/feature-gate-js-client';
import { css } from '@compiled/react';
import { media } from '@atlaskit/primitives/responsive';
import { N10, N50, N800 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import {
  Button,
  ButtonAppearance,
  ButtonSize,
  Checkbox,
  FeedbackHeader,
  Link,
  TextField,
} from '@atlassian/learning-components';
import TextArea from '@atlaskit/textarea';

import { FEEDBACK_PAGE_FF } from 'src/constants';

import { SingleSelectField } from './children/SingleSelectFiled';
import { mockedMultipleSelectOptions, MultipleSelectField } from './children/MultipleSelect';

export const FeedbackPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const courseName = location.state?.courseName || 'course';

  const [selectedFirstAnswer, setSelectedFirstAnswers] = useState<string | null>(null);
  const [selectedSecondAnswer, setSelectedSecondAnswers] = useState<string | null>(null);

  const multiSelectInitialState = mockedMultipleSelectOptions.reduce(
    (acc, option: string) => {
      acc[option] = false;

      return acc;
    },
    {} as Record<string, boolean>,
  );

  const [multiSelectAnswers, setMultiSelectAnswers] = useState<Record<string, boolean>>(multiSelectInitialState);

  const [ratingReason, setRatingReason] = useState('');
  const [courseFeedback, setCourseFeedback] = useState('');

  const [isParticipatingInResearch, setIsParticipatingInResearch] = useState(false);
  const [consentToContact, setConsentToContact] = useState(false);

  const isFeedbackPageAccessible = FeatureGates.checkGate(FEEDBACK_PAGE_FF);

  useEffect(() => {
    if (!isFeedbackPageAccessible) {
      navigate(`/not-found`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFirstQuestionSelect = (answer: string): void => setSelectedFirstAnswers(answer);
  const handleSecondQuestionSelect = (answer: string): void => setSelectedSecondAnswers(answer);
  const handleMultiSelectAnswers = (answer: string): void =>
    setMultiSelectAnswers((prev) => ({ ...prev, [answer]: !prev[answer] }));

  const handleRatingReason = (event: FormEvent<HTMLInputElement>): void => setRatingReason(event.currentTarget.value);
  const handleCourseFeedback = (event: FormEvent<HTMLTextAreaElement>): void =>
    setCourseFeedback(event.currentTarget.value);

  const handleParticipatingInResearch = (): void => setIsParticipatingInResearch(!isParticipatingInResearch);
  const handleConsentToContact = (): void => setConsentToContact(!consentToContact);

  const handleExit = (): void => navigate(`/course/${params.courseId}`);

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
  };

  const link = 'https://www.atlassian.com/legal/privacy-policy#what-this-policy-covers';

  const isSubmitDisabled = !selectedFirstAnswer || !selectedSecondAnswer;

  return (
    <main css={mainSectionStyles} id="course-body" data-testid="course-page">
      <div css={headerContainerStyles}>
        <div css={sharedContainerStyles} data-testid="course-header">
          <FeedbackHeader
            duration="1-3 MIN"
            heading="Feedback survey"
            description={`Thanks for sharing your thoughts on ${courseName}. Your feedback helps us improve.`}
          />
        </div>
      </div>
      <div css={sharedContainerStyles}>
        <section data-testid="feedback-section" css={feedbackStyles}>
          <div css={markdownContainerStyles}>
            <form onSubmit={handleSubmit}>
              <p css={requiredNote}>
                Questions marked with <span css={asteriskStyles}>*</span> are required
              </p>
              <SingleSelectField
                selectedAnswer={selectedFirstAnswer}
                onSelect={handleFirstQuestionSelect}
                fieldTitle="Overall, how satisfied are you with the Atlassian Learning Course?"
              />
              <SingleSelectField
                selectedAnswer={selectedSecondAnswer}
                onSelect={handleSecondQuestionSelect}
                fieldTitle="Overall, how easy was your experience navigating the Atlassian Learning website?"
              />
              <MultipleSelectField
                selectedAnswers={multiSelectAnswers}
                onSelect={handleMultiSelectAnswers}
                fieldTitle="Example of multiple choice question."
              />
              <div css={marginBottomStyles}>
                <p css={fieldTitleStyles}>Please explain why you selected this rating.</p>
                <TextField placeholder="Placeholder" value={ratingReason} onChange={handleRatingReason} />
              </div>
              <div css={marginBottomStyles}>
                <p css={fieldTitleStyles}>What did you like most about this course?</p>
                <div css={textAreaStyles}>
                  <TextArea
                    placeholder="Placeholder"
                    value={courseFeedback}
                    onChange={handleCourseFeedback}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                  />
                </div>
              </div>
              <div css={marginBottomStyles}>
                <div css={css({ marginBottom: token('space.100') })}>
                  <Checkbox
                    label="I'd like to participate in product research"
                    onChange={handleParticipatingInResearch}
                    isChecked={isParticipatingInResearch}
                  />
                </div>
                <div css={policyContainerStyles}>
                  <Checkbox
                    label={
                      <span>
                        Yes, Atlassian teams can contact me to learn about my experiences to improve Atlassian products
                        and services. I acknowledge the Atlassian Privacy Policy
                        <Link
                          label="Atlassian Privacy Policy."
                          target="_blank"
                          link={link}
                          onClick={() => window.open(link, '_blank', 'noopener')}
                        />
                      </span>
                    }
                    onChange={handleConsentToContact}
                    isChecked={consentToContact}
                  />
                </div>
              </div>
              <div css={[buttonContainerStyles, marginBottomStyles]}>
                <Button
                  appearance={ButtonAppearance.SECONDARY}
                  size={ButtonSize.LARGE}
                  isResponsive={true}
                  onClick={handleExit}
                  label="Exit"
                />
                <Button disabled={isSubmitDisabled} label="Submit" type="submit" />
              </div>
            </form>
          </div>
        </section>
      </div>
    </main>
  );
};

const mainSectionStyles = css({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const marginBottomStyles = css({
  marginBottom: token('space.600'),
});

const headerContainerStyles = css({
  backgroundColor: N10,
  marginBottom: token('space.800'),
});

const sharedContainerStyles = css({
  maxWidth: '846px',
  width: '100%',
  margin: '0 auto',

  [media.below.lg]: {
    maxWidth: 'unset',
    paddingLeft: token('space.300'),
    paddingRight: token('space.300'),
  },

  [media.below.sm]: {
    paddingLeft: token('space.200'),
    paddingRight: token('space.200'),
  },
});

const feedbackStyles = css({
  width: '100%',
  marginBottom: '56px',
  color: N800,
});

const markdownContainerStyles = css({
  maxWidth: '846px',
  margin: '0 auto',

  [media.below.lg]: {
    maxWidth: 'unset',
  },
  hr: {
    height: '1px',
    border: `0 solid  ${token('color.border')}`,
    margin: 0,
    backgroundColor: N50,
  },
});

const requiredNote = css({
  color: token('color.text.subtlest'),
  fontFamily: 'Charlie Display, sans-serif',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
  marginBottom: '29px',
});

const asteriskStyles = css({
  color: token('color.text.danger'),
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
});

const fieldTitleStyles = css({
  color: token('color.text'),
  fontFamily: 'Inter, sans-serif',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
  marginBottom: token('space.200'),
});

const textAreaStyles = css({
  textarea: {
    borderRadius: '4px',
    fontSize: '16px',
    height: '80px !important',
    padding: `${token('space.100')} !important`,
  },

  'textarea::placeholder': {
    fontWeight: 400,
    fontFamily: 'Inter, sans-serif',
    color: token('color.text.subtlest'),
  },
});

const policyContainerStyles = css({
  display: 'flex',
  a: {
    marginLeft: '5px',
  },
});

const buttonContainerStyles = css({
  display: 'flex',
  justifyContent: 'space-between',

  'button:nth-child(2)': {
    padding: '10px 12px',
    height: 'auto',
    lineHeight: '20px',
  },
});
