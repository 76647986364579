import { ContentHubType } from 'src/constants';

interface BreadCrumbsLink {
  label: string;
  link: string;
}

const LEARNING_PATHS_HUB_LABEL = 'Learning Paths';

export const generateBreadcrumbLinks = (
  learningPath: { title: string; slug: string } | null,
  course: { title: string; slug: string } | null,
  lessonSlug: string | null,
): BreadCrumbsLink[] => {
  const links: BreadCrumbsLink[] = [];

  const isHub = globalThis.location.pathname.includes(`hub/${ContentHubType.LEARNING_PATHS}`);
  const isCourse = course && globalThis.location.pathname.includes(`course/${course.slug}`);
  const isLesson = lessonSlug && globalThis.location.pathname.includes(`lesson/${lessonSlug}`);

  if (isHub) {
    links.push({
      label: LEARNING_PATHS_HUB_LABEL,
      link: `/hub/${ContentHubType.LEARNING_PATHS}`,
    });
  }

  if (learningPath && (isCourse || isLesson)) {
    const basePath = isHub ? `/hub/${ContentHubType.LEARNING_PATHS}` : '';

    links.push({
      label: learningPath.title,
      link: `${basePath}/path/${learningPath.slug}`,
    });
  }

  if (isLesson && course) {
    const basePath = isHub ? `/hub/${ContentHubType.LEARNING_PATHS}` : '';
    const pathPrefix = learningPath ? `/path/${learningPath.slug}` : '';

    links.push({
      label: course.title,
      link: `${basePath}${pathPrefix}/course/${course.slug}`,
    });
  }

  return links;
};
