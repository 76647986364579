import { useMemo } from 'react';

import { LearningPathData } from 'src/pages/LessonPage/children/BadgeModal';

enum ButtonLabel {
  FIND_YOUR_NEXT_COURSE = 'Find your next course',
  CONTINUE_TO_NEXT_COURSE = 'Continue to next course',
}

export const useHandleRedirect = (
  learningPathData: LearningPathData | null,
  isLearningPathComplete: boolean,
  redirectToCatalog: () => void,
  goToNextCourse: () => void,
): (() => void) =>
  useMemo(() => {
    if (learningPathData?.nextCourse && !isLearningPathComplete) {
      return goToNextCourse;
    }

    return redirectToCatalog;
  }, [learningPathData, isLearningPathComplete, redirectToCatalog, goToNextCourse]);

export const useGenerateButtonLabel = (
  learningPathData: LearningPathData | null,
  isLearningPathComplete: boolean,
): ButtonLabel =>
  useMemo(() => {
    if (learningPathData?.nextCourse && !isLearningPathComplete) {
      return ButtonLabel.CONTINUE_TO_NEXT_COURSE;
    }

    return ButtonLabel.FIND_YOUR_NEXT_COURSE;
  }, [learningPathData, isLearningPathComplete]);
