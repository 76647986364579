import { useDeviceSize } from '@atlassian/learning-components';
import React, { createContext, useState, ReactNode, useContext, useMemo } from 'react';

interface SidebarVisibilityContextProps {
  isSidebarVisible: boolean;
  setIsSidebarVisible: (value: boolean) => void;
}

const SidebarVisibilityContext = createContext<SidebarVisibilityContextProps>({
  isSidebarVisible: true,
  setIsSidebarVisible: () => {},
});

interface SidebarVisibilityProviderProps {
  children: ReactNode;
}

const TABLET_SCREEN_WIDTH = 768;

export const SidebarVisibilityProvider: React.FC<SidebarVisibilityProviderProps> = ({ children }) => {
  const deviceWidth = useDeviceSize();
  const isSidebarVisibleByDefault = useMemo(() => deviceWidth >= TABLET_SCREEN_WIDTH, [deviceWidth]);
  const [isSidebarVisible, setIsSidebarVisible] = useState(isSidebarVisibleByDefault);

  return (
    <SidebarVisibilityContext.Provider value={{ isSidebarVisible, setIsSidebarVisible }}>
      {children}
    </SidebarVisibilityContext.Provider>
  );
};

export const useSidebarVisibility = (): SidebarVisibilityContextProps => {
  const context = useContext(SidebarVisibilityContext);

  if (context === undefined) {
    throw new Error('useSidebarVisibility must be used within a SidebarVisibilityProvider');
  }

  return context;
};
