import { token } from '@atlaskit/tokens';

import pdfLearningPathHeaderUrl from 'src/assets/images/pdfLearningPathHeader.svg?url';
import { GeneratePdfContentType } from 'src/pages/MyLearningPage/types';

interface GeneratePdfProps {
  title: string;
  completionDate: string;
  userName: string;
  imageUrl?: string;
  contentType: GeneratePdfContentType;
}

export const generatePdf = async ({
  title,
  completionDate,
  userName,
  imageUrl,
  contentType,
}: GeneratePdfProps): Promise<void> => {
  const isLearningPath = contentType === GeneratePdfContentType.LEARNING_PATH;
  const isArchivedContent = contentType === GeneratePdfContentType.ARCHIVED_CONTENT;
  const myLearningCardsFileName = `${userName} - ${title} ${isLearningPath ? 'Learning Path' : 'Course'}.pdf`;
  const archivedContentFileName = `${userName} - ${title}.pdf`;

  const pdfContentDiv = document.createElement('div');

  pdfContentDiv.className = 'pdf-content';

  const headerImageSection = isLearningPath
    ? `<img class="certificate-background" src="${pdfLearningPathHeaderUrl}" style="${certificateBackgroundStyles}" alt="certificate-background"/>`
    : `<div style="${imageWrapperStyles}">
       <img class="certificate-image" src="${imageUrl ?? ''}" style="${imageStyles}" alt="certificate"/>
     </div>`;

  const [year, month, day] = completionDate.split('-');
  const formattedDate = `${day}/${month}/${year}`;

  const commonContent = `
      <div style="${contentWrapperStyles}">
      <div style="${secondTitleStyles}">${title}</div>
      </div>
      <div style="${contentWrapperStyles}">
          <div style="${firstTitleStyles}">Completed by</div>
      <div style="${secondTitleStyles}">${userName}</div>
      </div>
      <div style="${contentWrapperStyles}">
      <div style="${firstTitleStyles}">Completed on</div>
      <div style="${secondTitleStyles}">${formattedDate}</div>
      </div>
  `;

  const retiredPdfContainer = `
      <div style="${retiredPdfContainerStyles}">
      <div style="${commonContentWrapperStyles}">
      ${commonContent}
      </div>
      </div>
  `;

  const myLearningCardsPdfContainer = `
    <div style="${pdfContainerStyles}">
      ${headerImageSection}
      <div style="${firstTitleStyles}">${isLearningPath ? 'LEARNING PATH' : 'COURSE'}</div>
      ${commonContent}
    </div>
  `;

  pdfContentDiv.innerHTML = isArchivedContent ? retiredPdfContainer : myLearningCardsPdfContainer;

  document.querySelector('#pdf-container')?.appendChild(pdfContentDiv);

  const { default: html2pdf } = await import('html2pdf.js');

  await html2pdf()
    .from(pdfContentDiv)
    .set({
      margin: 0,
      filename: isArchivedContent ? archivedContentFileName : myLearningCardsFileName,
      html2canvas: { scale: 4, useCORS: true },
      jsPDF: {
        unit: 'px',
        format: isArchivedContent ? [420, 428] : [420, 520],
        orientation: 'portrait',
      },
    })
    .save();
};

const firstTitleStyles = `
  width: 340px;
  color: ${token('color.text')};
  text-align: center;
  font-family: 'Charlie Text';
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  text-transform: uppercase;
  margin: 0 auto 16px auto;
`;

const secondTitleStyles = `
  width: 340px;
  color: ${token('color.text')};
  text-align: center;
  font-family: 'Charlie Display';
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
`;

const imageWrapperStyles = `
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  flex-shrink: 0;
  margin: 62px auto 32px auto;
`;

const imageStyles = `
  width: 64px;
  height: 64px;
  flex-shrink: 0;
  border-radius: 64px;
  background: lightgray 50% / cover no-repeat;
`;

const pdfContainerStyles = `
  text-align: center;
  width: 420px;
  height: 520px;
  overflow: hidden;
`;

const retiredPdfContainerStyles = `
  text-align: center;
  width: 420px;
  height: 428px;
  overflow: hidden;
`;

const certificateBackgroundStyles = `
  width: 420px;
  height: 102px;
  flex-shrink: 0;
  margin: 0 0 56px 0;
`;

const commonContentWrapperStyles = `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 66px auto 0 auto;
`;

const contentWrapperStyles = `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto 40px auto;
`;
