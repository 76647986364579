import React from 'react';
import { useNavigate } from 'react-router-dom';

import { getInspectModeData } from 'src/utils/contentful';
import { ResourceCard } from 'src/components/ResourceCard';
import { ContentfulRelatedContentResource } from 'src/graphql/queries/GetSmCourse';
import { ContentType } from 'src/types';
import { ResourceType } from 'src/constants';
import { getContentProduct } from 'src/utils/getContentProduct';
import { getUrlPrefix } from 'src/services/http/user';

export interface RecommendedResourceProps {
  resource: ContentfulRelatedContentResource;
  sequence: number;
}

export const RecommendedResource: React.FC<RecommendedResourceProps> = ({ resource }) => {
  const navigate = useNavigate();

  if (resource?.__typename === ContentType.LEARNING_PATH) {
    return (
      <ResourceCard
        contentType={ResourceType.LearningPath}
        product={getContentProduct(resource.product)}
        inspectMode={getInspectModeData(resource, ['title'])}
        title={resource.title}
        tags={[{ label: 'learning path' }]}
        link={`${getUrlPrefix()}/path/${resource.slug}`}
        target="_self"
        onClick={() => navigate(`/path/${resource.slug}`)}
      />
    );
  }

  if (resource?.__typename === ContentType.COURSE) {
    return (
      <ResourceCard
        contentType={ResourceType.SmCourse}
        product={getContentProduct(resource.product)}
        inspectMode={getInspectModeData(resource, ['title'])}
        title={resource.title}
        tags={[{ label: 'course' }]}
        target="_self"
        link={`${getUrlPrefix()}/course/${resource.slug}`}
        onClick={() => navigate(`/course/${resource.slug}`)}
      />
    );
  }

  if (resource?.__typename === ContentType.LESSON) {
    return (
      <ResourceCard
        contentType={ResourceType.SmLesson}
        product={getContentProduct(resource.products)}
        inspectMode={getInspectModeData(resource, ['title'])}
        title={resource.title}
        tags={[{ label: 'lesson' }]}
        target="_self"
        link={`${getUrlPrefix()}/course/${resource.linkedFrom?.smCourseCollection.items[0]?.slug}/lesson/${
          resource.slug
        }`}
        onClick={() =>
          navigate(`/course/${resource.linkedFrom?.smCourseCollection.items[0]?.slug}/lesson/${resource.slug}`)
        }
      />
    );
  }

  return null;
};
