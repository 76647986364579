import React, { createContext, ReactNode, useMemo } from 'react';

import { GetCourseQuery, GetSmLessonQuery } from 'src/graphql/generated/graphql';
import { GetLearningPathQuery } from 'src/graphql/generated/GetLearningPath';

export interface SetHelmetProps {
  title: string;
  seoTags:
    | GetCourseQuery['smCourseCollection']['items'][0]['seo']
    | GetLearningPathQuery['learningPathCollection']['items'][0]['seo']
    | GetSmLessonQuery['smLessonCollection']['items'][0]['seo']
    | Record<string, never>;
}

interface HelmetContextProps {
  setHelmet: (helmet: SetHelmetProps) => void;
}

interface HelmetProviderProps {
  setHelmet: (helmet: SetHelmetProps) => void;
  children: ReactNode;
}

export const HelmetContext = createContext<HelmetContextProps>({
  setHelmet: () => {},
});

export const HelmetProvider: React.FC<HelmetProviderProps> = ({ setHelmet, children }) => {
  const memoizedSetHelmet = useMemo(() => ({ setHelmet }), [setHelmet]);

  return <HelmetContext.Provider value={memoizedSetHelmet}>{children}</HelmetContext.Provider>;
};
