import { sendTrackEvent, sendUIEvent } from 'src/utils/analytics';
import { User } from 'src/types';
import { LessonLoaderProps } from 'src/pages/LessonPage/loader';
import { isStandalone } from 'src/pages/LessonPage/utils/isStandalone';

interface TrackProgressParams {
  action: string;
  isLoading: boolean;
  hasError: boolean;
  user: User | null | undefined;
  courseSlug: string;
  lessonSlug: string;
}

interface TrackButtonClickParams {
  courseSlug: string;
  lessonSlug: string;
  lesson: LessonLoaderProps['lesson'];
  type: string;
  user: User | null | undefined;
}

interface TrackAudioProgressParams extends TrackProgressParams {
  audioProgress: number;
}

interface TrackVideoProgressParams extends TrackProgressParams {
  videoProgress: number;
  name: string;
  videoId: string;
}

export const trackAudioProgress = ({
  action,
  audioProgress,
  isLoading,
  hasError,
  user,
  courseSlug,
  lessonSlug,
}: TrackAudioProgressParams): void => {
  if (!isNaN(audioProgress) && !isLoading && !hasError) {
    sendTrackEvent(
      'lessonScreen',
      'audio',
      action,
      {
        courseSlug,
        lessonSlug,
        audioProgress,
      },
      user?.account_id,
    );
  }
};

export const trackVideoProgress = ({
  action,
  videoProgress,
  isLoading,
  hasError,
  user,
  courseSlug,
  lessonSlug,
  name,
  videoId,
}: TrackVideoProgressParams): void => {
  if (!isNaN(videoProgress) && !isLoading && !hasError) {
    sendTrackEvent(
      'lessonScreen',
      'video',
      action,
      {
        name,
        courseSlug,
        lessonSlug,
        videoProgress,
        videoId,
      },
      user?.account_id,
    );
  }
};

export const trackAudioButtonClick = ({ lessonSlug, courseSlug, lesson, type, user }: TrackButtonClickParams): void => {
  sendUIEvent(
    'lessonScreen',
    'button',
    'clicked',
    'audioPlayButton',
    {
      audioId: lesson?.audioPlayer?.sys.id,
      courseSlug,
      lessonSlug,
      type,
      isStandaloneLesson: isStandalone(),
    },
    user?.account_id,
  );
};
