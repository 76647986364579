import React, { MutableRefObject } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { QuestionStatus, QuestionType, QuizQuestion } from '@atlassian/learning-components';
import { cloneDeep } from 'lodash';
import shuffle from 'lodash.shuffle';

import { User } from 'src/types';
import { getImageAttrs, getInspectModeData } from 'src/utils/contentful';
import { getFeedbackMessageOptions } from 'src/pages/LessonPage/children/utils/optionsUtils';
import { ContentfulSmLesson, ContentfulSmQuestion } from 'src/graphql/queries/GetSmLesson';
import { sendTrackEvent } from 'src/utils/analytics';
import { QuizStatus } from 'src/pages/LessonPage/children/Quiz';
import { ContentfulSmCourse } from 'src/graphql/queries/GetSmCourse';
import {
  completeQuestionForQuiz,
  getOrCreateProgressForQuiz,
  getUncompletedQuestionsForQuiz,
  LearningProgressStatus,
  ProgressForCourses,
  ProgressForLessons,
  saveProgressForLesson,
  saveProgressForQuiz,
} from 'src/services/http/progress';
import { isStandalone } from 'src/pages/LessonPage/utils/isStandalone';

export interface QuizInteractionAnalytics {
  lessonId?: string;
  startTimeMs: number;
  notStarted: boolean;
  isDirty: boolean;
}

const QuestionTypeConverter: Record<ContentfulSmQuestion['type'], QuestionType> = {
  multiple: QuestionType.MULTI_SELECT,
  single: QuestionType.SINGLE_SELECT,
  matching: QuestionType.MATCHING,
};

export const getLastUncompletedQuestion = (questions: ContentfulSmQuestion[], uncompletedQuestions: string[]): number =>
  questions?.map((q) => !uncompletedQuestions.includes(q.sys.id)).lastIndexOf(true);

export const sortQuestions = (lesson: ContentfulSmLesson, uncompletedQuestions: string[]): ContentfulSmQuestion[] => {
  if (!lesson || !lesson.quiz) {
    return [];
  }

  return [...lesson.quiz.questionsCollection.items].sort((q) => (uncompletedQuestions.includes(q.sys.id) ? 1 : -1));
};

export const getMatchingQuestions = (
  questions: ContentfulSmQuestion[],
  preview: boolean,
  lesson: ContentfulSmLesson,
  user?: User | null | undefined,
): QuizQuestion[] =>
  questions.map((question) => {
    const leftAnswers = question.answersCollection.items.map((answer) => ({
      inspectMode: getInspectModeData(answer, ['title']),
      title: answer.title,
      matchingAnswer: { title: answer.matchingAnswer?.title || '' },
      correct: false,
      isLeftMatchingAnswer: true,
    }));

    const rightAnswers = question.answersCollection.items.map((answer) => ({
      inspectMode: getInspectModeData(answer.matchingAnswer, ['title']),
      title: answer.matchingAnswer?.title || '',
      matchingAnswer: { title: answer.title },
      correct: false,
      isLeftMatchingAnswer: false,
    }));

    return {
      inspectMode: getInspectModeData(question, ['title']),
      title: <span>{documentToReactComponents(question.title.json)}</span>,
      imageUrl: question.image ? getImageAttrs(question.image)?.src : '',
      feedbackMessage: <>{documentToReactComponents(question.feedbackMessage?.json, getFeedbackMessageOptions)}</>,
      answers: question.answersCollection.items.map((answer) => ({
        ...answer,
        inspectMode: getInspectModeData(answer, ['title', 'feedback']),
      })),
      type: QuestionTypeConverter[question.type],
      leftAnswers: shuffle(leftAnswers),
      rightAnswers: shuffle(rightAnswers),
      onCompletion: (): void => {
        if (!preview && user) {
          completeQuestionForQuiz({
            atlassianId: user.account_id,
            lessonId: lesson.sys.id,
            quizId: lesson.quiz.sys.id,
            questionId: question.sys.id,
          });
        }
      },
    };
  });

export const handleAnswerSubmit = (
  type: QuestionType,
  status: QuestionStatus,
  courseId: string | undefined,
  lessonId: string | undefined,
  quizStep: number,
  user: User | null | undefined,
): void => {
  if (status !== QuestionStatus.CORRECT) {
    sendTrackEvent(
      'lessonScreen',
      'quizQuestion',
      'answeredWrong',
      {
        courseSlug: courseId,
        lessonSlug: lessonId,
        questionIndex: quizStep,
        questionType: type,
        answerStatus: status,
        isStandaloneLesson: isStandalone(),
      },
      user?.account_id,
    );
  }
};

export const getUncompletedQuestionsAndStepAsync = async (
  user: User | null | undefined,
  lesson: ContentfulSmLesson,
): Promise<{ questions: ContentfulSmQuestion[]; step: number; isClean?: boolean }> => {
  if (!user) {
    return {
      questions: lesson.quiz.questionsCollection.items,
      step: -1,
      isClean: true,
    };
  }

  const uncompletedQuestions = await getUncompletedQuestionsForQuiz({
    atlassianId: user.account_id,
    lessonId: lesson.sys.id,
    quizId: lesson.quiz.sys.id,
    allQuestionIds: lesson.quiz?.questionsCollection.items.map((q) => q.sys.id) ?? [],
  });

  if (!uncompletedQuestions?.uncompletedQuestionIds?.length) {
    return {
      questions: [],
      step: 0,
    };
  }

  const questions = sortQuestions(lesson, uncompletedQuestions.uncompletedQuestionIds);
  const step = getLastUncompletedQuestion(questions, uncompletedQuestions.uncompletedQuestionIds);

  return {
    questions,
    step,
    isClean: uncompletedQuestions.uncompletedQuestionIds.length === questions.length,
  };
};

export const completeLessonAsync = async (
  user: User | null | undefined,
  lesson: ContentfulSmLesson | undefined,
  progress: ProgressForLessons | undefined,
): Promise<void> => {
  if (!lesson || !user) {
    return;
  }

  // complete any leftover sections
  const currentLessonProgress = progress?.lessons.find((p) => p.lessonId === lesson.sys.id);

  lesson.sectionListCollection.items.forEach((section) => {
    const currentSectionProgress = currentLessonProgress?.section?.find((p) => p.sectionId === section.id);

    if (currentSectionProgress?.status !== LearningProgressStatus.COMPLETED) {
      saveProgressForLesson({
        atlassianId: user.account_id,
        lesson: {
          lessonId: lesson.sys.id,
          status: LearningProgressStatus.COMPLETED,
          section: {
            sectionId: section.sys.id,
            status: LearningProgressStatus.COMPLETED,
          },
        },
      });
    }
  });

  // obviously, it is an SQS call, so no guaranty progress for lesson
  // will be created earlier than progress for quiz (in case lesson has no sections)
  // but let's at least try
  await saveProgressForLesson({
    atlassianId: user.account_id,
    lesson: {
      lessonId: lesson.sys.id,
      status: LearningProgressStatus.COMPLETED,
    },
  });

  // it is important to ensure that progress for quiz was created before updating it
  await getOrCreateProgressForQuiz(lesson.sys.id, lesson.quiz.sys.id);

  saveProgressForQuiz({
    atlassianId: user.account_id,
    lessonId: lesson.sys.id,
    quizId: lesson.quiz.sys.id,
    newStatus: LearningProgressStatus.COMPLETED,
  });
};

export const sendCompletionAnalytics = (
  course: ContentfulSmCourse,
  lesson: ContentfulSmLesson,
  progress: ProgressForLessons | undefined,
  user: User | null | undefined,
  quizInteractionAnalytics: QuizInteractionAnalytics | null | undefined,
): void => {
  if (!user || !progress) {
    return;
  }

  const areLessonsCompleted = course.lessonsCollection.items.every((l) => {
    const lessonProgress = progress.lessons.find((p) => p.lessonId === l.sys.id);

    if (lessonProgress?.lessonId === lesson.sys.id) {
      return true;
    }

    return lessonProgress?.status === LearningProgressStatus.COMPLETED;
  });

  if (areLessonsCompleted) {
    sendTrackEvent(
      'learningApp',
      'course',
      'completed',
      {
        courseSlug: course?.slug,
      },
      user.account_id,
    );
  }

  const quizCompletionDurationMs =
    quizInteractionAnalytics && new Date().getTime() - quizInteractionAnalytics.startTimeMs;

  sendTrackEvent(
    'lessonScreen',
    'quiz',
    'completed',
    {
      courseSlug: course.slug,
      lessonSlug: lesson.slug,
      interactionDurationMs: quizInteractionAnalytics?.notStarted ? quizCompletionDurationMs : 0,
    },
    user.account_id,
  );
};

export const updateProgressUponLessonCompletion = (
  course: ContentfulSmCourse,
  lesson: ContentfulSmLesson,
  progress: ProgressForLessons | undefined,
): ProgressForLessons => {
  const areAllLessonsCompleted = course.lessonsCollection.items.every(
    (l) => !!progress?.lessons?.find((p) => p.lessonId === l.sys.id),
  );

  const nextProgress = {
    ...progress,
    lessons: cloneDeep(progress?.lessons || []),
    overallStatus: areAllLessonsCompleted ? LearningProgressStatus.COMPLETED : LearningProgressStatus.IN_PROGRESS,
  };

  const lessonProgress = nextProgress.lessons.find((p) => p.lessonId === lesson.sys.id);

  if (!lessonProgress) {
    nextProgress.lessons.push({
      lessonId: lesson.sys.id,
      status: LearningProgressStatus.COMPLETED,
      section: [
        {
          sectionId: 'quiz',
          status: LearningProgressStatus.COMPLETED,
        },
      ],
    });

    return nextProgress;
  }

  lessonProgress.status = LearningProgressStatus.COMPLETED;
  const sectionProgress = lessonProgress.section.find((p) => p.sectionId === 'quiz');

  if (!sectionProgress) {
    lessonProgress.section.push({
      sectionId: 'quiz',
      status: LearningProgressStatus.COMPLETED,
    });

    return nextProgress;
  }

  sectionProgress.status = LearningProgressStatus.COMPLETED;

  return nextProgress;
};

export const updateLearningPathProgressUponLessonCompletion = (
  course: ContentfulSmCourse,
  progressForLessons: ProgressForLessons | undefined,
  progressForCourses: ProgressForCourses | undefined,
): ProgressForCourses => {
  const nextProgressForCourses = {
    overallStatus: LearningProgressStatus.IN_PROGRESS,
    courses: [],
    ...progressForCourses,
  };

  if (!progressForLessons?.lessons.every((l) => l.status === LearningProgressStatus.COMPLETED)) {
    return nextProgressForCourses;
  }

  const courseProgressIndex = nextProgressForCourses.courses.findIndex((c) => c.courseId === course.sys.id);

  if (courseProgressIndex < 0) {
    nextProgressForCourses.courses.push({
      courseId: course.sys.id,
      status: LearningProgressStatus.COMPLETED,
    });
  } else if (nextProgressForCourses.courses[courseProgressIndex].status !== LearningProgressStatus.COMPLETED) {
    nextProgressForCourses.courses[courseProgressIndex].status = LearningProgressStatus.COMPLETED;
  }

  if (nextProgressForCourses.courses.every((c) => c.status === LearningProgressStatus.COMPLETED)) {
    nextProgressForCourses.overallStatus = LearningProgressStatus.COMPLETED;
  }

  return nextProgressForCourses;
};

export const handleUnload = ({
  lesson,
  user,
  quizInteractionAnalytics,
  quizAbandonedAnalyticsAttributes,
}: {
  lesson: ContentfulSmLesson;
  user: User | null | undefined;
  quizInteractionAnalytics: MutableRefObject<QuizInteractionAnalytics | null>;
  quizAbandonedAnalyticsAttributes: MutableRefObject<null | Record<string, string | number | undefined>>;
}): void => {
  const interactionDurationMs =
    quizInteractionAnalytics.current &&
    !!quizInteractionAnalytics.current.startTimeMs &&
    new Date().getTime() - quizInteractionAnalytics.current.startTimeMs;

  quizInteractionAnalytics.current = {
    lessonId: lesson.sys.id,
    startTimeMs: 0,
    notStarted: false,
    isDirty: false,
  };

  if (quizAbandonedAnalyticsAttributes.current && interactionDurationMs) {
    sendTrackEvent(
      'lessonScreen',
      'quiz',
      'abandoned',
      {
        ...quizAbandonedAnalyticsAttributes.current,
        interactionDurationMs,
      },
      user?.account_id,
    );
    quizAbandonedAnalyticsAttributes.current = null;
  }
};

export const handleInteraction = (
  quizInteractionAnalytics: MutableRefObject<QuizInteractionAnalytics | null>,
  quizStatus: QuizStatus,
  lesson: ContentfulSmLesson,
): void => {
  if (
    quizInteractionAnalytics.current &&
    !quizInteractionAnalytics.current?.isDirty &&
    quizStatus !== QuizStatus.COMPLETED
  ) {
    quizInteractionAnalytics.current = {
      isDirty: true,
      lessonId: lesson.sys.id,
      startTimeMs: new Date().getTime(),
      notStarted: quizInteractionAnalytics.current?.notStarted,
    };
  }
};
