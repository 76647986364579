import { gql } from 'graphql-tag';

import {
  AT_IMAGE_FRAGMENT,
  ML_LAYOUT_CONTENT_FRAGMENT,
  ML_NAVTAB_FRAGMENT,
  ML_ACCORDION_FRAGMENT,
  OR_LESSON_SECTION_CONTAINER_FRAGMENT,
  OR_QUIZ_FRAGMENT,
  SEO_FRAGMENT,
  ENTRY_LAYOUT_FRAGMENT,
  ENTRY_FRAGMENT,
} from 'src/graphql/fragments/fragments';
import { GetSmLessonQuery } from 'src/graphql/generated/GetSmLesson';
import { MlLayoutContent, MlNavtab } from 'src/graphql/generated/graphql';

export const GET_SM_LESSON = gql`
  ${AT_IMAGE_FRAGMENT}
  ${ML_LAYOUT_CONTENT_FRAGMENT}
  ${ML_NAVTAB_FRAGMENT}
  ${ML_ACCORDION_FRAGMENT}
  ${OR_LESSON_SECTION_CONTAINER_FRAGMENT}
  ${OR_QUIZ_FRAGMENT}
  ${SEO_FRAGMENT}
  ${ENTRY_LAYOUT_FRAGMENT}
  ${ENTRY_FRAGMENT}
  query GetSmLesson($slug: String!, $preview: Boolean!) {
    smLessonCollection(preview: $preview, limit: 1, where: { slug: $slug }) {
      items {
        __typename
        sys {
          id
        }
        name
        title
        description
        slug
        duration
        audioPlayer {
          sys {
            id
          }
          name
          audioFile
          cdnUrl
        }
        sectionListCollection(limit: 40, where: { sys: { id_exists: true } }) {
          items {
            ...OrLessonSectionContainerFragment
          }
        }
        quiz {
          ...SmQuizFragment
        }
        seo {
          ...SeoFragment
        }
      }
    }
  }
`;

export type ContentfulSmLessonResponse = GetSmLessonQuery;
export type ContentfulSmLesson = GetSmLessonQuery['smLessonCollection']['items'][0];
export type ContentfulLessonSectionContainer =
  GetSmLessonQuery['smLessonCollection']['items'][0]['sectionListCollection']['items'][0];
export type ContentfulSmQuestion =
  GetSmLessonQuery['smLessonCollection']['items'][0]['quiz']['questionsCollection']['items'][0];
export type ContentfulSeo = GetSmLessonQuery['smLessonCollection']['items'][0]['seo'];

export type ContentfulLayoutContent = MlLayoutContent;
export type ContentfulLessonTabContent = MlNavtab;
