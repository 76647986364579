import { gql } from 'graphql-tag';

import {
  AT_IMAGE_FRAGMENT,
  HERO_FRAGMENT,
  ASSOCIATED_CONTENT_RESOURCES_COLLECTION_FRAGMENT,
  SEO_FRAGMENT,
  MARKETING_CONTENT_BLOCK_FRAGMENT,
} from 'src/graphql/fragments/fragments';
import { GetContentHubCollectionQuery } from 'src/graphql/generated/GetContentHub';

export const GET_CONTENT_HUB_COLLECTION = gql`
  ${SEO_FRAGMENT}
  ${AT_IMAGE_FRAGMENT}
  ${HERO_FRAGMENT}
  ${ASSOCIATED_CONTENT_RESOURCES_COLLECTION_FRAGMENT}
  ${MARKETING_CONTENT_BLOCK_FRAGMENT}
  query getContentHubCollection($preview: Boolean!, $type: String!) {
    contentHubCollection(limit: 1, preview: $preview, where: { type: $type }) {
      __typename
      items {
        sys {
          id
        }
        nameInternal
        titleExternal
        type
        hero {
          ...GetHeroFragment
        }
        linkedContentBlockCollection(limit: 100, preview: $preview, where: { sys: { id_exists: true } }) {
          __typename
          items {
            ...GetMarketingContentBlockFragment
          }
        }
        seo {
          ...SeoFragment
        }
      }
    }
  }
`;

export type GetContentHubCollectionResponse = GetContentHubCollectionQuery;
export type ContentfulContentHub = GetContentHubCollectionQuery['contentHubCollection']['items'][0];
export type ContentBlockData =
  GetContentHubCollectionQuery['contentHubCollection']['items'][0]['linkedContentBlockCollection']['items'];
export type AssociatedContent =
  GetContentHubCollectionQuery['contentHubCollection']['items'][0]['linkedContentBlockCollection']['items'][0]['associatedContentResourcesCollection']['items'];
export type AssociatedContentAsset =
  GetContentHubCollectionQuery['contentHubCollection']['items'][0]['linkedContentBlockCollection']['items'][0]['asset'];
