import React, { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LearningHeader, ContentType } from '@atlassian/learning-components';
import FeatureGates from '@atlaskit/feature-gate-js-client';

import { User } from 'src/types';
import { getInspectModeData } from 'src/utils/contentful';
import { sendTrackEvent, sendUIEvent } from 'src/utils/analytics';
import { ContentfulSmCourse } from 'src/graphql/queries/GetSmCourse';
import { FEEDBACK_PAGE_FF } from 'src/constants';
import {
  LearningProgressStatus,
  mapServerStatusToComponentsStatus,
  ProgressForLessons,
} from 'src/services/http/progress';

export interface CourseHeaderProps {
  course: ContentfulSmCourse;
  user: User | null | undefined;
  onLoginUser: () => void;
  isLoading: boolean;
  onSave: () => void;
  isCourseSaved: boolean;
  progress?: ProgressForLessons;
  isLoadingProgress?: boolean;
}

export const CourseHeader: React.FC<CourseHeaderProps> = ({
  course,
  user,
  isLoading,
  onLoginUser,
  onSave,
  isCourseSaved,
  progress,
  isLoadingProgress,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const currentUrl = globalThis?.location?.href;

  const isFeedbackPageAccessible = FeatureGates.checkGate(FEEDBACK_PAGE_FF);

  const navigateToLesson = useCallback(
    (lessonSlug: string): void => {
      navigate(`${location.pathname.replace(/\/$/, '')}/lesson/${lessonSlug}`);
    },
    [navigate, location],
  );

  const startCourse = useCallback(() => {
    const lessons = course?.lessonsCollection?.items;

    if (!lessons?.length) {
      return;
    }

    const hasNotStartedCourse =
      !progress?.overallStatus || progress.overallStatus === LearningProgressStatus.NOT_STARTED;

    const shouldNavigateToFirstLesson =
      hasNotStartedCourse || progress.overallStatus === LearningProgressStatus.COMPLETED;

    if (hasNotStartedCourse) {
      sendTrackEvent(
        'learningApp',
        'course',
        'started',
        {
          courseSlug: course?.slug,
        },
        user?.account_id,
      );
    }

    if (shouldNavigateToFirstLesson) {
      navigateToLesson(lessons[0]?.slug);

      return;
    }

    for (const lesson of lessons) {
      const lessonProgress = progress.lessons.find((p) => p.lessonId === lesson.sys.id);

      if (lessonProgress?.status !== LearningProgressStatus.COMPLETED) {
        navigateToLesson(lesson.slug);

        return;
      }
    }

    // just a safe measure
    navigateToLesson(lessons[0]?.slug);
  }, [course, progress, navigateToLesson, user]);

  const handleSignIn = useCallback((): void => {
    if (onLoginUser) {
      sendUIEvent('courseScreen', 'button', 'clicked', 'signInButton', { objectType: 'courseHeader' });
      onLoginUser();
    }
  }, [onLoginUser]);

  const handleSave = useCallback((): void => {
    if (onSave) {
      onSave();
      sendUIEvent('courseScreen', 'button', 'clicked', 'saveForLaterButton', {
        resourceType: 'course',
        resourceSlug: course.slug,
      });
    }
  }, [onSave, course.slug]);

  // Add conditions when the survey is completed the button should not be shown
  const shouldShowFeedback = progress?.overallStatus === LearningProgressStatus.COMPLETED && isFeedbackPageAccessible;
  const navigateToFeedbackPage = useCallback(() => {
    const url = `/course/${course.slug.replace(/\/$/, '')}/survey`;

    window.open(url, '_blank');
  }, [course.slug]);

  return (
    <div data-testid="course-header">
      <LearningHeader
        inspectMode={getInspectModeData(course ? course : { sys: { id: '' } }, ['title'])}
        anonymousView={!user?.account_id}
        heading={course?.title || 'Course Title Not Found'}
        urlToShare={currentUrl}
        onSignIn={handleSignIn}
        onStartCourse={startCourse}
        startButtonLoading={isLoadingProgress}
        isLoading={isLoading}
        tagLine=""
        contentType={ContentType.COURSE}
        progressStatus={mapServerStatusToComponentsStatus(progress?.overallStatus)}
        onSave={handleSave}
        isSaved={isCourseSaved}
        shouldShowFeedback={shouldShowFeedback}
        onStartFeedback={navigateToFeedbackPage}
      />
    </div>
  );
};
