import { LoaderFunction } from 'react-router-dom';

import { fetchGraphQL } from 'src/services/contentful/client';
import {
  ContentfulContentHub,
  GET_CONTENT_HUB_COLLECTION,
  GetContentHubCollectionResponse,
} from 'src/graphql/queries/GetContentHub';
import { GetContentHubCollectionQueryVariables } from 'src/graphql/generated/GetContentHub';
import { ContentHubType } from 'src/constants';

export interface RoleBasedHubLoaderProps {
  roleBasedHubData: ContentfulContentHub;
}

const getRoleBasedHubData = async (type: string, preview: boolean): Promise<ContentfulContentHub | undefined> => {
  const response = await fetchGraphQL<GetContentHubCollectionResponse, GetContentHubCollectionQueryVariables>(
    GET_CONTENT_HUB_COLLECTION,
    {
      preview,
      type,
    },
  );

  return response.contentHubCollection.items[0];
};

export const loader: LoaderFunction = async ({ request }): Promise<RoleBasedHubLoaderProps> => {
  const preview = request.url.includes('preview=true');

  const roleBasedHubData = await getRoleBasedHubData(ContentHubType.ROLE_BASED, preview);

  if (!roleBasedHubData) {
    throw new Error('Role Based Hub was not found');
  }

  return { roleBasedHubData };
};
