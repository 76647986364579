/* eslint-disable @typescript-eslint/no-explicit-any */
import { css } from '@compiled/react';
import { MouseEvent, ReactElement, ReactNode } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { BLOCKS } from '@contentful/rich-text-types';
import { Text, TextProps } from '@atlassian/learning-components';
import { Options } from '@contentful/rich-text-react-renderer';

interface TextStyles {
  fontWeight?: 'bold';
  fontStyle?: 'italic';
  textDecoration?: 'underline';
}

export const onClickAnchor = (e: MouseEvent<HTMLAnchorElement>, idToNavigate: string): void => {
  e.preventDefault();
  const element = document.getElementById(idToNavigate);

  if (element) {
    const topOfElement = element.offsetTop;

    window.scroll({ top: topOfElement, behavior: 'smooth' });
    window.history.replaceState(true, '', `#${idToNavigate}`);
  }
};

export const getTextStyle = (types?: string[]): TextStyles => {
  if (!types) {
    return {};
  }

  return {
    ...(types.includes('b') ? { fontWeight: 'bold' } : {}),
    ...(types.includes('i') ? { fontStyle: 'italic' } : {}),
    ...(types.includes('u') ? { textDecoration: 'underline' } : {}),
  };
};

/**
 * @description it takes an array of matches and optional types, and returns a AtText component
 * with properties and styles based on the matches
 * @param {string[]} matches - An array of strings representing the matches found in the text
 * @param {string[]} [types] - An  array of string values representing formatting to apply to the text
 * @return {*}  {ReactElement}  - specifically an `<AtText>` component with certain props and childre
 */
export const createAtTextFromMatches = (matches: string[], types?: string[]): ReactElement => {
  let props: Omit<TextProps, 'children'> = {};

  const formatArray = matches[2].toUpperCase().trim().split(',');

  /** Set property font size from (Text)[small] || (Text)[large]*/
  const indexFontSize = formatArray.findIndex((item) => ['SMALL', 'REGULAR', 'LARGE'].includes(item));
  const fontSize = indexFontSize >= 0 ? formatArray[indexFontSize].toLowerCase() : null;

  if (fontSize) {
    props = { ...props, size: fontSize as 'large' | 'small' | 'regular' | undefined };
  }

  /** Set property font weight from (Text)[fc-semibold] || (Text)[fc-bold]*/
  const indexFontWeight = formatArray.findIndex((element) => element.startsWith('FW-'));
  const fontWeight = indexFontWeight >= 0 ? formatArray[indexFontWeight].split('FW-')[1].toLowerCase() : null;

  if (fontWeight && ['regular', 'semibold', 'bold'].includes(fontWeight)) {
    const value = fontWeight as 'bold' | 'regular' | 'semibold';

    props = { ...props, fontWeight: value };
  }

  /** Set property bg color from  (Text)[BG-#FFFFFF]*/
  const indexBgColor = formatArray.findIndex((element) => element.startsWith('BG-'));
  const bgColor = indexBgColor >= 0 ? formatArray[indexBgColor].split('BG-')[1] : null;
  const allowedColors: { [key: string]: string } = {
    B300: '#85B8FF',
    L300: '#B3DF72',
    P300: '#B8ACF6',
    Y300: '#F5CD47',
  };

  if (bgColor) {
    const colorName = allowedColors[bgColor.toUpperCase()] ?? undefined;

    props = { ...props, backgroundColor: colorName };
  }

  return (
    <span key={matches[1].replace(/ /g, '_')} style={getTextStyle(types)}>
      <Text fontWeight={types?.includes('b') ? 'bold' : 'regular'} size="large" {...props}>
        {matches[1]}
      </Text>
    </span>
  );
};

/**
 * @description it takes an array of React elements as input and recursively processes each
 * element to add styling if necessary
 * @param {ReactElement[]} [children=[]]
 * @return {*}  {ReactElement[]}
 */

export const addStyling = (children: ReactElement[] = []): ReactElement[] => {
  /** Clean children because sometimes on copy/paste CF create new separated nodes
   * for examples ['Text{Colored text}[', 'fc-B400', '] '] and it is imposible
   * evaluate match on separated strings
   */
  const cleanChildren = children.reduce(
    (currentGroup: any, currentElement) => {
      if (typeof currentElement === 'string') {
        currentGroup[currentGroup.length - 1] += currentElement;
      } else {
        currentGroup.push(currentElement);
        currentGroup.push('');
      }

      return currentGroup;
    },
    [''],
  );

  if (cleanChildren[cleanChildren.length - 1] === '') {
    cleanChildren.pop();
  }

  const mappedChildren = cleanChildren.flatMap((child: any) => {
    if (child?.type === 'code') {
      return child;
    }

    return processRecursively(child) || child;
  });

  return mappedChildren;
};

/**
 * @description takes a string or React element as input and recursively processes
 * it to create an array of JSX elements
 * @param {(string | ReactElement)} child
 * @param {string[]} [types=[]]
 * @return {*}  {(ReactElement[] | null)}
 */
export const processRecursively = (child: string | ReactElement, types: string[] = []): ReactElement[] | null => {
  if (typeof child === 'string') {
    const uniqueId = uuidv4();

    // this split for regex is required to trick sonar cube to ignore Denial of Service (DoS) Hotspot
    const part1 = '\\{+[^}]+\\}+\\[[^\\]]+\\]';
    const part2 = '\\{+[^}]+\\}+';
    const part3 = '[^{}]+';
    const regex = new RegExp(`(${part1})|(${part2})|(${part3})`, 'g');

    const textSplitted = child.match(regex);

    if (textSplitted && textSplitted?.length > 0) {
      return textSplitted.map((text, index) => {
        const part1 = '\\{+([^}]+)\\}+';
        const part2 = '\\[([^\\]]+)\\]';

        const matches = text.match(new RegExp(`${part1}${part2}`));

        if (matches) {
          return createAtTextFromMatches(matches, types);
        }

        return (
          <span key={uniqueId + index} style={getTextStyle(types)}>
            <Text fontWeight={types.includes('b') ? 'bold' : 'regular'} size="large">
              {text}
            </Text>
          </span>
        );
      });
    }
  }

  if (child && typeof child === 'object') {
    types.push(child.type as string);
    const content = child.props?.children;

    return processRecursively(content, types);
  }

  return null;
};

export const getFeedbackMessageOptions: Options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children): ReactNode => {
      const hasNonEmptyContent = node.content.some(
        (contentItem) => contentItem.nodeType === 'text' && contentItem.value.trim() !== '',
      );

      if (!hasNonEmptyContent) {
        return null;
      }

      return <p css={paragraphStyles}>{children}</p>;
    },
  },
};

const paragraphStyles = css({
  marginBottom: '16px !important',

  ':last-child': {
    marginBottom: '0 !important',
  },
});
