import { useCallback, useState } from 'react';

import { User } from 'src/types';
import { sendUIEvent } from 'src/utils/analytics';

interface UseLoginModalFlag {
  isLoginModalVisible: boolean;
  onCloseButtonClick: () => void;
  handleUserLogin: () => void;
}

export const useLoginModalFlag = (user: User | null | undefined): UseLoginModalFlag => {
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);

  const onCloseButtonClick = useCallback((): void => {
    sendUIEvent('lessonScreen', 'button', 'clicked', 'closeButton', { objectType: 'modal' });
    setIsLoginModalVisible(false);
  }, []);

  const handleUserLogin = (): void => {
    if (!user) {
      setIsLoginModalVisible(true);
    }
  };

  return { isLoginModalVisible, onCloseButtonClick, handleUserLogin };
};
