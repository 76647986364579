import { gql } from 'graphql-tag';

import {
  ASSOCIATED_CONTENT_RESOURCES_COLLECTION_FRAGMENT,
  AT_IMAGE_FRAGMENT,
  HERO_FRAGMENT,
  MARKETING_CONTENT_BLOCK_FRAGMENT,
  SEO_FRAGMENT,
} from 'src/graphql/fragments/fragments';
import { GetTopicCollectionQuery } from 'src/graphql/generated/GetTopicCollection';

export const GET_TOPIC_COLLECTION = gql`
  ${HERO_FRAGMENT}
  ${AT_IMAGE_FRAGMENT}
  ${ASSOCIATED_CONTENT_RESOURCES_COLLECTION_FRAGMENT}
  ${SEO_FRAGMENT}
  ${MARKETING_CONTENT_BLOCK_FRAGMENT}
  query getTopicCollection($slug: String!, $preview: Boolean!) {
    topicCollection(preview: $preview, limit: 15, where: { slug: $slug }) {
      __typename
      items {
        sys {
          id
        }
        topicNameInternal
        topicTitleExternal
        slug
        topicDescription
        topicHero {
          ...GetHeroFragment
        }
        contentContainerCollection(limit: 100, preview: $preview, where: { sys: { id_exists: true } }) {
          __typename
          items {
            ...GetMarketingContentBlockFragment
          }
        }
        seo {
          ...SeoFragment
        }
      }
    }
  }
`;

export type GetTopicCollectionResponse = GetTopicCollectionQuery;
export type ContentfulTopic = GetTopicCollectionQuery['topicCollection']['items'][0];
export type TopicAssociatedContent =
  GetTopicCollectionQuery['topicCollection']['items'][0]['contentContainerCollection']['items'][0]['associatedContentResourcesCollection']['items'];
export type TopicAssociatedContentAsset =
  GetTopicCollectionQuery['topicCollection']['items'][0]['contentContainerCollection']['items'][0]['associatedContentResourcesCollection']['items'][0]['asset'];
