import { ASTNode, parse, print } from 'graphql';

const GRAPHQL_URI = '/gateway/api/learning-app/contentful/graphql';

export const cache = new Map<string, unknown>();

export async function fetchGraphQL<T, V>(query: ASTNode, variables: V): Promise<T> {
  const operationName = extractOperationName(query);

  const cacheKey = JSON.stringify({ query, variables, operationName });

  if (cache.has(cacheKey)) {
    return cache.get(cacheKey) as T;
  }

  const response = await fetch(GRAPHQL_URI, {
    headers: {
      'content-type': 'application/json',
    },
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({
      operationName: operationName,
      query: print(query),
      variables: variables,
    }),
  });

  const jsonResponse = await response.json();

  if (response.status === 200) {
    cache.set(cacheKey, jsonResponse.data);
  }

  return jsonResponse.data;
}

export const extractOperationName = (query: ASTNode): string | undefined => {
  const parsedQuery = parse(print(query));
  const selectionSetBody = parsedQuery.definitions[0]?.loc?.source.body;

  const operationNameMatch = selectionSetBody?.match(/query\s+(\w+)/);

  return operationNameMatch ? operationNameMatch[1] : undefined;
};
