import React from 'react';
import { css } from '@compiled/react';
import { Heading } from '@atlassian/learning-components';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';

import { getOptions } from 'src/pages/LessonPage/children/utils/getOptions';
import { getInspectModeData } from 'src/utils/contentful';
import { ContentfulLessonSectionContainer } from 'src/graphql/queries/GetSmLesson';
import { User } from 'src/types';

interface LessonSectionProps {
  section: ContentfulLessonSectionContainer;
  deviceWidth: number;
  user: User | null | undefined;
  updateVideoProgress: (progress: number, name: string, videoId: string) => void;
}

export const LessonSection: React.FC<LessonSectionProps> = ({ section, deviceWidth, user, updateVideoProgress }) => {
  const inspectorProps = useContentfulInspectorMode();

  return (
    <div
      key={section.sys.id}
      css={css({ padding: '80px 16px' })}
      style={{ backgroundColor: section.backgroundColor ?? '#FFFFFF' }}
    >
      <section id={section.sys.id} data-testid={section.sys.id} css={lessonSectionStyles}>
        {section.title && (
          <div css={css({ margin: '20px auto', maxWidth: '720px' })}>
            <Heading level={2} id={section.id.replace(' ', '-')} inspectMode={getInspectModeData(section, ['title'])}>
              {section.title}
            </Heading>
          </div>
        )}
        <div {...inspectorProps({ entryId: section.sys.id, fieldId: 'children' })}>
          {section?.children?.json &&
            documentToReactComponents(
              section?.children?.json,
              getOptions(section?.children, deviceWidth, user, updateVideoProgress),
            )}
        </div>
      </section>
    </div>
  );
};

const lessonSectionStyles = css({
  maxWidth: '1020px',
  boxSizing: 'content-box',
  margin: '0 auto',

  a: {
    fontSize: '20px',
    fontFamily: 'Inter, sans-serif',
  },
});
