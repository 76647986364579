import React, { createContext, useState, useEffect, ReactNode, useMemo } from 'react';

import { getAtlassianUser } from 'src/services/http/user';
import { User } from 'src/types';
import { registerUserInNitro } from 'src/services/http/kms';

interface UserContextProps {
  user: User | null | undefined;
}

interface UserProviderProps {
  userInfo?: User | null;
  children: ReactNode;
}

export const UserContext = createContext<UserContextProps | undefined>(undefined);

export const UserProvider: React.FC<UserProviderProps> = ({ children, userInfo }) => {
  const [user, setUser] = useState<User | null | undefined>(userInfo);
  const memoizedUser = useMemo(() => ({ user }), [user]);

  useEffect(() => {
    const fetchUser = async (): Promise<void> => {
      const userData = await getAtlassianUser();

      setUser(userData);

      if (userData && !document.cookie.includes('kms-registered=true')) {
        const isRegistered = await registerUserInNitro();

        if (isRegistered) {
          document.cookie = 'kms-registered=true; path=/; max-age=31536000';
        }
      }
    };

    // safeguard in case SSR redirect won't work
    if (!user) {
      fetchUser();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <UserContext.Provider value={memoizedUser}> {children}</UserContext.Provider>;
};
