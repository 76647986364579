import React, { ReactElement, useContext, useEffect } from 'react';
import { css } from '@compiled/react';
import { Button, ButtonAppearance, ButtonSize, Eyebrow, Heading, Text } from '@atlassian/learning-components';
import { token } from '@atlaskit/tokens';
import { media } from '@atlaskit/primitives/responsive';
import { useLoaderData, useNavigate } from 'react-router-dom';

import { CertificatePreparationSection } from 'src/pages/collections/CertificationPreparation/children/CertificatePreparationSection';
import { HelmetContext } from 'src/components/Helmet';
import { AtlassianCommunitySection } from 'src/pages/collections/CertificationPreparation/children/AtlassianCommunitySection';
import { useAtlassianUser } from 'src/hooks/useAtlassianUser';
import { sendScreenEvent, sendUIEvent } from 'src/utils/analytics';
import { CertPrepLoaderProps } from 'src/pages/collections/CertificationPreparation/loader';
import { getImageAttrs } from 'src/utils/contentful';
import { LandingType } from 'src/constants';

export const CertificatePreparationCollectionPage = (): ReactElement => {
  const { certificationPrep } = useLoaderData() as CertPrepLoaderProps;
  const { continueLearningContent, onDemandContent, prepContent, relatedCertification, groupContent, hero } =
    certificationPrep;

  const navigate = useNavigate();

  const { user, isLoading, hasError } = useAtlassianUser();

  const helmet = useContext(HelmetContext);

  const eyebrow = relatedCertification?.certificationType?.certificationTypeNameInternal
    ? relatedCertification?.certificationType?.certificationTypeNameInternal
    : hero?.eyebrowText;

  helmet.setHelmet({
    title: `${certificationPrep.certificationPrepTitleExternal} | Learning - Atlassian Community`,
    seoTags: certificationPrep.seo,
  });

  useEffect(() => {
    if (!isLoading && !hasError && certificationPrep) {
      sendScreenEvent(
        'certificationPreparationCollectionScreen',
        {
          collectionSlug: certificationPrep.slug,
        },
        user?.account_id,
      );
    }
  }, [user, isLoading, hasError, certificationPrep]);

  const handleLearnMoreClick = (): void => {
    sendUIEvent('certificationPrepCollectionScreen', 'button', 'clicked', 'getStartedButton', {}, user?.account_id);
    navigate(`/landing/${LandingType.CERTIFICATION_DETAIL}/${relatedCertification?.slug}`);
  };

  return (
    <main data-testid="cert-prep-collection" css={mainStyles}>
      <section data-testid="cert-prep-header" css={headerSectionStyles}>
        <div css={containerStyles}>
          {hero && (
            <div css={headerContentStyles}>
              <Eyebrow fontWeight="semibold" size="medium" color={token('color.text.subtle')}>
                {eyebrow}
              </Eyebrow>
              <Heading level={0}>{hero.titleExternal}</Heading>
              <Text size="large">{hero.subtitle}</Text>
              <div css={buttonStyles}>
                <Button
                  label="View exam details"
                  appearance={ButtonAppearance.PRIMARY}
                  onClick={() => handleLearnMoreClick()}
                  size={ButtonSize.LARGE}
                  isResponsive={true}
                />
              </div>
            </div>
          )}
        </div>
      </section>
      <section data-testid="on-demand-content" css={certifiedSectionStyles}>
        {onDemandContent && (
          <div key={onDemandContent?.sys.id}>
            <CertificatePreparationSection
              title={onDemandContent?.contentSectionTitle?.contentSectionTitleExternal}
              imageAttrs={getImageAttrs(onDemandContent?.contentSectionTitle?.asset)}
              relatedContent={onDemandContent?.contentLinksCollection?.items}
              backgroundColor="#F8F8F8"
            />
          </div>
        )}
      </section>
      <section data-testid="prep-resources" css={certifiedSectionStyles}>
        {prepContent && (
          <div key={prepContent.sys.id}>
            <CertificatePreparationSection
              title={prepContent?.contentSectionTitle?.contentSectionTitleExternal}
              imageAttrs={getImageAttrs(prepContent?.contentSectionTitle?.asset)}
              relatedContent={prepContent?.contentLinksCollection?.items}
            />
          </div>
        )}
      </section>
      {groupContent && (
        <AtlassianCommunitySection
          title={groupContent?.contentSectionTitle?.contentSectionTitleExternal}
          imageAttrs={getImageAttrs(groupContent?.contentSectionTitle?.asset)}
          relatedContent={groupContent?.contentLinksCollection?.items}
        />
      )}
      <section data-testid="continue-learning" css={certifiedSectionStyles}>
        {continueLearningContent && (
          <div key={continueLearningContent.sys.id}>
            <CertificatePreparationSection
              title={continueLearningContent?.contentSectionTitle?.contentSectionTitleExternal}
              imageAttrs={getImageAttrs(continueLearningContent?.contentSectionTitle?.asset)}
              relatedContent={continueLearningContent?.contentLinksCollection?.items}
            />
          </div>
        )}
      </section>
    </main>
  );
};

const mainStyles = css({
  paddingTop: '136px',

  '@media (max-width: 1279px)': {
    paddingTop: '0',
  },
  marginBottom: '104px',
});

const headerSectionStyles = css({
  margin: `54px 0 ${token('space.600')} 0`,
});

const containerStyles = css({
  maxWidth: '1440px',
  width: '100%',
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',

  [media.below.lg]: {
    padding: `0 ${token('space.300')}`,
  },

  [media.below.sm]: {
    padding: `0 ${token('space.200')} !important`,
  },
});

const headerContentStyles = css({
  maxWidth: '720px',
  width: '100%',
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  gap: token('space.200'),
  textAlign: 'center',
});

const buttonStyles = css({
  display: 'flex',
  justifyContent: 'center',
});

const certifiedSectionStyles = css({
  marginBottom: token('space.1000'),

  [media.below.sm]: {
    marginBottom: '56px',
  },
});
