import { css } from '@compiled/react';
import { Tag } from '@atlassian/learning-components';

export const Taglist = (taxonomyList: string[]): React.ReactElement | null => {
  if (!taxonomyList || !taxonomyList.length) {
    return null;
  }

  return (
    <div css={css({ display: 'flex', flexWrap: 'wrap', gap: '8px' })}>
      {taxonomyList.map((tag) => (
        <Tag key={tag} label={tag} />
      ))}
    </div>
  );
};
