import { LinkTarget } from '@atlassian/learning-components';
import { NavigateFunction } from 'react-router-dom';

export const handleNavigate = (navigate: NavigateFunction, slug: string, target: LinkTarget): void => {
  if (target === '_blank') {
    window.open(slug, '_blank');
  } else {
    navigate(slug);
  }
};
