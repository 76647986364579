import { LoaderFunction } from 'react-router-dom';

import { fetchGraphQL } from 'src/services/contentful/client';
import { ContentfulSmCourse, ContentfulSmCourseResponse, GET_SM_COURSE } from 'src/graphql/queries/GetSmCourse';
import { ContentfulSmLesson, ContentfulSmLessonResponse, GET_SM_LESSON } from 'src/graphql/queries/GetSmLesson';
import { GetCourseQueryVariables } from 'src/graphql/generated/GetSmCourse';
import { GetSmLessonQueryVariables } from 'src/graphql/generated/GetSmLesson';

export const loader: LoaderFunction = async ({ params, request }): Promise<LessonLoaderProps> => {
  const lessonId = params.lessonId ?? '';
  const courseId = params.courseId ?? '';
  const preview = request.url.includes('preview=true');

  const lesson = await getLessonData(lessonId, preview);
  const course = await getCourse(courseId, preview);

  if (!lesson || !course) {
    throw new Error('Course or lesson was not found');
  }

  return { lesson, course };
};

const getLessonData = async (lessonId: string, preview: boolean): Promise<ContentfulSmLesson | undefined> => {
  const response = await fetchGraphQL<ContentfulSmLessonResponse, GetSmLessonQueryVariables>(GET_SM_LESSON, {
    slug: lessonId,
    preview,
  });

  return response.smLessonCollection.items[0];
};

const getCourse = async (courseId: string, preview: boolean): Promise<ContentfulSmCourse | undefined> => {
  const response = await fetchGraphQL<ContentfulSmCourseResponse, GetCourseQueryVariables>(GET_SM_COURSE, {
    slug: courseId,
    preview,
  });

  return response.smCourseCollection.items[0];
};

export interface LessonLoaderProps {
  lesson: ContentfulSmLesson;
  course: ContentfulSmCourse;
}
