export enum ContentType {
  ICON = 'AtIcon',
  IMAGE = 'AtImage',

  SECTION_MESSAGE = 'MlSectionMessage',
  LOZENGE = 'MlLozenge',
  ACCORDION = 'MlAccordion',
  INLINE_CODE = 'MlInlineCode',
  NAVTAB = 'MlNavtab',

  LAYOUT = 'OrLayout',
  LABELLED_GRAPHIC = 'OrLabelledGraphic',
  VIDEO = 'OrVideo',

  LESSON = 'SmLesson',
  COURSE = 'SmCourse',
  LEARNING_PATH = 'LearningPath',
  CERTIFICATION = 'Certification',
  ROLE = 'Role',
  TOPIC = 'Topic',
  CERTIFICATION_PREP = 'CertificationPrep',
  INSTRUCTOR_LED_TRAINING = 'InstructorLedTraining',
}
