import React from 'react';
import { Eyebrow, Heading, Text } from '@atlassian/learning-components';
import { css } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { media } from '@atlaskit/primitives/responsive';

import heroDesktopImage from 'src/assets/images/landings/learningPathLandingHeroDesktop.svg';

interface HeroSectionProps {
  title: string;
  subtitle: string;
  eyebrowText: string;
}

export const HeroSection: React.FC<HeroSectionProps> = ({ title, subtitle, eyebrowText }) => (
  <section css={heroSectionStyles} data-testid="learning-paths-hero-section">
    <img css={heroSectionImageStyles} src={`${heroDesktopImage}`} alt="learning-paths-hero-image" />
    <div css={eyebrowContainerStyles} data-testid="hero-eyebrow-text">
      <Eyebrow size="medium" fontWeight="semibold" color={token('color.text.subtle')}>
        {eyebrowText}
      </Eyebrow>
    </div>
    <div css={headerStyles} data-testid="hero-title">
      <Heading level={0}>{title}</Heading>
    </div>
    <div css={subtitleStyles} data-testid="hero-subtitle">
      <Text size="large">{subtitle}</Text>
    </div>
  </section>
);

const heroSectionStyles = css({
  width: '100%',
  height: '100%',
  maxWidth: '1022px',
  display: 'flex',
  marginTop: token('space.600'),
  marginBottom: token('space.600'),
  padding: `0 ${token('space.300')}`,
  flexDirection: 'column',
  alignItems: 'flex-start',

  [media.below.xs]: {
    marginTop: token('space.500'),
    marginBottom: token('space.500'),
    padding: `0 ${token('space.200')}`,
  },
});

const eyebrowContainerStyles = css({
  marginBottom: token('space.200'),
});

const headerStyles = css({
  marginBottom: token('space.200'),
});

const subtitleStyles = css({
  maxWidth: '720px',
});

const heroSectionImageStyles = css({
  position: 'absolute',
  width: '500px',
  height: '400px',
  top: '-5px',
  right: '0',

  [media.below.md]: {
    display: 'none',
  },
});
