import { useState, useEffect } from 'react';
import { ContentType } from '@atlassian/learning-components';

import { getIsContentSaved, saveOrUpdateContentSavedStatus } from 'src/services/http/myLearning';
import { User } from 'src/types';

interface UseSaveContentParams {
  contentId: string;
  contentType: ContentType;
  learningPathId?: string;
  user: User | null | undefined;
}

interface UseSaveContent {
  isContentSaved: boolean;
  saveContent: () => Promise<void>;
}

export const useSaveContent = ({
  contentId,
  contentType,
  learningPathId,
  user,
}: UseSaveContentParams): UseSaveContent => {
  const [isContentSaved, setIsContentSaved] = useState(false);

  useEffect(() => {
    const checkIfSaved = async (): Promise<void> => {
      if (user) {
        const isSaved = await getIsContentSaved(
          contentId,
          contentType === ContentType.COURSE ? 'course' : 'learningPath',
        );

        setIsContentSaved(isSaved);
      }
    };

    checkIfSaved();
  }, [contentId, contentType, user]);

  const saveContent = async (): Promise<void> => {
    const success = await saveOrUpdateContentSavedStatus(
      contentId,
      contentType as ContentType.LEARNING_PATH | ContentType.COURSE,
      !isContentSaved,
      learningPathId,
    );

    if (success) {
      setIsContentSaved(!isContentSaved);
    }
  };

  return { isContentSaved, saveContent };
};
