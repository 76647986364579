import React from 'react';
import { css } from '@compiled/react';
import { Heading, Link, LinkStyle, Text, Video } from '@atlassian/learning-components';
import { useNavigate } from 'react-router-dom';
import { token } from '@atlaskit/tokens';
import { media } from '@atlaskit/primitives/responsive';

import { sendUIEvent } from 'src/utils/analytics';
import { useAtlassianUser } from 'src/hooks/useAtlassianUser';
import { ContentHubType } from 'src/constants';
import { getAssetUrl, getUrlPrefix } from 'src/services/http/user';

export const CertificationDetailSection: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAtlassianUser();

  const onLinkClick = (): void => {
    navigate(`/hub/${ContentHubType.CERTIFICATIONS}`);
    sendUIEvent('homeScreen', 'link', 'clicked', 'getCertifiedLink', {}, user?.account_id);
  };

  return (
    <section data-testid="certification-detail-section" css={sectionContainerStyles}>
      <span css={headingStyles}>
        <Heading level={2}>Get certified with Atlassian</Heading>
      </span>
      <span css={textStyles}>
        <Text size="large">Earn global recognition and advance your career.</Text>
      </span>
      <span css={buttonStyles}>
        <Link
          style={LinkStyle.SEMIBOLD}
          label="Get certified"
          link={`${getUrlPrefix()}/hub/${ContentHubType.CERTIFICATIONS}`}
          onClick={onLinkClick}
          icon="arrow-right"
        />
      </span>
      <div css={videoContainerStyles}>
        <Video
          aria-label="certification detail video"
          name="certification detail video"
          videoId="certification-detail-video"
          brandfolderUrl="https://wac-cdn-bfldr.atlassian.com/K3MHR9G8/as/87pqv48r4g9mg888zczhnjk/atlassian_-_proj-001568_-_what-s_an_atlassian_certification_atlassian_learning_variant"
          poster={getAssetUrl('/images/homePage/videoPoster.png')}
          width="auto"
          height="auto"
          captions=""
          onControlClick={(type) =>
            sendUIEvent(
              'homeScreen',
              'button',
              'clicked',
              ['play', 'pause'].includes(type) ? 'videoPlayButton' : 'videoCaptionsButton',
              {
                name: 'certification detail video',
                videoId: 'certification-detail-video',
                type,
              },
              user?.account_id,
            )
          }
          controls
        />
      </div>
    </section>
  );
};

const sectionContainerStyles = css({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: `${token('space.1000')} ${token('space.300')}`,
  textAlign: 'center',

  [media.below.sm]: {
    padding: `56px ${token('space.200')} `,
  },
});

const headingStyles = css({
  marginBottom: `${token('space.200')}`,
});

const textStyles = css({
  marginBottom: token('space.500'),

  [media.below.sm]: {
    marginBottom: token('space.300'),
  },
});

const buttonStyles = css({
  marginBottom: token('space.300'),
  a: {
    fontSize: '20px',
    lineHeight: '24px',
  },

  [media.below.sm]: {
    a: {
      fontSize: '16px',
      lineHeight: '20px',
    },
  },
});

const videoContainerStyles = css({
  width: '814px',
  height: '458px',
  overflow: 'hidden',

  [media.below.md]: {
    maxWidth: '100%',
    height: 'auto',
  },
});
