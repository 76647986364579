import { ContentContainersCollection } from 'src/graphql/queries/GetFlexibleContentHub';
import {
  BenefitsContainer,
  FaqContainer,
  MarketingContentBlock,
  OrVideo,
  Resource,
  SocialProof,
  Statistic,
} from 'src/graphql/generated/graphql';

interface ContentContainers {
  benefitsContainer: BenefitsContainer;
  statistic: Statistic;
  getCertifiedSection: MarketingContentBlock;
  certifiedAssociateSection: MarketingContentBlock;
  certifiedProfessionalSection: MarketingContentBlock;
  video: OrVideo;
  socialProof: SocialProof;
  readyGetStartedSection: MarketingContentBlock;
  resource: Resource;
  faq: FaqContainer;
}

export const getContentContainers = (collection: ContentContainersCollection): ContentContainers => {
  const benefitsContainer = collection.find(
    (item) => item.__typename === 'BenefitsContainer',
  ) as unknown as BenefitsContainer;
  const statistic = collection.find((item) => item.__typename === 'Statistic') as unknown as Statistic;
  const getCertifiedSection = collection.find(
    (item) => item.__typename === 'MarketingContentBlock' && item.titleExternal === 'Get certified',
  ) as MarketingContentBlock;
  const certifiedAssociateSection = collection.find(
    (item) => item.__typename === 'MarketingContentBlock' && item.titleExternal === 'Atlassian Certified Associate',
  ) as MarketingContentBlock;
  const certifiedProfessionalSection = collection.find(
    (item) => item.__typename === 'MarketingContentBlock' && item.titleExternal === 'Atlassian Certified Professional',
  ) as MarketingContentBlock;
  const video = collection.find((item) => item.__typename === 'OrVideo') as unknown as OrVideo;
  const socialProof = collection.find((item) => item.__typename === 'SocialProof') as unknown as SocialProof;
  const readyGetStartedSection = collection.find(
    (item) => item.__typename === 'MarketingContentBlock' && item.titleExternal === 'Ready to get started?',
  ) as MarketingContentBlock;
  const resource = collection.find((item) => item.__typename === 'Resource') as Resource;
  const faq = collection.find((item) => item.__typename === 'FaqContainer') as unknown as FaqContainer;

  return {
    benefitsContainer,
    statistic,
    getCertifiedSection,
    certifiedAssociateSection,
    certifiedProfessionalSection,
    video,
    socialProof,
    readyGetStartedSection,
    resource,
    faq,
  };
};

export const getCertificationTypeLabels = (typeLabel: string): string => {
  const words = typeLabel.split(' ');
  const label = words[words.length - 1];

  return label;
};
