import React, { useCallback, useMemo, useState } from 'react';
import { css } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import {
  Button,
  ButtonAppearance,
  ButtonProps,
  ButtonSize,
  FlatContentCard,
  Heading,
  Icon,
} from '@atlassian/learning-components';
import { media } from '@atlaskit/primitives/responsive';

import { AssociatedContent } from 'src/graphql/generated/graphql';
import { getCertificationTypeLabels } from 'src/pages/hubs/CertificationPage/utils/utils';
import { useAtlassianUser } from 'src/hooks/useAtlassianUser';
import { sendUIEvent } from 'src/utils/analytics';
import { getImageAttrs } from 'src/utils/contentful';
import { useNavigateByHost } from 'src/hooks/useNavigateByHost';

import { CardDivider } from './CardDivider';

interface CertificationTypeProps {
  image?: {
    src: string;
    alt: string;
  };
  title: string;
  contentText?: string;
  expandItemsList: AssociatedContent[];
}

export const CertificationType: React.FC<CertificationTypeProps> = ({ image, title, contentText, expandItemsList }) => {
  const { user } = useAtlassianUser();
  const navigateByHost = useNavigateByHost();
  const [isExpanded, setIsExpanded] = useState(false);

  const label = useMemo(() => `${getCertificationTypeLabels(title)} certifications`, [title]);

  const handleSectionButtonClick = useCallback((): void => {
    setIsExpanded(!isExpanded);

    sendUIEvent(
      'certificationHubScreen',
      'button',
      'clicked',
      'expandCollapseButton',
      {
        sectionTitle: label,
        sectionAction: isExpanded ? 'collapse' : 'expand',
      },
      user?.account_id,
    );
  }, [isExpanded, label, user?.account_id]);

  const buttonProps: ButtonProps = useMemo(
    () => ({
      appearance: ButtonAppearance.SECONDARY,
      label: `${isExpanded ? 'Close' : 'View'} all ${label}`,
      onClick: handleSectionButtonClick,
      iconAfter: isExpanded ? <Icon type="chevron-up" /> : <Icon type="chevron-down" />,
    }),
    [isExpanded, label, handleSectionButtonClick],
  );

  return (
    <div css={containerStyles} data-testid="cert-type">
      <div css={cardContentStyles}>
        <div css={headingTextWrapperStyles}>
          {image && <img css={imageStyles} src={image.src} alt={image.alt} />}
          <Heading level={3}>{title}</Heading>
          {contentText && <p css={contentTextStyles}>{contentText}</p>}
        </div>
      </div>
      {isExpanded && (
        <div css={expandedAriaStyles} data-testid="certification-type-expanded-aria">
          <div css={headingTextWrapperStyles}>
            <Heading level={3}>{label}</Heading>
          </div>
          <div css={expandItemsWrapperStyles}>
            {expandItemsList.map(({ _id, link, titleExternal, asset, description }, idx) => (
              <div css={cardContainerStyles} key={_id}>
                <CardDivider index={idx} />
                <FlatContentCard
                  link={link.link}
                  linkLabel={link.label}
                  title={titleExternal}
                  text={description}
                  onClick={(title) => {
                    navigateByHost(link.link);
                    sendUIEvent(
                      'certificationHubScreen',
                      'link',
                      'clicked',
                      'cardLink',
                      {
                        contentBlockTitle: label,
                        cardTitle: title,
                      },
                      user?.account_id,
                    );
                  }}
                  image={getImageAttrs(asset)}
                />
                <CardDivider />
              </div>
            ))}
          </div>
        </div>
      )}
      <div css={buttonContainerStyles}>
        <div css={desktopButtonWrapper}>
          <Button size={ButtonSize.LARGE} isResponsive={true} {...buttonProps} />
        </div>
        <div css={mobileButtonWrapper}>
          <Button size={ButtonSize.MEDIUM} {...buttonProps} />
        </div>
      </div>
    </div>
  );
};

const containerStyles = css({
  '&:last-of-type': {
    'div:has(div > button)': {
      borderBottom: `1px solid ${token('color.border')}`,
    },
  },
});

const cardContentStyles = css({
  paddingTop: token('space.500'),
  boxSizing: 'content-box',
  display: 'flex',
  justifyContent: 'center',

  [media.below.md]: {
    margin: `0 ${token('space.300')}`,
  },

  [media.below.sm]: {
    paddingTop: token('space.300'),
    margin: `0 ${token('space.200')} !important`,
  },
});

const imageStyles = css({
  marginBottom: token('space.200'),
  height: '64px',
  width: 'auto',
  maxWidth: '100%',
});

const headingTextWrapperStyles = css({
  maxWidth: '1020px',
  width: '100%',
});

const contentTextStyles = css({
  marginBottom: token('space.400'),
  fontSize: '20px',
  lineHeight: '32px',
  fontWeight: 400,
  marginTop: token('space.200'),
  fontFamily: 'Inter, sans-serif',

  [media.below.sm]: {
    fontSize: '16px',
    lineHeight: '24px',
  },
});

const buttonContainerStyles = css({
  paddingBottom: token('space.500'),
  margin: '0 auto',
  maxWidth: '1020px',
  borderBottom: `1px solid ${token('color.border')}`,

  [media.below.md]: {
    margin: `0 ${token('space.300')}`,
  },

  [media.below.sm]: {
    margin: `0 ${token('space.200')} !important`,
  },
});

const expandedAriaStyles = css({
  backgroundColor: '#F8F8F8',
  padding: `56px 210px ${token('space.500')}`,
  marginBottom: token('space.500'),
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',

  [media.below.md]: {
    padding: `56px ${token('space.300')}`,
  },

  [media.below.sm]: {
    padding: `${token('space.400')} ${token('space.200')} ${token('space.500')} !important`,
  },
});

const expandItemsWrapperStyles = css({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '0 56px',
  marginTop: token('space.500'),
  maxWidth: '1020px',
  justifyContent: 'center',
});

const cardContainerStyles = css({
  [media.above.md]: {
    flex: '1 1 45%',
  },
});

const desktopButtonWrapper = css({
  [media.below.sm]: {
    display: 'none',
  },
});

const mobileButtonWrapper = css({
  display: 'none',

  [media.below.sm]: {
    display: 'block',
  },
});
