import React from 'react';
import { css } from '@compiled/react';
import { Eyebrow, Heading, Text } from '@atlassian/learning-components';
import { token } from '@atlaskit/tokens';
import { media } from '@atlaskit/primitives/responsive';

import { getAssetUrl } from 'src/services/http/user';

interface CustomerCardProps {
  imageSrc: string;
  altText: string;
  name: string;
  feedback: string;
  eyebrowText: string;
}
const CustomerCard: React.FC<CustomerCardProps> = ({ imageSrc, altText, name, feedback, eyebrowText }) => (
  <div data-testid={`customer-card-${name}`} css={cardContainerStyles}>
    <div css={cardImageStyles}>
      <img src={imageSrc} alt={altText} />
    </div>
    <div css={cardTextContentStyles}>
      <span css={cardHeadingStyles}>
        <Heading level={4}>{name}</Heading>
      </span>
      <span css={cardEyebrowStyles}>
        <Eyebrow>{eyebrowText}</Eyebrow>
      </span>
      <span css={cardFeedbackStyles}>
        <Text size="regular">{feedback}</Text>
      </span>
    </div>
  </div>
);

export const CustomerFeedbackSection: React.FC = () => (
  <section data-testid="customer-feedback-section" css={sectionContainerStyles}>
    <div css={cardsContainerStyles} data-testid="customer-feedback-section-cards">
      <CustomerCard
        imageSrc={getAssetUrl('/images/homePage/customerTomislav.svg')}
        altText="customer image"
        name="Tomislav Tobijas"
        feedback='"Atlassian courses make it incredibly easy to get onboarded with new tools and features you may not have tried before."'
        eyebrowText="Senior Atlassian Consultant, Koios Consulting"
      />
      <CustomerCard
        imageSrc={getAssetUrl('/images/homePage/customerAmanda.svg')}
        altText="customer image"
        name="Amanda Barber"
        feedback='"Atlassian live training helps me stay current – I always learn a new way of using a familiar tool."'
        eyebrowText="Product Manager, Gradecam"
      />
      <CustomerCard
        imageSrc={getAssetUrl('/images/homePage/customerKai.svg')}
        altText="customer image"
        name="Kai Krause"
        feedback={`"In four years of training with Atlassian, I've gained invaluable skills in Atlassian products and agile methodologies."`}
        eyebrowText="Senior Technical Consultant, VI2VA"
      />
    </div>
  </section>
);

const sectionContainerStyles = css({
  width: '100%',
  padding: `${token('space.1000')} ${token('space.300')}`,
  display: 'flex',
  justifyContent: 'center',

  [media.below.sm]: {
    padding: `56px ${token('space.200')}`,
  },
});

const cardsContainerStyles = css({
  width: '100%',
  maxWidth: '1022px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  [media.below.md]: {
    flexDirection: 'column',
  },
});

const cardContainerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: '290px',
  height: '412px',

  [media.below.md]: {
    width: '100%',
    maxWidth: '714px',
    height: '200px',
    flexDirection: 'row',
    '&:not(:last-child)': {
      marginBottom: token('space.500'),
    },
  },

  [media.below.sm]: {
    flexDirection: 'column !important',
    alignItems: 'center',
    maxWidth: '342px',
    height: 'auto !important',
    '&:not(:last-child)': {
      marginBottom: token('space.600'),
    },
  },
});

const cardImageStyles = css({
  marginBottom: `${token('space.300')}`,
  width: '200px',
  height: '200px',
  display: 'flex',
  justifyContent: 'center',

  [media.below.md]: {
    marginBottom: 0,
  },

  [media.below.sm]: {
    marginBottom: `${token('space.200')} !important`,
  },
});

const cardTextContentStyles = css({
  display: 'flex',
  maxWidth: '290px',
  flexDirection: 'column',
  alignItems: 'center',
  marginLeft: 0,

  [media.below.md]: {
    alignItems: 'flex-start',
    maxWidth: '466px',
    marginLeft: token('space.600'),
  },

  [media.below.sm]: {
    alignItems: 'center !important',
    maxWidth: '290px',
    marginLeft: '0 !important',
  },
});

const cardHeadingStyles = css({
  marginBottom: `${token('space.100')}`,
  textAlign: 'center',

  [media.below.sm]: {
    marginBottom: 0,
  },
});

const cardEyebrowStyles = css({
  marginBottom: `${token('space.200')}`,
  textAlign: 'center',
});

const cardFeedbackStyles = css({
  textAlign: 'center',

  [media.below.md]: {
    textAlign: 'start',
  },

  [media.below.sm]: {
    textAlign: 'center !important',
  },
});
