import { GET_CATALOG_RESOURCES_COUNT } from 'src/graphql/queries/GetCatalogResourcesCount';
import { GetCatalogResourcesCountQuery } from 'src/graphql/generated/graphql';
import {
  ContentfulGetCertifications,
  ContentfulGetCertPreps,
  ContentfulGetCourses,
  ContentfulGetInstructorLedTraining,
  ContentfulGetLearningPaths,
  ContentfulGetRoles,
  ContentfulGetTopics,
} from 'src/graphql/queries/GetCatalogResources';
import { fetchGraphQL } from 'src/services/contentful/client';
import { GetCatalogResourcesCountQueryVariables } from 'src/graphql/generated/GetCatalogResourcesCount';

const COURSES_COUNT_LIMIT = 25;
const LEARNING_PATHS_COUNT_LIMIT = 25;
const CERTIFICATIONS_COUNT_LIMIT = 25;
const COLLECTIONS_COUNT_LIMIT = 25;
const ILT_LIMIT = 25;

export enum TaxonomyType {
  COURSE = 'Course',
  LEARNING_PATH = 'Learning Path',
  CERTIFICATION = 'Certification',
  ROLE = 'Role',
  TOPIC = 'Topic',
  CERTIFICATION_PREP = 'Certification Preparation',
  INSTRUCTOR_LED_TRAINING = 'Team Training',
}

export interface TaxonomyCount {
  [key: string]: {
    [key: string]: number;
  };
}

export interface ResourceCount {
  taxonomyCount: TaxonomyCount;
  totalResources: number;
}

export interface WithDurationAndType {
  duration: number;
  type: string;
}

export type Resource =
  | ContentfulGetCourses
  | ContentfulGetLearningPaths
  | ContentfulGetCertifications
  | ContentfulGetRoles
  | ContentfulGetTopics
  | ContentfulGetCertPreps
  | ContentfulGetInstructorLedTraining;
export type ResourceWithDurationAndType =
  | (ContentfulGetCourses & WithDurationAndType)
  | (ContentfulGetLearningPaths & WithDurationAndType)
  | (ContentfulGetCertifications & WithDurationAndType)
  | (ContentfulGetRoles & WithDurationAndType)
  | (ContentfulGetTopics & WithDurationAndType)
  | (ContentfulGetCertPreps & WithDurationAndType)
  | (ContentfulGetInstructorLedTraining & WithDurationAndType);

export const isDurationMatching = (filter: string | string[], duration: number): boolean =>
  (filter.includes('Less than 30 min') && duration < 30) ||
  (filter.includes('30 min to 1 hour') && duration >= 30 && duration <= 60) ||
  (filter.includes('1 to 2 hours') && duration > 60 && duration <= 120) ||
  (filter.includes('More than 2 hours') && duration > 120);

export const isTypeMatching = (filter: string | string[], type: string): boolean => {
  const typeMap: Record<string, string> = {
    SmCourse: TaxonomyType.COURSE,
    LearningPath: TaxonomyType.LEARNING_PATH,
    Certification: TaxonomyType.CERTIFICATION,
    Role: TaxonomyType.ROLE,
    Topic: TaxonomyType.TOPIC,
    CertificationPrep: TaxonomyType.CERTIFICATION_PREP,
    InstructorLedTraining: TaxonomyType.INSTRUCTOR_LED_TRAINING,
  };

  if (Array.isArray(filter)) {
    return filter.some((f) => typeMap[type] === f);
  }

  return typeMap[type] === filter;
};

export const getCourseDuration = (course: ContentfulGetCourses): number =>
  course.lessonsCollection.items.reduce((acc, lesson) => acc + (lesson?.duration || 0), 0);

export const getLearningPathDuration = (learningPath: ContentfulGetLearningPaths): number =>
  learningPath.coursesCollection.items.reduce(
    (acc, course) => acc + getCourseDuration(course as unknown as ContentfulGetCourses),
    0,
  );

export const applyClientSideFilters = (
  resources: ResourceWithDurationAndType[],
  filters: Record<string, string[]>,
): ResourceWithDurationAndType[] =>
  resources.filter((resource) =>
    Object.keys(filters).every((filter) => {
      const property = String(resource[filter as keyof typeof resource]);

      if (property === null || property === undefined) {
        return false;
      }

      if (filter === 'duration') {
        return filters.duration.some((f) => isDurationMatching(f, resource.duration));
      }

      if (filter === 'type') {
        return filters.type.some((f) => isTypeMatching(f, resource.__typename as string));
      }

      return filters[filter].some((f) => property.includes(f));
    }),
  );

export const getCatalogResourcesCount = async (
  preview: boolean,
  page: number = 0,
): Promise<ResourceWithDurationAndType[]> => {
  const response = await fetchGraphQL<GetCatalogResourcesCountQuery, GetCatalogResourcesCountQueryVariables>(
    GET_CATALOG_RESOURCES_COUNT,
    {
      preview,
      skipCourses: COURSES_COUNT_LIMIT * page,
      skipLearningPaths: LEARNING_PATHS_COUNT_LIMIT * page,
      skipCertifications: CERTIFICATIONS_COUNT_LIMIT * page,
      skipCollections: COLLECTIONS_COUNT_LIMIT * page,
      skipInstructorLedTraining: ILT_LIMIT * page,
    },
  );

  const coursesWithDuration = response.smCourseCollection.items.map((c) => ({
    ...c,
    duration: getCourseDuration(c as ContentfulGetCourses),
    type: TaxonomyType.COURSE,
  }));

  const learningPathsWithDuration = response.learningPathCollection.items.map((lp) => ({
    ...lp,
    duration: getLearningPathDuration(lp as ContentfulGetLearningPaths),
    type: TaxonomyType.LEARNING_PATH,
  }));

  const certifications = response.certificationCollection.items.map((cert) => ({
    ...cert,
    type: TaxonomyType.CERTIFICATION,
  }));

  const roles = response.roleCollection.items.map((role) => ({
    ...role,
    type: TaxonomyType.ROLE,
  }));

  const topics = response.topicCollection.items.map((topic) => ({
    ...topic,
    type: TaxonomyType.TOPIC,
  }));

  const certPreps = response.certificationPrepCollection.items.map((certPrep) => ({
    ...certPrep,
    product: certPrep.relatedCertification?.product,
    type: TaxonomyType.CERTIFICATION_PREP,
  }));

  const instructorLedTrainingWithDuration = response.instructorLedTrainingCollection.items.map((ilt) => {
    // duration is optional for instructorLedTraining on contentful
    // if duration is null we exclude field to display actual counter
    // ignoring resource without duration
    const { duration, ...iltWithoutDuration } = ilt;

    return {
      ...(duration ? ilt : iltWithoutDuration),
      type: TaxonomyType.INSTRUCTOR_LED_TRAINING,
    };
  });

  return [
    ...coursesWithDuration,
    ...learningPathsWithDuration,
    ...certifications,
    ...roles,
    ...topics,
    ...certPreps,
    ...instructorLedTrainingWithDuration,
  ] as ResourceWithDurationAndType[];
};

export const getAllCatalogResourcesCount = async (preview: boolean): Promise<ResourceWithDurationAndType[]> => {
  let resources: ResourceWithDurationAndType[] = [];
  let initialLength = -1;
  let page = 0;

  while (resources.length > initialLength) {
    initialLength = resources.length;

    const moreResources = await getCatalogResourcesCount(preview, page);

    resources = [...resources, ...moreResources];

    page += 1;
  }

  return resources;
};

export const countAllResources = async (
  filters: Record<string, string[]>,
  preview: boolean = false,
): Promise<ResourceCount> => {
  const allResources = await getAllCatalogResourcesCount(preview);
  const filteredResources = applyClientSideFilters(allResources, filters);

  const taxonomyCount = {
    ...countTaxonomy(filteredResources),
    ...countDurationTaxonomy(filteredResources),
  };

  return {
    taxonomyCount,
    totalResources: filteredResources.length,
  };
};

export const countDurationTaxonomy = (items: ResourceWithDurationAndType[]): TaxonomyCount => {
  const counts: TaxonomyCount = {
    duration: {
      'Less than 30 min': 0,
      '30 min to 1 hour': 0,
      '1 to 2 hours': 0,
      'More than 2 hours': 0,
    },
  };

  items.forEach((item) => {
    Object.keys(counts.duration).forEach((range) => {
      if (isDurationMatching(range, item.duration)) {
        counts.duration[range]++;
      }
    });
  });

  return counts;
};

export const countTaxonomy = (items: ResourceWithDurationAndType[]): TaxonomyCount => {
  const counts: TaxonomyCount = {};

  items.forEach((item) => {
    Object.keys(item).forEach((taxonomyKey) => {
      const taxonomyGroup = item[taxonomyKey as keyof typeof item];

      if (!counts[taxonomyKey]) {
        counts[taxonomyKey] = {};
      }

      if (typeof taxonomyGroup === 'string') {
        const taxonomyValue = taxonomyGroup;

        if (!counts[taxonomyKey][taxonomyValue]) {
          counts[taxonomyKey][taxonomyValue] = 0;
        }

        counts[taxonomyKey][taxonomyValue]++;
      }

      if (Array.isArray(taxonomyGroup)) {
        const taxonomyValues = taxonomyGroup;

        taxonomyValues.forEach((taxonomyValue: string) => {
          if (!counts[taxonomyKey][taxonomyValue]) {
            counts[taxonomyKey][taxonomyValue] = 0;
          }

          counts[taxonomyKey][taxonomyValue]++;
        });
      }
    });
  });

  return counts;
};
