import { useContext } from 'react';

import { UserContext } from 'src/context/UserContext';
import { User } from 'src/types';

export const useAtlassianUser = (): { user: User | undefined | null; isLoading: boolean; hasError: boolean } => {
  const context = useContext(UserContext);

  if (context === undefined) {
    return { user: null, isLoading: false, hasError: true };
  }

  if (context.user === undefined) {
    return { user: undefined, isLoading: true, hasError: false };
  }

  if (context.user === null) {
    return { user: null, isLoading: false, hasError: false };
  }

  return { user: context.user, isLoading: false, hasError: false };
};
