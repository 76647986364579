import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

import { CustomLink } from './CustomLink';

interface MarkdownWrapperProps {
  children?: string | null;
}

export const MarkdownWrapper: React.FC<MarkdownWrapperProps> = ({ children }) => (
  <ReactMarkdown remarkPlugins={[remarkBreaks, remarkGfm]} rehypePlugins={[rehypeRaw]} components={{ a: CustomLink }}>
    {children}
  </ReactMarkdown>
);
