import '@atlaskit/css-reset';
import './styles/global.css';

import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { ConsentValidator } from '@atlassian/community-library';

import { HelmetProvider, SetHelmetProps } from 'src/components/Helmet';
import { generateMetaTags } from 'src/utils/generateMetaTags';

import { routes } from './routes';
import { UserProvider } from './context/UserContext';
import { SidebarVisibilityProvider } from './context/SidebarVisibilityContext';
import { User } from './types';
import { FeatureGatesBootstrapValues, initializeFeatureGatesClient } from './utils/initializeFeatureGates';
import { getAssetUrl, getEnvironment, getLogoutUrl, getUrlPrefix, isLocalhost } from './services/http/user';

Sentry.init({
  environment: isLocalhost() ? 'e2e' : getEnvironment(),
  dsn: process.env.SENTRY_DSN,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 1.0,
});

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouterV6(createBrowserRouter);
const router = sentryCreateBrowserRouter(routes, { basename: getUrlPrefix() });
const typedGlobalThis = globalThis as typeof globalThis & {
  __CURRENT_USER__: User;
  __IS_CONSENTED__: boolean;
  __FEATURE_GATES_VALUES__: FeatureGatesBootstrapValues;
};

const setHelmet = (helmet: SetHelmetProps): void => {
  try {
    const head = document.head;

    head.querySelectorAll('[data-react-helmet]').forEach((tag) => tag.remove());

    const metaTags = generateMetaTags(helmet);

    const doc = new DOMParser().parseFromString(metaTags, 'text/html');

    head.prepend(...Array.from(doc.head.children));
  } catch (error) {
    throw new Error('Error setting helmet: ' + error);
  }
};

initializeFeatureGatesClient().then(() =>
  hydrateRoot(
    document.getElementById('root') as HTMLElement,
    <UserProvider userInfo={typedGlobalThis.__CURRENT_USER__}>
      <ConsentValidator
        onLogout={() => window.location.replace(getLogoutUrl())}
        onConsentError={() => window.location.replace('/user/error/consent-failed')}
        coverImageSrc={getAssetUrl('/images/welcomeIllustration.png')}
        aaid={typedGlobalThis.__CURRENT_USER__?.account_id}
        isUserConsented={typedGlobalThis.__IS_CONSENTED__}
        env={getEnvironment()}
      >
        <SidebarVisibilityProvider>
          <HelmetProvider setHelmet={setHelmet}>
            <RouterProvider router={router} />
          </HelmetProvider>
        </SidebarVisibilityProvider>
      </ConsentValidator>
    </UserProvider>,
  ),
);
