import { gql } from 'graphql-tag';

import {
  AT_IMAGE_FRAGMENT,
  SEO_FRAGMENT,
  RELATED_CONTENT,
  OVERVIEW_ENTRY_BLOCK_FRAGMENT,
  OVERVIEW_ENTRY_INLINE_FRAGMENT,
  OR_VIDEO_FRAGMENT,
} from 'src/graphql/fragments/fragments';
import { GetCourseQuery } from 'src/graphql/generated/GetSmCourse';

export const GET_SM_COURSE = gql`
  ${AT_IMAGE_FRAGMENT}
  ${SEO_FRAGMENT}
  ${RELATED_CONTENT}
  ${OVERVIEW_ENTRY_BLOCK_FRAGMENT}
  ${OVERVIEW_ENTRY_INLINE_FRAGMENT}
  ${OR_VIDEO_FRAGMENT}
  query getCourse($slug: String!, $preview: Boolean!) {
    smCourseCollection(preview: $preview, limit: 1, where: { slug: $slug }) {
      items {
        __typename
        sys {
          id
        }
        name
        slug
        title
        teaser
        lessonsCollection(where: { sys: { id_exists: true } }) {
          items {
            sys {
              id
            }
            title
            duration
            description
            slug
            teaser
            sectionListCollection(where: { sys: { id_exists: true } }) {
              items {
                sys {
                  id
                }
                title
                id
              }
            }
          }
        }
        relatedContent {
          ...RelatedContentFragment
        }
        deployment
        product
        role
        learningLevel
        overview {
          json
          links {
            entries {
              block {
                ...OverviewEntryBlockFragment
              }
              inline {
                ...OverviewEntryInlineFragment
              }
            }
          }
        }
        seo {
          ...SeoFragment
        }
        reward {
          badge
          badgeCdnUrl
        }
      }
    }
  }
`;

export type ContentfulSmCourseResponse = GetCourseQuery;
export type ContentfulSmCourse = GetCourseQuery['smCourseCollection']['items'][0];
export type ContentfulRelatedContentResource =
  GetCourseQuery['smCourseCollection']['items'][0]['relatedContent']['recommendedResourcesCollection']['items'][0];
export type ContentfulRelatedContent = GetCourseQuery['smCourseCollection']['items'][0]['relatedContent'];
