import { gql } from 'graphql-tag';

import { GetAllTaxonomyQuery } from 'src/graphql/generated/graphql';

export const GET_ALL_TAXONOMY = gql`
  query GetAllTaxonomy($preview: Boolean!) {
    learningPathCollection(preview: $preview) {
      items {
        deployment
        learningLevel
        product
        role
      }
    }
    smCourseCollection(preview: $preview) {
      items {
        deployment
        product
        learningLevel
        role
      }
    }
    certificationCollection(preview: $preview) {
      items {
        product
      }
    }
    roleCollection(preview: $preview) {
      items {
        product
      }
    }
    topicCollection(preview: $preview) {
      items {
        product
      }
    }
    certificationPrepCollection(preview: $preview) {
      items {
        relatedCertification {
          product
        }
      }
    }
    instructorLedTrainingCollection(preview: $preview) {
      items {
        deployment
        product
        role
        learningLevel
      }
    }
  }
`;

export type ContentfulGetAllTaxonomyResponse = GetAllTaxonomyQuery;
