export const getContentProduct = (product: string[] | null): string => {
  const DEFAULT_PRODUCT = 'atlassian';
  const supportedProductsList = [
    'atlas',
    'atlassian',
    'bamboo',
    'bitbucket',
    'clover',
    'compass',
    'confluence',
    'crowd',
    'crucible',
    'fisheye',
    'focus',
    'guard',
    'jira',
    'jiraAlign',
    'jiraProductDiscovery',
    'jiraServiceManagement',
    'loom',
    'opsgenie',
    'rovo',
    'sourcetree',
  ];

  const toCamelCase = (str: string): string =>
    str
      .toLowerCase()
      .split(' ')
      .map((word, index) => (index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)))
      .join('');

  if (product && product.length === 1) {
    const currentProduct = toCamelCase(product[0]);
    const productSupported = supportedProductsList.includes(currentProduct);

    return productSupported ? currentProduct : DEFAULT_PRODUCT;
  }

  return DEFAULT_PRODUCT;
};
