import React, { useCallback } from 'react';
import { ContentType, LearningHeader } from '@atlassian/learning-components';
import { useLocation, useNavigate } from 'react-router-dom';
import FeatureGates from '@atlaskit/feature-gate-js-client';

import { ContentfullCourse, ContentfulLearningPath } from 'src/graphql/queries/GetLearningPath';
import { getInspectModeData } from 'src/utils/contentful';
import { User } from 'src/types';
import { sendTrackEvent, sendUIEvent } from 'src/utils/analytics';
import { FEEDBACK_PAGE_FF } from 'src/constants';
import {
  LearningProgressStatus,
  mapServerStatusToComponentsStatus,
  ProgressForCourses,
} from 'src/services/http/progress';

export interface LearningPathHeaderProps {
  learningPath: ContentfulLearningPath;
  user: User | null | undefined;
  onLoginUser: () => void;
  onSave: () => void;
  isLearningPathSaved: boolean;
  progress?: ProgressForCourses;
  isLoadingProgress?: boolean;
  isLoading: boolean;
}

export const LearningPathHeader: React.FC<LearningPathHeaderProps> = ({
  learningPath,
  onLoginUser,
  user,
  onSave,
  isLearningPathSaved,
  progress,
  isLoadingProgress,
  isLoading,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const isFeedbackPageAccessible = FeatureGates.checkGate(FEEDBACK_PAGE_FF);

  const handleSignIn = useCallback(() => onLoginUser(), [onLoginUser]);

  const navigateToCourse = useCallback(
    (courseSlug: string) => navigate(`${location.pathname.replace(/\/$/, '')}/course/${courseSlug}`),
    [navigate, location],
  );

  const findCourseToRedirect = useCallback(
    (courses: ContentfullCourse[], status: LearningProgressStatus): ContentfullCourse | undefined =>
      courses.find((course) => progress?.courses.some((p) => p.courseId === course.sys.id && p.status === status)),
    [progress],
  );

  const startLearningPath = useCallback(() => {
    const courses = learningPath?.coursesCollection?.items;

    if (!courses?.length) {
      return;
    }

    const hasNotStartedLearningPath =
      !progress?.overallStatus || progress.overallStatus === LearningProgressStatus.NOT_STARTED;

    const shouldNavigateToFirstCourse =
      hasNotStartedLearningPath || progress.overallStatus === LearningProgressStatus.COMPLETED;

    if (hasNotStartedLearningPath) {
      sendTrackEvent(
        'learningApp',
        'learningPath',
        'started',
        {
          learningPathSlug: learningPath?.slug,
        },
        user?.account_id,
      );
    }

    if (shouldNavigateToFirstCourse) {
      navigateToCourse(courses[0]?.slug);

      return;
    }

    const inProgressCourse = findCourseToRedirect(courses, LearningProgressStatus.IN_PROGRESS);

    if (inProgressCourse) {
      navigateToCourse(inProgressCourse.slug);

      return;
    }

    const notStartedCourse = findCourseToRedirect(courses, LearningProgressStatus.NOT_STARTED);

    if (notStartedCourse) {
      navigateToCourse(notStartedCourse.slug);

      return;
    }

    // just a safe measure
    navigateToCourse(courses[0]?.slug);
  }, [learningPath, progress, navigateToCourse, findCourseToRedirect, user]);

  const handleSave = useCallback(() => {
    if (onSave) {
      onSave();
      sendUIEvent('learningPathScreen', 'button', 'clicked', 'saveForLaterButton', {
        resourceSlug: learningPath.slug,
        resourceType: 'learningPath',
      });
    }
  }, [onSave, learningPath.slug]);

  // Add conditions when the survey is completed the button should not be shown
  const shouldShowFeedback = progress?.overallStatus === LearningProgressStatus.COMPLETED && isFeedbackPageAccessible;

  const navigateToFeedbackPage = useCallback(() => {
    const url = `/path/${learningPath.slug.replace(/\/$/, '')}/survey`;

    window.open(url, '_blank');
  }, [learningPath.slug]);

  return (
    <div data-testid="learning-path-header">
      <LearningHeader
        inspectMode={getInspectModeData(learningPath || { sys: { id: '' } }, ['title'])}
        anonymousView={!user?.account_id}
        heading={learningPath?.title || 'Learning Path Title Not Found'}
        urlToShare={globalThis?.location?.href}
        onSignIn={handleSignIn}
        onStartCourse={startLearningPath}
        startButtonLoading={isLoadingProgress}
        progressStatus={mapServerStatusToComponentsStatus(progress?.overallStatus)}
        isLoading={isLoading}
        tagLine=""
        contentType={ContentType.LEARNING_PATH}
        onSave={handleSave}
        isSaved={isLearningPathSaved}
        shouldShowFeedback={shouldShowFeedback}
        onStartFeedback={navigateToFeedbackPage}
      />
    </div>
  );
};
