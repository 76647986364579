import { css } from '@compiled/react';
import { ReactElement } from 'react';
import { token } from '@atlaskit/tokens';
import { N800 } from '@atlaskit/theme/colors';

import { addStyling } from 'src/pages/LessonPage/children/utils/optionsUtils';

interface ParagraphBlockProps {
  children: React.ReactNode;
}

export const ParagraphBlock: React.FC<ParagraphBlockProps> = ({ children }) => {
  const formattedContent: React.ReactNode[] = [];
  const contentArray = children as Array<ReactElement | string>;

  contentArray.forEach((child) => {
    if (typeof child === 'string' && child.indexOf('-') === 0) {
      //Bullets list in tables
      formattedContent.push(
        <li key={`bullet-${formattedContent.length}`}>
          <span css={listTextStyles}>{addStyling([child.replace('-', '') as unknown as ReactElement])}</span>
        </li>,
      );

      return;
    }

    if (typeof child === 'string' && child.indexOf(' - ') === 0) {
      //Bullets in sublist within tables
      formattedContent.push(
        <li key={`bullet-${formattedContent.length}`} css={secondLevelListStyles}>
          <span>{addStyling([child.replace(' - ', '') as unknown as ReactElement])}</span>
        </li>,
      );

      return;
    }

    if (typeof child === 'string' && child.indexOf(' + ') === 0) {
      //Bullets in third level lists within tables
      formattedContent.push(
        <li key={`bullet-${formattedContent.length}`} css={thirdLevelListStyles}>
          <span>{addStyling([child.replace(' + ', '') as unknown as ReactElement])}</span>
        </li>,
      );

      return;
    }

    if (typeof child === 'string' && child.includes('\n')) {
      const parts = child.split('\n');

      // Iterating through each part of the string split by '\n' allows us to apply styling to each line and insert a <br /> tag
      // between lines (except the last one) which is the case with formatted text such as underline, links etc. to ensure correct formatting with line breaks
      parts.forEach((part, index) => {
        if (part) {
          formattedContent.push(addStyling([part as unknown as ReactElement]));
        }
        if (index < parts.length - 1) {
          formattedContent.push(<br />);
        }
      });

      return;
    }

    formattedContent.push(addStyling([child as unknown as ReactElement]));
  });

  return (
    <div css={[css({ paddingBottom: token('space.150'), whiteSpace: 'pre-wrap' }), mainWidthSettingsStyles]}>
      {formattedContent}
    </div>
  );
};
const listTextStyles = css({
  lineHeight: 1.714,
  color: N800,
});

const secondLevelListStyles = css({
  listStyleType: 'circle',
  marginLeft: '0.75rem',
});

const thirdLevelListStyles = css({
  listStyleType: 'square',
  marginLeft: '1.5rem',
});

const mainWidthSettingsStyles = css({
  maxWidth: '720px',
  margin: '0 auto',
});
