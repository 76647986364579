import { LoaderFunction } from 'react-router-dom';

import {
  getMyLearning,
  getRecentlyViewedLessons,
  MyLearningItem,
  MyLearningData,
  RecentlyViewedLessonItem,
  getArchivedContent,
} from 'src/services/http/myLearning';
import { fetchGraphQL } from 'src/services/contentful/client';
import { GetAllMyLearningEntriesQuery, GetAllMyLearningEntriesQueryVariables } from 'src/graphql/generated/graphql';
import {
  CollectedDataItem,
  CourseEntry,
  GET_ALL_MY_LEARNING_ENTRIES,
  MatchingDataItem,
} from 'src/graphql/queries/GetAllMyLearningEntries';
import { MyLearningPageContentSections } from 'src/pages/MyLearningPage/constants';
import { getContentProduct } from 'src/utils/getContentProduct';

export interface MyLearningLoaderProps {
  myLearningResourceData: MyLearningResourceData | null;
  recentlyViewedLessonsResources: RecentlyViewedLessonResource[] | null;
  archivedContentResources: ArchivedContentResource[] | null;
}

export interface MyLearningResourceData {
  learningPaths: MyLearningResource[] | null;
  courses: MyLearningResource[] | null;
}

export interface MyLearningResource {
  completedDate?: string;
  startedDate?: string;
  contentfulId: string;
  progressTotal: number;
  subCollectionTotal: number;
  slug?: string;
  title: string;
  badgeUrl?: string;
  learningPathSlug?: string;
  learningPathContentfulId: string | null;
  product?: string;
  products?: string;
}

export interface RecentlyViewedLessonResource {
  contentfulId: string;
  lastVisitedDate: Date;
  progressTotal: number;
  subCollectionTotal: number;
  slug: string;
  title: string;
  courseSlug?: string;
  learningPathSlug?: string;
}

export interface ArchivedContentResource {
  entryTitle: string;
  completionDate: Date;
}

export const loader: LoaderFunction = async ({ params }): Promise<MyLearningLoaderProps> => {
  const myLearningPageStatus = params.status ?? '';

  const recentlyViewedLessonsData =
    myLearningPageStatus === MyLearningPageContentSections.IN_PROGRESS ? await getRecentlyViewedLessons() : null;

  const archivedContentData =
    myLearningPageStatus === MyLearningPageContentSections.COMPLETE ? await getArchivedContent() : null;

  const myLearningData = await getMyLearning(myLearningPageStatus);

  return getCoursesAndLearningPathsTitleAndSlugs(recentlyViewedLessonsData, myLearningData, archivedContentData);
};

export const getCoursesAndLearningPathsTitleAndSlugs = async (
  recentlyViewedLessonsData: RecentlyViewedLessonItem[] | null,
  myLearningData: MyLearningData | null,
  archivedContentData: ArchivedContentResource[] | null,
): Promise<MyLearningLoaderProps> => {
  if (!recentlyViewedLessonsData && !myLearningData && !archivedContentData) {
    return {
      recentlyViewedLessonsResources: null,
      myLearningResourceData: null,
      archivedContentResources: null,
    };
  }

  const contentfulIds = getAllContentfulIds(recentlyViewedLessonsData, myLearningData);

  const response = await fetchGraphQL<GetAllMyLearningEntriesQuery, GetAllMyLearningEntriesQueryVariables>(
    GET_ALL_MY_LEARNING_ENTRIES,
    { contentfulIds },
  );

  if (
    !response.smCourseCollection.items.length &&
    !response.learningPathCollection.items.length &&
    !response.smLessonCollection.items.length
  ) {
    return {
      recentlyViewedLessonsResources: null,
      myLearningResourceData: null,
      archivedContentResources: archivedContentData,
    };
  }

  const collectedData: CollectedDataItem[] = [
    ...response.smCourseCollection.items,
    ...response.learningPathCollection.items,
    ...response.smLessonCollection.items,
  ];

  return {
    recentlyViewedLessonsResources: transformRecentlyViewedLessonsData(recentlyViewedLessonsData, collectedData),
    myLearningResourceData: transformMyLearningData(myLearningData, collectedData),
    archivedContentResources: archivedContentData,
  };
};

const getAllContentfulIds = (
  recentlyViewedLessonsData: RecentlyViewedLessonItem[] | null,
  myLearningData: MyLearningData | null,
): string[] => {
  const contentfulIds =
    recentlyViewedLessonsData
      ?.filter((item: RecentlyViewedLessonItem) => item.progressTotal < item.subCollectionTotal)
      .map((item) => item.contentfulId) ?? [];

  const courseContentfulIds =
    recentlyViewedLessonsData
      ?.filter((item) => item.courseContentfulId)
      .map((item) => item.courseContentfulId as string) ?? [];

  const learningPathContentfulIds =
    recentlyViewedLessonsData
      ?.filter((item) => item.learningPathContentfulId)
      .map((item) => item.learningPathContentfulId as string) ?? [];

  const myLearningContentfulIds =
    (myLearningData && [
      ...myLearningData.courses.map((item) => item.contentfulId),
      ...myLearningData.learningPath.map((item) => item.contentfulId),
    ]) ??
    [];

  return [...contentfulIds, ...courseContentfulIds, ...learningPathContentfulIds, ...myLearningContentfulIds];
};

const transformRecentlyViewedLessonsData = (
  recentlyViewedLessonsData: RecentlyViewedLessonItem[] | null,
  collectedData: CollectedDataItem[],
): RecentlyViewedLessonResource[] | null => {
  if (!recentlyViewedLessonsData) {
    return null;
  }

  return recentlyViewedLessonsData
    .map((item) => mapToRecentlyViewedLessonResource(item, collectedData))
    .filter((item): item is RecentlyViewedLessonResource => !!item);
};

const mapToRecentlyViewedLessonResource = (
  item: RecentlyViewedLessonItem,
  collectedItems: CollectedDataItem[],
): RecentlyViewedLessonResource | null => {
  const matchingItem = collectedItems.find((value) => value.sys.id === item.contentfulId);
  const matchingCourseItem = collectedItems.find((value) => value.sys.id === item.courseContentfulId);
  const matchingLearningPathItem = collectedItems.find((value) => value.sys.id === item.learningPathContentfulId);

  if (matchingItem) {
    return {
      ...item,
      title: matchingItem.title,
      slug: matchingItem.slug,
      lastVisitedDate: new Date(item.lastVisitedDate),
      ...(matchingCourseItem ? { courseSlug: matchingCourseItem.slug } : {}),
      ...(matchingLearningPathItem ? { learningPathSlug: matchingLearningPathItem.slug } : {}),
    };
  }

  return null;
};

export const transformMyLearningData = (
  myLearningData: MyLearningData | null,
  collectedItems: CollectedDataItem[],
): MyLearningResourceData => {
  const learningPaths =
    myLearningData?.learningPath
      .map((item) => mapToMyLearningResources(item, collectedItems))
      .filter((item): item is MyLearningResource => !!item) ?? [];

  const courses =
    myLearningData?.courses
      .map((item) => mapToMyLearningResources(item, collectedItems))
      .filter((item): item is MyLearningResource => !!item) ?? [];

  return {
    learningPaths: learningPaths.length ? learningPaths : null,
    courses: courses.length ? courses : null,
  };
};

const mapToMyLearningResources = (
  item: MyLearningItem,
  collectedItems: CollectedDataItem[],
): MyLearningResource | null => {
  const matchingItem = collectedItems.find((data) => data.sys.id === item.contentfulId) as MatchingDataItem | undefined;
  const matchingLearningPathItem = collectedItems.find((value) => value.sys.id === item.learningPathContentfulId);

  if (matchingItem) {
    return {
      ...item,
      product: getContentProduct(matchingItem.product),
      title: matchingItem.title,
      slug: matchingItem.slug,
      badgeUrl:
        (matchingItem as CourseEntry).reward?.badgeCdnUrl || (matchingItem as CourseEntry).reward?.badge[0]?.cdn_url,
      ...(matchingLearningPathItem ? { learningPathSlug: matchingLearningPathItem.slug } : {}),
    };
  }

  return null;
};
