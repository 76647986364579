import React, { ReactElement, useCallback, useRef } from 'react';
import { FeedbackButtonType, QuickFeedback, ThumbType } from '@atlassian/learning-components';
import { useParams } from 'react-router-dom';

import { Opinion, submitQuickFeedback } from 'src/services/http/quickFeedback';
import { useAtlassianUser } from 'src/hooks/useAtlassianUser';
import { sendUIEvent } from 'src/utils/analytics';

interface QuickFeedbackComponentProps {
  lessonSlug: string;
  courseSlug: string;
  shouldShowDivider?: boolean;
}

export const QuickFeedbackComponent = ({
  lessonSlug,
  courseSlug,
  shouldShowDivider,
}: QuickFeedbackComponentProps): ReactElement => {
  const { pathId } = useParams();
  const { user } = useAtlassianUser();

  const feedback = useRef({
    opinion: '',
    thoughts: '',
    selectedOption: '',
  });

  const handleChangeThoughts = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    feedback.current.thoughts = e.target.value;
  }, []);

  const handleChangeOption = useCallback((option: string) => {
    feedback.current.selectedOption = option;
  }, []);

  const handleThumbClick = useCallback((thumbType: ThumbType) => {
    feedback.current.opinion = thumbType === ThumbType.THUMB_UP ? Opinion.SATISFIED : Opinion.NOT_SATISFIED;
  }, []);

  const handleSubmit = useCallback(
    (buttonType: FeedbackButtonType) => {
      submitQuickFeedback({
        opinion: feedback.current.opinion,
        thoughts: feedback.current.thoughts,
        selectedOption: feedback.current.selectedOption,
        lessonSlug: lessonSlug,
        courseSlug: courseSlug,
        learningPathSlug: pathId || '',
      });

      sendUIEvent(
        'lessonScreen',
        'button',
        'clicked',
        buttonType === FeedbackButtonType.SUBMIT ? 'submitFeedbackButton' : 'dismissFeedbackButton',
        {
          thumbType: feedback.current.opinion === Opinion.SATISFIED ? ThumbType.THUMB_UP : ThumbType.THUMB_DOWN,
          isThoughtsFieldEmpty: !feedback.current.thoughts.length,
          selectedOption: feedback.current.selectedOption,
        },
        user?.account_id,
      );
    },
    [courseSlug, lessonSlug, pathId, user?.account_id],
  );

  return (
    <QuickFeedback
      onSubmitFeedback={handleSubmit}
      onChangeThoughts={handleChangeThoughts}
      onChangeOption={handleChangeOption}
      onThumbClick={handleThumbClick}
      shouldShowDivider={shouldShowDivider}
    />
  );
};
