/* eslint-disable @typescript-eslint/no-explicit-any */
import { Video } from '@atlassian/learning-components';
import { css } from '@compiled/react';
import { useMemo } from 'react';

import { convertToSeconds } from 'src/pages/LessonPage/children/utils/mediaUtils';
import { sendUIEvent } from 'src/utils/analytics';
import { User } from 'src/types';
import { isStandalone } from 'src/pages/LessonPage/utils/isStandalone';
import { getVideoAttrs, getVideoCaptions } from 'src/utils/contentful';
import { OrVideo } from 'src/graphql/generated/graphql';

interface VideoBlockProps {
  entry: any;
  courseId: string | undefined;
  lessonId: string | undefined;
  fields: Record<string, any>;
  updateVideoProgress: ((progress: number, name: string, videoId: string) => void) | undefined;
  user: User | null | undefined;
}

export const VideoBlock: React.FC<VideoBlockProps> = ({
  entry,
  courseId,
  lessonId,
  fields,
  updateVideoProgress,
  user,
}) => {
  const videoAttrs = useMemo(() => getVideoAttrs(fields as OrVideo), [fields]);
  const captions = useMemo(
    () => getVideoCaptions(videoAttrs?.cdnCaptions, videoAttrs?.brandfolderCaptions),
    [videoAttrs?.cdnCaptions, videoAttrs?.brandfolderCaptions],
  );

  const chapters = fields?.chaptersCollection?.items.map(
    ({ chapterTitle, timeStamp }: { chapterTitle: string; timeStamp: string }) => ({
      time: convertToSeconds(timeStamp),
      label: chapterTitle,
    }),
  );

  return (
    <div css={spacingStyles}>
      <Video
        aria-label={entry.name}
        name={entry.name}
        videoId={entry.sys.id}
        brandfolderUrl={videoAttrs?.brandfolderUrl}
        orangeUrl={videoAttrs?.cdnUrl}
        vimeoUrl={videoAttrs?.vimeoUrl}
        youtubeUrl={videoAttrs?.youtubeUrl}
        captions={captions}
        chapters={chapters ? chapters : null}
        width={fields?.videoWidth ? fields.videoWidth : 'auto'}
        height={fields?.videoHeight ? fields.videoHeight : 'auto'}
        onControlClick={(type) =>
          sendUIEvent(
            lessonId ? 'lessonScreen' : 'courseScreen',
            'button',
            'clicked',
            ['play', 'pause'].includes(type) ? 'videoPlayButton' : 'videoCaptionsButton',
            {
              name: entry.name,
              videoId: entry.sys.id,
              courseSlug: courseId,
              lessonSlug: lessonId,
              type,
              ...(lessonId && isStandalone() ? { isStandaloneLesson: true } : {}),
            },
            user?.account_id,
          )
        }
        onProgressUpdate={updateVideoProgress}
        controls
      />
    </div>
  );
};

const spacingStyles = css({
  paddingTop: '40px',
  paddingBottom: '40px',
});
