import React from 'react';
import { Icon } from '@atlassian/learning-components';
import { css } from '@compiled/react';
import { N800 } from '@atlaskit/theme/colors';
import { media } from '@atlaskit/primitives/responsive';

export const ConfirmationMessage: React.FC = () => (
  <div data-testid="confirmation-message" css={confirmationMessageContainerStyles}>
    <div css={confirmationContentWrapperStyles}>
      <div>
        <Icon type="check-circle-outline" size="xlarge" color="#44546F" />
      </div>
      <div css={confirmationMessageContentStyles}>
        <h3 css={confirmationMessageHeadingStyles}>You’re on the list!</h3>
        <p css={confirmationMessageParagraphStyles}>Stay tuned for more ways to create your best work life</p>
      </div>
    </div>
  </div>
);

const confirmationMessageContainerStyles = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '400px',

  [media.below.md]: {
    height: '430px',
  },
});

const confirmationContentWrapperStyles = css({
  display: 'flex',
  alignItems: 'flex-start',
  gap: '16px',
});

const confirmationMessageContentStyles = css({
  maxWidth: '425px',
  width: '100%',

  [media.below.lg]: {
    maxWidth: '340px',
  },
});

const confirmationMessageHeadingStyles = css({
  marginTop: 0,
  marginBottom: '16px',
  fontSize: '32px',
  fontWeight: 600,
  fontFamily: 'Charlie Display, sans-serif',
  color: N800,
  lineHeight: '40px',
});

const confirmationMessageParagraphStyles = css({
  maxWidth: '425px',
  width: '100%',
  marginTop: 0,
  marginBottom: 0,
  fontSize: '20px',
  fontFamily: 'Inter, sans-serif',
  color: N800,
  lineHeight: '32px',

  [media.below.lg]: {
    maxWidth: '340px',
  },
});
