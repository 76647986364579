import { gql } from 'graphql-tag';

import { GetCatalogResourcesQuery } from 'src/graphql/generated/graphql';

export const GET_CATALOG_RESOURCES = gql`
  query GetCatalogResources(
    $preview: Boolean!
    $learningLevel: [String]
    $role: [String]
    $product: [String]
    $collectionProduct: [String]
    $deployment: [String]
    $skip: Int
  ) {
    learningPathCollection(
      preview: $preview
      limit: 9
      skip: $skip
      where: {
        AND: [
          {
            deployment_contains_some: $deployment
            learningLevel_in: $learningLevel
            role_in: $role
            product_contains_some: $product
            hideFromCatalog_exists: false
          }
        ]
      }
    ) {
      items {
        __typename
        slug
        title
        deployment
        learningLevel
        product
        role
        coursesCollection(where: { sys: { id_exists: true } }, limit: 20) {
          items {
            lessonsCollection(where: { sys: { id_exists: true } }, limit: 20) {
              items {
                duration
              }
            }
          }
        }
      }
    }
    smCourseCollection(
      preview: $preview
      limit: 9
      skip: $skip
      where: {
        AND: [
          {
            deployment_contains_some: $deployment
            product_contains_some: $product
            role_in: $role
            learningLevel_in: $learningLevel
            hideFromCatalog_exists: false
          }
        ]
      }
    ) {
      items {
        __typename
        slug
        title
        deployment
        product
        role
        learningLevel
        lessonsCollection(where: { sys: { id_exists: true } }, limit: 20) {
          items {
            duration
          }
        }
      }
    }
    instructorLedTrainingCollection(
      preview: $preview
      limit: 9
      skip: $skip
      where: {
        AND: [
          {
            deployment_contains_some: $deployment
            product_contains_some: $product
            role_in: $role
            learningLevel_in: $learningLevel
            hideFromCatalog_exists: false
          }
        ]
      }
    ) {
      items {
        __typename
        title: courseTitleExternal
        slug: intellumUrl
        deployment
        product
        role
        learningLevel
        duration
      }
    }
    certificationCollection(
      preview: $preview
      limit: 9
      skip: $skip
      where: { product_contains_some: $collectionProduct, hideFromCatalog_exists: false }
    ) {
      items {
        __typename
        title: certificationTitleExternal
        slug
        product
      }
    }
    roleCollection(
      preview: $preview
      limit: 9
      skip: $skip
      where: { product_contains_some: $collectionProduct, hideFromCatalog_exists: false }
    ) {
      items {
        __typename
        title: titleExternal
        slug
        product
      }
    }
    topicCollection(
      preview: $preview
      limit: 9
      skip: $skip
      where: { product_contains_some: $collectionProduct, hideFromCatalog_exists: false }
    ) {
      items {
        __typename
        title: topicTitleExternal
        slug
        product
      }
    }
    certificationPrepCollection(
      preview: $preview
      limit: 9
      skip: $skip
      where: { relatedCertification: { product_contains_some: $collectionProduct }, hideFromCatalog_exists: false }
    ) {
      items {
        __typename
        title: certificationPrepTitleExternal
        slug
        relatedCertification {
          product
        }
      }
    }
  }
`;

export type ContentfulGetCatalogResourcesResponse = GetCatalogResourcesQuery;
export type ContentfulGetCourses = GetCatalogResourcesQuery['smCourseCollection']['items'][0];
export type ContentfulGetLearningPaths = GetCatalogResourcesQuery['learningPathCollection']['items'][0];
export type ContentfulGetCertifications = GetCatalogResourcesQuery['certificationCollection']['items'][0];
export type ContentfulGetRoles = GetCatalogResourcesQuery['roleCollection']['items'][0];
export type ContentfulGetTopics = GetCatalogResourcesQuery['topicCollection']['items'][0];
export type ContentfulGetCertPreps = GetCatalogResourcesQuery['certificationPrepCollection']['items'][0];
export type ContentfulGetInstructorLedTraining =
  GetCatalogResourcesQuery['instructorLedTrainingCollection']['items'][0];
