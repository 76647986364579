import People from 'src/assets/images/People.svg';
import Ace from 'src/assets/images/Ace.svg';
import Check from 'src/assets/images/Check.svg';

export const links = [
  '/catalog?type=Team+Training',
  'https://university.atlassian.com/student/all_events',
  'https://ace.atlassian.com/community-led-classes/',
];

export const trainingBenefits = {
  heading: 'Training benefits',
  subheading: 'Each type of live training moves you closer to your goals. Choose which works best for you and:',
  list: [
    {
      icon: `${People}`,
      paragraph: 'Get answers to your questions in real time',
    },
    {
      icon: `${Ace}`,
      paragraph: 'Learn from expert instructors with practical industry knowledge',
    },
    {
      icon: `${Check}`,
      paragraph: 'Expand your network by learning alongside like-minded peers',
    },
  ],
  buttonLabel: undefined,
  link: undefined,
  analyticsButtonLabel: undefined,
};

export const teamTraining = {
  heading: 'Team training',
  subheading: 'Purchase a customized training for your team of 10-15 to accelerate adoption and improve engagement.',
  list: [
    {
      icon: `${People}`,
      paragraph: 'Learn with your team',
    },
    {
      icon: `${Ace}`,
      paragraph: 'Receive instruction from an Atlassian-authorized training partner',
    },
    {
      icon: `${Check}`,
      paragraph: 'Engage in hands-on practice environments to cement your learning and test your skills',
    },
  ],
  buttonLabel: 'See team training classes',
  link: links[0],
  analyticsButtonLabel: 'teamTrainingClassesButton',
};

export const publicClasses = {
  heading: 'Public classes',
  subheading: 'Sign up individually for a class that works with your schedule.',
  list: [
    {
      icon: `${People}`,
      paragraph: 'Learn from an Atlassian authorized training partner',
    },
    {
      icon: `${Ace}`,
      paragraph: 'Engage in hands-on practice environments',
    },
    {
      icon: `${Check}`,
      paragraph: 'Cement your learning and test your skills',
    },
  ],
  buttonLabel: 'See public classes',
  link: links[1],
  analyticsButtonLabel: 'publicClassesButton',
};

export const communityLedClasses = {
  heading: 'Community-led classes',
  subheading: 'Learn from a Community expert — and passionate Atlassian power user — for free.',
  list: [
    {
      icon: `${People}`,
      paragraph: 'Enjoy as many classes as you want — completely free of charge',
    },
    {
      icon: `${Ace}`,
      paragraph: 'Learn quickly (classes are often 60 minutes or less)',
    },
    {
      icon: `${Check}`,
      paragraph: 'Get access to a Community expert and their authentic user perspective',
    },
  ],
  buttonLabel: 'See community-led classes',
  link: links[2],
  analyticsButtonLabel: 'communityLedClassesButton',
};
