import { SetHelmetProps } from 'src/components/Helmet';

export const generateMetaTags = (helmet: SetHelmetProps): string => `
  ${helmet.title ? `<title data-react-helmet="true">${helmet.title}</title>` : ''}
  ${helmet.title ? `<meta data-react-helmet="true" name="og:title" content="${helmet.title}" />` : ''}
  ${helmet.seoTags?.robots ? `<meta name="robots" content="${helmet.seoTags.robots}" data-react-helmet="true" />` : ''}
  ${
  helmet.seoTags?.canonicalUrl
    ? `<link rel="canonical" href="${helmet.seoTags.canonicalUrl}" data-react-helmet="true" />`
    : ''
}
  ${
  helmet.seoTags?.description
    ? `<meta name="description" content="${helmet.seoTags.description}" data-react-helmet="true" />`
    : ''
}
  ${
  helmet.seoTags?.description
    ? `<meta name="og:description" content="${helmet.seoTags.description}" data-react-helmet="true" />`
    : ''
}
  ${
  helmet.seoTags?.openGraphType
    ? `<meta name="og:type" content="${helmet.seoTags.openGraphType}" data-react-helmet="true" />`
    : ''
}
  ${
  helmet.seoTags?.openGraphImage
    ? `<meta name="og:image" content="${helmet.seoTags.openGraphImage.url?.toString()}" data-react-helmet="true" />`
    : ''
}
  ${
  helmet.seoTags?.structuredData
    ? `<script type="application/ld+json" data-react-helmet="true">${JSON.stringify(
      helmet.seoTags.structuredData,
    )}</script>`
    : ''
}
`;
