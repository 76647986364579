import { FC, useContext, useEffect } from 'react';
import { css } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { Heading } from '@atlassian/learning-components';
import { media } from '@atlaskit/primitives/responsive';

import { useAtlassianUser } from 'src/hooks/useAtlassianUser';
import { HelmetContext, SetHelmetProps } from 'src/components/Helmet';
import { sendScreenEvent } from 'src/utils/analytics';
import { getAssetUrl } from 'src/services/http/user';

import { trainingBenefits, teamTraining, publicClasses, communityLedClasses } from './utils/utils';
import { HeroBanner } from './children/HeroBanner';
import { ListItemsSection } from './children/ListItemsSection';

const STATIC_SEO = {
  __typename: 'Seo',
  description: 'Instructor-led live training from Atlassian',
  robots: 'index,follow',
  canonicalUrl: 'https://community.atlassian.com/learning/landing/live-training',
  openGraphType: 'Website',
  structuredData: null,
  openGraphImage: {
    title: 'Atlassian Community',
    contentType: 'image/png',
    url: getAssetUrl('/images/homePage/seo.png'),
  },
};

export const LiveTrainingPage: FC = () => {
  const helmet = useContext(HelmetContext);
  const { user, isLoading, hasError } = useAtlassianUser();

  useEffect(() => {
    if (!isLoading && !hasError) {
      sendScreenEvent('liveTrainingScreen', {}, user?.account_id);
    }
  }, [user, isLoading, hasError]);

  helmet.setHelmet({
    title: 'Live Training | Learning - Atlassian Community',
    seoTags: STATIC_SEO as SetHelmetProps['seoTags'],
  });

  return (
    <main css={mainStyles} id="live-training-body" role="main" data-testid="live-training-page">
      <section css={sectionStyles}>
        <p css={eyebrowStyles}>Live training</p>
        <div css={headerStyles}>
          <Heading level={0}>Accelerate your learning with instructor-led training</Heading>
        </div>
      </section>
      <HeroBanner />
      <section css={[sectionStyles, trainingsMarginStyles]}>
        <ListItemsSection {...trainingBenefits} />
      </section>
      <section css={benefitsBgStyles}>
        <div css={sectionStyles}>
          <div css={benefitsImageStyles}></div>
          <img
            src={getAssetUrl('/images/liveTraining/teamTraining.png')}
            alt="Team training"
            css={benefitsImageStyles}
            data-testid="team-training-img"
          />
          <div css={teamTrainingMarginStyles}>
            <ListItemsSection {...teamTraining} />
          </div>
          <img src={getAssetUrl('/images/liveTraining/publicClasses.png')} alt="Public classes" css={teamImageStyles} />
        </div>
        <div css={sectionBgStyles}></div>
      </section>
      <section css={sectionStyles}>
        <div css={publicClassesMarginStyles}>
          <ListItemsSection {...publicClasses} />
        </div>
      </section>
      <section css={communityStyles}>
        <img
          src={getAssetUrl('/images/liveTraining/communityLedClasses.png')}
          alt="Community led classes"
          css={communityImageStyles}
        />
        <ListItemsSection {...communityLedClasses} />
        <div css={communityBgStyles}></div>
      </section>
      <section css={quoteStyles}>
        <div css={quoteContainerStyles}>
          <img src={getAssetUrl('/images/liveTraining/amanda.png')} alt="Amanda" css={quoteImageStyles} />
          <div>
            <q css={quoteTextStyles}>
              Atlassian live training helps me stay current — I always learn a new way of using a familiar tool.
            </q>
            <p css={authorStyles}>Amanda Barber</p>
            <p css={roleStyles}>Product Manager, GradeCam, Milwaukee, WI</p>
          </div>
        </div>
      </section>
    </main>
  );
};

const mainStyles = css({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: token('space.600'),
  paddingTop: '136px',
  overflowX: 'hidden',

  '@media (max-width: 1279px)': {
    marginTop: '0',
  },
});

const sectionStyles = css({
  maxWidth: '1020px',
});

const eyebrowStyles = css({
  color: token('color.text.subtle'),
  textAlign: 'center',
  fontFamily: 'Charlie Text, sans-serif',
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '18px',
  textTransform: 'uppercase',
  marginBottom: token('space.200'),
});

const headerStyles = css({
  textAlign: 'center',
  maxWidth: '720px',
  marginBottom: token('space.1000'),
});

const trainingsMarginStyles = css({
  marginTop: '28px',
  marginBottom: '155px',

  [media.below.sm]: {
    marginBottom: '69px',
  },
});

const benefitsBgStyles = css({
  zIndex: 1,
  position: 'relative',
  width: '100vw',
  height: '936px',
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',

  [media.below.lg]: {
    height: '795px',
  },

  [media.below.sm]: {
    height: '667px',
  },
});

const benefitsImageStyles = css({
  maxWidth: '1020px',
  height: 'auto',
  marginTop: '-90px',
  marginLeft: 'auto',
  marginRight: 'auto',

  [media.below.lg]: {
    maxWidth: '720px',
    marginTop: '-80px',
  },

  [media.below.sm]: {
    maxWidth: '343px',
    marginTop: '-30px',
  },
});

const teamImageStyles = css({
  maxWidth: '1020px',
  height: 'auto',
  marginLeft: 'auto',
  marginRight: 'auto',

  [media.below.lg]: {
    maxWidth: '720px',
  },

  [media.below.sm]: {
    maxWidth: '343px',
    height: 'auto',
  },
});

const sectionBgStyles = css({
  zIndex: -1,
  position: 'absolute',
  width: '100%',
  height: '100%',
  backgroundColor: '#F8EEFE',
  clipPath: 'polygon(0 0, 100% 40px, 100% calc(100% - 40px), 0 100%)',
});

const publicClassesMarginStyles = css({
  marginTop: '180px',
  marginBottom: '200px',

  [media.below.sm]: {
    marginTop: '125px',
    marginBottom: '125px',
  },
});

const communityStyles = css({
  zIndex: 1,
  position: 'relative',
  width: '100vw',
  height: '716px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',

  [media.below.lg]: {
    height: '645px',
  },

  [media.below.sm]: {
    height: '585px',
  },
});

const communityImageStyles = css({
  width: '1020px',
  height: 'auto',
  marginTop: '-155px',

  [media.below.lg]: {
    maxWidth: '720px',
    marginTop: '-148px',
  },

  [media.below.sm]: {
    maxWidth: '343px',
    marginTop: '-69px',
  },
});

const communityBgStyles = css({
  zIndex: -1,
  position: 'absolute',
  width: '100%',
  height: '100%',
  backgroundColor: '#EFFFD6',
  clipPath: 'polygon(0 0, 100% 40px, 100% 100%, 0 100%)',
});

const quoteStyles = css({
  zIndex: 1,
  position: 'relative',
  width: '100vw',
  height: '496px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#CFE1FD',

  [media.below.lg]: {
    height: '696px',
  },

  [media.below.sm]: {
    height: '655px',
  },
});

const quoteContainerStyles = css({
  maxWidth: '1020px',
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  alignItems: 'center',
  justifyContent: 'center',

  [media.below.lg]: {
    gridTemplateColumns: '1fr',
    justifyContent: 'center',
    maxWidth: '720px',
  },

  [media.below.sm]: {
    maxWidth: '375px',
    padding: `0 ${token('space.200')}`,
  },
});

const quoteImageStyles = css({
  width: '320px',
  height: 'auto',
  marginLeft: 'auto',
  marginRight: token('space.600'),

  [media.below.lg]: {
    width: '264px',
    margin: `0 auto ${token('space.500')} auto`,
  },

  [media.below.sm]: {
    width: '200px',
  },
});

const quoteTextStyles = css({
  color: '#000',
  fontFamily: 'Charlie Text, sans-serif',
  fontSize: '36px',
  fontWeight: 400,
  lineHeight: '48px',

  [media.below.sm]: {
    fontSize: '32px',
    lineHeight: '40px',
  },
});

const authorStyles = css({
  color: '#000',
  fontFamily: 'Charlie Text, sans-serif',
  fontSize: '24px',
  fontWeight: 700,
  lineHeight: '32px',
  marginTop: token('space.400'),

  [media.below.sm]: {
    fontSize: '20px',
    lineHeight: '28px',
  },
});

const roleStyles = css({
  color: '#000',
  fontFamily: 'Inter, sans-serif',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
});

const teamTrainingMarginStyles = css({
  '& > div': {
    marginBottom: '40px',

    [media.below.sm]: {
      marginBottom: '50px',
    },
  },
});
