import { Location, NavigateFunction } from 'react-router-dom';

import { ContentfulSmCourse } from 'src/graphql/queries/GetSmCourse';
import { ContentfulSmLesson } from 'src/graphql/queries/GetSmLesson';
import { LessonLoaderProps } from 'src/pages/LessonPage/loader';
import { ProgressForLessons } from 'src/services/http/progress';
import { getLearningPathPart, getLearningPathsHubPart } from 'src/utils/pathUtils';

export const SUBNAVBAR_HEIGHT = 68;

export enum BGColors {
  WHITE = '#ffffff',
  GREY = '#f7f8f9',
}

interface HandleLocationChangeParams {
  location: Location;
  lesson: LessonLoaderProps['lesson'];
  locationRef: React.MutableRefObject<string>;
}

export const getBackgroundColors = (
  lesson: LessonLoaderProps['lesson'],
): { quizBackground: string; nextLessonBackground: BGColors } => {
  const lastLessonSectionBackground =
    lesson?.sectionListCollection.items[lesson?.sectionListCollection.items.length - 1]?.backgroundColor;
  const quizBackground = lastLessonSectionBackground === BGColors.GREY ? BGColors.WHITE : BGColors.GREY;
  const nextLessonBackground = quizBackground === BGColors.GREY ? BGColors.WHITE : BGColors.GREY;

  return { quizBackground, nextLessonBackground };
};

export const handleLocationChange = ({ location, lesson, locationRef }: HandleLocationChangeParams): void => {
  if (!lesson) {
    return;
  }

  if (location.state) {
    const isNewLesson = locationRef.current !== lesson.slug;

    if (isNewLesson) {
      location.state.lessonSlug = lesson.slug;
      locationRef.current = lesson.slug;

      if (typeof window !== 'undefined') {
        window.scroll(0, 0);
        scrollByHash(location.hash, 1000);
      }
    }
  }
};

export const navigateToSection = (
  progress: ProgressForLessons | undefined,
  lesson: ContentfulSmLesson,
  course: ContentfulSmCourse,
  navigate: NavigateFunction,
  pathname: string,
  learningPathSlug?: string,
): void => {
  if (!progress) {
    return;
  }

  const lessonProgress = progress.lessons.find((l) => l.lessonId === lesson.sys.id);
  const firstUnseenSection = lesson.sectionListCollection.items.find(
    (s) => lessonProgress?.section.find((p) => s.sys.id === p.sectionId),
  );

  const learningPathPart = getLearningPathPart(learningPathSlug);
  const allLearningPathsHubPart = getLearningPathsHubPart(pathname);

  if (firstUnseenSection) {
    navigate(
      `${allLearningPathsHubPart}${learningPathPart}/course/${course?.slug}/lesson/${lesson.slug}#${firstUnseenSection.id}`,
      {
        replace: true,
      },
    );
  }
};

export const scrollByHash = (hash: string = '', timeout: number = 0): void => {
  const element = document.getElementById(hash.replace('#', ''));

  if (element) {
    setTimeout(() => {
      window.scrollTo({
        top: element.offsetTop - SUBNAVBAR_HEIGHT,
        behavior: 'smooth',
      });
    }, timeout);
  }
};
