import React, { useCallback, useContext, useEffect, useState, useRef } from 'react';
import { useLoaderData, useLocation, useNavigate, useParams } from 'react-router-dom';
import 'plyr/dist/plyr.css';
import { AudioPlayer, LessonHeader, ContentType, Text, useDeviceSize } from '@atlassian/learning-components';
import { css } from '@compiled/react';
import { media } from '@atlaskit/primitives/responsive';

import { HelmetContext } from 'src/components/Helmet';
import { useAtlassianUser } from 'src/hooks/useAtlassianUser';
import { getInspectModeData } from 'src/utils/contentful';
import { sendScreenEvent } from 'src/utils/analytics';
import { ScrollBehaviorTypes, setScrollBehaviour } from 'src/utils/lessonScrollBehavior';
import { useMediaProgressTracking } from 'src/hooks/useMediaProgressTracking';
import badge from 'src/assets/images/badge.svg';
import { useSaveRecentlyViewed } from 'src/hooks/useSaveRecentlyViewed';
import {
  getProgressForCourses,
  getProgressForLessons,
  LearningProgressStatus,
  ProgressForCourses,
  ProgressForLessons,
} from 'src/services/http/progress';
import { getNextCourse } from 'src/pages/LessonPage/children/utils/nextCourseUtils';
import { NextCourse } from 'src/pages/LessonPage/types/nextCourse';
import {
  ContentfulLearningPath,
  ContentfulLearningPathResponse,
  GET_LEARNING_PATH,
} from 'src/graphql/queries/GetLearningPath';
import { fetchGraphQL } from 'src/services/contentful/client';
import { GetLearningPathQueryVariables } from 'src/graphql/generated/GetLearningPath';
import { isStandalone } from 'src/pages/LessonPage/utils/isStandalone';
import { ContentHubType } from 'src/constants';
import { BreadcrumbsContainer } from 'src/components/BreadcrumbsContainer';
import { useSidebarVisibility } from 'src/context/SidebarVisibilityContext';
import { MarkdownWrapper } from 'src/components/MarkdownWrapper';

import { useLessonInteractionTracking } from '../LessonPage/children/hooks/useLessonInteractionTracking';
import { Quiz, QuizStatus } from './children/Quiz';
import { Sidebar } from './children/Sidebar';
import { LessonLoaderProps } from './loader';
import { NextLesson } from './children/NextLesson';
import { BadgeModal } from './children/BadgeModal';
import { calculateCourseCompletionRate, calculateLearningPathCompletionRate } from './children/utils/sidebarUtils';
import { sanitizeAudioUrl } from './children/utils/linkSanitizer';
import { LessonSection } from './children/LessonSection';
import { trackAudioButtonClick, trackAudioProgress, trackVideoProgress } from './utils/analytics';
import { getBackgroundColors, handleLocationChange, navigateToSection, scrollByHash } from './utils/utils';

export const LessonPage: React.FC = () => {
  const { course, lesson } = useLoaderData() as LessonLoaderProps;

  const deviceWidth = useDeviceSize();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const helmet = useContext(HelmetContext);
  const locationRef = useRef('');

  const learningPathSlug = params?.pathId;

  const [learningPath, setLearningPath] = useState<ContentfulLearningPath | null>(null);

  const [quizStatus, setQuizStatus] = useState<QuizStatus>(QuizStatus.UNINITIALIZED);
  const [currentUrl, setCurrentUrl] = useState<string>(globalThis.location.href);

  const [nextCourse, setNextCourse] = useState<NextCourse | null>(null);

  const [progress, setProgress] = useState<ProgressForLessons | undefined>();
  const [isLearningPathProgressLoading, setLearningPathProgressLoading] = useState(learningPathSlug ? true : false);
  const [learningPathProgress, setLearningPathProgress] = useState<ProgressForCourses | undefined>();

  const [isOpenedAudioPlayer, setIsOpenedAudioPlayer] = useState(false);

  const [isBreadcrumbVisible, setIsBreadcrumbVisible] = useState(false);

  const [audioProgress, setAudioProgress] = useState<number>(NaN);

  const [isLessonRecentlyCompleted, setIsLessonRecentlyCompleted] = useState(false);

  const updateAudioProgress = (progress: number): void => setAudioProgress(progress);
  const [videoDataProgress, setVideoProgress] = useState({ videoProgress: NaN, name: '', videoId: '' });
  const updateVideoProgress = (progress: number, name: string, videoId: string): void =>
    setVideoProgress({ videoProgress: progress, name, videoId });

  const { user, isLoading, hasError } = useAtlassianUser();
  const { onLessonStarted, onLessonCompletion } = useLessonInteractionTracking();

  const isStandaloneLesson = isStandalone();

  helmet.setHelmet({
    title: `${lesson?.title} | Learning - Atlassian Community`,
    seoTags: lesson?.seo,
  });

  const handleAudioPlayerOpen = useCallback(() => {
    setIsOpenedAudioPlayer((prev) => !prev);
  }, []);

  const handleLessonCompletion = (): void => {
    onLessonCompletion();
    setIsLessonRecentlyCompleted(true);
  };

  const sendAudioProgressEvent = useCallback(
    (action: string): void => {
      trackAudioProgress({
        action,
        audioProgress,
        isLoading,
        hasError,
        user,
        courseSlug: course.slug,
        lessonSlug: lesson?.slug,
      });
    },
    [audioProgress, course.slug, hasError, isLoading, lesson?.slug, user],
  );

  const sendVideoProgressEvent = useCallback(
    (action: string): void => {
      trackVideoProgress({
        action,
        name: videoDataProgress.name,
        videoId: videoDataProgress.videoId,
        videoProgress: videoDataProgress.videoProgress,
        isLoading,
        hasError,
        user,
        courseSlug: course.slug,
        lessonSlug: lesson?.slug,
      });
    },
    [
      videoDataProgress.name,
      videoDataProgress.videoId,
      videoDataProgress.videoProgress,
      isLoading,
      hasError,
      user,
      course.slug,
      lesson?.slug,
    ],
  );

  const { isSidebarVisible, setIsSidebarVisible } = useSidebarVisibility();
  const hasNavigatedToSection = useRef(false);

  const isAllLearningPathsHubIncluded = location.pathname?.includes(`hub/${ContentHubType.LEARNING_PATHS}`);
  const isLearningPathIncluded = location.pathname?.includes('path');

  useEffect(() => {
    setIsLessonRecentlyCompleted(false);
  }, [lesson]);

  useEffect(() => {
    if (learningPathSlug) {
      const getLearningPathAsync = async (pathId: string, preview: boolean): Promise<void> => {
        const response = await fetchGraphQL<ContentfulLearningPathResponse, GetLearningPathQueryVariables>(
          GET_LEARNING_PATH,
          { slug: pathId, preview },
        );

        setLearningPath(response.learningPathCollection.items[0]);
      };

      getLearningPathAsync(learningPathSlug, false);
    }
  }, [learningPathSlug]);

  // prevent the lesson sections check icons blinking and wrong icons filling
  useEffect(() => {
    const fetchProgressForLessons = async (): Promise<void> => {
      if (user) {
        setProgress(await getProgressForLessons(course.lessonsCollection.items.map((l) => l.sys.id)));
      }
    };

    fetchProgressForLessons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //prevents lesson auto completion after navigation to the lesson
  useEffect(() => {
    setScrollBehaviour(ScrollBehaviorTypes.SMOOTH);
    window.scroll(0, 0);

    scrollByHash(location.hash, 1100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Scrolls down to section included in the URL as a hash
  useEffect(() => {
    handleLocationChange({ location, lesson, locationRef });
  }, [lesson, location]);

  useEffect(() => {
    if (!isLoading && !hasError && course && lesson) {
      sendScreenEvent(
        'lessonScreen',
        {
          lessonSlug: lesson?.slug,
          courseSlug: course.slug,
          isStandaloneLesson,
        },
        user?.account_id,
      );
    }
  }, [user, isLoading, hasError, course, lesson, isStandaloneLesson]);

  useEffect(() => {
    // Getting learning path progress if learningPath exists
    if (learningPath) {
      const fetchProgressForLearningPathAsync = async (): Promise<void> => {
        if (user) {
          setLearningPathProgressLoading(true);
          setLearningPathProgress(
            await getProgressForCourses(learningPath.coursesCollection.items.map((c) => c.sys.id)),
          );
          setLearningPathProgressLoading(false);
        }
      };

      fetchProgressForLearningPathAsync();
    }
  }, [learningPath, user]);

  useEffect(() => {
    if (!learningPathProgress) {
      return;
    }

    // Getting next course for the current learning path
    const fetchNextCourseAsync = async (progressData: ProgressForCourses): Promise<void> => {
      if (learningPath && course) {
        const nextCourse = await getNextCourse(learningPath, course, progressData);

        setNextCourse(nextCourse);
      }
    };

    fetchNextCourseAsync(learningPathProgress);
  }, [course, learningPath, learningPathProgress, user]);

  useEffect(() => {
    const fetchProgressForLessons = async (): Promise<void> => {
      let progressForLessons;

      if (user) {
        progressForLessons = await getProgressForLessons(course.lessonsCollection.items.map((l) => l.sys.id));
      }

      setProgress(progressForLessons);
      const currentLessonProgress = progressForLessons?.lessons.find((l) => l.lessonId === lesson?.sys.id);

      onLessonStarted(
        course.slug,
        lesson?.slug,
        currentLessonProgress?.status === LearningProgressStatus.IN_PROGRESS,
        currentLessonProgress?.status === LearningProgressStatus.COMPLETED,
        user?.account_id,
      );
    };

    fetchProgressForLessons();
    // eslint-disable-next-line
  }, [user, course]);

  useEffect(() => {
    if (progress && !hasNavigatedToSection.current && !isStandaloneLesson) {
      navigateToSection(progress, lesson, course, navigate, location.pathname, learningPathSlug);
      hasNavigatedToSection.current = true;
    }
  }, [progress, lesson, course, navigate, learningPathSlug, isStandaloneLesson, location.pathname]);

  useEffect(() => {
    setIsOpenedAudioPlayer(false);
  }, [lesson?.sys.id]);

  useEffect(() => {
    setIsBreadcrumbVisible(isAllLearningPathsHubIncluded || isLearningPathIncluded);
  }, [location.pathname, isAllLearningPathsHubIncluded, isLearningPathIncluded]);

  // Send a request to add the lesson to the list of recently viewed section when the user is redirected or has left the page
  useSaveRecentlyViewed(lesson?.sys.id, ContentType.LESSON, learningPath?.sys.id, course.sys.id);

  useMediaProgressTracking(sendAudioProgressEvent, audioProgress);
  useMediaProgressTracking(sendVideoProgressEvent, videoDataProgress.videoProgress);

  const { quizBackground, nextLessonBackground } = getBackgroundColors(lesson);

  const brandfolderAudioUrl = lesson?.audioPlayer?.audioFile
    ? sanitizeAudioUrl(lesson?.audioPlayer?.audioFile[0].cdn_url)
    : '';

  const learningPathCompletionRate = calculateLearningPathCompletionRate(learningPathProgress, learningPath);

  const learningPathData = learningPath
    ? {
      learningPathSlug: learningPath.slug,
      learningPathCompletionRate,
      learningPathTitle: learningPath.title,
      nextCourse,
    }
    : null;

  return (
    <>
      <main css={[basePageStyles, isSidebarVisible && baseMenuOpenStyles]} id="lesson-body" data-testid="lesson-page">
        <div css={css({ display: 'flex', flex: 1 })}>
          <div data-testid="audio-player-container" css={audioPlayerContainerStyles}>
            <AudioPlayer
              source={lesson?.audioPlayer?.cdnUrl ? sanitizeAudioUrl(lesson.audioPlayer.cdnUrl) : brandfolderAudioUrl}
              onControlClick={(type) =>
                trackAudioButtonClick({
                  lessonSlug: lesson?.slug,
                  courseSlug: course.slug,
                  lesson,
                  type,
                  user,
                })
              }
              onProgressUpdate={updateAudioProgress}
              key={lesson?.sys.id}
              onClose={handleAudioPlayerOpen}
              isOpened={isOpenedAudioPlayer}
            />
          </div>
          {!isStandaloneLesson && (
            <Sidebar
              course={course}
              onChangeCurrentUrl={setCurrentUrl}
              isSidebarVisible={isSidebarVisible}
              onChangeSidebarVisibility={setIsSidebarVisible}
              lesson={lesson}
              badgeImg={course?.reward?.badgeCdnUrl || course?.reward?.badge?.[0]?.cdn_url || badge}
              progress={progress}
              onSectionCompleted={setProgress}
              isBreadcrumbVisible={isBreadcrumbVisible}
            />
          )}
          <div
            css={[
              css({ flexShrink: 0, flexGrow: 0, [media.below.md]: { display: 'none' } }),
              !isSidebarVisible && css({ width: '20px' }),
              isSidebarVisible && css({ width: '420px' }),
              isStandaloneLesson && css({ display: 'none' }),
            ]}
          />
          <div css={css({ flexGrow: 1, flexShrink: 1 })}>
            {isBreadcrumbVisible && (
              <BreadcrumbsContainer learningPath={learningPath} course={course} lessonSlug={lesson.slug} />
            )}
            <div css={[css({ background: '#FFF5D4' })]}>
              <div
                data-testid="lesson-header-container"
                css={[headerContainerStyles, isBreadcrumbVisible && breadcrumbMarginStyles]}
              >
                <LessonHeader
                  inspectMode={getInspectModeData(lesson, ['title'])}
                  heading={lesson?.title || ''}
                  tags={[{ label: `${lesson?.duration} min` }, { label: course.learningLevel || '' }]}
                  urlToShare={currentUrl}
                  description={
                    <Text size="large">
                      <div css={markdownStyles}>
                        <MarkdownWrapper>{lesson?.description ? lesson.description : ''}</MarkdownWrapper>
                      </div>
                    </Text>
                  }
                  onListenClick={handleAudioPlayerOpen}
                />
              </div>
            </div>
            {lesson?.sectionListCollection.items?.map((section) => (
              <LessonSection
                key={section.sys.id}
                section={section}
                deviceWidth={deviceWidth}
                user={user}
                updateVideoProgress={updateVideoProgress}
              />
            ))}
            <div id="quiz">
              {!!lesson?.quiz && (
                <section
                  data-testid="quiz"
                  css={css({ padding: '80px 16px' })}
                  style={{ backgroundColor: quizBackground }}
                >
                  <Quiz
                    lesson={lesson}
                    course={course}
                    user={user}
                    quizStatus={quizStatus}
                    onQuizStatusChange={setQuizStatus}
                    onLessonCompletion={handleLessonCompletion}
                    progress={progress}
                    onProgressChange={setProgress}
                    learningPathProgress={learningPath ? learningPathProgress : undefined}
                    onLearningPathChange={learningPath ? setLearningPathProgress : undefined}
                    isLearningPathProgressLoading={isLearningPathProgressLoading}
                  />
                </section>
              )}
            </div>
            <NextLesson
              courseSlug={course.slug}
              course={course}
              lesson={lesson}
              nextLessonBackground={nextLessonBackground}
              progress={progress}
            />
          </div>
        </div>
      </main>
      {!isStandaloneLesson && (
        <BadgeModal
          isLessonRecentlyCompleted={isLessonRecentlyCompleted}
          courseCompletionRate={calculateCourseCompletionRate(progress, course)}
          learningPathData={learningPathData}
          isLearningPathProgressLoading={isLearningPathProgressLoading}
          badgeImg={course?.reward?.badgeCdnUrl || course?.reward?.badge?.[0]?.cdn_url || badge}
          shouldHide={!!(learningPathSlug && !learningPath)}
        />
      )}
    </>
  );
};

const basePageStyles = css({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const baseMenuOpenStyles = css({
  position: 'relative',
});

const headerContainerStyles = css({
  maxWidth: '720px',
  margin: '0 auto',
  padding: '0 16px',
  boxSizing: 'content-box',
  marginTop: '136px',

  '@media (max-width: 1279px)': {
    marginTop: '0',
  },
  [media.below.sm]: {
    marginTop: '48px',
  },
  [media.above.sm]: {
    padding: '0 24px',
  },
});

const breadcrumbMarginStyles = {
  marginTop: '204px',

  '@media (max-width: 1279px)': {
    marginTop: '68px',
  },

  '@media (max-width: 768px)': {
    marginTop: '104px',
  },
};

const audioPlayerContainerStyles = css({
  position: 'fixed',
  bottom: '72px',
  right: '72px',
  zIndex: 100,

  [media.below.xs]: {
    left: '50%',
    right: 'auto',
    bottom: '16px',
    transform: 'translateX(-50%)',
    minWidth: '340px',
  },
});

// TODO: this should be fixed in typography
const markdownStyles = css({
  'ul, ol, li': {
    marginTop: '16px',
  },
});
