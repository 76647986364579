import { gql } from 'graphql-tag';

import {
  AT_IMAGE_FRAGMENT,
  BENEFIT_FRAGMENT,
  ENTRY_LAYOUT_FRAGMENT,
  REUSABLE_CONTENT_SECTION_FRAGMENT,
  SEO_FRAGMENT,
} from 'src/graphql/fragments/fragments';
import { GetCertificationDetailCollectionQuery } from 'src/graphql/generated/GetCertificationDetail';

export const GET_CERTIFICATION_DETAIL_COLLECTION = gql`
  ${AT_IMAGE_FRAGMENT}
  ${BENEFIT_FRAGMENT}
  ${ENTRY_LAYOUT_FRAGMENT}
  ${REUSABLE_CONTENT_SECTION_FRAGMENT}
  ${SEO_FRAGMENT}
  query getCertificationDetailCollection($preview: Boolean!, $slug: String!) {
    certificationCollection(preview: $preview, limit: 1, where: { slug: $slug }) {
      items {
        __typename
        sys {
          id
        }
        certificationNameInternal
        certificationTitleExternal
        certificationDescription
        slug
        examDetails
        examTopics
        certificationRenewals
        detailedExamTopics {
          ... on Asset {
            title
            description
            contentType
            fileName
            size
            url
            width
            height
          }
        }
        certificationType {
          sys {
            id
          }
          certificationTypeNameInternal
          certificationTypeTitleExternal
          certificationTypeDescription
          benefitsContainerCollection(limit: 4, preview: $preview, where: { sys: { id_exists: true } }) {
            items {
              ...GetBenefitFragment
            }
          }
          asset {
            ...AtImageFragment
          }
        }
        examSpecifics {
          __typename
          sys {
            id
          }
          certificationNameInternal
          price
          examTime
          questions
          passingScore
          productsCovered
          location
          validFor
        }
        certificateOutcomesCollection(limit: 50, preview: $preview, where: { sys: { id_exists: true } }) {
          items {
            __typename
            sys {
              id
            }
            outcomeNameInternal
            outcomesTitleExternal
            outcomes
            doesNotDo
          }
        }
        designedForCollection(limit: 50, preview: $preview, where: { sys: { id_exists: true } }) {
          items {
            __typename
            sys {
              id
            }
            certificationRoleNameInternal
            certificationRoleTitleExternal
          }
        }
        examSchedulingCollection(limit: 50, preview: $preview, where: { sys: { id_exists: true } }) {
          items {
            __typename
            sys {
              id
            }
            examSchedulingNameInternal
            schedulingDirections
          }
        }
        scheduleExam {
          ...GetReusableContentSectionFragment
        }
        relatedCertificationPrep {
          ...GetReusableContentSectionFragment
        }
        communityGroup {
          ...GetReusableContentSectionFragment
        }
        contactUs {
          ...GetReusableContentSectionFragment
        }
        relatedCredentialsCollection(limit: 3, preview: $preview, where: { sys: { id_exists: true } }) {
          items {
            ... on Certification {
              certificationTitleExternal
              slug
            }
          }
        }
        product
        seo {
          ...SeoFragment
        }
      }
    }
  }
`;

export type CertificationDetail = GetCertificationDetailCollectionQuery['certificationCollection']['items'][0];
