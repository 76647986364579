import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Button, ButtonAppearance, Divider, Heading, Icon } from '@atlassian/learning-components';
import { css } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { throttle } from 'lodash';

import { Taxonomy } from 'src/pages/CatalogPage/children/Taxonomy';
import { useQueryParams } from 'src/hooks/useQueryParams';
import { taxonomyToDisplayTextMap } from 'src/pages/CatalogPage/children/Sidebar';
import { Taxonomy as TaxonomyType } from 'src/pages/CatalogPage';
import { TaxonomyCount } from 'src/pages/CatalogPage/utils/taxonomy';

const NAVBAR_HEIGHT = 56;
const BUTTON_GAP = 12;
const INITIAL_BUTTON_POSITION = 180;

interface MobileSidebarProps {
  selectedFiltersLength: number;
  taxonomy: TaxonomyType;
  numberEntitiesPerTaxonomy: TaxonomyCount;
  isLoadingCount?: boolean;
  onFilterInteraction?: () => void;
}

export const MobileSidebar = ({
  taxonomy,
  selectedFiltersLength,
  numberEntitiesPerTaxonomy,
  onFilterInteraction,
  isLoadingCount,
}: MobileSidebarProps): ReactElement => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [expandedTaxonomy, setExpandedTaxonomy] = useState<string | null>(null);
  const [isFixed, setIsFixed] = useState(false);

  const filterButtonRef = useRef<HTMLDivElement>(null);

  const { clearAllQueryParams } = useQueryParams();

  const handleSaveChangesClick = (): void => {
    setIsMenuOpen(false);
  };

  const handleExpand = (taxonomyKey: string): void => {
    setExpandedTaxonomy((prev) => (prev === taxonomyKey ? null : taxonomyKey));
  };

  useEffect(() => {
    const initialPosition = filterButtonRef.current?.getBoundingClientRect().top || INITIAL_BUTTON_POSITION;
    const offset = initialPosition - NAVBAR_HEIGHT;

    const handleScroll = throttle((): void => {
      setIsFixed(window.scrollY > offset);
    }, 0);

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      {!isMenuOpen ? (
        <div css={[containerStyles, css({ height: '58px' })]}>
          <div ref={filterButtonRef} css={isFixed && mobileFilterButtonScrolledStyles}>
            <Button
              data-testid="mobile-filter-button"
              label="Filter"
              appearance={ButtonAppearance.SECONDARY}
              iconBefore={<Icon type="filter" />}
              onClick={() => setIsMenuOpen(true)}
            />
          </div>
        </div>
      ) : (
        <div css={containerStyles} data-testid="mobile-sidebar">
          <div css={contentStyles}>
            <div css={css({ width: '100%' })}>
              <div css={crossIconContainerStyles}>
                <Button
                  label="Close"
                  iconBefore={<Icon type="cross" />}
                  appearance={ButtonAppearance.ICON}
                  onClick={() => setIsMenuOpen(false)}
                />
              </div>
              <div css={headingContainerStyles}>
                <Heading level={5}>Filter by:</Heading>
              </div>
              <div css={selectedCounterContainerStyles}>
                <p css={selectedTitleStyles}>Selected:</p>
                <p css={[noFiltersStyles, selectedFiltersLength && selectedFiltersCounterStyles]}>
                  {selectedFiltersLength}
                </p>
              </div>
              {Object.keys(taxonomy).map((t) => (
                <Taxonomy
                  key={t}
                  isMobile={true}
                  taxonomy={taxonomyToDisplayTextMap[t]}
                  queryParamKey={t}
                  filters={taxonomy[t as keyof TaxonomyType]}
                  filterCounts={numberEntitiesPerTaxonomy[t]}
                  isLoadingCount={isLoadingCount}
                  isExpanded={expandedTaxonomy === t}
                  handleExpand={() => handleExpand(t)}
                  onFilterInteraction={onFilterInteraction}
                />
              ))}
            </div>
            <div css={modalFooterStyles}>
              <div css={dividerContainerStyles}>
                <Divider color={token('color.border')} height={2} />
              </div>
              <div css={buttonsListStyles}>
                <Button label="Clear All" appearance={ButtonAppearance.LINK} onClick={clearAllQueryParams} />
                <Button label="Save Changes" appearance={ButtonAppearance.PRIMARY} onClick={handleSaveChangesClick} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const containerStyles = css({
  paddingTop: token('space.200'),
});

const mobileFilterButtonScrolledStyles = css({
  position: 'fixed',
  top: NAVBAR_HEIGHT + BUTTON_GAP,
  backgroundColor: token('color.background.input'),
  borderRadius: token('space.050'),
});

const contentStyles = css({
  display: 'flex',
  width: '100%',
  position: 'fixed',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: token('space.200'),
  left: 0,
  top: '56px',
  bottom: 0,
  backgroundColor: token('color.background.input'),
  overflowY: 'auto',
  zIndex: 11,
});

const crossIconContainerStyles = css({
  display: 'flex',
  justifyContent: 'end',
});

const headingContainerStyles = css({
  paddingBottom: token('space.200'),
});

const selectedCounterContainerStyles = css({
  display: 'flex',
  alignItems: 'center',
});

const selectedTitleStyles = css({
  margin: 0,
  color: token('color.text.subtle'),
  fontFamily: 'Inter, sans-serif',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '22px',
});

const noFiltersStyles = css({
  margin: `0 0 0 ${token('space.050')}`,
  padding: `${token('space.025')} ${token('space.075')}`,
  color: token('color.text'),
  fontFamily: 'SF Pro, sans-serif',
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '12px',
  borderRadius: '8px',
  backgroundColor: token('color.background.neutral'),
});

const selectedFiltersCounterStyles = css({
  color: token('color.text.inverse'),
  backgroundColor: token('color.background.brand.bold'),
});

const dividerContainerStyles = css({
  width: '100%',
  marginTop: token('space.200'),
  marginBottom: token('space.200'),
});

const buttonsListStyles = css({
  display: 'flex',
  width: '327px',
  height: '40px',
  paddingRight: token('space.300'),
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: token('space.200'),
});

const modalFooterStyles = css({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
});
