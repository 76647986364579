/* eslint-disable @typescript-eslint/no-explicit-any */
import { LabelledGraphic, Text } from '@atlassian/learning-components';
import { css } from '@compiled/react';
import DOMpurify from 'dompurify';

import { User } from 'src/types';
import { sendUIEvent } from 'src/utils/analytics';
import { isStandalone } from 'src/pages/LessonPage/utils/isStandalone';
import { MarkdownWrapper } from 'src/components/MarkdownWrapper';

interface LabelledGraphicBlockProps {
  courseId: string | undefined;
  lessonId: string | undefined;
  fields: Record<string, any>;
  user: User | null | undefined;
  width: number;
}

export const LabelledGraphicBlock: React.FC<LabelledGraphicBlockProps> = ({
  fields,
  courseId,
  lessonId,
  user,
  width,
}) => {
  const brandfolderUrl = fields.image.brandfolderUrl ? fields.image.brandfolderUrl[0].cdn_url : null;
  const imageUrl = fields.image?.image?.url ? fields.image?.image?.url : null;
  const src = brandfolderUrl || imageUrl || null;

  const image = {
    src,
    width: fields.image?.width ? fields.image.width : null,
    height: fields.image?.height ? fields.image.height : null,
    alt: fields.image?.altText ? fields.image.altText : fields.image?.name,
    format: fields.image?.format ? fields.image.format : null,
    id: fields.image?.sys.id,
  };

  const newMarkers = fields.dataMarkers?.map((marker: any) => {
    const updatedMarker = { ...marker };

    if (width > 0 && width <= 425) {
      updatedMarker.posY = updatedMarker.posY - 4;
    } else if (width > 425 && width <= 1440) {
      updatedMarker.posY = updatedMarker.posY - 2;
    } else if (width > 1440 && width < 2560) {
      updatedMarker.posY = updatedMarker.posY - 1;
    }

    return {
      isSelected: false,
      posX: updatedMarker.posX,
      posY: updatedMarker.posY,
      icon: updatedMarker.icon,
      title: updatedMarker.title,
      arrowPosition: updatedMarker.arrowPosition,
      children: (
        <Text>
          <MarkdownWrapper>
            {updatedMarker.description && typeof window !== 'undefined'
              ? DOMpurify.sanitize(updatedMarker.description)
              : ''}
          </MarkdownWrapper>
        </Text>
      ),
    };
  });

  const analyticsAttributes = {
    name: fields.name,
    lessonSlug: lessonId,
    courseSlug: courseId,
    imageId: image.id,
    imageAlt: image.alt,
    isStandaloneLesson: isStandalone(),
  };

  const handleOnClickMarker = (markerIndex: number): void => {
    const analyticsAttributes = {
      name: fields.name,
      lessonSlug: lessonId,
      courseSlug: courseId,
      imageId: image.id,
      imageAlt: image.alt,
      markerIndex: markerIndex,
      markerTotalNumber: fields.dataMarkers.length,
      isStandaloneLesson: isStandalone(),
    };

    sendUIEvent(
      'lessonScreen',
      'button',
      'clicked',
      'labelledGraphicMarkerButton',
      analyticsAttributes,
      user?.account_id,
    );
  };

  return (
    <div css={spacingStyles}>
      <LabelledGraphic
        image={image}
        markers={newMarkers}
        onClickMarker={handleOnClickMarker}
        onClickDismiss={() => {
          sendUIEvent('lessonScreen', 'popup', 'closed', 'labelledGraphicPopup', analyticsAttributes, user?.account_id);
        }}
        onClickNext={() =>
          sendUIEvent(
            'lessonScreen',
            'button',
            'clicked',
            'labelledGraphicNextButton',
            analyticsAttributes,
            user?.account_id,
          )
        }
        onClickPrevious={() =>
          sendUIEvent(
            'lessonScreen',
            'button',
            'clicked',
            'labelledGraphicPrevButton',
            analyticsAttributes,
            user?.account_id,
          )
        }
        pulseAnimationColor={fields.pulseAnimationColor?.toUpperCase()}
      />
    </div>
  );
};

const spacingStyles = css({
  paddingTop: '40px',
  paddingBottom: '40px',
});
