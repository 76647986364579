import { AtImage, OrVideo } from 'src/graphql/generated/graphql';
import { Asset } from 'src/graphql/queries/GetRoleCollection';
import { sanitizeCaptionsUrl } from 'src/pages/LessonPage/children/utils/linkSanitizer';

export interface ImageAttrs {
  src: string;
  alt: string;
}

interface VideoAttrs {
  cdnCaptions: string;
  brandfolderCaptions: string;
  cdnUrl: string;
  brandfolderUrl: string;
  vimeoUrl: string;
  youtubeUrl: string;
}

interface InspectModeContentful {
  [key: string]: {
    entryId: string;
    fieldId: string;
  };
}

type VideoCaptions = string | undefined;

export const getInspectModeData = (
  entry: { sys: { id: string } } | undefined,
  fieldsIds: string[],
): InspectModeContentful | undefined => {
  const data = {};

  if (entry && entry?.sys && entry.sys.id) {
    fieldsIds.forEach((fieldId) => {
      const entryId = entry.sys.id;

      if (entryId) {
        Object.assign(data, { [fieldId]: { entryId, fieldId } });
      }
    });

    return data;
  }
};

export const getImageAttrs = (asset: AtImage | Asset | undefined): ImageAttrs | undefined => {
  if (!asset) {
    return undefined;
  }

  const imageAttr = {
    alt: asset.altText,
  };

  if (asset.cdnUrl) {
    return {
      ...imageAttr,
      src: asset.cdnUrl,
    };
  }

  if (asset.brandfolderUrl) {
    return {
      ...imageAttr,
      src: asset.brandfolderUrl[0].cdn_url,
    };
  }

  return {
    ...imageAttr,
    src: asset.image?.url || '',
  };
};

export const getVideoAttrs = (videoData: OrVideo | undefined): VideoAttrs | undefined => {
  if (!videoData) {
    return undefined;
  }

  const videoAttrs = {
    cdnCaptions: videoData.captionCdnUrl || '',
    brandfolderCaptions: videoData.captionFile?.[0]?.cdn_url || '',
    cdnUrl: '',
    brandfolderUrl: '',
    vimeoUrl: '',
    youtubeUrl: '',
  };

  if (videoData.captionCdnUrl) {
    videoAttrs.brandfolderCaptions = '';
  }

  if (videoData.cdnUrl) {
    return {
      ...videoAttrs,
      cdnUrl: videoData.cdnUrl,
    };
  }

  if (videoData.brandfolderUrl) {
    return {
      ...videoAttrs,
      brandfolderUrl: videoData.brandfolderUrl[0].cdn_url,
    };
  }

  if (videoData.vimeoUrl) {
    return {
      ...videoAttrs,
      vimeoUrl: videoData.vimeoUrl,
    };
  }

  return {
    ...videoAttrs,
    youtubeUrl: videoData.youtubeUrl,
  };
};

export const getVideoCaptions = (cdnCaptions: VideoCaptions, brandfolderCaptions: VideoCaptions): string => {
  if (cdnCaptions) {
    return cdnCaptions;
  }

  if (brandfolderCaptions) {
    return sanitizeCaptionsUrl(brandfolderCaptions);
  }

  return '';
};
