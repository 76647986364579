import { LoaderFunction } from 'react-router-dom';

import { fetchGraphQL } from 'src/services/contentful/client';
import { GetFlexibleContentHubCollectionQueryVariables } from 'src/graphql/generated/graphql';
import {
  ContentfulFlexibleContentHub,
  GET_FLEXIBLE_CONTENT_HUB_COLLECTION,
  GetFlexibleContentHubCollectionResponse,
} from 'src/graphql/queries/GetFlexibleContentHub';
import { ContentHubType } from 'src/constants';

export const loader: LoaderFunction = async ({ request }): Promise<CertificationHubLoaderProps | undefined> => {
  const preview = request.url.includes('preview=true');

  const certificationHubData = await getCertificationHubData(ContentHubType.CERTIFICATIONS, preview);

  if (!certificationHubData) {
    throw new Error('Certifications hub was not found');
  }

  return { certificationHubData };
};

const getCertificationHubData = async (
  type: string,
  preview: boolean,
): Promise<ContentfulFlexibleContentHub | undefined> => {
  const response = await fetchGraphQL<
    GetFlexibleContentHubCollectionResponse,
    GetFlexibleContentHubCollectionQueryVariables
  >(GET_FLEXIBLE_CONTENT_HUB_COLLECTION, {
    preview,
    type,
  });

  return response.flexibleContentHubCollection.items[0];
};

export interface CertificationHubLoaderProps {
  certificationHubData: ContentfulFlexibleContentHub;
}
