import React, { FC } from 'react';
import { LinkTarget } from '@atlassian/learning-components';
import { useNavigate } from 'react-router-dom';

import { ResourceCard } from 'src/components/ResourceCard';
import { formatDurationFromMinutes } from 'src/utils/formatDurationFromMinutes';
import { ContentType } from 'src/types';
import { CollectionType, LandingType, ResourceType } from 'src/constants';
import { getContentProduct } from 'src/utils/getContentProduct';
import { ResourceWithDurationAndType } from 'src/pages/CatalogPage/utils/taxonomy';
import { handleNavigate } from 'src/pages/CatalogPage/utils/handleNavigate';
import { getUrlPrefix } from 'src/services/http/user';

interface CardsListProps {
  resources: ResourceWithDurationAndType[];
  handleCardClick: (slug: string, label: string) => void;
}

const OPTIONS = {
  [ContentType.COURSE]: { label: 'course', pathParam: 'course', target: '_self' },
  [ContentType.LEARNING_PATH]: { label: 'learning path', pathParam: 'path', target: '_self' },
  [ContentType.CERTIFICATION]: {
    label: 'certification',
    pathParam: `landing/${LandingType.CERTIFICATION_DETAIL}`,
    target: '_self',
  },
  [ContentType.ROLE]: {
    label: 'collection',
    pathParam: `collection/${CollectionType.ROLE_BASED}`,
    target: '_self',
  },
  [ContentType.TOPIC]: {
    label: 'collection',
    pathParam: `collection/${CollectionType.TOPIC}`,
    target: '_self',
  },
  [ContentType.CERTIFICATION_PREP]: {
    label: 'collection',
    pathParam: `collection/${CollectionType.CERT_PREP}`,
    target: '_self',
  },
  [ContentType.INSTRUCTOR_LED_TRAINING]: { label: 'team training', pathParam: '', target: '_blank' },
};

const COLLECTION_TYPES = [ContentType.ROLE, ContentType.TOPIC, ContentType.CERTIFICATION_PREP];

export const CardsList: FC<CardsListProps> = ({ resources, handleCardClick }) => {
  const navigate = useNavigate();

  return (
    <>
      {resources.map((resource, index) => {
        const { label, pathParam, target } = OPTIONS[resource.__typename];
        const product =
          resource.__typename === ContentType.CERTIFICATION_PREP
            ? resource.relatedCertification?.product || null
            : resource.product;
        const contentType = COLLECTION_TYPES.includes(resource.__typename as ContentType)
          ? ResourceType.Collection
          : ResourceType[resource.__typename as keyof typeof ResourceType];
        const slug = pathParam ? `/${pathParam}/${resource.slug}` : `${resource.slug}`;
        const productTag = product?.length ? [{ label: product.length > 1 ? 'Multiple products' : product[0] }] : [];

        const tags = [{ label }, ...productTag];

        if (resource.duration) {
          tags.unshift({ label: formatDurationFromMinutes(resource.duration) });
        }

        return (
          <ResourceCard
            contentType={contentType}
            product={getContentProduct(product)}
            title={resource.title}
            link={pathParam ? `${getUrlPrefix()}${slug}` : slug}
            onClick={() => {
              handleCardClick(resource.slug, label);
              handleNavigate(navigate, slug, target as LinkTarget);
            }}
            target={target as LinkTarget}
            tags={tags}
            key={resource.slug + index}
          />
        );
      })}
    </>
  );
};
