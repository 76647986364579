export const sanitizeAudioUrl = (url: string): string => {
  const mp3Index = url.indexOf('.mp3');

  if (mp3Index !== -1) {
    return url.substring(0, mp3Index + 4);
  }

  return url;
};

export const sanitizeCaptionsUrl = (url: string): string => {
  const vttIndex = url.indexOf('.vtt');

  if (vttIndex !== -1) {
    return url.substring(0, vttIndex + 4);
  }

  return url;
};
