import React, { useCallback, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  ContentOverviewCard,
  Button,
  ButtonAppearance,
  ContentType,
  formatMinutes,
  ContentOverviewCardState,
} from '@atlassian/learning-components';
import { css } from '@compiled/react';
import { N800 } from '@atlaskit/theme/colors';

import { getInspectModeData } from 'src/utils/contentful';
import { ContentfulLearningPath } from 'src/graphql/queries/GetLearningPath';
import { User } from 'src/types';
import { LearningProgressStatus, ProgressForCourses } from 'src/services/http/progress';
import { constructCourseUrl } from 'src/utils/constructUrl';
import { sendTrackEvent } from 'src/utils/analytics';

import { getDuration } from './utils/learningPathUtils';

interface CourseListProps {
  learningPath: ContentfulLearningPath | null;
  user?: User | null;
  numCoursesShown?: number;
  isLoading?: boolean;
  progress?: ProgressForCourses;
  isLoadingProgress?: boolean;
}

export const MAX_COURSES_SHOWN = 6;

export const CourseList: React.FC<CourseListProps> = ({
  learningPath,
  user,
  numCoursesShown = MAX_COURSES_SHOWN,
  isLoading = false,
  progress,
  isLoadingProgress,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [shouldShowAllCourses, setShouldShowAllCourses] = useState<boolean>(false);

  const onCardClick = useCallback(
    (course: ContentfulLearningPath['coursesCollection']['items'][0]): void => {
      const hasNotStartedLearningPath =
        !progress?.overallStatus || progress.overallStatus === LearningProgressStatus.NOT_STARTED;

      if (hasNotStartedLearningPath) {
        sendTrackEvent(
          'learningApp',
          'learningPath',
          'started',
          {
            learningPathSlug: learningPath?.slug,
          },
          user?.account_id,
        );
      }

      if (course && learningPath) {
        const url = constructCourseUrl(course.slug, learningPath.slug, location.pathname);

        navigate(url);
      }
    },
    [navigate, learningPath, location.pathname, progress, user],
  );

  const getCourseState = (courseId: string): ContentOverviewCardState => {
    if (isLoading || isLoadingProgress) {
      return ContentOverviewCardState.LOADING;
    }

    if (!progress || !user) {
      return ContentOverviewCardState.DEFAULT;
    }

    const courseProgress = progress.courses.find((p) => p.courseId === courseId);

    if (courseProgress?.status === LearningProgressStatus.IN_PROGRESS) {
      return ContentOverviewCardState.IN_PROGRESS;
    }

    if (courseProgress?.status === LearningProgressStatus.COMPLETED) {
      return ContentOverviewCardState.FINISHED;
    }

    return ContentOverviewCardState.DEFAULT;
  };

  const showAllCourses = useCallback(() => {
    setShouldShowAllCourses(true);
  }, [setShouldShowAllCourses]);

  if (!learningPath?.coursesCollection.items || learningPath?.coursesCollection.items.length === 0) {
    return null;
  }

  const filteredCourses = learningPath?.coursesCollection.items.filter(
    (course) => course?.lessonsCollection.items.length,
  );

  const coursesToShow = shouldShowAllCourses ? filteredCourses : filteredCourses?.slice(0, numCoursesShown);

  return (
    <section data-testid="course-list" css={sectionStyles}>
      <div css={containerStyles}>
        <p data-testid="course-quantity-title" css={headerStyles}>
          {filteredCourses?.length}
          {`${filteredCourses.length > 1 ? ' courses' : ' course'} in this learning path`}
        </p>
        {coursesToShow.map((course) => (
          <ContentOverviewCard
            inspectMode={getInspectModeData(course, ['title', 'overview'])}
            key={course?.sys.id}
            tags={[{ label: formatMinutes(getDuration(course)) }]}
            heading={course?.title}
            subheading={course?.teaser}
            collapsedSectionTitle="Lessons"
            collapsedSectionItems={course?.lessonsCollection.items
              .filter((section) => section.title)
              .map((section) => section.title)}
            onClickButton={() => onCardClick(course)}
            state={getCourseState(course?.sys.id)}
            contentType={ContentType.LEARNING_PATH}
          />
        ))}
      </div>
      <div css={css({ marginTop: '1rem' })}>
        {coursesToShow.length < filteredCourses.length && (
          <Button label="See All Courses" appearance={ButtonAppearance.LINK} onClick={showAllCourses} />
        )}
      </div>
    </section>
  );
};

const sectionStyles = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  rowGap: '16px',
  marginTop: '24px',
  width: '100%',
});

const containerStyles = css({
  width: '100%',
  gap: '16px',
  display: 'flex',
  flexDirection: 'column',
});

const headerStyles = css({
  fontFamily: 'Charlie Display, sans-serif',
  fontSize: '24px',
  lineHeight: '32px',
  fontWeight: 600,
  color: N800,
  marginTop: 0,
  marginBottom: '16px',
});
