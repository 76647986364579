import { Radio } from '@atlassian/learning-components';
import { token } from '@atlaskit/tokens';
import { css } from '@compiled/react';
import { ReactElement } from 'react';

interface SingleSelectFieldProps {
  selectedAnswer: string | null;
  fieldTitle: string;
  onSelect: (answer: string) => void;
}

const satisfactionRate = ['1', '2', '3', '4', '5'];

export const SingleSelectField = ({ selectedAnswer, fieldTitle, onSelect }: SingleSelectFieldProps): ReactElement => (
  <div css={css({ marginBottom: token('space.600') })}>
    <p css={fieldTitleStyles}>
      {fieldTitle}
      <span css={astericsStyles}>*</span>
    </p>
    <div css={inlineStyles}>
      <p css={[rangeLabelStyles, css({ marginRight: '26.67px' })]}>Very dissatisfied</p>
      <div css={inlineStyles}>
        {satisfactionRate.map((rate) => (
          <div css={css({ marginRight: '26.67px' })} key={rate}>
            <Radio
              name={fieldTitle}
              label={rate}
              isChecked={selectedAnswer === rate}
              setIsChecked={() => onSelect(rate)}
            />
          </div>
        ))}
      </div>
      <p css={[rangeLabelStyles, css({ marginTop: 0 })]}>Very satisfied</p>
    </div>
  </div>
);

const fieldTitleStyles = css({
  color: token('color.text'),
  fontFamily: 'Inter, sans-serif',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
  marginBottom: token('space.200'),
});

const astericsStyles = css({
  color: token('color.text.danger'),
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
});

const rangeLabelStyles = css({
  color: token('color.text.subtlest'),
  fontFamily: 'Inter, sans-serif',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '18px',
});

const inlineStyles = css({
  display: 'flex',
  alignItems: 'center',
});
