import { LoaderFunction } from 'react-router-dom';

import { fetchGraphQL } from 'src/services/contentful/client';
import { CertificationDetail, GET_CERTIFICATION_DETAIL_COLLECTION } from 'src/graphql/queries/GetCertificationDetail';
import {
  GetCertificationDetailCollectionQuery,
  GetCertificationDetailCollectionQueryVariables,
} from 'src/graphql/generated/GetCertificationDetail';

export const loader: LoaderFunction = async ({ params, request }): Promise<CertificationDetailLoaderProps> => {
  const certificationId = params.certificationId ?? '';
  const preview = request.url.includes('preview=true');

  const certDetailData = await getCertDetailData(certificationId, preview);

  if (!certDetailData) {
    throw new Error('Cert Detail was not found');
  }

  return { certDetailData };
};

const getCertDetailData = async (slug: string, preview: boolean): Promise<CertificationDetail> => {
  const response = await fetchGraphQL<
    GetCertificationDetailCollectionQuery,
    GetCertificationDetailCollectionQueryVariables
  >(GET_CERTIFICATION_DETAIL_COLLECTION, {
    preview,
    slug,
  });

  return response.certificationCollection.items[0];
};

export interface CertificationDetailLoaderProps {
  certDetailData: CertificationDetail;
}
