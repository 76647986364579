import { ContentType } from '@atlassian/learning-components';

import type { Request } from '@atlassian/tesseract-runtime-globals';

export interface RecentlyViewedLessonItem {
  contentfulId: string;
  subCollectionTotal: number;
  progressTotal: number;
  lastVisitedDate: string;
  courseContentfulId: string | null;
  learningPathContentfulId: string | null;
}

export interface ArchivedContentItem {
  entryTitle: string;
  completionDate: Date;
}

export interface MyLearningItem {
  completedDate?: string;
  startedDate?: string;
  contentfulId: string;
  progressTotal: number;
  subCollectionTotal: number;
  learningPathContentfulId: string | null;
  isFromIntellum?: boolean;
}

export interface MyLearningData {
  learningPath: MyLearningItem[];
  courses: MyLearningItem[];
}

const typedGlobalThis = globalThis as typeof globalThis & { __COOKIES__: Request['headers']['cookie'] };

export const getIsContentSaved = async (
  contentfulId: string,
  contentType: 'course' | 'learningPath',
): Promise<boolean> => {
  const res = await fetch(
    `${globalThis?.location?.origin}/gateway/api/learning-app-progress-fe/my-learning/is-saved/${contentfulId}/${contentType}`,
    {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(typedGlobalThis.__COOKIES__ ? { cookie: typedGlobalThis.__COOKIES__ } : {}),
      },
    },
  );

  if (res.status !== 200) {
    return false;
  }

  return res.json();
};

export const saveOrUpdateContentSavedStatus = async (
  contentfulId: string,
  contentType: ContentType.COURSE | ContentType.LEARNING_PATH,
  isSaved: boolean,
  learningPathContentfulId?: string,
): Promise<boolean> => {
  const res = await fetch('/gateway/api/learning-app-progress-fe/my-learning/is-saved', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify({
      contentfulId,
      contentType,
      isSaved,
      learningPathContentfulId,
    }),
  });

  return res.status === 200 || res.status === 201;
};

export const getRecentlyViewedLessons = async (pageSize: number = 100): Promise<RecentlyViewedLessonItem[] | null> => {
  const url = `${globalThis?.location?.origin}/gateway/api/learning-app-progress-fe/my-learning/recently-viewed?pageNumber=0&pageSize=${pageSize}`;

  const response = await fetch(url, {
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      ...(typedGlobalThis.__COOKIES__ ? { cookie: typedGlobalThis.__COOKIES__ } : {}),
    },
  });

  if (response.status === 200) {
    const data = await response.json();

    return data.recentlyViewed;
  }

  return null;
};

export const getFullTranscriptReportData = async (): Promise<MyLearningData | null> => {
  const url = `${globalThis?.location?.origin}/gateway/api/learning-app-progress-fe/my-learning/report`;

  const response = await fetch(url, {
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      ...(typedGlobalThis.__COOKIES__ ? { cookie: typedGlobalThis.__COOKIES__ } : {}),
    },
  });

  if (response.status === 200) {
    return response.json();
  }

  return null;
};

export const saveContentForRecentlyViewed = async (
  atlassianId: string,
  contentfulId: string,
  contentType: ContentType,
  learningPathId?: string,
  courseId?: string,
): Promise<void> => {
  const url = `/gateway/api/learning-app-progress/sqs/viewed-content`;

  const lastTimeViewed = new Date().toISOString().split('.')[0] + 'Z';

  const body = {
    atlassianId,
    contentfulId,
    contentType,
    dateTimeViewed: lastTimeViewed,
    learningPathContentfulId: learningPathId,
    courseContentfulId: courseId,
  };

  await fetch(url, {
    credentials: 'include',
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      MessageBody: body,
    }),
  });
};

export const getMyLearning = async (
  myLearningTab: string,
  sortingType: string = 'default',
): Promise<MyLearningData | null> => {
  const tabStatus = myLearningTab === 'in-progress' ? 'inProgress' : myLearningTab;
  const res = await fetch(
    `${globalThis?.location?.origin}/gateway/api/learning-app-progress-fe/my-learning?myLearningTab=${tabStatus}&sortedField=${sortingType}`,
    {
      credentials: 'include',
      headers: {
        ...(typedGlobalThis.__COOKIES__ ? { cookie: typedGlobalThis.__COOKIES__ } : {}),
      },
    },
  );

  if (res.status !== 200) {
    return null;
  }

  return res.json();
};

export const getArchivedContent = async (
  pageNumber: number = 0,
  pageSize: number = 100,
): Promise<ArchivedContentItem[] | null> => {
  const url = `${globalThis?.location?.origin}/gateway/api/learning-app-progress-fe/my-learning/retired-content?pageNumber=${pageNumber}&pageSize=${pageSize}`;

  const response = await fetch(url, {
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      ...(typedGlobalThis.__COOKIES__ ? { cookie: typedGlobalThis.__COOKIES__ } : {}),
    },
  });

  if (response.status === 200) {
    return response.json();
  }

  return null;
};
