import { isProduction } from 'src/services/http/user';

export const REPORT_PROBLEM_URL =
  'https://jsd-widget.atlassian.com/api/embeddable/67d937a3-0a8c-4eea-b8dd-a1c5f16c9ee8/request?requestTypeId=10';
export const REPORT_PROBLEM_URL_DEV =
  'https://jsd-widget.atlassian.com/api/embeddable/298dd788-f8ab-43d2-916c-4ac084fb6125/request?requestTypeId=22';

interface ReportProblemArgs {
  problemType: string;
  description: string;
  canBeContacted?: boolean;
  canBeResearched?: boolean;
  sourcePage: string;
  atlassianID?: string;
}

const canBeContactedMap: { [key: string]: string } = {
  true: '10024',
  false: '10025',
};

const canBeResearchedMap: { [key: string]: string } = {
  true: '10026',
  false: '10027',
};

export const reportProblem = (report: ReportProblemArgs): Promise<Response> => {
  const fields = [
    {
      id: 'summary',
      value: report.description,
    },
    {
      id: 'customfield_10048',
      value: { id: report.problemType },
    },
    {
      id: 'description',
      value: report.description,
    },
    {
      id: 'customfield_10057',
      value: report.sourcePage,
    },
    {
      id: 'customfield_10058',
      value: report.atlassianID || '',
    },
    {
      id: 'customfield_10050',
      value: { id: canBeContactedMap[`${report.canBeContacted}`] },
    },
    {
      id: 'customfield_10054',
      value: { id: canBeResearchedMap[`${report.canBeResearched}`] },
    },
    {
      id: 'email',
      value: 'do-not-reply@atlassian.com',
    },
  ];

  const reportProblemFields = { fields };

  const reportProblemFieldsJSON = JSON.stringify(reportProblemFields);

  const url = isProduction() ? REPORT_PROBLEM_URL : REPORT_PROBLEM_URL_DEV;

  return fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: '*/*',
      'Content-Type': 'application/json',
    },
    body: reportProblemFieldsJSON,
  });
};
