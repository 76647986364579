/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { MouseEvent, ReactNode } from 'react';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import {
  Icon,
  Link,
  Text,
  Heading,
  SectionMessage,
  InlineCode,
  Lozenge,
  LessonColumns,
  Blockquote,
  List,
  Eyebrow,
} from '@atlassian/learning-components';
import { Options, documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { css } from '@compiled/react';
import { N20, N50 } from '@atlaskit/theme/colors';
import { useParams } from 'react-router-dom';

import { ContentType } from 'src/types/contentful/content-type';
import { User } from 'src/types';
import { ContentfulLayoutContent, ContentfulLessonTabContent } from 'src/graphql/queries/GetSmLesson';
import { LessonsTabWrapperComponent } from 'src/pages/LessonPage/children/LessonsTabWrapperComponent';
import { onClickAnchor } from 'src/pages/LessonPage/children/utils/optionsUtils';
import { AccordionBlock } from 'src/pages/LessonPage/children/blocks/AccordionBlock';
import { ImageBlock } from 'src/pages/LessonPage/children/blocks/ImageBlock';
import { LabelledGraphicBlock } from 'src/pages/LessonPage/children/blocks/LabelledGraphicBlock';
import { ParagraphBlock } from 'src/pages/LessonPage/children/blocks/ParagraphBlock';
import { VideoBlock } from 'src/pages/LessonPage/children/blocks/VideoBlock';
import { ImageInline } from 'src/pages/LessonPage/children/inlines/ImageInline';
import { MarkdownWrapper } from 'src/components/MarkdownWrapper';

export const getOptions = (
  content: any,
  width: number,
  user?: User | null,
  updateVideoProgress?: (progress: number, name: string, videoId: string) => void,
): Options => ({
  renderNode: {
    [INLINES.HYPERLINK]: (node): ReactNode => (
      <Link
        link={node.data.uri}
        label={(node.content[0] as { value: string })?.value}
        onClick={
          node.data.uri.indexOf('#') === 0
            ? (e: MouseEvent<HTMLAnchorElement>): void => onClickAnchor(e, node.data.uri.substring(1))
            : (): void => {
              window.open(node.data.uri);
            }
        }
      />
    ),
    [INLINES.ASSET_HYPERLINK]: (node): ReactNode => {
      const asset = content.links?.assets.hyperlink?.find((asset: any) => asset.sys.id === node.data.target.sys.id);

      return asset ? <Link link={asset.url} label={(node.content[0] as { value: string })?.value} /> : null;
    },
    [INLINES.EMBEDDED_ENTRY]: (node): ReactNode => {
      const { courseId, lessonId } = useParams();

      const entry = content.links?.entries.inline.find((block: any) => block?.sys.id === node.data.target.sys.id);
      const fields = entry as unknown as Record<string, any>;

      switch (entry?.__typename) {
        case ContentType.ICON: {
          return (
            <span css={iconContainerStyles}>
              <Icon
                type={fields.type}
                size={fields.size}
                color={fields.color}
                alt={fields.alt}
                rotation={fields.rotation}
              />
            </span>
          );
        }
        case ContentType.IMAGE: {
          return <ImageInline fields={fields} entry={entry} courseId={courseId} lessonId={lessonId} user={user} />;
        }
        case ContentType.LOZENGE: {
          const appearance = fields.appearance.includes(':') ? fields.appearance.split(':')[0] : fields.appearance;

          return (
            <span>
              <Lozenge text={fields.text} appearance={appearance} isBold={fields.isBold} />
            </span>
          );
        }
        case ContentType.SECTION_MESSAGE: {
          return (
            <div css={[spacingStyles, messageSectionContainerStyles]}>
              <SectionMessage appearance={fields.appearance}>
                <Text size="large">
                  <MarkdownWrapper>{fields.description ? fields.description : ''}</MarkdownWrapper>
                </Text>
              </SectionMessage>
            </div>
          );
        }
      }
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node): ReactNode => {
      const { courseId, lessonId } = useParams();

      const entry = content.links?.entries.block.find((block: any) => block?.sys.id === node.data.target.sys.id);

      const fields = entry as unknown as Record<string, any>;

      switch (entry?.__typename) {
        case ContentType.VIDEO: {
          return (
            <VideoBlock
              entry={entry}
              courseId={courseId}
              lessonId={lessonId}
              fields={fields}
              updateVideoProgress={updateVideoProgress}
              user={user}
            />
          );
        }
        case ContentType.IMAGE: {
          return <ImageBlock entry={entry} courseId={courseId} lessonId={lessonId} fields={fields} user={user} />;
        }
        case ContentType.ACCORDION: {
          return <AccordionBlock courseId={courseId} lessonId={lessonId} fields={fields} width={width} />;
        }
        case ContentType.INLINE_CODE: {
          return <InlineCode code={fields.code} />;
        }
        case ContentType.NAVTAB: {
          return <LessonsTabWrapperComponent block={entry as ContentfulLessonTabContent} />;
        }
        case ContentType.LABELLED_GRAPHIC: {
          return (
            <LabelledGraphicBlock courseId={courseId} lessonId={lessonId} fields={fields} width={width} user={user} />
          );
        }
        case ContentType.LAYOUT: {
          return (
            <LessonColumns columns={fields.columns}>
              {fields.columnContentCollection.items.map((contentLayout: ContentfulLayoutContent) => (
                <div key={contentLayout.sys.id}>
                  {contentLayout.content.json &&
                    documentToReactComponents(contentLayout.content.json, getOptions(contentLayout.content, width))}
                </div>
              ))}
            </LessonColumns>
          );
        }
        case ContentType.SECTION_MESSAGE: {
          return (
            <div css={[spacingStyles, messageSectionContainerStyles]}>
              <SectionMessage appearance={fields.appearance}>
                <Text size="large">
                  <MarkdownWrapper>{fields.description ? fields.description : ''}</MarkdownWrapper>
                </Text>
              </SectionMessage>
            </div>
          );
        }
      }
    },
    [BLOCKS.QUOTE]: (node, children): ReactNode => (
      <div css={mainWidthSettingsStyles}>
        <Blockquote data-testid="blockquote">{children}</Blockquote>
      </div>
    ),
    [BLOCKS.UL_LIST]: (node, children): ReactNode => (
      <div css={mainWidthSettingsStyles}>
        <List data-testid="unordered-list">{children}</List>
      </div>
    ),
    [BLOCKS.OL_LIST]: (node, children): ReactNode => (
      <div css={mainWidthSettingsStyles}>
        <List type="ordered" data-testid="ordered-list">
          {children}
        </List>
      </div>
    ),
    [BLOCKS.LIST_ITEM]: (node, children): ReactNode => <li>{children}</li>,
    [BLOCKS.PARAGRAPH]: (node, children): ReactNode => <ParagraphBlock>{children}</ParagraphBlock>,
    [BLOCKS.HEADING_2]: (node, children): ReactNode => {
      const textElement = (node.content[0] as { value: string })?.value;
      const idElement = textElement?.toLowerCase().replace(/\s+/g, '-') ?? '';

      return (
        <div css={[mainWidthSettingsStyles, css({ marginTop: '40px', marginBottom: '24px' })]}>
          <Heading level={2} id={idElement}>
            {children}
          </Heading>
        </div>
      );
    },
    [BLOCKS.HEADING_3]: (node, children): ReactNode => {
      const textElement = (node.content[0] as { value: string })?.value;
      const idElement = textElement?.toLowerCase().replace(/\s+/g, '-') ?? '';

      return (
        <div css={[mainWidthSettingsStyles, css({ marginTop: '40px', marginBottom: '24px' })]}>
          <Heading level={3} id={idElement}>
            {children}
          </Heading>
        </div>
      );
    },
    [BLOCKS.HEADING_4]: (node, children): ReactNode => (
      <div css={mainWidthSettingsStyles}>
        <Eyebrow>{children}</Eyebrow>
      </div>
    ),
    [BLOCKS.HR]: (): ReactNode => <hr css={[spacingStyles, css({ borderColor: N50 })]} />,
    [BLOCKS.TABLE]: (node, children): ReactNode => (
      <div css={mainWidthSettingsStyles}>
        <table css={[tableStyles, spacingStyles]}>
          <tbody>{children}</tbody>
        </table>
      </div>
    ),
    [BLOCKS.TABLE_CELL]: (node, children): ReactNode => <td css={tableCellStyles}>{children}</td>,
    [BLOCKS.TABLE_HEADER_CELL]: (node, children): ReactNode => <th css={tableHeaderStyles}>{children}</th>,
  },
  renderMark: {
    [MARKS.CODE]: (code): ReactNode => (
      <code css={codeStyles} data-testid="monospace" aria-label="code snippet">
        {code}
      </code>
    ),
  },
});

const mainWidthSettingsStyles = css({
  maxWidth: '720px',
  margin: '0 auto',
});

const iconContainerStyles = css({
  display: 'inline-flex',
  verticalAlign: 'top',
});

const spacingStyles = css({
  paddingTop: '40px',
  paddingBottom: '40px',
});

const messageSectionContainerStyles = css({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
});

const tableStyles = css({
  borderCollapse: 'collapse',
  display: 'block',
  maxWidth: '100%',
  overflowX: 'auto',

  tbody: {
    border: 'none',
  },
});

const tableCellStyles = css({
  padding: '16px !important',
  border: '1px solid rgba(9, 30, 66, 0.14)',
  verticalAlign: 'top',

  a: {
    wordBreak: 'normal',
  },

  img: {
    maxWidth: 'fit-content',
  },

  'li span': {
    marginLeft: '-0.75rem',
    whiteSpace: 'pre-wrap',
  },

  'li span a': {
    marginLeft: '0.75rem',
  },
});

const tableHeaderStyles = css({
  padding: '16px !important',
  fontWeight: 600,
  border: '1px solid rgba(9, 30, 66, 0.14)',
  backgroundColor: N20,
  textAlign: 'left',

  div: {
    paddingBottom: 0,
  },
});

const codeStyles = css({
  fontFamily: 'system-ui, sans-serif',
  backgroundColor: N20,
  border: 'none',
  borderRadius: '0.125rem',
  display: 'inline',
  padding: '0.125rem',
  whiteSpace: 'pre-wrap',
  overflow: 'auto',
});
