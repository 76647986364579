import { Field } from '@atlassian/learning-components';

import { Taglist } from 'src/components/TagList';
import { ContentfulLearningPath } from 'src/graphql/queries/GetLearningPath';

export const getDuration = (course: ContentfulLearningPath['coursesCollection']['items'][0]): number =>
  course?.lessonsCollection.items.reduce((total, { duration }) => total + duration, 0);

export const remapLearningPathTaxonomy = (learningPath: ContentfulLearningPath, duration: string): Field[] => [
  {
    name: 'duration',
    value: duration,
  },
  {
    name: 'role',
    value: learningPath.role,
  },
  {
    name: 'level',
    value: learningPath.learningLevel,
  },
  {
    name: 'product',
    value: Taglist(learningPath.product),
  },
];
