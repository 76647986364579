import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { taxonomyToDisplayTextMap } from 'src/pages/CatalogPage/children/Sidebar';
import { isDurationMatching, isTypeMatching, ResourceWithDurationAndType } from 'src/pages/CatalogPage/utils/taxonomy';

interface Filters {
  filteredResources: ResourceWithDurationAndType[];
  selectedFiltersLength: number;
}

export const useFilters = (resources: ResourceWithDurationAndType[]): Filters => {
  const [searchParams] = useSearchParams();

  const selectedFilters = useMemo(
    () =>
      Object.keys(taxonomyToDisplayTextMap).flatMap((taxonomy) =>
        searchParams.getAll(taxonomy).map((filter) => ({ [taxonomy]: filter.split(',') })),
      ),
    [searchParams],
  );

  const durationFilters = useMemo(
    () => searchParams.getAll('duration').flatMap((filter) => filter.split(',')),
    [searchParams],
  );

  const typeFilters = useMemo(() => searchParams.getAll('type').flatMap((filter) => filter.split(',')), [searchParams]);

  const filteredResources = useMemo(
    () =>
      resources.filter((resource) => {
        const matchesDuration =
          durationFilters.length > 0
            ? durationFilters.some((filter) => isDurationMatching(filter, resource.duration))
            : true;

        const matchesType =
          typeFilters.length > 0 ? typeFilters.some((filter) => isTypeMatching(filter, resource.type)) : true;

        return matchesDuration && matchesType;
      }),
    [resources, durationFilters, typeFilters],
  );

  const selectedFiltersLength = selectedFilters.reduce((acc, filter) => {
    const [filters] = Object.values(filter);

    return acc + filters.length;
  }, 0);

  return { filteredResources, selectedFiltersLength };
};
