import React, { useContext, useEffect } from 'react';
import { css } from '@compiled/react';
import FeatureGates from '@atlaskit/feature-gate-js-client';

import { RecommendedContentSection } from 'src/pages/HomePage/children/RecommendedContentSection';
import { HelmetContext, SetHelmetProps } from 'src/components/Helmet';
import { useAtlassianUser } from 'src/hooks/useAtlassianUser';
import { sendScreenEvent } from 'src/utils/analytics';
import { AllLearningPathsSection } from 'src/pages/HomePage/children/AllLearningPathsSection';
import { LiveTrainingSection } from 'src/pages/HomePage/children/LiveTrainingSection';
import { CertificationDetailSection } from 'src/pages/HomePage/children/CertificationDetailSection';
import { CareerSection } from 'src/pages/HomePage/children/CareerSection';
import { CustomerFeedbackSection } from 'src/pages/HomePage/children/CustomerFeedbackSection';
import { CoursesByProductSection } from 'src/pages/HomePage/children/CoursesByProductSection';
import { getAssetUrl } from 'src/services/http/user';
import { EMAIL_SUBSCRIPTION_FF } from 'src/constants';
import { EmailSubscription } from 'src/pages/HomePage/children/EmailSubscription';

import { Header } from './children/Header';

const STATIC_SEO = {
  __typename: 'Seo',
  description:
    'Learn Atlassian products quickly with online courses and live training, build skills and confidence, and earn globally recognized certifications.',
  robots: '',
  canonicalUrl: 'https://community.atlassian.com/learning',
  openGraphType: 'Website',
  structuredData: null,
  openGraphImage: {
    title: 'Atlassian Community',
    contentType: 'image/png',
    url: getAssetUrl('/images/homePage/seo.png'),
  },
};

export const HomePage: React.FC = () => {
  const { user, isLoading, hasError } = useAtlassianUser();
  const helmet = useContext(HelmetContext);
  const isEmailSubscriptionAvailable = FeatureGates.checkGate(EMAIL_SUBSCRIPTION_FF);

  helmet.setHelmet({
    title: 'Learning - Atlassian Community',
    seoTags: STATIC_SEO as SetHelmetProps['seoTags'],
  });

  useEffect(() => {
    if (!isLoading && !hasError) {
      sendScreenEvent('homeScreen', {}, user?.account_id);
    }
  }, [user, isLoading, hasError]);

  return (
    <main css={homePageContainerStyles}>
      <Header />
      <RecommendedContentSection />
      <AllLearningPathsSection />
      <LiveTrainingSection />
      <CertificationDetailSection />
      <CareerSection />
      <CustomerFeedbackSection />
      <CoursesByProductSection />
      {isEmailSubscriptionAvailable && <EmailSubscription />}
    </main>
  );
};

const homePageContainerStyles = css({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '136px',

  '@media (max-width: 1279px)': {
    marginTop: '0',
  },
});
