import React from 'react';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import { css } from '@compiled/react';
import { N800 } from '@atlaskit/theme/colors';
import { media } from '@atlaskit/primitives/responsive';

import { RecommendedResource } from 'src/pages/CoursePage/children/RecommendedResource';
import { ContentfulRelatedContent } from 'src/graphql/queries/GetSmCourse';
import { MarkdownWrapper } from 'src/components/MarkdownWrapper';

export interface RelatedContentSectionProps {
  relatedContent?: ContentfulRelatedContent;
}

export const RelatedContentSection: React.FC<RelatedContentSectionProps> = ({ relatedContent }) => {
  const inspectorProps = useContentfulInspectorMode();

  if (!relatedContent?.recommendedResourcesCollection.items.length) {
    return null;
  }

  return (
    <section data-testid="related-content-section" css={sectionStyles}>
      <div css={containerStyles}>
        <div css={textContainerStyles}>
          <h3 {...inspectorProps({ entryId: relatedContent.sys.id, fieldId: 'title' })} css={headerStyles}>
            {relatedContent.relatedTitle}
          </h3>
          <div
            css={css({ marginTop: '12px' })}
            {...inspectorProps({ entryId: relatedContent.sys.id, fieldId: 'description' })}
          >
            <MarkdownWrapper>{relatedContent.relatedContentDescription}</MarkdownWrapper>
          </div>
        </div>
        <div css={gridStyles}>
          {relatedContent.recommendedResourcesCollection.items.map((resource, i) => (
            <RecommendedResource key={resource.sys.id} sequence={i} resource={resource} />
          ))}
        </div>
      </div>
    </section>
  );
};

const sectionStyles = css({
  width: '100%',
  padding: '0 1.5rem',
  paddingBottom: '2rem',

  [media.above.lg]: {
    paddingBottom: '4rem',
  },
  [media.below.sm]: {
    padding: '0 1rem',
  },
});

const containerStyles = css({
  maxWidth: '1110px',
  margin: '0 auto',
  color: N800,
});

const textContainerStyles = css({
  textAlign: 'center',
  marginTop: '2rem',
  marginBottom: '2rem',

  [media.below.sm]: {
    textAlign: 'left',
  },
});

const headerStyles = css({
  fontFamily: 'Charlie Display, sans-serif',
  fontWeight: 600,
  fontSize: '1.5rem',
  lineHeight: '2rem',
  marginBottom: '0.25rem',
});

const gridStyles = css({
  display: 'grid',
  gap: '1.5rem',
  gridTemplateColumns: 'repeat(auto-fill, minmax(343px, 1fr))',

  [media.below.sm]: {
    gridTemplateColumns: 'repeat(1, 1fr)',
  },
});
