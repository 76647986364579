import { gql } from 'graphql-tag';

import {
  AT_IMAGE_FRAGMENT,
  HERO_FRAGMENT,
  REUSABLE_CONTENT_SECTION_FRAGMENT,
  SEO_FRAGMENT,
} from 'src/graphql/fragments/fragments';
import { GetCertPrepCollectionQuery } from 'src/graphql/generated/GetCertPrepCollection';

export const GET_CERT_PREP_COLLECTION = gql`
  ${HERO_FRAGMENT}
  ${AT_IMAGE_FRAGMENT}
  ${SEO_FRAGMENT}
  ${REUSABLE_CONTENT_SECTION_FRAGMENT}
  query getCertPrepCollection($slug: String!, $preview: Boolean!) {
    certificationPrepCollection(preview: $preview, where: { slug: $slug }) {
      __typename
      items {
        sys {
          id
        }
        certificationPrepNameInternal
        slug
        certificationPrepTitleExternal
        hero {
          ...GetHeroFragment
        }
        relatedCertification {
          sys {
            id
          }
          slug
          certificationType {
            certificationTypeNameInternal
          }
        }
        onDemandContent {
          ...GetReusableContentSectionFragment
        }
        prepContent {
          ...GetReusableContentSectionFragment
        }
        groupContent {
          ...GetReusableContentSectionFragment
        }
        continueLearningContent {
          ...GetReusableContentSectionFragment
        }
        seo {
          ...SeoFragment
        }
      }
    }
  }
`;

export type GetCertPrepCollectionResponse = GetCertPrepCollectionQuery;
export type ContentfulCertPrep = GetCertPrepCollectionResponse['certificationPrepCollection']['items'][0];
export type ContentfulCertPrepLinks =
  GetCertPrepCollectionResponse['certificationPrepCollection']['items'][0]['onDemandContent']['contentLinksCollection']['items'];
export type ContentfulCertPrepRelatedContentAsset =
  GetCertPrepCollectionResponse['certificationPrepCollection']['items'][0]['onDemandContent']['contentSectionTitle']['asset'];
