import { FC } from 'react';
import { css } from '@compiled/react';
import { useSearchParams } from 'react-router-dom';
import { Checkbox } from '@atlassian/learning-components';
import { token } from '@atlaskit/tokens';
import { media } from '@atlaskit/primitives/responsive';
import Badge from '@atlaskit/badge';

import { useQueryParams } from 'src/hooks/useQueryParams';
import { sendUIEvent } from 'src/utils/analytics';
import { useAtlassianUser } from 'src/hooks/useAtlassianUser';

interface FiltersProps {
  filter: string;
  number?: number;
  isLoadingNumber?: boolean;
  queryParamKey: string;
  onFilterInteraction?: () => void;
  isFirstFilter?: boolean;
  isMobile?: boolean;
}

const FILTERS_STRING_MAX_LENGTH = 23;

export const Filter: FC<FiltersProps> = ({
  filter,
  queryParamKey,
  number,
  onFilterInteraction,
  isLoadingNumber,
  isFirstFilter,
  isMobile,
}) => {
  const [searchParams] = useSearchParams();
  const { setQueryParam, removeQueryParam } = useQueryParams();
  const { user } = useAtlassianUser();

  const isAtLeastOneFilterCheckedFromTaxonomy = !!searchParams.getAll(queryParamKey)[0];
  const isChecked = isAtLeastOneFilterCheckedFromTaxonomy
    ? searchParams.getAll(queryParamKey)[0].split(',').includes(filter)
    : false;

  const handleFilterSelect = (): void => {
    if (isChecked) {
      removeQueryParam(queryParamKey, filter);
    } else {
      setQueryParam(queryParamKey, filter);

      sendUIEvent(
        'catalogScreen',
        'filter',
        'clicked',
        undefined,
        {
          taxonomy: queryParamKey,
          value: filter,
        },
        user?.account_id,
      );
    }
    onFilterInteraction && onFilterInteraction();
  };

  const handleKeyDown = (event: React.KeyboardEvent): void => {
    if (event.key === 'Enter' || event.code === 'Space') {
      handleFilterSelect();
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const label = filter.length > FILTERS_STRING_MAX_LENGTH ? `${filter.slice(0, FILTERS_STRING_MAX_LENGTH)}...` : filter;

  return (
    <div
      aria-label={`${queryParamKey} filter checkbox`}
      css={[
        filtersContainerStyles,
        isFirstFilter && isMobile && css({ marginTop: `${token('space.100')} !important` }),
      ]}
      onKeyDown={handleKeyDown}
      tabIndex={0}
    >
      <Checkbox
        isChecked={isChecked}
        onChange={handleFilterSelect}
        label={label}
        data-testid={`${queryParamKey}-filter`}
        tabIndex={-1}
      />
      {!isLoadingNumber && (
        <div data-testid="filter-badge" css={badgeContainerStyles}>
          <Badge>{number || 0}</Badge>
        </div>
      )}
    </div>
  );
};

const filtersContainerStyles = css({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '12px',
  padding: '4px 0 4px 0',

  label: {
    whiteSpace: 'nowrap',
  },

  [media.below.md]: {
    padding: 0,
    marginTop: token('space.300'),
    marginBottom: token('space.300'),

    '&:last-child': {
      marginBottom: 0,
    },
  },
});

const badgeContainerStyles = css({
  display: 'flex',
  flexWrap: 'nowrap',
  marginLeft: '6px',
  width: '50px',
});
