import { GetAllTaxonomyQuery } from 'src/graphql/generated/GetAllTaxonomy';
import { Taxonomy } from 'src/pages/CatalogPage/loader';

import { TaxonomyType } from './taxonomy';

export const sanitizeTaxonomy = (taxonomy: GetAllTaxonomyQuery): Taxonomy => {
  const uniqueTaxonomy = {
    learningLevel: new Set<string>(),
    product: new Set<string>(),
    role: new Set<string>(),
    deployment: new Set<string>(),
  };

  taxonomy.learningPathCollection.items.forEach((item) => {
    item.deployment && item.deployment.forEach((d) => uniqueTaxonomy.deployment.add(d));
    item.learningLevel && uniqueTaxonomy.learningLevel.add(item.learningLevel);
    item.product && item.product.forEach((p) => uniqueTaxonomy.product.add(p));
    item.role && uniqueTaxonomy.role.add(item.role);
  });

  taxonomy.smCourseCollection.items.forEach((item) => {
    item.deployment && item.deployment.forEach((d) => uniqueTaxonomy.deployment.add(d));
    item.product && item.product.forEach((p) => uniqueTaxonomy.product.add(p));
    item.learningLevel && uniqueTaxonomy.learningLevel.add(item.learningLevel);
    item.role && uniqueTaxonomy.role.add(item.role);
  });

  taxonomy.instructorLedTrainingCollection.items.forEach((item) => {
    item.deployment && item.deployment.forEach((d) => uniqueTaxonomy.deployment.add(d));
    item.product && item.product.forEach((p) => uniqueTaxonomy.product.add(p));
    item.learningLevel && uniqueTaxonomy.learningLevel.add(item.learningLevel);
    item.role && uniqueTaxonomy.role.add(item.role);
  });

  taxonomy.certificationCollection.items.forEach((item) => {
    item.product && item.product.forEach((p) => uniqueTaxonomy.product.add(p));
  });

  taxonomy.roleCollection.items.forEach((item) => {
    item.product && item.product.forEach((p) => uniqueTaxonomy.product.add(p));
  });

  taxonomy.topicCollection.items.forEach((item) => {
    item.product && item.product.forEach((p) => uniqueTaxonomy.product.add(p));
  });

  taxonomy.certificationPrepCollection.items.forEach((item) => {
    item.relatedCertification?.product &&
      item.relatedCertification.product?.forEach((p) => uniqueTaxonomy.product.add(p));
  });

  const finalTaxonomy = {
    duration: ['Less than 30 min', '30 min to 1 hour', '1 to 2 hours', 'More than 2 hours'],
    type: [
      TaxonomyType.COURSE,
      TaxonomyType.LEARNING_PATH,
      TaxonomyType.CERTIFICATION,
      TaxonomyType.ROLE,
      TaxonomyType.TOPIC,
      TaxonomyType.CERTIFICATION_PREP,
      TaxonomyType.INSTRUCTOR_LED_TRAINING,
    ],
    learningLevel: Array.from(uniqueTaxonomy.learningLevel),
    product: Array.from(uniqueTaxonomy.product),
    role: Array.from(uniqueTaxonomy.role),
    deployment: Array.from(uniqueTaxonomy.deployment),
  };

  return finalTaxonomy;
};
