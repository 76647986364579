import React from 'react';
import { GreetingCard } from '@atlassian/learning-components';
import { useNavigate } from 'react-router-dom';

import { getLoginUrlWithHomePageRedirect } from 'src/services/http/user';

export const CommunityLogin: React.FC = () => {
  const navigate = useNavigate();

  const handleContinue = (): void => {
    navigate('/');
  };

  const handleLogIn = (): void => {
    window.location.replace(getLoginUrlWithHomePageRedirect());
  };

  return <GreetingCard onClickLogIn={handleLogIn} onClickContinue={handleContinue} />;
};
