import React from 'react';
import { Heading, Text } from '@atlassian/learning-components';
import { token } from '@atlaskit/tokens';
import { css } from '@compiled/react';
import { media } from '@atlaskit/primitives/responsive';

interface SectionWrapperProps {
  image?: {
    src: string;
    alt: string;
    size?: 'small' | 'large';
  };
  title?: string;
  subtitle?: string;
  bgColor?: string;
  textColor?: string;
  children: React.ReactNode;
}

export const SectionWrapper: React.FC<SectionWrapperProps> = ({
  title,
  subtitle,
  image,
  children,
  bgColor = token('color.background.input'),
  textColor = token('color.text'),
}) => (
  <section css={css({ backgroundColor: bgColor })}>
    <div css={contentWrapperStyles}>
      {image && (
        <img css={[imageStyles, image?.size === 'large' && largeImageStyles]} src={image.src} alt={image.alt} />
      )}
      <div css={textWrapperStyles}>
        <div css={subtitle && headingWrapperStyles}>
          <Heading color={textColor} level={2}>
            {title}
          </Heading>
        </div>
        {subtitle && <Text color={textColor}>{subtitle}</Text>}
      </div>
      <div css={childrenWrapperStyles}>{children}</div>
    </div>
  </section>
);

const contentWrapperStyles = css({
  padding: `${token('space.1000')} 210px`,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: '1440px',
  margin: '0 auto',

  [media.below.lg]: {
    paddingRight: token('space.300'),
    paddingLeft: token('space.300'),
  },

  [media.below.sm]: {
    padding: `${token('space.500')} ${token('space.200')}`,
  },
});

const imageStyles = css({
  marginBottom: token('space.400'),
  width: 'auto',
  height: '80px',
  maxWidth: '100%',

  [media.below.sm]: {
    marginBottom: token('space.300'),
  },
});

const largeImageStyles = css({
  marginBottom: token('space.600'),
  maxWidth: '814px',
  width: 'auto',
  height: '300px',

  [media.below.lg]: {
    maxWidth: '100%',
    height: '270px',
  },

  [media.below.sm]: {
    height: '129px',
  },
});

const textWrapperStyles = css({
  textAlign: 'center',
  maxWidth: '720px',
});

const headingWrapperStyles = css({
  marginBottom: token('space.200'),
});

const childrenWrapperStyles = css({
  marginTop: token('space.600'),

  [media.below.sm]: {
    marginTop: token('space.300'),
  },
});
