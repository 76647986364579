export const formatDurationFromMinutes = (totalMinutes: number): string => {
  if (totalMinutes > 59) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    if (minutes === 0) {
      return `${hours} h`;
    }

    return `${hours} h ${minutes} min`;
  }

  return `${totalMinutes} min`;
};
