import { FC, useState } from 'react';
import { css } from '@compiled/react';
import { Button, ButtonAppearance, Divider, Icon } from '@atlassian/learning-components';
import { token } from '@atlaskit/tokens';
import { media } from '@atlaskit/primitives/responsive';

import { Filter } from './Filter';

interface TaxonomyProps {
  taxonomy: string;
  queryParamKey: string;
  filters: string[];
  filterCounts: { [key: string]: number };
  isLoadingCount?: boolean;
  shouldShowAllFilters?: boolean;
  isExpanded: boolean;
  isMobile?: boolean;
  handleExpand: () => void;
  onFilterInteraction?: () => void;
}

const INITIAL_FILTERS_TO_SHOW = 5;

export const Taxonomy: FC<TaxonomyProps> = ({
  taxonomy,
  queryParamKey,
  filters,
  filterCounts,
  isLoadingCount,
  isExpanded,
  isMobile,
  handleExpand,
  onFilterInteraction,
  shouldShowAllFilters = false,
}) => {
  const [showAllFilters, setShowAllFilters] = useState(shouldShowAllFilters);

  const handleShowMore = (): void => setShowAllFilters(true);

  const filtersToShow = showAllFilters ? filters : filters.slice(0, INITIAL_FILTERS_TO_SHOW);

  return (
    <div css={taxonomyContainerStyles}>
      <div css={dividerContainerStyles}>
        <Divider color={token('color.border')} height={2} />
      </div>
      <div>
        <div css={taxonomyExpanderStyles} onClick={handleExpand} data-testid={`${queryParamKey}-taxonomy-expander`}>
          {isExpanded ? <Icon type="chevron-down" /> : <Icon type="chevron-right" />}
          <p css={taxonomyStyles}>{taxonomy.toUpperCase()}</p>
        </div>
      </div>
      <div css={css({ marginLeft: '34px' })}>
        {isExpanded &&
          filtersToShow.map((filter, index) => (
            <Filter
              isFirstFilter={index === 0}
              isMobile={isMobile}
              key={filter}
              filter={filter}
              queryParamKey={queryParamKey}
              number={filterCounts?.[filter]}
              onFilterInteraction={onFilterInteraction}
              isLoadingNumber={isLoadingCount}
            />
          ))}
      </div>
      {isExpanded && Object.keys(filters).length > INITIAL_FILTERS_TO_SHOW && !showAllFilters && (
        <div css={showMoreLinkContainerStyles}>
          <Button onClick={handleShowMore} label="Show More" appearance={ButtonAppearance.LINK} />
        </div>
      )}
    </div>
  );
};

const taxonomyContainerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  marginBottom: token('space.200'),

  [media.below.md]: {
    marginBottom: 0,
  },
});

const taxonomyExpanderStyles = css({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  marginBottom: `${token('space.100')} !important`,

  [media.below.md]: {
    marginBottom: 0,
  },
});

const taxonomyStyles = css({
  fontFamily: 'Charlie Text, sans-serif',
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '18px',
  marginLeft: token('space.100'),
  marginTop: 0,
  marginBottom: 0,
});

const dividerContainerStyles = css({
  display: 'none',

  [media.below.md]: {
    display: 'block',
    width: '100%',
    marginTop: token('space.200'),
    marginBottom: token('space.200'),
  },
});

const showMoreLinkContainerStyles = css({
  display: 'none',

  [media.below.md]: {
    display: 'block',
    margin: '0 auto',
  },
});
