import { gql } from 'graphql-tag';

import { GetLearningPathIdQuery } from 'src/graphql/generated/graphql';

export const GET_LEARNING_PATH_ID = gql`
  query getLearningPathId($slug: String!, $preview: Boolean!) {
    learningPathCollection(preview: $preview, limit: 1, where: { slug: $slug }) {
      items {
        __typename
        sys {
          id
        }
        slug
        title
      }
    }
  }
`;

export type GetLearningPathId = GetLearningPathIdQuery['learningPathCollection']['items'][0];
