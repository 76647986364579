import { LoaderFunction } from 'react-router-dom';

import { fetchGraphQL } from 'src/services/contentful/client';
import {
  ContentfulCertPrep,
  GetCertPrepCollectionResponse,
  GET_CERT_PREP_COLLECTION,
} from 'src/graphql/queries/GetCertPrepCollection';
import { GetCertPrepCollectionQueryVariables } from 'src/graphql/generated/GetCertPrepCollection';

export const loader: LoaderFunction = async ({ params, request }): Promise<CertPrepLoaderProps> => {
  const preview = request.url.includes('preview=true');
  const certPrepId = params.certPrepId ?? '';

  const certPrepData = await getCertificationPreparation(certPrepId, preview);

  if (!certPrepData) {
    throw new Error('Certification prep was not found');
  }

  return { certificationPrep: certPrepData };
};

const getCertificationPreparation = async (
  certPrepId: string,
  preview: boolean,
): Promise<ContentfulCertPrep | undefined> => {
  const response = await fetchGraphQL<GetCertPrepCollectionResponse, GetCertPrepCollectionQueryVariables>(
    GET_CERT_PREP_COLLECTION,
    {
      slug: certPrepId,
      preview,
    },
  );

  return response.certificationPrepCollection.items[0];
};

export interface CertPrepLoaderProps {
  certificationPrep: ContentfulCertPrep;
}
