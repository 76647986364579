import { Modal, Link } from '@atlassian/learning-components';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ModalTransition } from '@atlaskit/modal-dialog';
import { useNavigate, useParams } from 'react-router-dom';
import FeatureGates from '@atlaskit/feature-gate-js-client';

import { FEEDBACK_PAGE_FF } from 'src/constants';
import { NextCourse } from 'src/pages/LessonPage/types/nextCourse';
import { useGenerateButtonLabel, useHandleRedirect } from 'src/pages/LessonPage/children/utils/modalHooks';
import { getLearningPathPart, getLearningPathsHubPart } from 'src/utils/pathUtils';

export interface LearningPathData {
  learningPathSlug: string;
  learningPathCompletionRate: number;
  learningPathTitle: string;
  nextCourse: NextCourse | null;
}

interface BadgeModalProps {
  courseCompletionRate: number;
  badgeImg: string;
  isLearningPathProgressLoading: boolean;
  learningPathData: LearningPathData | null;
  shouldHide: boolean;
  isLessonRecentlyCompleted: boolean;
}

const COMPLETED_RATE = 100;

export const BadgeModal: React.FC<BadgeModalProps> = ({
  courseCompletionRate,
  learningPathData,
  badgeImg,
  isLearningPathProgressLoading,
  shouldHide,
  isLessonRecentlyCompleted,
}) => {
  const navigate = useNavigate();
  const params = useParams();

  const [isOpen, setIsOpen] = useState(false);
  const isCourseModalRendered = useRef(false);
  const isPathModalRendered = useRef(false);

  const isFeedbackPageAccessible = FeatureGates.checkGate(FEEDBACK_PAGE_FF);
  const isLearningPathComplete = useMemo(
    () => !!(learningPathData && learningPathData.learningPathCompletionRate === COMPLETED_RATE),
    [learningPathData],
  );

  useEffect(() => {
    if (isLearningPathProgressLoading || shouldHide) {
      return;
    }

    const shouldOpenModal = !learningPathData && courseCompletionRate === COMPLETED_RATE;
    const shouldOpenModalWithNextCourse =
      learningPathData && !isLearningPathProgressLoading && courseCompletionRate === COMPLETED_RATE;

    if (
      ((shouldOpenModal || shouldOpenModalWithNextCourse) &&
        !isCourseModalRendered.current &&
        isLessonRecentlyCompleted) ||
      (isLearningPathComplete && !isPathModalRendered.current && isLessonRecentlyCompleted)
    ) {
      setIsOpen(true);
    }

    if (shouldOpenModal || shouldOpenModalWithNextCourse) {
      isCourseModalRendered.current = true;
    }

    if (isLearningPathComplete) {
      isPathModalRendered.current = true;
    }
  }, [
    courseCompletionRate,
    isLearningPathComplete,
    isLearningPathProgressLoading,
    isLessonRecentlyCompleted,
    learningPathData,
    setIsOpen,
    shouldHide,
  ]);

  const closeModal = useCallback(() => setIsOpen(false), []);

  const redirectToCatalog = useCallback(() => {
    setIsOpen(false);
    navigate('/catalog');
  }, [navigate]);

  const goToNextCourse = useCallback(() => {
    setIsOpen(false);

    navigate(
      `/path/${learningPathData?.learningPathSlug}/course/${learningPathData?.nextCourse?.slug}/lesson/${learningPathData?.nextCourse?.nextLessonSlug}`,
    );
  }, [learningPathData, navigate]);

  const generateButtonLabelForFirstDialog = useGenerateButtonLabel(learningPathData, isLearningPathComplete);

  const handleRedirectForFirstDialog = useHandleRedirect(
    learningPathData,
    isLearningPathComplete,
    redirectToCatalog,
    goToNextCourse,
  );

  if (!isOpen || shouldHide) {
    return null;
  }

  const courseSlug = params?.courseId;
  const learningPathSlug = params?.pathId;
  const learningPathPart = getLearningPathPart(learningPathSlug);
  const allLearningPathsHubPart = getLearningPathsHubPart(location.pathname);

  const surveyLink = `${allLearningPathsHubPart}/${learningPathPart}/course/${courseSlug}/survey`;

  const surveyText = isFeedbackPageAccessible && (
    <p>
      Help us improve this course by taking a{' '}
      <Link label="short survey" link={surveyLink} onClick={() => window.open(surveyLink, '_blank', 'noopener')} />.
    </p>
  );

  if (isLearningPathComplete) {
    return (
      <ModalTransition>
        <Modal
          heading="Congrats"
          subheading={
            <p>You earned a course badge, and completed the learning path {learningPathData?.learningPathTitle}!</p>
          }
          surveyText={surveyText}
          imageLink={badgeImg}
          closeModal={closeModal}
          actionButton={{
            label: 'Find your next course',
            onClick: redirectToCatalog,
          }}
          width={720}
        />
      </ModalTransition>
    );
  }

  return (
    <ModalTransition>
      <Modal
        heading="You earned a badge!"
        subheading={
          <p>Great work! You earned a badge by completing this course. All badges are saved in your profile.</p>
        }
        surveyText={surveyText}
        imageLink={badgeImg}
        closeModal={closeModal}
        actionButton={{
          label: generateButtonLabelForFirstDialog,
          onClick: handleRedirectForFirstDialog,
        }}
        width={720}
      />
    </ModalTransition>
  );
};
