import React from 'react';
import { Divider } from '@atlassian/learning-components';
import { token } from '@atlaskit/tokens';
import { css } from '@compiled/react';
import { media } from '@atlaskit/primitives/responsive';

interface CardDividerProps {
  index?: number;
}

export const CardDivider: React.FC<CardDividerProps> = ({ index }) => {
  const shouldRender = !index || index === 0;
  const shouldRenderOnlyOnDesktop = index === 1;

  if (!(shouldRender || shouldRenderOnlyOnDesktop)) {
    return null;
  }

  return (
    <div css={[baseStyles, shouldRenderOnlyOnDesktop && desktopStyles]}>
      <Divider color={token('color.border')} />
    </div>
  );
};

const baseStyles = css({
  width: '100%',
});

const desktopStyles = css({
  [media.below.md]: {
    display: 'none',
  },
});
