import { Button, ButtonAppearance, Heading, LearningProgressBar } from '@atlassian/learning-components';
import React, { useCallback, useState } from 'react';
import { css } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { media } from '@atlaskit/primitives/responsive';
import { useNavigate } from 'react-router-dom';

import { LearningEmptyState } from 'src/pages/MyLearningPage/children/LearningEmptyState';
import { timeConverter } from 'src/pages/MyLearningPage/children/utils/timeConverter';
import { RecentlyViewedLessonResource } from 'src/pages/MyLearningPage';
import { MAX_ITEMS_SHOWN, MyLearningPageContentSections } from 'src/pages/MyLearningPage/constants';

interface RecentlyViewedSectionProps {
  resources: RecentlyViewedLessonResource[] | null;
  handleTabChange: (index: number) => void;
}

export const RecentlyViewedLessonSection: React.FC<RecentlyViewedSectionProps> = ({ resources, handleTabChange }) => {
  const navigate = useNavigate();
  const [lessonsToShowCount, setLessonsToShowCount] = useState<number>(MAX_ITEMS_SHOWN);

  const navigateToContentPage = useCallback(
    (contentSlug: string, learningPathSlug?: string, courseSlug?: string) => {
      const path = learningPathSlug
        ? `/path/${learningPathSlug}/course/${courseSlug}/lesson/${contentSlug}`
        : `/course/${courseSlug}/lesson/${contentSlug}`;

      navigate(path);
    },
    [navigate],
  );

  const handleKeyDown = (event: React.KeyboardEvent, resource: RecentlyViewedLessonResource): void => {
    if (event.key === 'Enter' || event.code === 'Space') {
      navigateToContentPage(resource.slug, resource.learningPathSlug, resource.courseSlug);
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const showMoreLessons = useCallback(() => {
    setLessonsToShowCount((prevCount) => prevCount + 10);
  }, []);

  if (!resources) {
    return (
      <section
        data-testid="recently-viewed-section"
        css={[recentlyViewedSectionStyles, !resources && noResourcesSectionStyles]}
        role="doc-chapter"
        aria-labelledby="recently-viewed-heading"
      >
        <Heading level={4}>Lessons</Heading>
        <LearningEmptyState
          learningStatus={MyLearningPageContentSections.RECENTLY_VIEWED}
          onTabChange={handleTabChange}
        />
      </section>
    );
  }

  const lessonsToShow = resources.slice(0, lessonsToShowCount);
  const showMoreLabel = `Show more (${resources.length - lessonsToShow.length})`;

  return (
    <section
      data-testid="recently-viewed-section"
      css={[recentlyViewedSectionStyles, !resources && noResourcesSectionStyles]}
      role="doc-chapter"
      aria-labelledby="recently-viewed-heading"
    >
      <Heading id="recently-viewed-heading" level={4}>
        Lessons
      </Heading>
      <div css={recentlyViewedLabelContainerStyles}>
        <div css={recentlyViewedTitleLabelStyles}>
          <span css={recentlyViewedLabelStyles}>Title</span>
        </div>
        <div css={recentlyViewedDateLabelStyles}>
          <span css={recentlyViewedLabelStyles}>Viewed</span>
        </div>
        <div css={recentlyViewedProgressLabelStyles}>
          <span css={recentlyViewedLabelStyles}>Progress</span>
        </div>
      </div>
      {lessonsToShow.map((resource) => (
        <div
          key={resource.contentfulId}
          data-testid="recently-viewed-container"
          css={recentlyViewedContainerStyles}
          tabIndex={0}
          onKeyDown={(event) => handleKeyDown(event, resource)}
          onClick={() => navigateToContentPage(resource.slug, resource?.learningPathSlug, resource?.courseSlug)}
        >
          <div data-testid="recently-viewed-title-container" css={recentlyViewedTitleContainerStyles}>
            <span css={recentlyViewedTitleStyles} data-testid="recently-viewed-title">
              {resource.title}
            </span>
          </div>
          <div data-testid="recently-viewed-date-container" css={recentlyViewedDateContainerStyles}>
            <span data-testid="recently-viewed-date" css={recentlyViewedDateStyles}>
              {timeConverter(resource.lastVisitedDate)}
            </span>
          </div>
          <div data-testid="recently-viewed-progress-bar-container" css={[recentlyViewedProgressBarContainerStyles]}>
            <LearningProgressBar
              completedResources={Math.min(resource.progressTotal, resource.subCollectionTotal)}
              resourceCount={resource.subCollectionTotal}
            />
          </div>
        </div>
      ))}
      {lessonsToShow.length < resources.length && (
        <div css={recentlyViewedShowMoreContainerStyles}>
          <Button label={showMoreLabel} appearance={ButtonAppearance.PRIMARY} onClick={showMoreLessons} />
        </div>
      )}
    </section>
  );
};

const recentlyViewedSectionStyles = css({
  display: 'flex',
  flexFlow: 'wrap',
  paddingTop: token('space.600'),
  marginTop: token('space.600'),
  marginBottom: token('space.400'),

  [media.below.md]: {
    marginTop: token('space.400'),
    marginBottom: token('space.300'),
  },
});

const noResourcesSectionStyles = css({
  width: '100%',
  borderTop: `1px solid ${token('color.border')}`,
});

const recentlyViewedLabelContainerStyles = css({
  display: 'grid',
  gridTemplateColumns: 'repeat(12, 1fr)',
  gap: token('space.300'),
  width: '100%',
  paddingTop: token('space.400'),
  paddingBottom: token('space.200'),
  borderBottom: `1px solid ${token('color.border')}`,

  '&:last-of-type': {
    borderTop: `1px solid ${token('color.border')}`,
  },

  [media.below.md]: {
    display: 'none',
  },

  [media.below.xs]: {
    display: 'none',
  },
});

const recentlyViewedTitleLabelStyles = css({
  gridColumn: 'span 8',
  display: 'flex',
  alignItems: 'center',
});

const recentlyViewedDateLabelStyles = css({
  gridColumn: 'span 2',
  display: 'flex',
  alignItems: 'center',
});

const recentlyViewedProgressLabelStyles = css({
  gridColumn: 'span 2',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
});

const recentlyViewedContainerStyles = css({
  display: 'grid',
  gridTemplateColumns: 'repeat(12, 1fr)',
  gap: token('space.300'),
  width: '100%',
  paddingTop: '18px',
  paddingBottom: '18px',
  borderBottom: `1px solid ${token('color.border')}`,

  '&:hover': {
    cursor: 'pointer',
  },

  '&:first-of-type': {
    borderTop: `1px solid ${token('color.border')}`,
  },

  [media.below.md]: {
    gridTemplateColumns: 'repeat(8, 1fr)',
  },

  [media.below.xs]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
});

const recentlyViewedTitleContainerStyles = css({
  gridColumn: 'span 8',
  paddingRight: token('space.300'),
  display: 'flex',
  alignItems: 'center',

  [media.below.md]: {
    gridColumn: 'span 8',
    order: 1,
    maxWidth: '512px',
  },

  [media.below.xs]: {
    gridColumn: 'span 8',
    order: 1,
    paddingRight: token('space.0'),
  },
});

const recentlyViewedProgressBarContainerStyles = css({
  gridColumn: 'span 2',
  paddingRight: token('space.300'),
  display: 'flex',
  alignItems: 'center',

  [media.below.md]: {
    gridColumn: 'span 4',
    order: 2,
    maxWidth: '144px',
    paddingRight: token('space.0'),
    textAlign: 'right',
    justifySelf: 'flex-start',
  },

  [media.below.xs]: {
    gridColumn: 'span 4',
    order: 2,
    textAlign: 'left',
    justifySelf: 'flex-start',
    marginLeft: token('space.0'),
    paddingRight: token('space.0'),
  },
});

const recentlyViewedDateContainerStyles = css({
  gridColumn: 'span 2',
  paddingRight: token('space.300'),
  display: 'flex',
  alignItems: 'center',

  [media.below.md]: {
    gridColumn: 'span 4',
    order: 3,
    maxWidth: '144px',
    paddingLeft: token('space.300'),
    paddingRight: token('space.0'),
    textAlign: 'right',
    justifySelf: 'flex-end',
  },

  [media.below.xs]: {
    gridColumn: 'span 4',
    order: 3,
    textAlign: 'right',
    justifySelf: 'flex-end',
    paddingRight: token('space.0'),
    paddingLeft: token('space.0'),
  },
});

const recentlyViewedTitleStyles = css({
  color: token('color.text.brand'),
  textAlign: 'left',
  fontFamily: 'Charlie Text',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '20px',
});

const recentlyViewedLabelStyles = css({
  color: token('color.text'),
  fontFamily: 'Charlie Text, sans-serif',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 600,
  textTransform: 'uppercase',
  lineHeight: '16px',
});

const recentlyViewedDateStyles = css({
  color: token('color.text'),
  fontFamily: 'Inter, sans-serif',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '22px',
});

const recentlyViewedShowMoreContainerStyles = css({
  margin: `${token('space.400')} auto ${token('space.400')} auto`,
});
