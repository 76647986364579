import { ReactElement, useContext, useEffect, useMemo } from 'react';
import { useLoaderData } from 'react-router-dom';
import { css } from '@compiled/react';
import { Eyebrow, Heading, Text } from '@atlassian/learning-components';
import { token } from '@atlaskit/tokens';
import { media } from '@atlaskit/primitives/responsive';

import { CertificationSection } from 'src/pages/hubs/CertificatePreparationPage/children/CertificationSection';
import { HelmetContext } from 'src/components/Helmet';
import { CertificatePreparationLoaderProps } from 'src/pages/hubs/CertificatePreparationPage/loader';
import { sendScreenEvent } from 'src/utils/analytics';
import { useAtlassianUser } from 'src/hooks/useAtlassianUser';
import { getImageAttrs } from 'src/utils/contentful';

export const CertificatePreparationPage = (): ReactElement => {
  const helmet = useContext(HelmetContext);
  const { certPrepData } = useLoaderData() as CertificatePreparationLoaderProps;
  const { user, isLoading, hasError } = useAtlassianUser();

  helmet.setHelmet({
    title: `${certPrepData.titleExternal} | Learning - Atlassian Community`,
    seoTags: certPrepData.seo,
  });

  const heroAsset = useMemo(() => getImageAttrs(certPrepData.hero.asset), [certPrepData.hero.asset]);

  useEffect(() => {
    if (!isLoading && !hasError) {
      sendScreenEvent('certPrepHubScreen', {}, user?.account_id);
    }
  }, [user, isLoading, hasError]);

  return (
    <main data-testid="cert-prep-hub" css={mainStyles}>
      <section data-testid="cert-prep-header" css={headerSectionStyles}>
        <div css={containerStyles}>
          <div css={headerContentStyles}>
            <Eyebrow fontWeight="semibold" size="medium" color={token('color.text.subtle')}>
              {certPrepData.hero.eyebrowText}
            </Eyebrow>
            <Heading level={0}>{certPrepData.hero.titleExternal}</Heading>
            <Text size="large">{certPrepData.hero.subtitle}</Text>
          </div>
        </div>
      </section>
      {heroAsset && (
        <section data-testid="cert-prep-banner" css={bannerSectionStyles}>
          <img css={bannerStyles} src={heroAsset.src} alt={heroAsset.alt} />
        </section>
      )}
      {certPrepData.linkedContentBlockCollection.items.map((linkedContentBlock) => (
        <section
          data-testid={linkedContentBlock.titleExternal}
          key={linkedContentBlock.titleExternal}
          css={certifiedSectionStyles}
        >
          <CertificationSection
            asset={linkedContentBlock.asset}
            title={linkedContentBlock.titleExternal}
            description={linkedContentBlock.subtitle}
            associatedContent={linkedContentBlock.associatedContentResourcesCollection.items}
          />
        </section>
      ))}
    </main>
  );
};

const mainStyles = css({
  marginBottom: '104px',
  paddingTop: '136px',

  '@media (max-width: 1279px)': {
    paddingTop: '0',
  },
});

const containerStyles = css({
  maxWidth: '1440px',
  width: '100%',
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',

  [media.below.lg]: {
    padding: `0 ${token('space.300')}`,
  },

  [media.below.sm]: {
    padding: `0 ${token('space.200')} !important`,
  },
});

const headerSectionStyles = css({
  marginTop: '54px',
});

const headerContentStyles = css({
  maxWidth: '720px',
  width: '100%',
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  gap: token('space.200'),
  textAlign: 'center',
});

const bannerSectionStyles = css({
  width: '100%',
  marginTop: token('space.600'),
});

const bannerStyles = css({
  width: '100%',
  height: '210px',
  objectFit: 'cover',
});

const certifiedSectionStyles = css({
  marginTop: '80px',

  [media.below.sm]: {
    marginTop: token('space.500'),
  },
});
