import React, { ReactElement } from 'react';
import { Heading, Link } from '@atlassian/learning-components';
import { css } from '@compiled/react';
import { media } from '@atlaskit/primitives/responsive';
import { token } from '@atlaskit/tokens';

import blueShape from 'src/assets/images/collections/blueShape.svg';
import { ContentfulCertPrepLinks } from 'src/graphql/queries/GetCertPrepCollection';
import { AssociatedContent } from 'src/graphql/generated/graphql';
import { ImageAttrs } from 'src/utils/contentful';
import { sendUIEvent } from 'src/utils/analytics';
import { useAtlassianUser } from 'src/hooks/useAtlassianUser';
import { useNavigateByHost } from 'src/hooks/useNavigateByHost';

interface BlueCertificationSectionProps {
  imageAttrs: ImageAttrs | undefined;
  title: string;
  relatedContent: ContentfulCertPrepLinks;
}

export const AtlassianCommunitySection = ({
  imageAttrs,
  title,
  relatedContent,
}: BlueCertificationSectionProps): ReactElement => {
  const { user } = useAtlassianUser();

  const navigateByHost = useNavigateByHost();

  const handleLearnMoreClick = (cardTitle: string, contentLink: string): void => {
    sendUIEvent(
      'certificationPreparationCollectionScreen',
      'link',
      'clicked',
      'cardLink',
      {
        contentBlockTitle: title,
        cardTitle: cardTitle,
      },
      user?.account_id,
    );
    navigateByHost(contentLink);
  };

  return (
    <section
      data-testid="community-resources"
      css={[
        joinAtlassianCommunityContainerStyles,
        relatedContent.length > 1 && adaptiveMultipleJoinAtlassianCommunityContainerStyles,
      ]}
    >
      <div css={topBlueShapeWrapperStyles}>
        <img css={topBlueShapeStyles} src={`${blueShape}`} alt="blue shape image" />
      </div>
      <div css={joinAtlassianCommunityContentStyles}>
        <div css={joinAtlassianContentStyles}>
          {imageAttrs && <img css={headingImageStyles} src={imageAttrs.src} alt={imageAttrs.alt} />}
          <div css={headingWrapperStyles}>
            <Heading level={2}>{title}</Heading>
          </div>
        </div>
        {relatedContent.length > 0 && (
          <div
            css={[
              joinAtlassianCardContainerStyles,
              relatedContent.length > 1
                ? css(adaptiveMultipleJoinAtlassianCardContainerStyles)
                : css(adaptiveSingleJoinAtlassianCardContainerStyles),
            ]}
          >
            {(relatedContent as unknown as AssociatedContent[]).map((content, index) => (
              <div css={joinAtlassianCardItemStyles} key={`${content.sys.id}-${index}`}>
                <span css={joinAtlassianHeadingStyles}>{content?.titleExternal}</span>
                <span css={joinAtlassianDescriptionStyles}>{content?.description}</span>
                <Link
                  label={content.link?.label ?? 'Learn more'}
                  link={content.link?.link}
                  onClick={() => handleLearnMoreClick(content?.titleExternal, content.link?.link)}
                  icon="arrow-right"
                  fontColor={token('color.text.brand')}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

const joinAtlassianCommunityContainerStyles = css({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  marginBottom: '190px',

  [media.below.md]: {
    marginBottom: '190px',
  },

  [media.below.sm]: {
    marginBottom: '128px',
  },
});

const adaptiveMultipleJoinAtlassianCommunityContainerStyles = css({
  marginBottom: '390px',

  [media.below.md]: {
    marginBottom: '330px',
  },
  [media.below.sm]: {
    marginBottom: '341px',
  },
});

const topBlueShapeWrapperStyles = css({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '-1px',
});

const topBlueShapeStyles = css({
  width: '100%',
});

const joinAtlassianCommunityContentStyles = css({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: token('space.800'),
  backgroundColor: '#CFE1FD',
  alignItems: 'center',

  [media.below.md]: {
    flexDirection: 'column',
    alignItems: 'center',
  },

  [media.below.sm]: {},
});

const joinAtlassianContentStyles = css({
  maxWidth: '720px',
  width: '100%',
  margin: '0 auto',
  textAlign: 'center',

  [media.below.sm]: {
    maxWidth: '343px',
  },
});

const headingWrapperStyles = css({
  marginTop: token('space.400'),
  marginBottom: token('space.600'),
  [media.below.sm]: {
    marginBottom: token('space.500'),
  },
});

const joinAtlassianCardContainerStyles = css({
  width: '100%',
  maxWidth: '1020px',
  display: 'flex',
  flexDirection: 'column',
  padding: token('space.1000'),
  backgroundColor: '#E9F2FE',
  gap: token('space.600'),

  [media.below.md]: {
    gap: token('space.500'),
    maxWidth: '720px',
    padding: token('space.500'),
  },

  [media.below.sm]: {
    maxWidth: '343px !important',
    padding: token('space.300'),

    margin: '0 24px -70px 24px !important',
  },
});

const adaptiveMultipleJoinAtlassianCardContainerStyles = css({
  marginBottom: '-300px',

  [media.below.md]: {
    marginBottom: '-240px',
  },

  [media.below.sm]: {
    marginBottom: '-285px !important',
  },
});

const adaptiveSingleJoinAtlassianCardContainerStyles = css({
  marginBottom: '-110px',

  [media.below.md]: {
    marginBottom: '-40px',
  },

  [media.below.sm]: {
    margin: '0 24px -70px 24px !important',
  },
});

const joinAtlassianCardItemStyles = css({
  display: 'flex',
  flexDirection: 'column',
  gap: token('space.400'),
  backgroundColor: '#E9F2FE',
  maxWidth: '860px',

  [media.below.md]: {
    gap: token('space.0'),
    maxWidth: '640px',
    '&:not(:last-child)': {
      marginBottom: token('space.500'),
    },
  },

  [media.below.sm]: {
    maxWidth: '295px !important',
    '&:not(:last-child)': {
      marginBottom: token('space.300'),
    },
    gap: token('space.300'),
  },
});

const headingImageStyles = css({
  height: '80px',
  width: 'auto',
  maxWidth: '100%',
  boxSizing: 'border-box',
});

const joinAtlassianHeadingStyles = css({
  color: token('color.text'),
  fontFamily: 'Charlie Display',
  fontSize: '24px',
  fontWeight: 700,
  [media.below.md]: {
    paddingBottom: token('space.100'),
  },
});

const joinAtlassianDescriptionStyles = css({
  color: token('color.text'),
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: 400,
  [media.below.md]: {
    paddingBottom: token('space.400'),
  },
});
