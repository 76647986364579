import { gql } from 'graphql-tag';

export const GET_CATALOG_RESOURCES_COUNT = gql`
  query GetCatalogResourcesCount(
    $preview: Boolean!
    $skipCourses: Int
    $skipLearningPaths: Int
    $skipCertifications: Int
    $skipCollections: Int
    $skipInstructorLedTraining: Int
  ) {
    learningPathCollection(
      preview: $preview
      limit: 25
      skip: $skipLearningPaths
      where: { hideFromCatalog_exists: false }
    ) {
      items {
        __typename
        deployment
        product
        learningLevel
        role
        coursesCollection(where: { sys: { id_exists: true } }, limit: 20) {
          items {
            lessonsCollection(where: { sys: { id_exists: true } }, limit: 20) {
              items {
                duration
              }
            }
          }
        }
      }
    }
    smCourseCollection(preview: $preview, limit: 25, skip: $skipCourses, where: { hideFromCatalog_exists: false }) {
      items {
        __typename
        deployment
        product
        learningLevel
        role
        lessonsCollection(where: { sys: { id_exists: true } }, limit: 20) {
          items {
            duration
          }
        }
      }
    }
    certificationCollection(
      preview: $preview
      limit: 25
      skip: $skipCertifications
      where: { hideFromCatalog_exists: false }
    ) {
      items {
        __typename
        product
      }
    }
    roleCollection(preview: $preview, limit: 25, skip: $skipCollections, where: { hideFromCatalog_exists: false }) {
      items {
        __typename
        product
      }
    }
    topicCollection(preview: $preview, limit: 25, skip: $skipCollections, where: { hideFromCatalog_exists: false }) {
      items {
        __typename
        product
      }
    }
    certificationPrepCollection(
      preview: $preview
      limit: 25
      skip: $skipCollections
      where: { hideFromCatalog_exists: false }
    ) {
      items {
        __typename
        relatedCertification {
          product
        }
      }
    }
    instructorLedTrainingCollection(
      preview: $preview
      limit: 25
      skip: $skipInstructorLedTraining
      where: { hideFromCatalog_exists: false }
    ) {
      items {
        __typename
        title: courseTitleExternal
        slug: intellumUrl
        deployment
        product
        role
        learningLevel
        duration
      }
    }
  }
`;
