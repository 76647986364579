import React from 'react';
import { Heading, Button, ButtonAppearance, ButtonSize } from '@atlassian/learning-components';
import { css } from '@compiled/react';
import { token } from '@atlaskit/tokens';

import { useQueryParams } from 'src/hooks/useQueryParams';

export const NoResults: React.FC = () => {
  const { clearAllQueryParams } = useQueryParams();

  const handleClick = (e: React.MouseEvent): void => {
    e.preventDefault();
    clearAllQueryParams();
  };

  return (
    <div css={containerStyles} data-testid="no-results-container">
      <img alt="" css={imagePlaceholderStyles} />
      <div css={contentContainerStyles}>
        <div css={emptyStateDescriptionStyles}>
          <Heading level={4}>We couldn’t find any matches</Heading>
          <span css={textStyles}>Try using different filters.</span>
        </div>
        <div>
          <Button
            label="Clear filters"
            isResponsive={true}
            size={ButtonSize.LARGE}
            appearance={ButtonAppearance.PRIMARY}
            onClick={handleClick}
          />
        </div>
      </div>
    </div>
  );
};

const containerStyles = css({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  padding: '56px 0',
  border: `1px solid ${token('color.border.disabled')}`,
  borderRadius: '8px',
  gap: '24px',
});

const contentContainerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  alignItems: 'center',
});

const emptyStateDescriptionStyles = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '16px',
});

const textStyles = css({
  fontSize: '18px',
  fontFamily: 'Inter, sans-serif',
  fontWeight: 400,
  lineHeight: '28px',
  color: 'black',
});

const imagePlaceholderStyles = css({
  width: '132px',
  height: '132px',
  textAlign: 'center',
  borderRadius: '50%',
  backgroundColor: '#D9D9D9',
});
