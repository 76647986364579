import React, { useCallback, useEffect, useState } from 'react';
import { css } from '@compiled/react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { LessonSidebar, LessonSidebarProps } from '@atlassian/learning-components';
import { media } from '@atlaskit/primitives/responsive';
import { token } from '@atlaskit/tokens';

import {
  calculateCourseCompletionRate,
  getLessonList,
  handleNavigation,
  completeSectionAsync,
} from 'src/pages/LessonPage/children/utils/sidebarUtils';
import { ContentfulSmCourse } from 'src/graphql/queries/GetSmCourse';
import { ContentfulSmLesson } from 'src/graphql/queries/GetSmLesson';
import { usePreviewMode } from 'src/hooks/usePreviewMode';
import { useAtlassianUser } from 'src/hooks/useAtlassianUser';
import { LearningProgressStatus, ProgressForLessons } from 'src/services/http/progress';
import { sendTrackEvent } from 'src/utils/analytics';
import { constructCourseUrl } from 'src/utils/constructUrl';
import { isStandalone } from 'src/pages/LessonPage/utils/isStandalone';
import { SUBNAVBAR_HEIGHT } from 'src/pages/LessonPage/utils/utils';

import { useIntersactionObserverForTracking } from './hooks/useIntersactionObserverForTracking';

export interface SidebarProps {
  course: ContentfulSmCourse;
  onChangeCurrentUrl: (url: string) => void;
  isSidebarVisible: boolean;
  onChangeSidebarVisibility: (visible: boolean) => void;
  lesson: ContentfulSmLesson;
  badgeImg: string;
  progress?: ProgressForLessons;
  onSectionCompleted: (progress: ProgressForLessons) => void;
  isBreadcrumbVisible: boolean;
}

export const TOP_NAVBAR_HEIGHT = 112;

const scrollToElement = (
  element: HTMLElement,
  intervalId: ReturnType<typeof setInterval>,
  observer: MutationObserver,
): void => {
  window.scrollTo({
    top: element.offsetTop - SUBNAVBAR_HEIGHT,
    behavior: 'smooth',
  });

  clearInterval(intervalId);
  observer.disconnect();
};

const scrollToSection = (sectionSlug: string): void => {
  const intervalId: ReturnType<typeof setInterval> = setInterval(() => {
    const element = document.getElementById(sectionSlug);

    if (element) {
      scrollToElement(element, intervalId, observer);
    }
  }, 0);

  const observer = new MutationObserver((mutations, obs) => {
    const element = document.getElementById(sectionSlug);

    if (element) {
      scrollToElement(element, intervalId, obs);
    }
  });

  observer.observe(document.body, {
    childList: true,
    subtree: true,
  });
};

export const Sidebar: React.FC<SidebarProps> = ({
  course,
  onChangeCurrentUrl,
  isSidebarVisible,
  onChangeSidebarVisibility,
  lesson,
  badgeImg,
  progress,
  onSectionCompleted,
  isBreadcrumbVisible,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [scrollProgress, setScrollProgress] = useState(0);

  useEffect(() => {
    const handleScroll = (): void => {
      setScrollProgress(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const dynamicStyles = {
    transform: `translateY(-${Math.min(scrollProgress, SUBNAVBAR_HEIGHT)}px)`,
    height: `calc(100vh - ${136 - Math.min(scrollProgress, SUBNAVBAR_HEIGHT)}px)`,
  };

  useEffect(() => {
    onChangeCurrentUrl(globalThis.location.href);
  }, [location, onChangeCurrentUrl]);

  let lessons: LessonSidebarProps['lessons'] | null = null;

  const onCourseNavigation = useCallback(() => {
    const url = constructCourseUrl(course?.slug || '', params?.pathId, location.pathname);

    navigate(url);
  }, [course?.slug, navigate, params?.pathId, location.pathname]);

  const onNavigation = useCallback(
    (options: { [key: string]: string }): void => {
      handleNavigation({
        options,
        location,
        params,
        course,
        navigate,
        scrollToSection,
      });
    },
    // eslint-disable-next-line
    [course, navigate],
  );

  const { user, isLoading, hasError } = useAtlassianUser();
  const preview = usePreviewMode();

  // Sets up IntersectionObservers for sections
  const { seenSections } = useIntersactionObserverForTracking({ lesson });

  useEffect(() => {
    if (!lesson || !progress) {
      return;
    }
    const sectionName = seenSections.at(-1) ?? '';
    const section = lesson.sectionListCollection.items.find((s) => s.id === sectionName)?.sys.id ?? '';

    if (!section || section === 'quiz') {
      return;
    }

    const lessonProgress = progress?.lessons.find((p) => p.lessonId === lesson.sys.id);
    const isAlreadyTracked =
      lessonProgress?.section.find((s) => s.sectionId === section)?.status === LearningProgressStatus.COMPLETED;

    if (isAlreadyTracked) {
      return;
    }

    onSectionCompleted(completeSectionAsync(progress, section, lesson, user, preview));

    if (!isLoading && !hasError && course && !preview) {
      sendTrackEvent(
        'lessonScreen',
        'lessonSection',
        'completed',
        {
          courseSlug: course?.slug,
          lessonSlug: lesson?.slug,
          sectionId: section,
          isStandalone: isStandalone(),
        },
        user?.account_id,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seenSections]);

  if (course) {
    lessons = getLessonList(course, onNavigation, lesson?.slug, progress)?.map((lessonData) => ({
      ...lessonData,
      sections: lessonData.sections?.map((section) => ({
        ...section,
        isCompleted:
          section.isCompleted ||
          (lessonData.slug === lesson?.slug && seenSections.includes(section.slug?.replace('#', ''))),
      })),
    }));
  }

  if (!lessons) {
    return null;
  }

  return (
    <div
      css={[
        sidebarContainerStyles,
        isSidebarVisible && css({ width: '26.25rem' }),
        !isSidebarVisible && isBreadcrumbVisible && hiddenMenuStyles,
        isBreadcrumbVisible && borderStyles,
        disableDynamicStylesOnTable,
      ]}
      style={dynamicStyles}
    >
      <LessonSidebar
        lessons={lessons}
        isVisible={isSidebarVisible}
        onChangeMenuVisibility={onChangeSidebarVisibility}
        onCourseNavigation={onCourseNavigation}
        progress={calculateCourseCompletionRate(progress, course)}
        pathColor="#216E4E"
        title={course?.title || ''}
        image={{
          src: badgeImg,
          alt: 'certification',
        }}
      />
    </div>
  );
};

const disableDynamicStylesOnTable = css({
  '@media (max-width: 1279px)': {
    transform: 'unset !important',
    top: '56px !important',
    height: 'calc(100vh -56px) !important',
  },
});

const sidebarContainerStyles = css({
  transition: 'top 0.3s',
  zIndex: 5,
  position: 'fixed',
  top: '136px',

  '@media (max-width: 768px)': {
    boxShadow: `0px 0px 4px ${token('color.blanket')}`,
  },

  '@media (max-width: 576px)': {
    height: 'calc(100vh - 104px)',
  },
});

const borderStyles = css({
  borderTop: `1px solid ${token('color.border')}`,

  [media.below.md]: {
    borderTop: 'none !important',
  },
});

const hiddenMenuStyles = css({
  width: '20px',
  borderTop: `1px solid ${token('color.border')}`,

  [media.below.md]: {
    width: '18px',
    top: '56px',
    boxShadow: 'none !important',
  },
});
