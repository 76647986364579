import { useEffect } from 'react';
import { ContentType } from '@atlassian/learning-components';

import { saveContentForRecentlyViewed } from 'src/services/http/myLearning';
import { useAtlassianUser } from 'src/hooks/useAtlassianUser';

export const useSaveRecentlyViewed = (
  contentfulId: string | undefined,
  contentType: ContentType,
  learningPathId?: string,
  courseId?: string,
): void => {
  const { user, isLoading } = useAtlassianUser();

  useEffect(() => {
    if (user?.account_id && !isLoading && contentfulId) {
      saveContentForRecentlyViewed(user.account_id, contentfulId, contentType, learningPathId, courseId);
    }
  }, [user?.account_id, contentfulId, contentType, isLoading, courseId, learningPathId]);
};
