import { ContentHubType } from 'src/constants';

export const getLearningPathsHubPart = (pathname?: string): string => {
  const isAllLearningPathsHubIncluded = pathname?.includes(`hub/${ContentHubType.LEARNING_PATHS}`);

  const allLearningPathsHubPart = isAllLearningPathsHubIncluded ? `/hub/${ContentHubType.LEARNING_PATHS}` : '';

  return allLearningPathsHubPart;
};

export const getLearningPathPart = (learningPathSlug?: string): string =>
  learningPathSlug ? `/path/${learningPathSlug}` : '';
