import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

export const useMediaProgressTracking = (sendProgressEvent: (action: string) => void, progress: number): void => {
  const { courseId, lessonId } = useParams();
  const isMediaStartedButNotEnded = progress !== 100 && progress > 1;

  useEffect(() => {
    const handleBeforeUnload = (): void => {
      if (isMediaStartedButNotEnded) {
        sendProgressEvent('abandoned');
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return (): void => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [sendProgressEvent, progress, isMediaStartedButNotEnded]);

  useEffect(() => {
    if (isMediaStartedButNotEnded) {
      sendProgressEvent('abandoned');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseId, lessonId]);

  useEffect(() => {
    if (progress === 100) {
      sendProgressEvent('completed');
    }
  }, [courseId, lessonId, sendProgressEvent, progress]);
};
