import { LoaderFunction } from 'react-router-dom';

import { fetchGraphQL } from 'src/services/contentful/client';
import {
  ContentfulContentHub,
  GET_CONTENT_HUB_COLLECTION,
  GetContentHubCollectionResponse,
} from 'src/graphql/queries/GetContentHub';
import { GetContentHubCollectionQueryVariables } from 'src/graphql/generated/GetContentHub';
import { ContentHubType } from 'src/constants';

export const loader: LoaderFunction = async ({ request }): Promise<CertificatePreparationLoaderProps> => {
  const preview = request.url.includes('preview=true');

  const certPrepData = await getCertPrepData(ContentHubType.CERT_PREP, preview);

  if (!certPrepData) {
    throw new Error('Cert Prep was not found');
  }

  return { certPrepData };
};

const getCertPrepData = async (type: string, preview: boolean): Promise<ContentfulContentHub | undefined> => {
  const response = await fetchGraphQL<GetContentHubCollectionResponse, GetContentHubCollectionQueryVariables>(
    GET_CONTENT_HUB_COLLECTION,
    {
      preview,
      type,
    },
  );

  return response.contentHubCollection.items[0];
};

export interface CertificatePreparationLoaderProps {
  certPrepData: ContentfulContentHub;
}
