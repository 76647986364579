import { LessonSidebarProps, Section } from '@atlassian/learning-components';
import { Location, NavigateFunction, Params } from 'react-router-dom';
import { cloneDeep } from 'lodash';

import { ScrollBehaviorTypes, setScrollBehaviour } from 'src/utils/lessonScrollBehavior';
import { ContentfulSmCourse } from 'src/graphql/queries/GetSmCourse';
import { ContentfulSmLesson } from 'src/graphql/queries/GetSmLesson';
import { User } from 'src/types';
import {
  ProgressForLessons,
  LearningProgressStatus,
  saveProgressForLesson,
  ProgressForCourses,
} from 'src/services/http/progress';
import { ContentfulLearningPath } from 'src/graphql/queries/GetLearningPath';
import { getLearningPathPart, getLearningPathsHubPart } from 'src/utils/pathUtils';

interface HandleNavigationParams {
  options: { [key: string]: string };
  location: Location;
  params: Readonly<Params<string>>;
  course: ContentfulSmCourse;
  navigate: NavigateFunction;
  scrollToSection: (sectionSlug: string) => void;
}

export const calculateLearningPathCompletionRate = (
  progress: ProgressForCourses | undefined,
  learningPath: ContentfulLearningPath | null,
): number => {
  if (!progress || !learningPath) {
    return 0;
  }

  const numberOfCompletedCourses = progress.courses.filter((c) => c.status === LearningProgressStatus.COMPLETED).length;

  return (numberOfCompletedCourses / learningPath.coursesCollection.items.length) * 100;
};

export const calculateCourseCompletionRate = (
  progress: ProgressForLessons | undefined,
  course: ContentfulSmCourse | undefined,
): number => {
  if (!progress || !course) {
    return 0;
  }

  const numberOfCompletedLessons = progress.lessons.filter((l) => l.status === LearningProgressStatus.COMPLETED).length;

  return (numberOfCompletedLessons / course.lessonsCollection.items.length) * 100;
};

export const getSectionList = (
  lesson: ContentfulSmCourse['lessonsCollection']['items'][0],
  onNavigation: (options: { [key: string]: string }) => void,
  progress: ProgressForLessons | undefined,
): Section[] => {
  const lessonProgress = progress?.lessons.find((p) => p.lessonId === lesson.sys.id);
  const sections = lesson.sectionListCollection.items
    .filter((section) => section.title)
    .map((section) => ({
      sectionTitle: section.title,
      slug: section.id,
      lessonSlug: lesson.slug,
      isCompleted:
        lessonProgress?.status === LearningProgressStatus.COMPLETED ||
        lessonProgress?.section?.find((p) => p.sectionId === section.sys.id)?.status ===
          LearningProgressStatus.COMPLETED,
      onNavigation,
    }));

  sections.push({
    sectionTitle: 'Quiz',
    slug: 'quiz',
    lessonSlug: lesson.slug,
    isCompleted: lessonProgress?.status === LearningProgressStatus.COMPLETED,
    onNavigation,
  });

  return sections;
};

export const getLessonList = (
  course: ContentfulSmCourse,
  onNavigation: (options: { [key: string]: string }) => void,
  currentLessonSlug: string,
  progress: ProgressForLessons | undefined,
): LessonSidebarProps['lessons'] =>
  course.lessonsCollection.items.map((lesson) => {
    const sections = getSectionList(lesson, onNavigation, progress);

    return {
      lessonTitle: lesson.title,
      slug: lesson.slug,
      duration: `${lesson.duration} MIN`,
      sections,
      isCurrentLesson: currentLessonSlug === lesson.slug,
      isCompleted:
        progress?.lessons.find((p) => p.lessonId === lesson.sys.id)?.status === LearningProgressStatus.COMPLETED,
      onNavigation,
    };
  });

export const completeSectionAsync = (
  progress: ProgressForLessons | undefined,
  section: string,
  lesson: ContentfulSmLesson,
  user: User | null | undefined,
  preview: boolean,
): ProgressForLessons => {
  const nextProgress = cloneDeep(progress || {}) as ProgressForLessons;

  if (!nextProgress.lessons) {
    nextProgress.lessons = [];
  }

  let lessonIndex = nextProgress.lessons.findIndex((p) => p.lessonId === lesson.sys.id);

  if (lessonIndex < 0) {
    nextProgress.lessons.push({
      lessonId: lesson.sys.id,
      status: LearningProgressStatus.IN_PROGRESS,
      section: [],
    });
    lessonIndex = nextProgress.lessons.length - 1;
  }

  const sectionIndex = nextProgress.lessons[lessonIndex].section.findIndex((s) => s.sectionId === section);

  if (sectionIndex < 0) {
    nextProgress.lessons[lessonIndex].section.push({
      sectionId: section,
      status: LearningProgressStatus.COMPLETED,
    });
  } else {
    nextProgress.lessons[lessonIndex].section[sectionIndex].status = LearningProgressStatus.COMPLETED;
  }

  if (user && !preview) {
    saveProgressForLesson({
      atlassianId: user.account_id,
      lesson: {
        lessonId: lesson.sys.id,
        status: LearningProgressStatus.IN_PROGRESS,
        section: {
          sectionId: section,
          status: LearningProgressStatus.COMPLETED,
        },
      },
    });
  }

  return nextProgress;
};

export const handleNavigation = ({
  options,
  location,
  params,
  course,
  navigate,
  scrollToSection,
}: HandleNavigationParams): void => {
  const lessonSlug = options.lessonSlug;
  const sectionSlug = options.sectionSlug && options.sectionSlug.replace(' ', '-');
  const isLessonSectionClicked = !!sectionSlug;
  const prevLocationState = !location.state ? { lessonSlug: '' } : { lessonSlug: location.state.lessonSlug };
  const isCurrentLessonClicked = window.location.pathname.includes(lessonSlug);

  const learningPathSlug = params?.pathId;
  const learningPathPart = getLearningPathPart(learningPathSlug);
  const allLearningPathsHubPart = getLearningPathsHubPart(location.pathname);

  if (!isCurrentLessonClicked || (!isCurrentLessonClicked && !isLessonSectionClicked)) {
    setScrollBehaviour(ScrollBehaviorTypes.UNSET);
  } else {
    setScrollBehaviour(ScrollBehaviorTypes.SMOOTH);
  }

  if (isLessonSectionClicked) {
    navigate(
      `${allLearningPathsHubPart}${learningPathPart}/course/${course?.slug}/lesson/${lessonSlug}#${sectionSlug}`,
      {
        state: { ...prevLocationState },
      },
    );
    scrollToSection(sectionSlug);
  } else {
    navigate(`${allLearningPathsHubPart}${learningPathPart}/course/${course?.slug}/lesson/${lessonSlug}`, {
      replace: true,
      state: { ...prevLocationState },
    });
  }
};
