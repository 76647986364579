import { media } from '@atlaskit/primitives/responsive';
import { css } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { Divider, FlatContentCard, FlatContentCardStyle, Heading, Text } from '@atlassian/learning-components';
import { ReactElement, useMemo } from 'react';

import { AssociatedContent, AssociatedContentAsset } from 'src/graphql/queries/GetContentHub';
import { sendUIEvent } from 'src/utils/analytics';
import { useAtlassianUser } from 'src/hooks/useAtlassianUser';
import { useNavigateByHost } from 'src/hooks/useNavigateByHost';
import { getImageAttrs } from 'src/utils/contentful';

export interface CertificationSectionProps {
  asset: AssociatedContentAsset;
  title: string;
  description: string;
  associatedContent: AssociatedContent;
}

export const CertificationSection = ({
  asset,
  title,
  description,
  associatedContent,
}: CertificationSectionProps): ReactElement => {
  const { user } = useAtlassianUser();
  const navigateByHost = useNavigateByHost();

  const handleLearnMoreClick = (cardTitle: string, link?: string): void => {
    sendUIEvent(
      'certPrepHubScreen',
      'link',
      'clicked',
      'cardLink',
      {
        contentBlockTitle: title,
        cardTitle: cardTitle,
      },
      user?.account_id,
    );

    if (link) {
      navigateByHost(link);
    }
  };

  const assetUrl = useMemo(() => getImageAttrs(asset), [asset]);

  return (
    <div css={containerStyles} data-testid="cert-section">
      <div css={certifiedContentStyles}>
        {assetUrl && <img src={assetUrl.src} alt={assetUrl.alt} css={imageStyles} />}
        <div css={headingContainerStyles}>
          <Heading level={3}>{title}</Heading>
        </div>
        <Text size="large">{description}</Text>
      </div>
      <div css={certificationsContainerStyles} data-testid="certificates-container">
        {associatedContent.map((content) => (
          <div key={content.titleExternal} css={certificationStyles}>
            <Divider color={token('color.border')} height={1} />
            <FlatContentCard
              title={content.titleExternal}
              text={content.description}
              link={content.link?.link}
              linkLabel={content.link?.label}
              style={FlatContentCardStyle.TALL}
              onClick={(title: string) => handleLearnMoreClick(title, content.link?.link)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const containerStyles = css({
  maxWidth: '1440px',
  width: '100%',
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',

  [media.below.lg]: {
    padding: `0 ${token('space.300')}`,
  },

  [media.below.sm]: {
    padding: `0 ${token('space.200')} !important`,
  },
});

const certifiedContentStyles = css({
  maxWidth: '1020px',
  width: '100%',
  margin: '0 auto',
  textAlign: 'center',

  [media.below.md]: {
    maxWidth: '720px',
  },

  [media.below.sm]: {
    maxWidth: '343px !important',
  },
});

const headingContainerStyles = css({
  marginTop: token('space.400'),
  marginBottom: token('space.200'),
});

const certificationsContainerStyles = css({
  width: '100%',
  maxWidth: '1020px',
  marginTop: token('space.600'),
  margin: `${token('space.600')} auto 0`,
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  columnGap: '56px',

  [media.below.md]: {
    gridTemplateColumns: '1fr',
  },
  [media.below.sm]: {
    marginTop: token('space.300'),
  },
});

const certificationStyles = css({
  maxWidth: '482px',
  width: '100%',
  margin: '0 auto',

  [media.below.md]: {
    maxWidth: 'unset',
  },
});

const imageStyles = css({
  width: 'auto',
  height: '80px',
  maxWidth: '100%',
});
