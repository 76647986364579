import React, { Fragment, useContext, useEffect } from 'react';
import { css } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { media } from '@atlaskit/primitives/responsive';
import { Eyebrow, FlatContentCardStyle, Heading, IconType, Link } from '@atlassian/learning-components';
import { useLoaderData } from 'react-router-dom';

import { HelmetContext } from 'src/components/Helmet';
import { useAtlassianUser } from 'src/hooks/useAtlassianUser';
import { AssociatedContent } from 'src/graphql/generated/graphql';
import { getImageAttrs } from 'src/utils/contentful';
import { sendScreenEvent, sendUIEvent } from 'src/utils/analytics';
import { handleCardLinkClick } from 'src/pages/collections/RoleBasedCollectionPage/utils/utils';
import { useNavigateByHost } from 'src/hooks/useNavigateByHost';

import { RoleLoaderProps } from './loader';
import { CollectionType, Section } from './children/Section';
import { SectionWrapper } from './children/SectionWrapper';
import { ContentCard } from './children/ContentCard';

export const RoleBasedCollectionPage: React.FC = () => {
  const helmet = useContext(HelmetContext);
  const { user, isLoading, hasError } = useAtlassianUser();

  const { roleData } = useLoaderData() as RoleLoaderProps;
  const {
    nameInternal,
    titleExternal,
    similarRoleTitles,
    salary,
    description,
    icon,
    contentSectionOrder,
    seo,
    contentOverrideContainerCollection,
  } = roleData;

  const navigateByHost = useNavigateByHost();

  helmet.setHelmet({
    title: `${nameInternal} | Learning - Atlassian Community`,
    seoTags: seo,
  });

  useEffect(() => {
    if (!isLoading && !hasError && roleData) {
      sendScreenEvent(
        'roleBasedCollectionScreen',
        {
          collectionSlug: roleData.slug,
        },
        user?.account_id,
      );
    }
  }, [user, isLoading, hasError, roleData]);

  const similarTitlesContainer = (
    <>
      <div css={heroTitleWrapperStyles}>
        <Heading level={4}>Similar titles</Heading>
      </div>
      <p css={similarTitlesStyles}>
        {similarRoleTitles?.map((title) => (
          <Fragment key={title}>
            {title}
            <br />
          </Fragment>
        ))}
      </p>
    </>
  );
  const iconAttrs = getImageAttrs(icon);
  const headerImage = <img css={headerImageStyles} src={iconAttrs?.src} alt={iconAttrs?.alt} />;

  const onSalaryLinkClick = (): void => {
    window.open(salary?.reference?.link || '', '_blank', 'noopener');
    sendUIEvent('roleBasedCollectionScreen', 'link', 'clicked', 'salaryLink', {}, user?.account_id);
  };

  return (
    <main css={mainSectionStyles} data-testid="collection-body" id="collection-body">
      <section css={heroSectionStyles}>
        <div css={leftHeroSectionStyles}>
          <Eyebrow color={token('color.text.subtle')} size="small">
            {nameInternal}
          </Eyebrow>
          <div css={heroMainTitleWrapperStyles}>
            <Heading level={0}>{titleExternal}</Heading>
          </div>
          <div css={tabletHeroImagePlaceholder}>{headerImage}</div>
          <div css={heroTitleWrapperStyles}>
            <Heading level={4}>Job description</Heading>
          </div>
          <p css={jobDescriptionTextStyles}>{description}</p>
          <div css={heroTitleWrapperStyles}>
            <Heading level={4}>Average salary</Heading>
          </div>
          <span css={salaryStyles}>{salary?.salary}</span>
          <div>
            <span css={salaryDescriptionStyles}>{salary?.referenceText}</span>
            <Link
              color={token('color.text.brand')}
              icon={salary?.reference?.icon?.type as IconType}
              label={salary?.reference?.label}
              link={salary?.reference?.link}
              onClick={onSalaryLinkClick}
            />
            <div css={tabletSimilarTitlesStyles}>{similarTitlesContainer}</div>
          </div>
        </div>
        <div css={rightHeroSectionStyles}>
          <div css={heroImagePlaceholder}>{headerImage}</div>
          <div>{similarTitlesContainer}</div>
        </div>
      </section>
      <div data-testid={contentOverrideContainerCollection.items.length ? 'content-override' : 'content'}>
        {contentOverrideContainerCollection.items.length
          ? contentOverrideContainerCollection.items.map((item, idx) => (
            <SectionWrapper
              key={item.contentSectionTitle?.contentSectionTitleExternal + item.sys.id}
              title={item.contentSectionTitle?.contentSectionTitleExternal}
              subtitle={item.contentSectionTitle?.contentSectionSubtitle}
              image={getImageAttrs(item.contentSectionTitle?.asset)}
              bgColor={idx % 2 === 0 ? '#F8F8F8' : undefined}
            >
              <div css={cardsContainerStyles}>
                {(item.contentLinksCollection.items as unknown as AssociatedContent[]).map(
                  ({ sys, link, titleExternal, description, asset }, idx) => (
                    <ContentCard
                      key={titleExternal + sys.id}
                      link={link?.link}
                      style={FlatContentCardStyle.FULL}
                      title={titleExternal}
                      text={description}
                      image={getImageAttrs(asset)}
                      showTopDivider={idx < 2}
                      onClick={(title) => {
                        handleCardLinkClick(
                          title,
                          item.contentSectionTitle?.contentSectionTitleExternal,
                          user?.account_id,
                        );
                        link?.link && navigateByHost(link?.link);
                      }}
                    />
                  ),
                )}
              </div>
            </SectionWrapper>
          ))
          : contentSectionOrder.map((section) => (
            <Section key={section} collectionType={section as CollectionType} roleData={roleData} />
          ))}
      </div>
    </main>
  );
};

const mainSectionStyles = css({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  marginTop: '136px',

  '@media (max-width: 1279px)': {
    marginTop: '0',
  },
});

const heroSectionStyles = css({
  display: 'flex',
  gap: '128px',
  justifyContent: 'space-between',
  padding: `${token('space.600')} 210px`,
  maxWidth: '1440px',
  margin: '0 auto',

  [media.below.lg]: {
    gap: 0,
    padding: `${token('space.600')} ${token('space.300')}`,
    paddingBottom: token('space.1000'),
    flexDirection: 'column',
    alignItems: 'center',
  },

  [media.below.sm]: {
    padding: `${token('space.600')} ${token('space.200')} ${token('space.500')}`,
  },
});

const leftHeroSectionStyles = css({
  display: 'flex',
  flexDirection: 'column',

  [media.below.lg]: {
    alignItems: 'center',
    textAlign: 'center',
  },
});

const rightHeroSectionStyles = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',

  [media.below.lg]: {
    display: 'none',
  },
});

const heroMainTitleWrapperStyles = css({
  marginTop: token('space.200'),
});

const heroTitleWrapperStyles = css({
  marginTop: token('space.600'),
  marginBottom: token('space.200'),
  fontWeight: 700,
  fontFamily: 'Charlie Display, sans-serif',

  [media.below.lg]: {
    marginBottom: token('space.200'),
  },

  [media.below.sm]: {
    marginTop: token('space.400'),
  },
});

const tabletHeroImagePlaceholder = css({
  display: 'none',
  width: 'auto',
  maxWidth: '100%',
  height: '290px',

  [media.below.lg]: {
    display: 'block',
    marginTop: token('space.600'),
  },

  [media.below.sm]: {
    marginTop: token('space.400'),
  },
});

const headerImageStyles = css({
  height: '100%',
});

const heroImagePlaceholder = css({
  width: 'auto',
  height: '290px',
  maxWidth: '100%',

  [media.below.lg]: {
    display: 'none',
  },
});

const tabletSimilarTitlesStyles = css({
  display: 'none',

  [media.below.lg]: {
    display: 'block',
  },
});

const jobDescriptionTextStyles = css({
  fontFamily: 'Inter, sans-serif',
  fontWeight: 400,
  fontSize: '20px',
  lineHeight: '32px',

  [media.below.sm]: {
    fontSize: '16px',
    lineHeight: '24px',
  },
});

const salaryStyles = css({
  fontSize: '120px',
  lineHeight: '120px',
  fontWeight: 800,
  fontFamily: 'Charlie Display, sans-serif',
  color: token('color.text.brand'),
  marginBottom: token('space.200'),

  [media.below.sm]: {
    fontSize: '80px',
    lineHeight: '80px',
  },
});

const salaryDescriptionStyles = css({
  marginRight: token('space.100'),
  fontFamily: 'Inter, sans-serif',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '20px',
});

const similarTitlesStyles = css({
  fontFamily: 'Inter, sans-serif',
  fontWeight: 400,
  fontSize: '20px',
  lineHeight: '36px',

  [media.below.sm]: {
    fontSize: '16px',
    lineHeight: '30px',
  },
});

const cardsContainerStyles = css({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  columnGap: '56px',

  [media.below.lg]: {
    display: 'flex',
    flexDirection: 'column',

    borderTop: `1px solid ${token('color.border')}`,
  },
});
