import type { Request } from '@atlassian/tesseract-runtime-globals';

export const registerUserInNitro = async (): Promise<boolean> => {
  const typedGlobalThis = globalThis as typeof globalThis & { __COOKIES__: Request['headers']['cookie'] };

  const headers = {
    'Content-Type': 'application/json',
    Origin: globalThis.location.origin,
    ...(typedGlobalThis.__COOKIES__ ? { cookie: typedGlobalThis.__COOKIES__ } : {}),
  };

  try {
    const response = await fetch(`${globalThis.location.origin}/gateway/api/learning-app/kms/user`, {
      method: 'POST',
      credentials: 'include',
      headers,
    });

    return response.ok;
  } catch (error) {
    return false;
  }
};
