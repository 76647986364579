import { gql } from 'graphql-tag';

import { GetLearningPathQuery } from 'src/graphql/generated/GetLearningPath';
import {
  RELATED_CONTENT,
  AT_IMAGE_FRAGMENT,
  SEO_FRAGMENT,
  OVERVIEW_ENTRY_BLOCK_FRAGMENT,
  OVERVIEW_ENTRY_INLINE_FRAGMENT,
  OR_VIDEO_FRAGMENT,
} from 'src/graphql//fragments/fragments';

export const GET_LEARNING_PATH = gql`
  ${AT_IMAGE_FRAGMENT}
  ${RELATED_CONTENT}
  ${SEO_FRAGMENT}
  ${OVERVIEW_ENTRY_BLOCK_FRAGMENT}
  ${OVERVIEW_ENTRY_INLINE_FRAGMENT}
  ${OR_VIDEO_FRAGMENT}
  query getLearningPath($slug: String!, $preview: Boolean!) {
    learningPathCollection(preview: $preview, limit: 1, where: { slug: $slug }) {
      items {
        __typename
        sys {
          id
        }
        name
        title
        slug
        learningPathTagline
        learningLevel
        product
        role
        learningPathSummary {
          json
          links {
            entries {
              block {
                ...OverviewEntryBlockFragment
              }
              inline {
                ...OverviewEntryInlineFragment
              }
            }
          }
        }
        coursesCollection(where: { sys: { id_exists: true } }) {
          items {
            sys {
              id
            }
            title
            name
            teaser
            overview {
              json
              links {
                entries {
                  block {
                    ...OverviewEntryBlockFragment
                  }
                  inline {
                    ...OverviewEntryInlineFragment
                  }
                }
              }
            }
            slug
            lessonsCollection(where: { sys: { id_exists: true } }) {
              items {
                sys {
                  id
                }
                duration
                title
                slug
              }
            }
          }
        }
        relatedContent {
          ...RelatedContentFragment
        }
        seo {
          ...SeoFragment
        }
      }
    }
  }
`;

export type ContentfulLearningPathResponse = GetLearningPathQuery;
export type ContentfulLearningPath = GetLearningPathQuery['learningPathCollection']['items'][0];
export type ContentfullCourse = ContentfulLearningPath['coursesCollection']['items'][0];
