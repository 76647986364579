import { ProgressStatus } from '@atlassian/learning-components';

export const SERVICE_PREFIX = '/gateway/api/learning-app-progress-fe/v2/progress';
export const SQS_GATEWAY_PREFIX = '/gateway/api/learning-app-progress/sqs/progress';

export const getProgressForLessons = async (lessonIds: string[]): Promise<ProgressForLessons | undefined> => {
  const response = await fetch(`${SERVICE_PREFIX}/lesson?lessonIds=${lessonIds.join(',')}`);

  if (response.status === 204) {
    return {
      overallStatus: LearningProgressStatus.NOT_STARTED,
      lessons: [],
    };
  }

  if (response.status === 200) {
    return response.json();
  }
};

export const getProgressForCourses = async (courseIds: string[]): Promise<ProgressForCourses | undefined> => {
  const response = await fetch(`${SERVICE_PREFIX}/course?courseIds=${courseIds.join(',')}`);

  if (response.status === 204) {
    return {
      overallStatus: LearningProgressStatus.NOT_STARTED,
      courses: [],
    };
  }

  if (response.status === 200) {
    return response.json();
  }
};

export const getOrCreateProgressForQuiz = async (
  lessonId: string,
  quizId: string,
): Promise<ProgressForQuiz | undefined> => {
  const response = await fetch(`${SERVICE_PREFIX}/quiz?contentfulLessonId=${lessonId}&contentfulQuizId=${quizId}`);

  if (response.status === 204) {
    return {
      lessonId,
      quizId,
      status: LearningProgressStatus.NOT_STARTED,
    };
  }

  if (response.status === 200) {
    return response.json();
  }
};

export const getUncompletedQuestionsForQuiz = async (
  payload: UncompletedQuestionsPayload,
): Promise<UncompletedQuestions | undefined> => {
  const response = await fetch(`${SERVICE_PREFIX}/quiz/question/uncompleted`, {
    method: 'PUT',
    body: JSON.stringify(payload),
    headers: {
      'content-type': 'application/json',
    },
  });

  if (response.status === 204) {
    return {
      atlassianId: payload.atlassianId,
      lessonId: payload.lessonId,
      quizId: payload.quizId,
      uncompletedQuestionIds: payload.allQuestionIds,
    };
  }

  if (response.status === 200) {
    return response.json();
  }
};

export const saveProgressForLesson = (payload: LessonProgressPayload): Promise<Response> =>
  fetch(`${SQS_GATEWAY_PREFIX}/lesson`, {
    method: 'PUT',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      MessageBody: {
        ...payload,
        lesson: {
          ...payload.lesson,
          ...(payload.lesson.status === LearningProgressStatus.COMPLETED
            ? { completedDate: new Date(Date.now()).toISOString().split('.')[0] }
            : {}),
        },
      } as LessonProgressPayload,
    }),
  });

export const saveProgressForQuiz = (payload: QuizProgressPayload): Promise<Response> =>
  fetch(`${SERVICE_PREFIX}/quiz`, {
    method: 'PUT',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

export const completeQuestionForQuiz = (payload: QuizQuestionPayload): Promise<Response> =>
  fetch(`${SQS_GATEWAY_PREFIX}/quiz/question`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

export const mapServerStatusToComponentsStatus = (status: LearningProgressStatus | undefined): ProgressStatus => {
  const progressStatusMap: { [key in LearningProgressStatus]?: ProgressStatus } = {
    [LearningProgressStatus.COMPLETED]: ProgressStatus.FINISHED,
    [LearningProgressStatus.IN_PROGRESS]: ProgressStatus.IN_PROGRESS,
  };

  return progressStatusMap[status!] || ProgressStatus.NOT_STARTED;
};

export enum LearningProgressStatus {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}

export interface ProgressForLessons {
  overallStatus: LearningProgressStatus;
  lessons: {
    lessonId: string;
    status: LearningProgressStatus;
    section: {
      sectionId: string;
      status: LearningProgressStatus;
    }[];
  }[];
}

export interface ProgressForCourses {
  overallStatus: LearningProgressStatus;
  courses: {
    courseId: string;
    status: LearningProgressStatus;
  }[];
}

export interface ProgressForQuiz {
  atlassianId?: string;
  lessonId: string;
  quizId: string;
  status: LearningProgressStatus;
}

export interface UncompletedQuestions {
  atlassianId: string;
  lessonId: string;
  quizId: string;
  uncompletedQuestionIds: string[];
}

export interface UncompletedQuestionsPayload {
  atlassianId: string;
  lessonId: string;
  quizId: string;
  allQuestionIds: string[];
}

export interface LessonProgressPayload {
  atlassianId: string;
  lesson: {
    lessonId: string;
    status: LearningProgressStatus;
    completedDate?: string;
    startedDate?: string;
    section?: {
      sectionId: string;
      status: LearningProgressStatus;
    };
  };
}

export interface QuizProgressPayload {
  atlassianId: string;
  lessonId: string;
  quizId: string;
  newStatus: LearningProgressStatus;
}

export interface QuizQuestionPayload {
  atlassianId: string;
  lessonId: string;
  quizId: string;
  questionId: string;
}
