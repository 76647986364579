import { ContentfulSmCourse } from 'src/graphql/queries/GetSmCourse';
import { ContentfulLearningPath } from 'src/graphql/queries/GetLearningPath';
import { getProgressForLessons, LearningProgressStatus, ProgressForCourses } from 'src/services/http/progress';
import { NextCourse } from 'src/pages/LessonPage/types/nextCourse';

const findNextCourse = (
  currentLearningPath: ContentfulLearningPath,
  currentCourse: ContentfulSmCourse,
  completedCourses: string[],
): ContentfulSmCourse | null => {
  const currentCourseIndex = currentLearningPath.coursesCollection.items.findIndex(
    (course) => course.sys.id === currentCourse.sys.id,
  );

  const nextCourseBeforeCurrent = currentLearningPath.coursesCollection.items.find(
    (c, index) => index < currentCourseIndex && !completedCourses.includes(c.sys.id),
  ) as ContentfulSmCourse;

  const nextCourseAfterCurrent = currentLearningPath.coursesCollection.items.find(
    (c, index) => index > currentCourseIndex && !completedCourses.includes(c.sys.id),
  ) as ContentfulSmCourse;

  return nextCourseAfterCurrent || nextCourseBeforeCurrent || null;
};

export const findNextLessonSlug = (
  lessons: { sys: { id: string }; slug: string }[],
  inProgressLessonIds: string[],
  completedLessonIds: string[],
): string => {
  if (inProgressLessonIds.length === 0 && completedLessonIds.length === 0) {
    return lessons[0].slug;
  }

  const allCompletedLessons = lessons.every((lesson) => completedLessonIds.includes(lesson.sys.id));

  if (!allCompletedLessons) {
    const lastCompletedLessonId = completedLessonIds[completedLessonIds.length - 1];
    const nextLessonIndex = lessons.findIndex((lesson) => lesson.sys.id === lastCompletedLessonId) + 1;

    return lessons[nextLessonIndex]?.slug || lessons[0].slug;
  }

  const firstInProgressLesson = lessons.find((lesson) => inProgressLessonIds.includes(lesson.sys.id));

  return firstInProgressLesson?.slug || lessons[0].slug;
};

export const getNextCourse = async (
  currentLearningPath: ContentfulLearningPath,
  currentCourse: ContentfulSmCourse,
  progress: ProgressForCourses | undefined,
): Promise<NextCourse | null> => {
  if (!progress || !currentCourse || !currentLearningPath) {
    return null;
  }

  const nextCourse = findNextCourse(
    currentLearningPath,
    currentCourse,
    progress.courses.filter((p) => p.status === LearningProgressStatus.COMPLETED).map((p) => p.courseId),
  );

  if (!nextCourse) {
    return null;
  }

  const lessonProgress = await getProgressForLessons(
    nextCourse?.lessonsCollection?.items.map((lesson) => lesson.sys.id) || [],
  );

  const nextLessonSlug = findNextLessonSlug(
    nextCourse.lessonsCollection.items,
    lessonProgress?.lessons
      .filter((lesson) => lesson.status === LearningProgressStatus.IN_PROGRESS)
      .map((lesson) => lesson.lessonId) as string[],
    lessonProgress?.lessons
      .filter((lesson) => lesson.status === LearningProgressStatus.COMPLETED)
      .map((lesson) => lesson.lessonId) as string[],
  );

  return { ...nextCourse, nextLessonSlug };
};
