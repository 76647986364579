import { LoaderFunction } from 'react-router-dom';

import { fetchGraphQL } from 'src/services/contentful/client';
import {
  ContentfulLearningPath,
  ContentfulLearningPathResponse,
  GET_LEARNING_PATH,
} from 'src/graphql/queries/GetLearningPath';
import { GetLearningPathQueryVariables } from 'src/graphql/generated/GetLearningPath';

export const loader: LoaderFunction = async ({ params, request }): Promise<LearningPathLoaderProps> => {
  const pathId = params.pathId ?? '';
  const preview = request.url.includes('preview=true');

  const learningPath = await getLearningPath(pathId, preview);

  if (!learningPath) {
    throw new Error('Learning path was not found');
  }

  return { learningPath };
};

const getLearningPath = async (pathId: string, preview: boolean): Promise<ContentfulLearningPath | undefined> => {
  const response = await fetchGraphQL<ContentfulLearningPathResponse, GetLearningPathQueryVariables>(
    GET_LEARNING_PATH,
    { slug: pathId, preview },
  );

  return response.learningPathCollection.items[0];
};

export interface LearningPathLoaderProps {
  learningPath: ContentfulLearningPath;
}
