import React, { useRef, useState, useEffect } from 'react';
import { css } from '@compiled/react';
import { Icon } from '@atlassian/learning-components';
import { token } from '@atlaskit/tokens';
import { useLocation, useNavigate } from 'react-router-dom';

import { ContentHubType } from 'src/constants';

interface SubNavbarDropdownProps {
  onDropdownItemClick: () => void;
}

const DEFAULT_LINKS: { [key: string]: { text: string; href: string } } = {
  BROWSE_ALL: { text: 'Browse all learning', href: '/catalog' },
  LEARNING_PATHS: {
    text: 'Learning paths',
    href: `/hub/${ContentHubType.LEARNING_PATHS}`,
  },
  PREPARE_FOR_CERTIFICATION: {
    text: 'Prepare for a certification',
    href: `/hub/${ContentHubType.CERT_PREP}`,
  },
  GROW_YOUR_CAREER: {
    text: 'Grow your career',
    href: `/hub/${ContentHubType.ROLE_BASED}`,
  },
};

export const SubNavbarDropdown = ({ onDropdownItemClick }: SubNavbarDropdownProps): React.ReactElement => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dropdownTitleRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const isAnyItemSelected = Object.values(DEFAULT_LINKS).some(({ href }) => location.pathname.includes(href));

  const onDropdownClickHandler = (): void => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleOnClick = (href: string): void => {
    setIsMenuOpen(!isMenuOpen);
    if (onDropdownItemClick) {
      onDropdownItemClick();
      navigate(href);
    }
  };

  const handleClickOutside = (event: MouseEvent): void => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target as Node) &&
      dropdownTitleRef.current &&
      !dropdownTitleRef.current.contains(event.target as Node)
    ) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMenuOpen]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === 'Enter') {
      onDropdownClickHandler();
    }
  };

  const handleMenuItemKeyDown = (event: React.KeyboardEvent<HTMLLIElement>, href: string): void => {
    if (event.key === 'Enter') {
      handleOnClick(href);
    }
  };

  return (
    <>
      <div
        tabIndex={0}
        ref={dropdownTitleRef}
        css={headerStyles}
        onClick={onDropdownClickHandler}
        onKeyDown={handleKeyDown}
      >
        <a css={[contentLinkStyles, (isMenuOpen || isAnyItemSelected) && selectedLinkStyles]}>
          On-demand learning
          <Icon type={isMenuOpen ? 'chevron-up' : 'chevron-down'} alt={isMenuOpen ? 'Collapse item' : 'Expand item'} />
        </a>
      </div>
      {isMenuOpen && (
        <div ref={menuRef} tabIndex={0}>
          <ul css={openMenuStyles} aria-label="On-demand" role="menu">
            {Object.entries(DEFAULT_LINKS).map(([label, value]) => (
              <li
                tabIndex={0}
                key={label}
                css={menuItemContainerBaseStyles}
                role="menuitem"
                onClick={() => handleOnClick(value.href)}
                onKeyDown={(event) => handleMenuItemKeyDown(event, value.href)}
              >
                <span css={[fontStyles, location.pathname.includes(value.href) && activeTabStyles]}>{value.text}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

const openMenuStyles = css({
  marginTop: '28px !important',
  background: token('color.background.input'),
  boxShadow:
    '0px 0px 1px 0px var(--shadow-overlay-first, rgba(9, 30, 66, 0.31)), 0px 8px 12px 0px var(--shadow-overlay-second, rgba(9, 30, 66, 0.15)), 0px 0px 0px 1px var(--shadow-overlay-third, rgba(188, 214, 240, 0.00)) inset',
  position: 'absolute',
  borderRadius: '4px',
  alignItems: 'flex-start',
  padding: '8px 0',
  width: '240px',
  left: 0,
  zIndex: 1000,
});

const contentLinkStyles = css({
  display: 'flex',
  alignItems: 'center',
  fontWeight: token('font.weight.regular'),
  color: token('color.text'),
  '&:hover': {
    textDecoration: 'none',
    color: token('color.link'),
  },
});

const headerStyles = css({
  display: 'flex',
  justifyContent: 'space-between',
  cursor: 'pointer',
  height: '100%',
  width: '100%',

  '&:hover': {
    color: token('color.link'),
  },
});

const menuItemContainerBaseStyles = css({
  display: 'flex',
  padding: `${token('space.200')} ${token('space.200')} ${token('space.200')} 24px`,
  alignItems: 'center',
  alignSelf: 'stretch',
  margin: '0',

  '&:hover': {
    color: token('color.link'),
  },

  '&:active': {
    background: token('color.background.accent.blue.subtlest'),
  },

  color: token('color.text'),
  cursor: 'pointer',
});

const selectedLinkStyles = css({
  textDecoration: 'none',
  color: token('color.link'),
  cursor: 'pointer',
});

const fontStyles = css({
  fontWeight: token('font.weight.regular'),
  '&:hover': {
    color: token('color.link'),
  },
  '&:active': {
    background: token('color.background.accent.blue.subtlest'),
  },
});

const activeTabStyles = css({
  color: token('color.link'),
});
