import React, { useEffect, useState } from 'react';
import { css } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { media } from '@atlaskit/primitives/responsive';
import {
  Button,
  ButtonAppearance,
  ButtonSize,
  ContentType,
  Heading,
  LearningCourseCard,
  DropdownMenuCheckbox,
} from '@atlassian/learning-components';
import { useNavigate } from 'react-router-dom';

import { LearningEmptyState } from 'src/pages/MyLearningPage/children/LearningEmptyState';
import { sendUIEvent } from 'src/utils/analytics';
import { generatePdf } from 'src/pages/MyLearningPage/children/utils/generatePdf';
import { useAtlassianUser } from 'src/hooks/useAtlassianUser';
import {
  getCoursesAndLearningPathsTitleAndSlugs,
  MyLearningResource,
  MyLearningResourceData,
} from 'src/pages/MyLearningPage';
import {
  getFullTranscriptReportData,
  getMyLearning,
  saveOrUpdateContentSavedStatus,
} from 'src/services/http/myLearning';
import { generateCSV } from 'src/pages/MyLearningPage/children/utils/generateCSV';
import { MyLearningPageContentSections } from 'src/pages/MyLearningPage/constants';
import { ResourceCard } from 'src/components/ResourceCard';
import { ResourceType } from 'src/constants';
import { GeneratePdfContentType } from 'src/pages/MyLearningPage/types';
import { getUrlPrefix } from 'src/services/http/user';

interface MyLearningSectionProps {
  status: string;
  handleTabChange: (index: number) => void;
  myLearningResourceData: MyLearningResourceData | null;
}

enum SortingType {
  MOST_RECENT = 'default',
  MOST_COMPLETE = 'mostComplete',
}

interface FilterItem {
  isSelected: boolean;
  isDisabled: boolean;
}

const filterMapping: Record<string, SortingType> = {
  'Most recent': SortingType.MOST_RECENT,
  'Most complete': SortingType.MOST_COMPLETE,
};

export const MyLearningContentSection: React.FC<MyLearningSectionProps> = ({
  status,
  handleTabChange,
  myLearningResourceData,
}) => {
  const { user } = useAtlassianUser();
  const [learningData, setLearningData] = useState<MyLearningResourceData | null>(myLearningResourceData);
  const [isFullTranscriptDataLoading, setIsFullTranscriptDataLoading] = useState(false);
  const filterItemsChecked: Record<'Most recent' | 'Most complete', FilterItem> = {
    'Most recent': { isSelected: true, isDisabled: false },
    'Most complete': { isSelected: false, isDisabled: false },
  };
  const navigate = useNavigate();
  const isSavedTab = status === MyLearningPageContentSections.SAVED;
  const isCompleteTab = status === MyLearningPageContentSections.COMPLETE;
  const isInProgressTab = status === MyLearningPageContentSections.IN_PROGRESS;

  useEffect(() => {
    setLearningData(myLearningResourceData);
  }, [myLearningResourceData]);

  const handleSave = async (
    content: MyLearningResource,
    contentType: ContentType.LEARNING_PATH | ContentType.COURSE,
  ): Promise<void> => {
    const isSaved = await saveOrUpdateContentSavedStatus(
      content.contentfulId,
      contentType,
      false,
      content?.learningPathSlug,
    );

    const filteredCourses =
      learningData && learningData.courses?.filter((item) => item.contentfulId !== content.contentfulId);
    const filteredLearningPath =
      learningData && learningData.learningPaths?.filter((item) => item.contentfulId !== content.contentfulId);

    if (isSaved && learningData) {
      const updatedLearningData: MyLearningResourceData = {
        ...learningData,
        learningPaths: filteredLearningPath?.length ? filteredLearningPath : null,
        courses: filteredCourses?.length ? filteredCourses : null,
      };

      setLearningData(updatedLearningData);
    }
  };

  const handleLearningCardClick = (
    content: MyLearningResource,
    contentType: ContentType.COURSE | ContentType.LEARNING_PATH,
  ): void => {
    if (user) {
      sendUIEvent(
        'myLearningScreen',
        'card',
        'clicked',
        'myLearningCard',
        {
          resourceSlug: content.slug,
          type: contentType,
        },
        user.account_id,
      );
    }

    if (contentType === ContentType.COURSE) {
      navigate(`/course/${content.slug}`);
    } else {
      navigate(`/path/${content.slug}`);
    }
  };

  const handleGeneratePdf = (
    title: string,
    completionDate: string,
    userName: string,
    contentType: GeneratePdfContentType,
    imageUrl?: string,
  ): Promise<void> => {
    user &&
      sendUIEvent(
        'myLearningScreen',
        'button',
        'clicked',
        'downloadPDF',
        {
          resourceSlug: title,
          resourceType: contentType,
        },
        user.account_id,
      );

    return generatePdf({
      title,
      completionDate,
      userName,
      imageUrl,
      contentType,
    });
  };

  const transformCompletionData = (completionDate?: string): string => completionDate?.split('T')[0] ?? '';

  const handleOnFilterChange = async (name: string): Promise<void> => {
    const updatedLearningData = await getMyLearning('in-progress', filterMapping[name]);
    const { myLearningResourceData } = await getCoursesAndLearningPathsTitleAndSlugs(null, updatedLearningData, null);

    setLearningData(myLearningResourceData);

    sendUIEvent(
      'myLearningScreen',
      'dropdown',
      'clicked',
      'myLearningSortButton',
      {
        sortType: filterMapping[name],
      },
      user?.account_id,
    );
  };

  const handleDownloadCSVReportClick = async (): Promise<void> => {
    if (!myLearningResourceData) {
      return;
    }
    setIsFullTranscriptDataLoading(true);

    const fullTranscriptData = await getFullTranscriptReportData();
    const inProgressResourceData = await getMyLearning('in-progress');

    const { myLearningResourceData: inProgressTransformedData } = await getCoursesAndLearningPathsTitleAndSlugs(
      null,
      inProgressResourceData,
      null,
    );

    const completedLearningPathData = myLearningResourceData.learningPaths ? myLearningResourceData.learningPaths : [];
    const completedCourseData = myLearningResourceData.courses ? myLearningResourceData.courses : [];
    const inProgressLearningPathsData =
      inProgressTransformedData && inProgressTransformedData.learningPaths
        ? inProgressTransformedData.learningPaths
        : [];
    const inProgressCourseData =
      inProgressTransformedData && inProgressTransformedData.courses ? inProgressTransformedData.courses : [];

    const combinedMyLearningData: MyLearningResourceData = {
      learningPaths: [...completedLearningPathData, ...inProgressLearningPathsData],
      courses: [...completedCourseData, ...inProgressCourseData],
    };

    setIsFullTranscriptDataLoading(false);

    if (!fullTranscriptData) {
      return;
    }

    generateCSV({
      userName: user?.name ?? '',
      fullTranscriptData: fullTranscriptData,
      myLearningData: combinedMyLearningData,
    });
    sendUIEvent('myLearningScreen', 'button', 'clicked', 'downloadCSV', {}, user?.account_id);
  };

  if (!learningData?.learningPaths && !learningData?.courses) {
    return <LearningEmptyState learningStatus={status} onTabChange={handleTabChange} />;
  }

  return (
    <>
      {learningData && learningData.learningPaths && (
        <div css={contentSectionStyles} data-testid="learning-path-section">
          {learningData.learningPaths && (
            <div css={sectionHeaderContainerStyles}>
              <Heading level={4}>Learning paths</Heading>
              {isCompleteTab && (
                <Button
                  isLoading={isFullTranscriptDataLoading}
                  label="Download full transcript"
                  onClick={handleDownloadCSVReportClick}
                  appearance={ButtonAppearance.PRIMARY}
                />
              )}
              {isInProgressTab && (
                <div css={filterStyles}>
                  <span css={spanStyles}>sort by</span>
                  <DropdownMenuCheckbox
                    data-testId="my-learning-sorting-dropdown"
                    items={filterItemsChecked}
                    onItemClick={(label) => handleOnFilterChange(label)}
                    placement="bottom-end"
                  />
                </div>
              )}
            </div>
          )}
          <div css={cardsContainerStyles}>
            {learningData.learningPaths.map((path) => (
              <div key={path.contentfulId} css={cardContainerStyles}>
                <ResourceCard
                  contentType={ResourceType.LearningPath}
                  product={path.product}
                  title={path.title}
                  target="_self"
                  link={`${getUrlPrefix()}/path/${path.slug}`}
                  onClick={() => handleLearningCardClick(path, ContentType.LEARNING_PATH)}
                  tags={[]}
                  onGeneratePdf={() =>
                    handleGeneratePdf(
                      path.title,
                      transformCompletionData(path?.completedDate),
                      user?.name ?? '',
                      GeneratePdfContentType.LEARNING_PATH,
                    )
                  }
                  learningProgress={{
                    completedResources: path.progressTotal,
                    resourceCount: path.subCollectionTotal,
                    onSave: () => handleSave(path, ContentType.LEARNING_PATH),
                    isSaved: true,
                    completionDate: transformCompletionData(path.completedDate),
                  }}
                  minHeight={262}
                  shouldDisplayBookmark={isSavedTab}
                  shouldDisplayShareButton={isCompleteTab}
                />
              </div>
            ))}
          </div>
        </div>
      )}
      {learningData && learningData.courses && (
        <div css={contentSectionStyles} data-testid="course-section">
          <div css={sectionHeaderContainerStyles}>
            <Heading level={4}>Courses</Heading>
            {!learningData.learningPaths && isCompleteTab && (
              <Button
                label="Download full transcript"
                onClick={handleDownloadCSVReportClick}
                appearance={ButtonAppearance.PRIMARY}
                size={ButtonSize.LARGE}
                isResponsive={true}
                isLoading={isFullTranscriptDataLoading}
              />
            )}
            {!learningData.learningPaths && isInProgressTab && (
              <div css={filterStyles}>
                <span css={spanStyles}>sort by</span>
                <DropdownMenuCheckbox
                  items={filterItemsChecked}
                  onItemClick={(label: string) => handleOnFilterChange(label)}
                />
              </div>
            )}
          </div>
          <div css={cardsContainerStyles}>
            {learningData.courses.map((course) => (
              <div key={course.contentfulId} css={cardContainerStyles}>
                <LearningCourseCard
                  imageUrl={course.badgeUrl}
                  title={course.title}
                  link={
                    course.learningPathSlug
                      ? `${getUrlPrefix()}/path/${course.learningPathSlug}/course/${course.slug}`
                      : `${getUrlPrefix()}/course/${course.slug}`
                  }
                  onClick={() => handleLearningCardClick(course, ContentType.COURSE)}
                  onGeneratePdf={() =>
                    handleGeneratePdf(
                      course.title,
                      transformCompletionData(course.completedDate),
                      user?.name ?? '',
                      GeneratePdfContentType.COURSE,
                      course.badgeUrl,
                    )
                  }
                  learningProgress={{
                    completedResources: course.progressTotal,
                    resourceCount: course.subCollectionTotal,
                    onSave: () => handleSave(course, ContentType.COURSE),
                    isSaved: true,
                    completionDate: transformCompletionData(course.completedDate),
                  }}
                  shouldDisplayBookmark={isSavedTab}
                  shouldDisplayShareButton={isCompleteTab}
                  minHeight={153}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

const contentSectionStyles = css({
  marginTop: token('space.400'),
});

const cardsContainerStyles = css({
  marginTop: token('space.300'),
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  gap: token('space.300'),

  '@media (max-width: 1279px)': {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
  },

  [media.below.sm]: {
    gridTemplateColumns: 'repeat(1, 1fr)',
  },
});

const cardContainerStyles = css({
  width: '394px',

  '@media (max-width: 1279px)': {
    width: 'unset',
  },
});

const filterStyles = css({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: token('space.200'),
});

const spanStyles = css({
  fontFamily: 'Charlie Text, sans-serif',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '18px',
  textTransform: 'uppercase',
  marginRight: token('space.200'),
});

const sectionHeaderContainerStyles = css({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  alignItems: 'center',

  [media.below.sm]: {
    flexDirection: 'column-reverse',
    alignItems: 'flex-start',
    gap: token('space.200'),
  },
});
