import React from 'react';
import EmptyState from '@atlaskit/empty-state';
import { Button, ButtonAppearance, Heading } from '@atlassian/learning-components';
import { token } from '@atlaskit/tokens';
import { css } from '@compiled/react';
import { useNavigate } from 'react-router-dom';

import inProgressEmptyIcon from 'src/assets/images/learningEmptyStateIcons/inProgressEmptyIcon.svg?url';
import savedEmptyIcon from 'src/assets/images/learningEmptyStateIcons/savedEmptyIcon.svg?url';
import completedEmptyIcon from 'src/assets/images/learningEmptyStateIcons/completedEmptyIcon.svg?url';
import recentlyViewedEmptyIcon from 'src/assets/images/learningEmptyStateIcons/recentlyViewedEmptyIcon.svg?url';
import { MyLearningPageContentSections } from 'src/pages/MyLearningPage/constants';

interface LearningEmptyStateProps {
  learningStatus: string;
  onTabChange: (index: number) => void;
}

interface EmptyStateConfig {
  [key: string]: {
    imageUrl: string;
    header: string;
    description: string;
    buttonLabel: string;
  };
}

export const emptyStateConfig: EmptyStateConfig = {
  [MyLearningPageContentSections.IN_PROGRESS]: {
    imageUrl: inProgressEmptyIcon,
    header: 'Complete your first lesson',
    description: 'Once you complete a lesson, we’ll display your course and learning path progress here.',
    buttonLabel: 'Browse content',
  },
  [MyLearningPageContentSections.SAVED]: {
    imageUrl: savedEmptyIcon,
    header: 'Access content faster',
    description: 'Save courses and learning paths for easy access later. We’ll display saved content here.',
    buttonLabel: 'Browse content',
  },
  [MyLearningPageContentSections.COMPLETE]: {
    imageUrl: completedEmptyIcon,
    header: 'Earn your first badge',
    description:
      'When you complete a course or learning path, you’ll earn a badge. You can view completed learning here.',
    buttonLabel: 'See what’s in progress',
  },
  [MyLearningPageContentSections.RECENTLY_VIEWED]: {
    imageUrl: recentlyViewedEmptyIcon,
    header: 'Start exploring',
    description:
      'You haven’t viewed anything yet. All recently viewed lessons, courses, and learning paths will appear here.',
    buttonLabel: 'Browse content',
  },
};

export const LearningEmptyState: React.FC<LearningEmptyStateProps> = ({
  learningStatus,
  onTabChange: handleTabChange,
}) => {
  const navigate = useNavigate();

  const { imageUrl, header, description, buttonLabel } = emptyStateConfig[learningStatus];

  const handleBrowseContent = (buttonLabel: string): void => {
    if (buttonLabel === 'Browse content') {
      navigate('/catalog');
    } else {
      handleTabChange(0);
    }
  };

  return (
    <div css={containerStyles} data-testid="learning-empty-state">
      <EmptyState
        imageUrl={imageUrl}
        header={header}
        headingLevel={5}
        description={
          <div css={descriptionContainerStyles}>
            <Heading level={6} color={token('color.text.subtlest')}>
              {description}
            </Heading>
          </div>
        }
        primaryAction={
          <Button
            appearance={ButtonAppearance.PRIMARY}
            label={buttonLabel}
            onClick={() => handleBrowseContent(buttonLabel)}
          />
        }
      />
    </div>
  );
};

const containerStyles = css({
  width: '100%',
  margin: '0 auto',
  display: 'flex',
  direction: 'column',
  img: {
    marginBottom: '19px',
  },
});

const descriptionContainerStyles = css({
  maxWidth: '365px',
  width: '100%',
  margin: '30px auto 33px',
});
