import { Checkbox } from '@atlassian/learning-components';
import { token } from '@atlaskit/tokens';
import { css } from '@compiled/react';
import { ReactElement } from 'react';

interface MultipleSelectFieldProps {
  fieldTitle: string;
  selectedAnswers: Record<string, boolean>;
  onSelect: (answer: string) => void;
}

export const mockedMultipleSelectOptions = [
  'Example answer 1',
  'Example answer 2',
  'Example answer 3',
  'Example answer 4',
  'Example answer 5',
];

export const MultipleSelectField = ({
  fieldTitle,
  selectedAnswers,
  onSelect,
}: MultipleSelectFieldProps): ReactElement => (
  <div css={css({ marginBottom: token('space.600') })}>
    <p css={fieldTitleStyles}>{fieldTitle}</p>
    <div>
      {mockedMultipleSelectOptions.map((option) => (
        <div css={css({ marginBottom: token('space.100') })} key={option}>
          <Checkbox label={option} isChecked={selectedAnswers[option]} onChange={() => onSelect(option)} />
        </div>
      ))}
    </div>
  </div>
);

const fieldTitleStyles = css({
  color: token('color.text'),
  fontFamily: 'Inter, sans-serif',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
  marginBottom: token('space.200'),
});
