import { Button, ButtonAppearance, PopupShare, useDeviceSize } from '@atlassian/learning-components';
import React, { useState } from 'react';
import { css } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { media } from '@atlaskit/primitives/responsive';
import QuestionCircleIcon from '@atlaskit/icon/core/question-circle';
import Tooltip from '@atlaskit/tooltip';

import { timeConverter } from 'src/pages/MyLearningPage/children/utils/timeConverter';
import { ArchivedContentResource } from 'src/pages/MyLearningPage';
import { sendUIEvent } from 'src/utils/analytics';
import { generatePdf } from 'src/pages/MyLearningPage/children/utils/generatePdf';
import { useAtlassianUser } from 'src/hooks/useAtlassianUser';
import { MAX_ITEMS_SHOWN } from 'src/pages/MyLearningPage/constants';
import { GeneratePdfContentType } from 'src/pages/MyLearningPage/types';

interface ArchivedContentSectionProps {
  resources: ArchivedContentResource[] | null;
}

export const ArchivedContentSection: React.FC<ArchivedContentSectionProps> = ({ resources }) => {
  const [retiredItemsToShowCount, setRetiredItemsToShowCount] = useState<number>(MAX_ITEMS_SHOWN);

  const { user } = useAtlassianUser();
  const deviceWidth = useDeviceSize();
  const isTablet = deviceWidth < 1024;

  const showMoreRetiredContent = (): void => {
    setRetiredItemsToShowCount((prevCount) => prevCount + 10);
  };

  const handleGeneratePdf = (
    title: string,
    date: Date,
    userName: string,
    contentType: GeneratePdfContentType,
    imageUrl?: string,
  ): Promise<void> => {
    const completionDate = new Date(date).toISOString().split('T')[0];

    user &&
      sendUIEvent(
        'myLearningScreenArchivedContent',
        'button',
        'clicked',
        'downloadPDF',
        {
          resourceSlug: title,
          resourceType: GeneratePdfContentType.ARCHIVED_CONTENT,
        },
        user?.account_id,
      );

    return generatePdf({
      title,
      completionDate,
      userName,
      imageUrl,
      contentType,
    });
  };

  if (!resources) {
    return null;
  }

  const lessonsToShow = resources.slice(0, retiredItemsToShowCount);
  const showMoreLabel = `Show more (${resources.length - lessonsToShow.length})`;

  return (
    <section
      data-testid="archived-content-section"
      css={[archivedSectionStyles, !resources && noResourcesSectionStyles]}
      role="doc-chapter"
      aria-labelledby="archived-content-heading"
    >
      <div css={archivedWrapperStyles}>
        <h3 tabIndex={0} id="archived-content-heading" css={archivedHeadingStyles}>
          Retired
        </h3>
        <Tooltip content="Completed courses and learning paths that we no longer offer" position="top" truncate={true}>
          <QuestionCircleIcon label="Retired" />
        </Tooltip>
      </div>
      <div css={archivedLabelContainerStyles}>
        <div css={archivedTitleLabelStyles}>
          <span css={archivedLabelStyles}>Title</span>
        </div>
        <div css={archivedDateLabelStyles}>
          <span css={archivedLabelStyles}>Date completed</span>
        </div>
        <div css={archivedProgressLabelStyles}>
          <span css={[archivedLabelStyles, { textAlign: 'end' }]}>Export PDF</span>
        </div>
      </div>
      {lessonsToShow.map((resource, index) => (
        <div
          key={`${resource.entryTitle + index}`}
          data-testid="archived-content-container"
          css={archivedContainerStyles}
          tabIndex={0}
        >
          <div data-testid="archived-content-title-container" css={archivedTitleContainerStyles}>
            <span css={archivedTitleStyles} data-testid="archived-content-title">
              {resource.entryTitle}
            </span>
          </div>
          <div data-testid="archived-content-date-container" css={archivedDateContainerStyles}>
            <span data-testid="archived-content-date" css={archivedDateStyles}>
              {timeConverter(resource.completionDate)}
            </span>
          </div>
          <div data-testid="archived-content-share-button-container" css={[archivedProgressBarContainerStyles]}>
            <PopupShare
              urlToShare=""
              alignToLeft={!isTablet}
              shareOnFb={false}
              shareOnLinkedin={false}
              showCopyLink={false}
              shareOnPdf={true}
              onGeneratePdf={() =>
                handleGeneratePdf(
                  resource.entryTitle,
                  resource.completionDate,
                  user?.name || '',
                  GeneratePdfContentType.ARCHIVED_CONTENT,
                )
              }
            />
          </div>
        </div>
      ))}
      {lessonsToShow.length < resources.length && (
        <div css={archivedShowMoreContainerStyles}>
          <Button label={showMoreLabel} appearance={ButtonAppearance.PRIMARY} onClick={showMoreRetiredContent} />
        </div>
      )}
    </section>
  );
};

const archivedSectionStyles = css({
  display: 'flex',
  flexFlow: 'wrap',
  paddingTop: token('space.600'),
  marginTop: token('space.600'),
  marginBottom: token('space.400'),

  [media.below.md]: {
    marginTop: token('space.400'),
    marginBottom: token('space.300'),
  },
});

const noResourcesSectionStyles = css({
  width: '100%',
  borderTop: `1px solid ${token('color.border')}`,
});

const archivedWrapperStyles = css({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  gap: token('space.075'),
  marginBottom: token('space.400'),
});

const archivedHeadingStyles = css({
  fontFamily: 'Charlie Display, sans-serif',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '32px',
  color: token('color.text'),
});

const archivedLabelContainerStyles = css({
  display: 'grid',
  gridTemplateColumns: 'repeat(13, 1fr)',
  gap: token('space.300'),
  width: '100%',
  paddingTop: '16px',
  paddingBottom: '16px',

  [media.below.md]: {
    display: 'none',
  },
  [media.below.xs]: {
    display: 'none',
  },
});

const archivedTitleLabelStyles = css({
  gridColumn: 'span 10',
  display: 'flex',
  alignItems: 'center',
});

const archivedDateLabelStyles = css({
  gridColumn: 'span 2',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
});

const archivedProgressLabelStyles = css({
  gridColumn: 'span 1',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
});

const archivedLabelStyles = css({
  color: token('color.text'),
  fontFamily: 'Charlie Text, sans-serif',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '16px',
  textTransform: 'uppercase',
});

const archivedContainerStyles = css({
  display: 'grid',
  gridTemplateColumns: 'repeat(13, 1fr)',
  gap: token('space.300'),
  width: '100%',
  padding: `${token('space.200')} 0`,
  borderBottom: `1px solid ${token('color.border')}`,

  '&:nth-of-type(3)': {
    borderTop: `1px solid ${token('color.border')}`,
  },

  [media.below.md]: {
    gridTemplateColumns: 'repeat(8, 1fr)',
  },

  [media.below.xs]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
});

const archivedTitleContainerStyles = css({
  gridColumn: 'span 10',
  paddingRight: token('space.300'),
  display: 'flex',
  alignItems: 'center',

  [media.below.md]: {
    gridColumn: 'span 8',
    order: 1,
    maxWidth: '512px',
  },

  [media.below.xs]: {
    gridColumn: 'span 8',
    order: 1,
    paddingRight: token('space.0'),
  },
});

const archivedProgressBarContainerStyles = css({
  gridColumn: 'span 1',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',

  [media.below.md]: {
    gridColumn: 'span 4',
    order: 2,
    maxWidth: '144px',
    textAlign: 'right',
    justifySelf: 'flex-start',
  },

  [media.below.xs]: {
    gridColumn: 'span 4',
    order: 2,
    textAlign: 'left',
    justifySelf: 'flex-start',
  },
});

const archivedDateContainerStyles = css({
  gridColumn: 'span 2',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',

  [media.below.md]: {
    gridColumn: 'span 4',
    order: 3,
    maxWidth: '144px',
    textAlign: 'right',
    justifySelf: 'flex-end',
  },

  [media.below.xs]: {
    gridColumn: 'span 4',
    order: 3,
    textAlign: 'right',
    justifySelf: 'flex-end',
  },
});

const archivedTitleStyles = css({
  color: token('color.text.subtle'),
  fontFamily: 'Inter, sans-serif',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
});

const archivedDateStyles = css({
  color: token('color.text.subtle'),
  fontFamily: 'Charlie Text, sans-serif',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '18px',
});

const archivedShowMoreContainerStyles = css({
  margin: `${token('space.400')} auto ${token('space.400')} auto`,
});
