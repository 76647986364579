import { Heading, Link } from '@atlassian/learning-components';
import { media } from '@atlaskit/primitives/responsive';
import { token } from '@atlaskit/tokens';
import { css } from '@compiled/react';
import { useNavigate } from 'react-router-dom';
import { FC } from 'react';

import { sendUIEvent } from 'src/utils/analytics';
import { useAtlassianUser } from 'src/hooks/useAtlassianUser';
import { getAssetUrl } from 'src/services/http/user';
import { links } from 'src/pages/landings/LiveTrainingPage/utils/utils';

export const HeroBanner: FC = () => {
  const { user } = useAtlassianUser();
  const navigate = useNavigate();

  const sendAnalyticsEvent = (type: string): void => {
    sendUIEvent('liveTrainingScreen', 'link', 'clicked', type, {}, user?.account_id);
  };

  return (
    <section css={cardsSectionStyles}>
      <div css={cardContainerStyles}>
        <img
          src={getAssetUrl('/images/liveTraining/teamTrainingHeroBanner.png')}
          alt="Team training session"
          css={imageCardStyles}
        />
        <div>
          <div css={cardHeadingContainerStyles}>
            <Heading level={3}>Team training</Heading>
          </div>
          <p css={cardTextBodyStyles}>
            Purchase a customized training for your team of 10-15 to accelerate adoption and improve engagement.
          </p>
          <div css={cardLinkContainerStyles}>
            <Link
              label="Learn more"
              link={links[0]}
              icon="arrow-right"
              fontColor={token('color.text')}
              onClick={() => {
                sendAnalyticsEvent('teamTrainingCardLink');
                navigate(links[0]);
              }}
            />
          </div>
        </div>
        <div css={teamTrainingCardStyles}></div>
      </div>
      <div css={[cardContainerStyles, middleCardContainerStyles]}>
        <img
          src={getAssetUrl('/images/liveTraining/ladyWithLaptop.png')}
          alt="Public classes"
          css={imageCardStyles}
          data-testid="lady-with-laptop-img"
        />
        <div>
          <div css={cardHeadingContainerStyles}>
            <Heading level={3}>Public classes</Heading>
          </div>
          <p css={cardTextBodyStyles}>
            Sign up individually for a class that works with your schedule, instructed by an Atlassian-authorized
            training partner.
          </p>
          <div css={cardLinkContainerStyles}>
            <Link
              label="Learn more"
              link={links[1]}
              icon="arrow-right"
              fontColor={token('color.text')}
              onClick={() => {
                sendAnalyticsEvent('publicClassesCardLink');
                window.open(links[1]);
              }}
            />
          </div>
        </div>
        <div css={publicClassesCardStyles}></div>
      </div>
      <div css={[cardContainerStyles, smCardContainerStyles]}>
        <img src={getAssetUrl('/images/liveTraining/communityLed.png')} alt="Community Led" css={imageCardStyles} />
        <div>
          <div css={cardHeadingContainerStyles}>
            <Heading level={3}>Community-led classes</Heading>
          </div>
          <p css={cardTextBodyStyles}>
            Learn from a Community expert — and passionate Atlassian power user — for free.
          </p>
          <div css={cardLinkContainerStyles}>
            <Link
              label="Learn more"
              icon="arrow-right"
              link={links[2]}
              fontColor={token('color.text')}
              onClick={() => {
                sendAnalyticsEvent('communityLedClassesCardLink');
                window.open(links[2]);
              }}
            />
          </div>
        </div>
        <div css={communityLedClassesCardStyles}></div>
      </div>
    </section>
  );
};

const cardsSectionStyles = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap',
  height: '755px',
  width: '100vw',
  backgroundColor: '#101214',

  [media.below.lg]: {
    height: '1040px',
  },

  [media.below.sm]: {
    flexDirection: 'column',
    height: '1442px',
  },
});

const cardHeadingContainerStyles = css({
  textAlign: 'center',
  marginBottom: token('space.300'),

  [media.below.lg]: {
    textAlign: 'left',
  },

  [media.below.sm]: {
    textAlign: 'center',
    marginBottom: token('space.100'),
  },
});

const cardContainerStyles = css({
  zIndex: 1,
  position: 'relative',
  display: 'grid',
  gridTemplateColumns: '1fr',
  alignItems: 'center',
  justifyContent: 'center',
  width: '328px',
  height: '520px',
  padding: `0 ${token('space.400')}`,
  marginTop: token('space.100'),
  marginBottom: token('space.100'),
  marginLeft: '9px',
  marginRight: '9px',

  [media.below.lg]: {
    gridTemplateColumns: '1fr 1fr',
    width: '720px',
    height: '304px',
  },

  [media.below.sm]: {
    gridTemplateColumns: '1fr',
    width: '328px',
    height: '398px',
    margin: '40px 0',
  },
});

const middleCardContainerStyles = css({
  marginTop: '-32px',

  [media.below.lg]: {
    marginTop: token('space.100'),
  },
});

const smCardContainerStyles = css({
  [media.below.sm]: {
    marginBottom: 0,
  },
});

const cardLinkContainerStyles = css({
  textAlign: 'center',
  marginBottom: '30px',

  [media.below.lg]: {
    textAlign: 'left',
    marginBottom: 0,
  },

  [media.below.sm]: {
    textAlign: 'center',
    marginBottom: '30px',
  },
});

const teamTrainingCardStyles = css({
  zIndex: -1,
  position: 'absolute',
  width: '100%',
  height: '100%',
  backgroundColor: '#BF63F3',
  clipPath: 'polygon(6px 6px, calc(100% - 5px) 0, 100% 100%, 0 100%)',

  [media.below.lg]: {
    clipPath: 'polygon(0 4px, 100% 0, 100% 100%, 7px calc(100% - 6px))',
  },

  [media.below.sm]: {
    clipPath: 'polygon(6px 4px, calc(100% - 5px) 0, 100% 100%, 0 100%)',
  },
});

const publicClassesCardStyles = css({
  zIndex: -1,
  position: 'absolute',
  width: '100%',
  height: '100%',
  backgroundColor: '#FFC716',
  clipPath: 'polygon(0 0, 100% 0, calc(100% - 8px) 100%, 0 100%)',

  [media.below.lg]: {
    clipPath: 'polygon(0 0, 100% 5px, calc(100% - 7px) calc(100% - 6px), 0 100%)',
  },

  [media.below.sm]: {
    clipPath: 'polygon(0 0, 100% 0, calc(100% - 8px) 100%, 0 100%)',
  },
});

const communityLedClassesCardStyles = css({
  zIndex: -1,
  position: 'absolute',
  width: '100%',
  height: '100%',
  backgroundColor: '#82B536',
  clipPath: 'polygon(0 0, 100% 0, calc(100% - 2px) calc(100% - 6px) , 0 100%)',

  [media.below.lg]: {
    clipPath: 'polygon(0 0, calc(100% - 7px) 5px, 100% calc(100% - 6px), 0 100%)',
  },

  [media.below.sm]: {
    clipPath: 'polygon(0 0, 100% 0, calc(100% - 2px) calc(100% - 4px), 0 100%)',
  },
});

const imageCardStyles = css({
  width: '280px',
  height: 'auto',
  marginTop: '-80px',

  [media.below.lg]: {
    marginTop: 0,
    marginBottom: 0,
  },

  [media.below.sm]: {
    marginTop: '-60px',
  },
});

const cardTextBodyStyles = css({
  fontFamily: 'Inter, sans-serif',
  fontSize: '20px',
  fontWeight: 400,
  lineHeight: '32px',
  marginBottom: token('space.400'),
  textAlign: 'center',

  [media.below.lg]: {
    textAlign: 'left',
  },

  [media.below.sm]: {
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
    marginBottom: token('space.300'),
  },
});
