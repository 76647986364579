import React, { useMemo } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Heading, Button, ButtonAppearance } from '@atlassian/learning-components';
import { css } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { media } from '@atlaskit/primitives/responsive';

import { BGColors } from 'src/pages/LessonPage/utils/utils';
import { ScrollBehaviorTypes, setScrollBehaviour } from 'src/utils/lessonScrollBehavior';
import { ContentfulSmCourse } from 'src/graphql/queries/GetSmCourse';
import { ContentfulSmLesson } from 'src/graphql/queries/GetSmLesson';
import { getNextLesson } from 'src/pages/LessonPage/children/utils/nextLessonUtils';
import { LearningProgressStatus, ProgressForLessons } from 'src/services/http/progress';
import { QuickFeedbackComponent } from 'src/pages/LessonPage/children/QuickFeedbackComponent';
import { isStandalone } from 'src/pages/LessonPage/utils/isStandalone';
import { getLearningPathPart, getLearningPathsHubPart } from 'src/utils/pathUtils';

interface NextLessonProps {
  courseSlug: string;
  course: ContentfulSmCourse;
  lesson: ContentfulSmLesson;
  nextLessonBackground: BGColors;
  progress?: ProgressForLessons;
}

export const NextLesson: React.FC<NextLessonProps> = ({
  courseSlug,
  course,
  lesson,
  nextLessonBackground,
  progress,
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const isStandaloneLesson = isStandalone();

  const nextLessonForAuthorisedUser = useMemo(() => {
    if (!course || !lesson || !progress) {
      return;
    }

    const currentLessonIndex = course.lessonsCollection.items.findIndex((l) => l.sys.id === lesson?.sys.id);

    const lessonBefore = course.lessonsCollection.items.find(
      (l) => progress.lessons.find((p) => p.lessonId === l.sys.id)?.status !== LearningProgressStatus.COMPLETED,
    );
    const lessonAfter = course.lessonsCollection.items.find(
      (l, index) =>
        index > currentLessonIndex &&
        progress.lessons.find((p) => p.lessonId === l.sys.id)?.status !== LearningProgressStatus.COMPLETED,
    );

    return lessonAfter || lessonBefore;
  }, [course, lesson, progress]);

  const nextLessonForUnauthorisedUser = useMemo(() => getNextLesson(course, lesson), [course, lesson]);

  const isLastIncompleteLesson = useMemo(() => {
    if (!course || !lesson || !progress) {
      return;
    }

    const allLessonsExceptCurrent = course.lessonsCollection.items.filter((l) => l.sys.id !== lesson.sys.id);
    const isCurrentLessonLastOne = allLessonsExceptCurrent.every(
      (l) => progress.lessons.find((p) => p.lessonId === l.sys.id)?.status === LearningProgressStatus.COMPLETED,
    );

    return isCurrentLessonLastOne;
  }, [course, lesson, progress]);

  const areAllLessonsCompleted = useMemo(() => {
    if (!progress || !course) {
      return;
    }

    return course.lessonsCollection.items.every(
      (l) => progress.lessons.find((p) => p.lessonId === l.sys.id)?.status === LearningProgressStatus.COMPLETED,
    );
  }, [course, progress]);

  const onNextLessonClick = (): void => {
    const learningPathSlug = params?.pathId;
    const learningPathPart = getLearningPathPart(learningPathSlug);
    const allLearningPathsHubPart = getLearningPathsHubPart(location.pathname);

    // to prevent lesson auto completion
    setScrollBehaviour(ScrollBehaviorTypes.UNSET);
    nextLesson?.slug &&
      navigate(`${allLearningPathsHubPart}${learningPathPart}/course/${courseSlug}/lesson/${nextLesson.slug}`);
  };

  const nextLesson = nextLessonForAuthorisedUser || nextLessonForUnauthorisedUser;
  const isNextLessonAvailable = nextLesson && !isLastIncompleteLesson && !areAllLessonsCompleted;

  if (!isNextLessonAvailable) {
    return (
      <section
        css={sectionContainerStyles}
        style={{ backgroundColor: nextLessonBackground }}
        data-testid="quick-feedback-section"
      >
        <div css={quickFeedbackContainer}>
          <QuickFeedbackComponent
            shouldShowDivider={false}
            key={lesson.slug}
            lessonSlug={lesson.slug}
            courseSlug={course.slug}
          />
        </div>
      </section>
    );
  }

  return (
    <section css={sectionContainerStyles} id="next-lesson-section" style={{ backgroundColor: nextLessonBackground }}>
      <div css={quickFeedbackContainer} data-testid="quick-feedback-section">
        <QuickFeedbackComponent key={lesson.slug} lessonSlug={lesson.slug} courseSlug={course.slug} />
      </div>
      {!isStandaloneLesson && (
        <div css={nextSectionContainer} data-testid="next-lesson-section">
          <p css={nextLessonHeading}>next lesson</p>
          <div css={css({ marginBottom: '16px' })}>
            <Heading level={3}>{nextLesson?.title || 'No more lessons in this course'}</Heading>
          </div>
          <ul css={lessonObjectivesStyles}>
            {nextLesson &&
              nextLesson.sectionListCollection.items.map((section) => (
                <li css={css({ marginLeft: '24px' })} key={section.id}>
                  {section.title}
                </li>
              ))}
          </ul>
          <div css={nextLessonButtonStyles}>
            <Button label="Go to next lesson" appearance={ButtonAppearance.PRIMARY} onClick={onNextLessonClick} />
          </div>
        </div>
      )}
    </section>
  );
};

const sectionContainerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: token('space.1000'),
  paddingBottom: token('space.1000'),
  paddingLeft: token('space.250'),
  paddingRight: token('space.250'),
});

const quickFeedbackContainer = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  maxWidth: '608px',
  width: '100%',
});

const nextSectionContainer = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  maxWidth: '608px',
  width: '100%',
});

const nextLessonHeading = css({
  fontFamily: 'Charlie Text, sans-serif',
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '18px',
  color: token('color.text.subtle'),
  marginBottom: '16px',
  textTransform: 'uppercase',
});

const lessonObjectivesStyles = css({
  fontFamily: 'Inter, sans-serif',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  color: token('color.text'),
  listStyleType: 'disc',
  marginTop: 0,
  marginBottom: token('space.500'),
  paddingLeft: 0,
});

const nextLessonButtonStyles = css({
  marginLeft: 'auto',

  [media.below.sm]: {
    marginRight: 'auto',
    marginLeft: 0,
  },
});
