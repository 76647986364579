import { useSearchParams } from 'react-router-dom';

interface QueryParamsProps {
  setQueryParam: (key: string, value: string) => void;
  removeQueryParam: (key: string, value: string) => void;
  clearAllQueryParams: () => void;
}

export const useQueryParams = (): QueryParamsProps => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setQueryParam = (key: string, value: string): void => {
    let values = [];

    if (searchParams.has(key)) {
      values = searchParams.getAll(key);

      if (!values.includes(value)) {
        values.push(value);
      }
    } else {
      values = [value];
    }

    const newSearchParams = new URLSearchParams(searchParams);

    newSearchParams.set(key, values.join(','));

    setSearchParams(newSearchParams);
  };

  const removeQueryParam = (key: string, value: string): void => {
    if (searchParams.has(key)) {
      let values = searchParams.getAll(key)[0].split(',');

      values = values.filter((v) => v !== value);

      const newSearchParams = new URLSearchParams(searchParams);

      if (values.length > 0) {
        newSearchParams.set(key, values.join(','));
      } else {
        newSearchParams.delete(key);
      }

      setSearchParams(newSearchParams);
    }
  };

  const clearAllQueryParams = (): void => {
    const newSearchParams = new URLSearchParams();

    setSearchParams(newSearchParams);
  };

  return { setQueryParam, removeQueryParam, clearAllQueryParams };
};
