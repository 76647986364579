import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Icon, Modal, QuestionStatus, QuestionType, Quiz as QuizComponent } from '@atlassian/learning-components';
import { useParams } from 'react-router-dom';
import { ModalTransition } from '@atlaskit/modal-dialog';
import Spinner from '@atlaskit/spinner';
import { css } from '@compiled/react';

import { User } from 'src/types';
import { ContentfulSmLesson, ContentfulSmQuestion } from 'src/graphql/queries/GetSmLesson';
import { ContentfulSmCourse } from 'src/graphql/queries/GetSmCourse';
import { getInspectModeData } from 'src/utils/contentful';
import { sendUIEvent } from 'src/utils/analytics';
import { getAssetUrl, loginUser } from 'src/services/http/user';
import { usePreviewMode } from 'src/hooks/usePreviewMode';
import {
  getOrCreateProgressForQuiz,
  LearningProgressStatus,
  ProgressForCourses,
  ProgressForLessons,
  saveProgressForLesson,
} from 'src/services/http/progress';

import {
  completeLessonAsync,
  getMatchingQuestions,
  getUncompletedQuestionsAndStepAsync,
  handleAnswerSubmit,
  handleInteraction,
  handleUnload,
  sendCompletionAnalytics,
  updateLearningPathProgressUponLessonCompletion,
  updateProgressUponLessonCompletion,
} from './utils/quizUtils';
import { useLoginModalFlag } from './hooks/useLoginModalFlag';

export interface QuizProps {
  lesson: ContentfulSmLesson;
  course: ContentfulSmCourse;
  user?: User | null;
  quizStatus: QuizStatus;
  onLessonCompletion: () => void;
  progress?: ProgressForLessons;
  onProgressChange?: (progress: ProgressForLessons) => void;
  learningPathProgress?: ProgressForCourses;
  onLearningPathChange?: (progress: ProgressForCourses) => void;
  isLearningPathProgressLoading?: boolean;
  onQuizStatusChange: (status: QuizStatus) => void;
}

export enum QuizStatus {
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  UNINITIALIZED = 'UNINITIALIZED',
}

export const Quiz: React.FC<QuizProps> = ({
  lesson,
  course,
  user,
  quizStatus,
  onQuizStatusChange,
  onLessonCompletion,
  progress,
  onProgressChange,
  learningPathProgress,
  onLearningPathChange,
  isLearningPathProgressLoading,
}) => {
  const [quizStep, setQuizStep] = useState(0);
  const [quizQuestions, setQuizQuestions] = useState<ContentfulSmQuestion[]>([]);
  const preview = usePreviewMode();
  const { courseId, lessonId } = useParams();
  const quizAbandonedAnalyticsAttributes = useRef<null | Record<string, string | number | undefined>>(null);
  const quizInteractionAnalytics = useRef<null | {
    lessonId?: string;
    startTimeMs: number;
    notStarted: boolean;
    isDirty: boolean;
  }>(null);

  const questions = useMemo(
    () => getMatchingQuestions(quizQuestions, preview, lesson, user),
    [quizQuestions, preview, lesson, user],
  );

  useEffect(() => {
    if (!lesson.quiz || quizStatus !== QuizStatus.UNINITIALIZED) {
      return;
    }

    const fetchQuizProgressAndSetQuestions = async (): Promise<void> => {
      const lessonProgress = progress?.lessons.find((p) => p.lessonId === lesson.sys.id);
      const isNewLessonForUser = !lessonProgress || lessonProgress.status === LearningProgressStatus.NOT_STARTED;

      if (user && progress && isNewLessonForUser) {
        // obviously, it is an SQS call, so no guaranty progress for lesson
        // will be created earlier than progress for quiz
        // but let's at least try
        await saveProgressForLesson({
          atlassianId: user.account_id,
          lesson: {
            lessonId: lesson.sys.id,
            status: LearningProgressStatus.IN_PROGRESS,
            startedDate: new Date(Date.now()).toISOString().split('.')[0],
          },
        });
      }

      const quizProgress = user && (await getOrCreateProgressForQuiz(lesson.sys.id, lesson.quiz.sys.id));

      if (quizProgress?.status === LearningProgressStatus.COMPLETED) {
        // safeguard for the case when lesson for some reason did not complete upon quiz completion
        if (progress?.lessons.find((p) => p.lessonId === lesson.sys.id)?.status !== LearningProgressStatus.COMPLETED) {
          completeLessonAsync(user, lesson, progress);
        }

        onQuizStatusChange(QuizStatus.COMPLETED);
        setQuizQuestions(lesson?.quiz?.questionsCollection.items ?? []);

        const updatedLessonProgress = updateProgressUponLessonCompletion(course, lesson, progress);

        onProgressChange?.(updatedLessonProgress);

        if (!isLearningPathProgressLoading && onLearningPathChange) {
          onLearningPathChange(
            updateLearningPathProgressUponLessonCompletion(course, updatedLessonProgress, learningPathProgress),
          );
        }

        return;
      }

      const { questions, step, isClean } = await getUncompletedQuestionsAndStepAsync(user, lesson);

      onQuizStatusChange(QuizStatus.IN_PROGRESS);

      setQuizStep(step === -1 ? 0 : step + 1);
      setQuizQuestions(questions);

      if (isClean) {
        quizInteractionAnalytics.current = {
          lessonId: lesson.sys.id,
          startTimeMs: 0,
          notStarted: true,
          isDirty: false,
        };
      }
    };

    fetchQuizProgressAndSetQuestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course, lesson, onProgressChange, onQuizStatusChange, progress, quizStatus, user, isLearningPathProgressLoading]);

  useEffect(() => {
    onQuizStatusChange(QuizStatus.UNINITIALIZED);
    setQuizQuestions([]);
    setQuizStep(0);

    quizInteractionAnalytics.current = {
      lessonId: lesson.sys.id,
      startTimeMs: quizInteractionAnalytics.current ? quizInteractionAnalytics.current?.startTimeMs : 0,
      notStarted: false,
      isDirty: false,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lesson.sys.id, onQuizStatusChange]);

  const onLoginButtonClick = useCallback((): void => {
    sendUIEvent('lessonScreen', 'button', 'clicked', 'signInButton', { objectType: 'modal' });
    loginUser();
  }, []);

  const { isLoginModalVisible, handleUserLogin, onCloseButtonClick } = useLoginModalFlag(user);

  const onQuizCompletedChange = (completed: boolean): void => {
    onQuizStatusChange(completed ? QuizStatus.COMPLETED : QuizStatus.IN_PROGRESS);
  };

  useEffect(() => {
    const handleUnloadWithParams = handleUnload.bind(null, {
      lesson,
      user,
      quizInteractionAnalytics,
      quizAbandonedAnalyticsAttributes,
    });

    window.addEventListener('beforeunload', handleUnloadWithParams);

    return () => {
      handleUnloadWithParams();

      window.removeEventListener('beforeunload', handleUnloadWithParams);
    };
    // run only on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLessonCompletion = useCallback(async () => {
    if (!preview && progress && onProgressChange) {
      await completeLessonAsync(user, lesson, progress);

      const updatedLessonProgress = updateProgressUponLessonCompletion(course, lesson, progress);

      if (!isLearningPathProgressLoading && onLearningPathChange) {
        onLearningPathChange(
          updateLearningPathProgressUponLessonCompletion(course, updatedLessonProgress, learningPathProgress),
        );
      }

      sendCompletionAnalytics(course, lesson, progress, user, quizInteractionAnalytics.current);
      onLessonCompletion();
      onProgressChange(updatedLessonProgress);
    }

    onQuizStatusChange(QuizStatus.COMPLETED);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    courseId,
    lessonId,
    course,
    lesson,
    user,
    preview,
    onLessonCompletion,
    onQuizStatusChange,
    quizInteractionAnalytics,
    progress,
    onProgressChange,
    isLearningPathProgressLoading,
  ]);

  useEffect(() => {
    handleUnload({
      lesson: lesson,
      user: user,
      quizInteractionAnalytics: quizInteractionAnalytics,
      quizAbandonedAnalyticsAttributes: quizAbandonedAnalyticsAttributes,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessonId, handleUnload]);

  useEffect(() => {
    const quizCompletionPercentage = Math.round((quizStep / quizQuestions.length) * 100);

    if (user && quizStatus === QuizStatus.IN_PROGRESS && quizCompletionPercentage > 0) {
      quizAbandonedAnalyticsAttributes.current = {
        courseSlug: courseId,
        lessonSlug: lessonId,
        quizCompletionPercentage,
      };
    }
  }, [user, courseId, lessonId, quizStep, quizQuestions, quizStatus]);

  const isQuizLoaded = quizStatus !== QuizStatus.UNINITIALIZED && lessonId === lesson.slug && !!quizQuestions.length;

  return (
    <>
      {isQuizLoaded ? (
        <QuizComponent
          lessonId={lessonId}
          isLoggedInUser={!!user}
          handleUserLogin={handleUserLogin}
          inspectMode={getInspectModeData(lesson.quiz, ['title'])}
          title={lesson.quiz.title}
          onCompletion={handleLessonCompletion}
          isCompleted={quizStatus === QuizStatus.COMPLETED}
          setIsCompleted={onQuizCompletedChange}
          step={quizStep}
          setStep={setQuizStep}
          questions={questions}
          onAnswerSubmit={(type: QuestionType, status: QuestionStatus) =>
            handleAnswerSubmit(type, status, courseId, lessonId, quizStep, user)
          }
          onInteraction={handleInteraction.bind(null, quizInteractionAnalytics, quizStatus, lesson)}
        />
      ) : (
        <div css={css({ display: 'flex', justifyContent: 'center' })}>
          <Spinner size="large" />
        </div>
      )}
      {isLoginModalVisible && (
        <ModalTransition>
          <Modal
            heading="Get the credit you deserve"
            subheading={
              <p>
                Log in with your Atlassian account to track your progress, access your learning history, and earn badges
                as you go.
              </p>
            }
            imageLink={getAssetUrl('/images/badgeModalImage.svg')}
            closeModal={onCloseButtonClick}
            actionButton={{
              label: 'Log in',
              onClick: onLoginButtonClick,
              iconBefore: <Icon type="atlassian-logo" />,
            }}
            width="large"
          />
        </ModalTransition>
      )}
    </>
  );
};
