import { Field } from '@atlassian/learning-components';

import { Taglist } from 'src/components/TagList';
import { ContentfulSmCourse } from 'src/graphql/queries/GetSmCourse';

export const remapCourseTaxonomy = (course: ContentfulSmCourse, duration: string): Field[] => [
  {
    name: 'duration',
    value: duration,
  },
  {
    name: 'role',
    value: course.role ? course.role : '',
  },
  {
    name: 'level',
    value: course.learningLevel,
  },
  {
    name: 'product',
    value: Taglist(course.product),
  },
];
