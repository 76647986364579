import React, { useContext, useEffect, useMemo } from 'react';
import { css } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import {
  Accordion,
  AccordionSize,
  AccordionTabProps,
  FlatContentCard,
  FlatContentCardType,
  Heading,
  useDeviceSize,
  Video,
} from '@atlassian/learning-components';
import { media } from '@atlaskit/primitives/responsive';
import { useLoaderData } from 'react-router-dom';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import { HelmetContext } from 'src/components/Helmet';
import { getOptions } from 'src/pages/LessonPage/children/utils/getOptions';
import { useAtlassianUser } from 'src/hooks/useAtlassianUser';
import { sendScreenEvent, sendUIEvent } from 'src/utils/analytics';
import { getAssetUrl } from 'src/services/http/user';
import { getImageAttrs, getVideoAttrs, getVideoCaptions } from 'src/utils/contentful';
import { useNavigateByHost } from 'src/hooks/useNavigateByHost';

import { CertificationType } from './children/CertificationType';
import { CertificationBenefit } from './children/CertificationBenefit';
import { CardDivider } from './children/CardDivider';
import { CertificationHubLoaderProps } from './loader';
import { getContentContainers } from './utils/utils';

export const CertificationPage: React.FC = () => {
  const helmet = useContext(HelmetContext);
  const { user, isLoading, hasError } = useAtlassianUser();
  const navigateByHost = useNavigateByHost();
  const deviceWidth = useDeviceSize();

  const { certificationHubData } = useLoaderData() as CertificationHubLoaderProps;
  const { titleExternal, seo, hero, contentContainerCollection } = certificationHubData;

  helmet.setHelmet({
    title: `${titleExternal} | Learning - Atlassian Community`,
    seoTags: seo,
  });

  const {
    benefitsContainer,
    statistic,
    getCertifiedSection,
    certifiedAssociateSection,
    certifiedProfessionalSection,
    video,
    socialProof,
    readyGetStartedSection,
    resource,
    faq,
  } = getContentContainers(contentContainerCollection.items);

  const videoAttrs = useMemo(() => getVideoAttrs(video), [video]);
  const captions = useMemo(
    () => getVideoCaptions(videoAttrs?.cdnCaptions, videoAttrs?.brandfolderCaptions),
    [videoAttrs?.brandfolderCaptions, videoAttrs?.cdnCaptions],
  );

  const customerHeadshotAttrs = useMemo(
    () => getImageAttrs(socialProof?.customerHeadshot),
    [socialProof?.customerHeadshot],
  );
  const statisticAssetAttrs = useMemo(() => getImageAttrs(statistic?.asset), [statistic?.asset]);

  const tabsContent: AccordionTabProps[] = faq?.questionsCollection?.items.map(({ titleExternal, answer }) => ({
    title: titleExternal,
    isExpanded: false,
    children: documentToReactComponents(answer?.json, getOptions(answer, deviceWidth)),
  }));

  useEffect(() => {
    if (!isLoading && !hasError) {
      sendScreenEvent('certificationHubScreen', {}, user?.account_id);
    }
  }, [user, isLoading, hasError]);

  return (
    <main css={mainSectionStyles} data-testid="certification-body" id="certification-body">
      <section css={headerStyles}>
        <div css={headerTextWrapperStyles}>
          <span css={eyeBrowTextStyles}>{hero?.eyebrowText}</span>
          <div css={titleWrapperStyles}>
            <Heading level={0}>{hero?.titleExternal}</Heading>
          </div>
          <span css={subtitleStyles}>{hero?.subtitle}</span>
        </div>
      </section>
      <div css={headerImageWrapperStyles}>
        <img
          css={heroBannerStyles}
          src={getAssetUrl('/images/hubs/certificationPageHeroBanner.svg')}
          alt="hero banner"
        />
      </div>
      {benefitsContainer && (
        <section css={benefitsSectionStyles}>
          <Heading level={2}>{benefitsContainer.titleExternal}</Heading>
          <div css={benefitCardsContainerStyles}>
            {benefitsContainer.benefitsCollection.items.map(({ titleExternal, description, asset }) => (
              <CertificationBenefit
                key={titleExternal}
                image={getImageAttrs(asset)}
                title={titleExternal}
                subtitle={documentToReactComponents(description?.json, getOptions(description, deviceWidth))}
              />
            ))}
          </div>
        </section>
      )}
      {statistic && (
        <section css={percentageMessageContainerStyles}>
          <div css={percentageMessageStyles}>
            {documentToReactComponents(
              statistic.statDescription?.json,
              getOptions(statistic.statDescription, deviceWidth),
            )}
          </div>
          <img css={percentageImage} src={statisticAssetAttrs?.src} alt={statisticAssetAttrs?.alt} />
        </section>
      )}
      {getCertifiedSection && (
        <section css={getCertifiedContainerStyles}>
          <div css={getCertifiedTextContainerStyles}>
            <div css={getCertifiedTextWrapperStyles}>
              <Heading level={2}>{getCertifiedSection.titleExternal}</Heading>
              <p css={getCertifiedTextStyles}>{getCertifiedSection.subtitle}</p>
            </div>
          </div>
          <div>
            <CertificationType
              image={getImageAttrs(certifiedAssociateSection.asset)}
              title={certifiedAssociateSection.titleExternal}
              contentText={certifiedAssociateSection?.subtitle}
              expandItemsList={certifiedAssociateSection.associatedContentResourcesCollection.items}
            />
            <CertificationType
              image={getImageAttrs(certifiedProfessionalSection.asset)}
              title={certifiedProfessionalSection.titleExternal}
              contentText={certifiedProfessionalSection?.subtitle}
              expandItemsList={certifiedProfessionalSection.associatedContentResourcesCollection.items}
            />
          </div>
        </section>
      )}
      {(video || socialProof) && (
        <section css={videoSectionsStyles}>
          {videoAttrs && (
            <div css={videoContainerStyles}>
              <div css={videoWrapperStyles}>
                <Video
                  name={video.name}
                  videoId={video.sys.id}
                  captions={captions}
                  controls
                  width={video?.width || 'auto'}
                  height={video?.height || 'auto'}
                  brandfolderUrl={videoAttrs.brandfolderUrl}
                  poster={videoAttrs.brandfolderUrl || undefined}
                  vimeoUrl={videoAttrs.vimeoUrl}
                  youtubeUrl={videoAttrs.youtubeUrl}
                  onControlClick={(type) =>
                    sendUIEvent(
                      'certificationHubScreen',
                      'button',
                      'clicked',
                      ['play', 'pause'].includes(type) ? 'videoPlayButton' : 'videoCaptionsButton',
                      {
                        name: video.name,
                        videoId: video.sys.id,
                        type,
                      },
                      user?.account_id,
                    )
                  }
                />
              </div>
              <img css={topBlueShapeStyles} src={getAssetUrl('/images/hubs/blueShape1.svg')} alt="blue shape image" />
            </div>
          )}
          {socialProof && (
            <div css={quoteContainerStyles}>
              <div css={quoteWrapperStyles}>
                {customerHeadshotAttrs && (
                  <img css={managerPhotoStyles} src={customerHeadshotAttrs.src} alt={customerHeadshotAttrs.alt} />
                )}
                <div css={managerTextContainerStyles}>
                  <div css={managerTextStyles}>
                    {documentToReactComponents(socialProof.quote?.json, getOptions(socialProof.quote, deviceWidth))}
                  </div>
                  <div css={managerNameStyles}>
                    <Heading level={4}>{socialProof.customerName}</Heading>
                  </div>
                  <span css={managerPositionStyles}>{socialProof.customerRole}</span>
                </div>
              </div>
            </div>
          )}
          <img css={bottomBlueShapeStyles} src={getAssetUrl('/images/hubs/blueShape2.svg')} alt="blue shape image" />
        </section>
      )}
      {readyGetStartedSection && (
        <section css={readyToGetStartedStyles}>
          <Heading level={2}>{readyGetStartedSection.titleExternal}</Heading>
          <div css={readyToGetStartedCardsStyles}>
            {readyGetStartedSection.associatedContentResourcesCollection.items.map(
              ({ _id, link, titleExternal, asset, description }) => (
                <FlatContentCard
                  bgColor={token('color.background.input')}
                  key={_id}
                  headingLevel={3}
                  type={FlatContentCardType.SQUARE}
                  link={link.link}
                  linkLabel={link.label}
                  onClick={(title) => {
                    navigateByHost(link.link);
                    sendUIEvent(
                      'certificationHubScreen',
                      'link',
                      'clicked',
                      'cardLink',
                      {
                        contentBlockTitle: readyGetStartedSection.titleExternal,
                        cardTitle: title,
                      },
                      user?.account_id,
                    );
                  }}
                  title={titleExternal}
                  text={description}
                  image={getImageAttrs(asset)}
                />
              ),
            )}
          </div>
        </section>
      )}
      {resource && (
        <section css={certificationDesignationsStyles} data-testid="certification-designations">
          <FlatContentCard
            title={resource.titleExternal}
            text={resource.description}
            onClick={(title) => {
              navigateByHost(resource.link);
              sendUIEvent(
                'certificationHubScreen',
                'link',
                'clicked',
                'cardLink',
                {
                  cardTitle: title,
                },
                user?.account_id,
              );
            }}
            link={resource.link}
            linkLabel={resource.ctaText}
            headingLevel={2}
          />
          <CardDivider />
        </section>
      )}
      {faq && (
        <section css={certificationsFaqsStyles}>
          <div css={certificationsFaqsTitleStyles}>
            <Heading level={2}>{faq.titleExternal}</Heading>
          </div>
          <Accordion
            tabsContent={tabsContent}
            size={AccordionSize.DEFAULT}
            shouldSingleTabBeOpen={true}
            onChange={(tabState: { [key: number]: string } | undefined) => {
              if (tabState) {
                const [[tabIndex, state]] = Object.entries(tabState);

                sendUIEvent(
                  'certificationHubScreen',
                  'accordion',
                  'clicked',
                  'faqAccordionComponent',
                  {
                    name: faq.nameInternal,
                    type: state,
                    tabIndex,
                  },
                  user?.account_id,
                );
              }
            }}
          />
        </section>
      )}
    </main>
  );
};

const mainSectionStyles = css({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '136px',

  '@media (max-width: 1279px)': {
    marginTop: '0',
  },
});

const headerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  paddingTop: token('space.600'),
  paddingBottom: token('space.500'),

  [media.below.md]: {
    paddingRight: token('space.300'),
    paddingLeft: token('space.300'),
  },

  [media.below.sm]: {
    padding: `${token('space.600')} ${token('space.200')} ${token('space.300')} !important`,
  },
});

const titleWrapperStyles = css({
  margin: `${token('space.200')} 0`,
});

const headerTextWrapperStyles = css({
  maxWidth: '720px',
});

const eyeBrowTextStyles = css({
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '18px',
  color: token('color.text.subtle'),
  textTransform: 'uppercase',
  marginBottom: token('space.200'),
});

const subtitleStyles = css({
  fontSize: '20px',
  fontFamily: 'Inter, sans-serif',
  fontWeight: 400,
  lineHeight: '32px',
  marginTop: token('space.200'),

  [media.below.sm]: {
    fontSize: '16px',
    lineHeight: '24px',
  },
});

const headerImageWrapperStyles = css({
  width: '100%',
  backgroundClip: 'content-box',
});

const heroBannerStyles = css({
  width: '100%',
  minHeight: '375px',

  [media.below.lg]: {
    minHeight: '200px',
  },

  [media.below.sm]: {
    minHeight: '97px',
  },
});

const benefitsSectionStyles = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  padding: token('space.800'),

  [media.below.md]: {
    paddingRight: token('space.300'),
    paddingLeft: token('space.300'),
  },

  [media.below.sm]: {
    padding: `${token('space.400')} ${token('space.200')} ${token('space.600')} !important`,
    textAlign: 'center',
  },
});

const benefitCardsContainerStyles = css({
  display: 'flex',
  justifyContent: 'center',
  marginTop: token('space.600'),
  width: '100%',

  [media.below.md]: {
    flexDirection: 'column',
    marginTop: token('space.400'),
  },

  [media.below.sm]: {
    marginTop: token('space.300'),
  },
});

const percentageMessageContainerStyles = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  padding: '55px 120px 70px',
  margin: '0 90px',
  backgroundColor: '#FCA700',
  clipPath: 'polygon(0 0, 100% 10px, calc(100% - 21px) calc(100% - 9px), 9px 100%)',

  [media.below.md]: {
    padding: token('space.1000'),
    paddingTop: '70px',
    margin: `0 ${token('space.300')}`,
    textAlign: 'center',
    flexDirection: 'column-reverse',
    clipPath: 'polygon(0 0, 100% 14px, calc(100% - 12px) calc(100% - 13px), 5px 100%)',
  },

  [media.below.sm]: {
    clipPath: 'polygon(0 0, 100% 14px, 100% calc(100% - 13px), 0 100%)',
    margin: '0 !important',
    padding: `${token('space.600')} ${token('space.200')} 56px !important`,
  },
});

const percentageMessageStyles = css({
  paddingTop: token('space.150'),
  marginRight: '108px',

  [media.below.md]: {
    marginRight: 0,
  },

  'span:last-of-type': {
    fontFamily: 'Charlie Text, sans-serif',
    fontSize: '36px',
    lineHeight: '48px',
    display: 'inline-block',
    maxWidth: '670px',

    [media.below.md]: {
      marginRight: 0,
    },

    [media.below.sm]: {
      fontSize: '32px',
      lineHeight: '40px',
    },
  },
});

const percentageImage = css({
  height: '260px',
  width: 'auto',
  maxWidth: '100%',

  [media.below.lg]: {
    height: '220px',
    marginBottom: token('space.300'),
  },
  [media.below.sm]: {
    height: '200px',
  },
});

const getCertifiedContainerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '104px',
  width: '100%',

  [media.below.md]: {
    paddingTop: token('space.1000'),
  },

  [media.below.sm]: {
    paddingTop: '56px !important',
  },
});

const getCertifiedTextContainerStyles = css({
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'center',

  [media.below.md]: {
    padding: `0 ${token('space.300')}`,
  },

  [media.below.sm]: {
    padding: `0 ${token('space.200')} !important`,
  },
});

const getCertifiedTextWrapperStyles = css({
  maxWidth: '1020px',
  width: '100%',
  borderBottom: `1px solid ${token('color.border')}`,
});

const getCertifiedTextStyles = css({
  marginTop: token('space.200'),
  marginBottom: token('space.500'),
  fontSize: '20px',
  lineHeight: '32px',
  fontFamily: 'Inter, sans-serif',

  [media.below.sm]: {
    marginBottom: token('space.400'),
    fontSize: '16px',
    lineHeight: '24px',
  },
});

const videoSectionsStyles = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '104px',
  width: '100%',

  [media.below.md]: {
    marginTop: token('space.1000'),
  },
});

const videoContainerStyles = css({
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
  width: '100%',
});

const videoWrapperStyles = css({
  maxWidth: '720px',
  width: '100%',

  [media.below.sm]: {
    maxWidth: '343px',
  },
});

const topBlueShapeStyles = css({
  position: 'absolute',
  bottom: 0,
  zIndex: '-1',
  width: '100%',
});

const quoteContainerStyles = css({
  backgroundColor: '#CFE1FD',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
});

const quoteWrapperStyles = css({
  display: 'flex',
  justifyContent: 'center',
  padding: '104px 210px',
  marginTop: '-1px',
  marginBottom: '-1px',
  width: '100%',
  maxWidth: '1440px',

  [media.below.md]: {
    flexDirection: 'column',
    padding: `${token('space.500')} ${token('space.300')} ${token('space.1000')}`,
    alignItems: 'center',
  },

  [media.below.sm]: {
    padding: `${token('space.500')} ${token('space.200')} !important`,
  },
});

const managerPhotoStyles = css({
  marginRight: token('space.600'),
  width: 'auto',
  maxWidth: '100%',
  height: '404px',

  [media.below.md]: {
    height: '333px',
    marginRight: 0,
    marginBottom: token('space.300'),
  },

  [media.below.sm]: {
    height: '252px',
  },
});

const managerTextContainerStyles = css({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
});

const managerTextStyles = css({
  'span:last-of-type': {
    fontSize: '36px',
    fontWeight: 400,
    lineHeight: '48px',
    fontFamily: 'Charlie Text, sans-serif',

    [media.below.sm]: {
      fontSize: '32px',
      lineHeight: '40px',
    },
  },
});

const managerNameStyles = css({
  marginTop: token('space.300'),
  marginBottom: token('space.100'),

  [media.below.sm]: {
    fontSize: '20px',
    lineHeight: '28px',
  },
});

const managerPositionStyles = css({
  fontSize: '16px',
  lineHeight: '24px',
  fontFamily: 'Inter, sans-serif',
});

const bottomBlueShapeStyles = css({
  width: '100%',
  backgroundColor: '#F8F8F8',
  zIndex: -1,
});

const readyToGetStartedStyles = css({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  padding: `56px 210px ${token('space.1000')}`,
  backgroundColor: '#F8F8F8',
  width: '100%',

  [media.below.md]: {
    paddingRight: token('space.300'),
    paddingLeft: token('space.300'),
  },

  [media.below.sm]: {
    padding: `${token('space.500')} ${token('space.200')} !important`,
  },
});

const readyToGetStartedCardsStyles = css({
  display: 'flex',
  marginTop: token('space.500'),
  gap: '0 30px',

  [media.below.md]: {
    display: 'grid',
    gap: token('space.300'),
    gridTemplateColumns: 'repeat(2, 1fr)',
  },

  [media.below.sm]: {
    gridTemplateColumns: 'auto !important',
    gap: token('space.200'),
  },
});

const certificationDesignationsStyles = css({
  padding: `${token('space.600')} 210px ${token('space.1000')}`,
  maxWidth: '1440px',

  'span:not(a span)': {
    fontSize: '20px',
    lineHeight: '32px',

    [media.below.sm]: {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },

  [media.below.md]: {
    padding: `${token('space.300')} ${token('space.300')} ${token('space.1000')}`,
  },

  [media.below.sm]: {
    padding: `${token('space.200')} ${token('space.200')} 60px !important`,
  },
});

const certificationsFaqsStyles = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '0 210px',
  maxWidth: '1440px',
  width: '100%',

  [media.below.md]: {
    padding: `0 ${token('space.300')}`,
  },

  [media.below.sm]: {
    padding: `0 ${token('space.200')} !important`,
  },
});

const certificationsFaqsTitleStyles = css({
  marginBottom: '35px',
});
