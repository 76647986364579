import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { sendTrackEvent } from 'src/utils/analytics';
import { isStandalone } from 'src/pages/LessonPage/utils/isStandalone';

interface LessonInteractionAnalyticType {
  atlassianId?: string;
  courseSlug: string;
  lessonSlug: string;
  startTimeMs: number;
  isStarted: boolean;
  isCompleted: boolean;
}
export const useLessonInteractionTracking = (): {
  onLessonStarted: (
    courseSlug: string,
    lessonSlug: string,
    isStarted: boolean,
    isCompleted: boolean,
    aaid?: string,
  ) => void;
  onLessonCompletion: () => void;
} => {
  const { courseId, lessonId } = useParams();
  const lessonInteractionAnalytics = useRef<null | LessonInteractionAnalyticType>();
  const isStandaloneLesson = isStandalone();

  const handleUnload = (): void => {
    if (lessonInteractionAnalytics.current && lessonInteractionAnalytics.current.lessonSlug) {
      sendTrackEvent(
        'lessonScreen',
        'lesson',
        'abandoned',
        {
          courseSlug: lessonInteractionAnalytics.current.courseSlug,
          lessonSlug: lessonInteractionAnalytics.current.lessonSlug,
          interactionDuration: calculateInteractionDuration(),
          isStarted: lessonInteractionAnalytics.current.isStarted,
          isCompleted: lessonInteractionAnalytics.current.isCompleted,
          isStandalone: isStandaloneLesson,
        },
        lessonInteractionAnalytics.current.atlassianId,
      );
    }
    lessonInteractionAnalytics.current = null;
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleUnload);

    return () => {
      handleUnload();
      window.removeEventListener('beforeunload', handleUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(handleUnload, [lessonId, courseId, isStandaloneLesson]);
  const calculateInteractionDuration = (): number | null | undefined =>
    lessonInteractionAnalytics.current && new Date().getTime() - lessonInteractionAnalytics.current.startTimeMs;

  const onLessonStarted = (
    courseSlug: string,
    lessonSlug: string,
    isStarted: boolean,
    isCompleted: boolean,
    aaid?: string,
  ): void => {
    lessonInteractionAnalytics.current = {
      atlassianId: aaid,
      courseSlug: courseSlug,
      lessonSlug: lessonSlug,
      startTimeMs: new Date().getTime(),
      isStarted,
      isCompleted,
    };

    if (!isStarted) {
      sendTrackEvent(
        'lessonScreen',
        'lesson',
        'started',
        {
          courseSlug,
          lessonSlug,
          isStandalone: isStandaloneLesson,
        },
        aaid,
      );
    }
  };

  const onLessonCompletion = (): void => {
    if (lessonInteractionAnalytics.current && lessonInteractionAnalytics.current.lessonSlug) {
      lessonInteractionAnalytics.current.isCompleted = true;

      sendTrackEvent(
        'lessonScreen',
        'lesson',
        'completed',
        {
          courseSlug: lessonInteractionAnalytics.current.courseSlug,
          lessonSlug: lessonInteractionAnalytics.current.lessonSlug,
          interactionDuration: !lessonInteractionAnalytics.current.isStarted ? calculateInteractionDuration() : 0,
          isStandalone: isStandaloneLesson,
        },
        lessonInteractionAnalytics.current.atlassianId,
      );
    }
  };

  return { onLessonStarted, onLessonCompletion };
};
