import React from 'react';
import { css } from '@compiled/react';
import { Button, ButtonAppearance, ButtonSize, Heading, Link, LinkStyle, Text } from '@atlassian/learning-components';
import { useNavigate } from 'react-router-dom';
import { token } from '@atlaskit/tokens';
import { media } from '@atlaskit/primitives/responsive';
import { N0 } from '@atlaskit/theme/colors';

import { ContentHubType } from 'src/constants';
import { sendUIEvent } from 'src/utils/analytics';
import { useAtlassianUser } from 'src/hooks/useAtlassianUser';
import { getAssetUrl, getUrlPrefix } from 'src/services/http/user';

interface LearningPathCardProps {
  imageSrc: string;
  altText: string;
  title: string;
  onClick: () => void;
  link: string;
}

const cardsData = [
  {
    title: 'Get started quickly',
    assetURL: '/images/homePage/getStartedQuickly.png',
    altText: 'Get started quickly image',
  },
  {
    title: 'Build admin skills',
    assetURL: '/images/homePage/buildAdminSkills.png',
    altText: 'Build admin skills image',
  },
  {
    title: 'Boost productivity and teamwork',
    assetURL: '/images/homePage/boostProductivity.png',
    altText: 'Boost productivity and teamwork image',
  },
];

const LearningPathSectionCard: React.FC<LearningPathCardProps> = ({ imageSrc, altText, title, onClick, link }) => (
  <div css={cardContainerStyles}>
    <div>
      <img css={cardImageStyles} src={imageSrc} alt={altText} />
      <span css={cardHeadingStyles}>
        <Heading level={3}>{title}</Heading>
      </span>
    </div>
    <span css={linkStyles}>
      <Link style={LinkStyle.SEMIBOLD} label="Learn more" link={link} onClick={onClick} icon="arrow-right" />
    </span>
  </div>
);

export const AllLearningPathsSection: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAtlassianUser();

  const onCardLinkClick = (cardTitle: string, redirectPath: string): void => {
    navigate(`/hub/${ContentHubType.LEARNING_PATHS}#${redirectPath}`);
    sendUIEvent(
      'homeScreen',
      'link',
      'clicked',
      'cardLink',
      {
        contentBlockTitle: 'Focused learning, quick results',
        cardTitle,
      },
      user?.account_id,
    );
  };

  const onButtonClick = (): void => {
    navigate(`/hub/${ContentHubType.LEARNING_PATHS}`);
    sendUIEvent('homeScreen', 'button', 'clicked', 'seeAllLearningPathsButton', {}, user?.account_id);
  };

  return (
    <section data-testid="all-learning-paths-section" css={sectionContainerStyles}>
      <span css={headingStyles}>
        <Heading level={2}>Focused learning, quick results</Heading>
      </span>
      <span css={textStyles}>
        <Text size="large">
          Find free courses or explore focused learning paths designed around your goals. From bite-sized learning to
          in-depth instruction, there’s something for all levels.
        </Text>
      </span>
      <span css={buttonStyles}>
        <Button
          label="See all learning paths"
          size={ButtonSize.LARGE}
          isResponsive={true}
          appearance={ButtonAppearance.PRIMARY}
          onClick={onButtonClick}
        />
      </span>
      <div css={cardsContainerStyles} data-testid="all-learning-paths-section-cards">
        {cardsData.map((card) => {
          const redirectLink = card.title.toLowerCase().replaceAll(' ', '-');

          return (
            <LearningPathSectionCard
              key={card.title}
              imageSrc={getAssetUrl(card.assetURL)}
              altText={card.altText}
              title={card.title}
              onClick={() => onCardLinkClick(card.title, redirectLink)}
              link={`${getUrlPrefix()}/hub/${ContentHubType.LEARNING_PATHS}#${redirectLink}`}
            />
          );
        })}
      </div>
    </section>
  );
};

const sectionContainerStyles = css({
  width: '100%',
  backgroundColor: '#E6F0FC',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: `${token('space.1000')} ${token('space.300')}`,

  [media.below.sm]: {
    padding: `${token('space.600')} ${token('space.200')} 56px`,
  },
});

const headingStyles = css({
  marginBottom: `${token('space.200')}`,
  maxWidth: '720px',
  textAlign: 'center',
});

const textStyles = css({
  marginBottom: token('space.500'),
  maxWidth: '720px',
  textAlign: 'center',
});

const buttonStyles = css({
  marginBottom: token('space.600'),
});

const cardsContainerStyles = css({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  columnGap: token('space.300'),
  rowGap: '0',

  [media.below.md]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
    rowGap: token('space.300'),
  },

  [media.below.sm]: {
    gridTemplateColumns: 'auto !important',
  },
});

const cardContainerStyles = css({
  backgroundColor: N0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: token('space.500'),
  maxWidth: '324px',
  height: '432px',

  [media.below.md]: {
    height: '400px',
  },

  [media.below.sm]: {
    padding: token('space.400'),
    height: '360px',
  },
});

const cardHeadingStyles = css({
  marginBottom: `${token('space.800')}`,

  [media.below.md]: {
    marginBottom: '72px',
  },

  [media.below.sm]: {
    marginBottom: '56px',
  },
});

const cardImageStyles = css({
  marginBottom: `${token('space.300')}`,
  width: '245px',
  height: '160px',
});

const linkStyles = css({
  a: {
    fontSize: '20px',
    lineHeight: '24px',
  },

  [media.below.sm]: {
    a: {
      fontSize: '16px',
      lineHeight: '20px',
    },
  },
});
