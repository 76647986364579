/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useState } from 'react';
import { css } from '@compiled/react';
import { Accordion } from '@atlassian/learning-components';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import { sendUIEvent } from 'src/utils/analytics';
import { getOptions } from 'src/pages/LessonPage/children/utils/getOptions';
import { useMediaProgressTracking } from 'src/hooks/useMediaProgressTracking';
import { useAtlassianUser } from 'src/hooks/useAtlassianUser';
import { trackVideoProgress } from 'src/pages/LessonPage/utils/analytics';
import { isStandalone } from 'src/pages/LessonPage/utils/isStandalone';

interface AccordionBlockProps {
  courseId: string | undefined;
  lessonId: string | undefined;
  fields: Record<string, any>;
  width: number;
}

export const AccordionBlock: React.FC<AccordionBlockProps> = ({ fields, courseId, lessonId, width }) => {
  const { user, isLoading, hasError } = useAtlassianUser();

  const [videoDataProgress, setVideoProgress] = useState({ videoProgress: NaN, name: '', videoId: '' });
  const updateVideoProgress = (progress: number, name: string, videoId: string): void =>
    setVideoProgress({ videoProgress: progress, name, videoId });

  const sendVideoProgressEvent = useCallback(
    (action: string): void => {
      trackVideoProgress({
        action,
        name: videoDataProgress.name,
        videoId: videoDataProgress.videoId,
        videoProgress: videoDataProgress.videoProgress,
        isLoading,
        hasError,
        user,
        courseSlug: courseId!,
        lessonSlug: lessonId!,
      });
    },
    [
      courseId,
      hasError,
      isLoading,
      lessonId,
      user,
      videoDataProgress.name,
      videoDataProgress.videoId,
      videoDataProgress.videoProgress,
    ],
  );

  const tabsContent = fields.tabsLabel?.map((label: string, index: number) => {
    const tabContent = fields.tabsContentCollection.items[index];

    return {
      title: label,
      children: documentToReactComponents(
        tabContent?.content.json,
        getOptions(tabContent?.content, width, user, updateVideoProgress),
      ),
    };
  });

  useMediaProgressTracking(sendVideoProgressEvent, videoDataProgress.videoProgress);

  return (
    <div css={[accordionContainerStyles, spacingStyles]}>
      <Accordion
        size={fields.size}
        shouldSingleTabBeOpen={fields.shouldSingleTabBeOpen}
        tabsContent={tabsContent || []}
        onChange={(tabState: { [key: number]: string } | undefined) => {
          if (tabState) {
            const [[tabIndex, state]] = Object.entries(tabState);

            sendUIEvent(
              'lessonScreen',
              'accordion',
              'clicked',
              'lessonAccordionComponent',
              {
                name: fields.name,
                lessonSlug: lessonId,
                courseSlug: courseId,
                type: state,
                tabIndex,
                isStandaloneLesson: isStandalone(),
              },
              user?.account_id,
            );
          }
        }}
      />
    </div>
  );
};

const accordionContainerStyles = css({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
});

const spacingStyles = css({
  paddingTop: '40px',
  paddingBottom: '40px',
});
