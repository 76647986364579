import { useNavigate } from 'react-router-dom';

const INTERNAL_ROUTES = ['/forums', '/events'];

export const useNavigateByHost = (): ((url: string) => void) => {
  const navigate = useNavigate();

  const navigateByHost = (url: string): void => {
    try {
      const targetUrl = url.startsWith('http') ? url : `https://${url}`;
      const targetHost = new URL(targetUrl).host;
      const currentHost = window.location.host;

      if (targetHost === currentHost) {
        let path = new URL(targetUrl).pathname;

        // Check if the path starts with any of the internal routes
        const isInternalRoute = INTERNAL_ROUTES.some((route) => path.startsWith(route));

        // Only remove /learning prefix if it's not an internal route
        if (isInternalRoute) {
          window.location.href = path;

          return;
        }

        // Remove /learning prefix if it exists
        if (path.startsWith('/learning')) {
          path = path.substring('/learning'.length) || '/';
        }

        navigate(path);
      } else {
        window.open(url, '_blank', 'noopener,noreferrer');
      }
    } catch (error) {
      window.open(url, '_blank', 'noopener,noreferrer');
    }
  };

  return navigateByHost;
};
