import { LoaderFunction } from 'react-router-dom';

import { fetchGraphQL } from 'src/services/contentful/client';
import {
  ContentfulTopic,
  GET_TOPIC_COLLECTION,
  GetTopicCollectionResponse,
} from 'src/graphql/queries/GetTopicCollection';
import { GetTopicCollectionQueryVariables } from 'src/graphql/generated/GetTopicCollection';

export const loader: LoaderFunction = async ({ params, request }): Promise<TopicLoaderProps> => {
  const preview = request.url.includes('preview=true');
  const topicId = params.topicId ?? '';

  const topicData = await getTopic(topicId, preview);

  if (!topicData) {
    throw new Error('Topic was not found');
  }

  return { topicData };
};

const getTopic = async (topicId: string, preview: boolean): Promise<ContentfulTopic | undefined> => {
  const response = await fetchGraphQL<GetTopicCollectionResponse, GetTopicCollectionQueryVariables>(
    GET_TOPIC_COLLECTION,
    {
      slug: topicId,
      preview,
    },
  );

  return response.topicCollection.items[0];
};

export interface TopicLoaderProps {
  topicData: ContentfulTopic;
}
