import { UIEventPayload } from '@atlassiansox/analytics-web-client';
import { Attributes } from '@atlassiansox/analytics-web-client/dist/types/types';
import { ENVIRONMENT } from '@atlassian/community-library/dist/constants';
import { CommunityAnalyticsClient, eventType } from '@atlassian/community-library';

import { getEnvironment } from 'src/services/http/user';
import { detectPage } from 'src/utils/detectPage';
import { Environment } from 'src/constants';

let communityClientInstance: CommunityAnalyticsClient | null = null;

export const getCommunityAnalyticsClient = async (userId?: string): Promise<CommunityAnalyticsClient> => {
  if (communityClientInstance) {
    return communityClientInstance;
  }

  const { CommunityAnalyticsClient } = await import('@atlassian/community-library');

  const env = getEnvironment();

  communityClientInstance = CommunityAnalyticsClient;

  CommunityAnalyticsClient.initWithPageAnalytics(
    CommunityAnalyticsClient.SUB_APP.LEARNING,
    {
      useLegacyUrl: env === Environment.DEVELOPMENT,
    },
    env as unknown as ENVIRONMENT,
    undefined,
    userId || null,
  );

  return communityClientInstance;
};

export const sendScreenEvent = async (name: string, attributes: Attributes, userId?: string): Promise<void> => {
  const analyticsCommunityClient = await getCommunityAnalyticsClient(userId);

  (analyticsCommunityClient as typeof CommunityAnalyticsClient).sendEvent(eventType.SCREEN, {
    name,
    attributes,
  });
};

export const sendOperationalEvent = async (
  source: string,
  actionSubject: string,
  action: string,
  attributes: Attributes,
  userId?: string,
): Promise<void> => {
  const analyticsCommunityClient = await getCommunityAnalyticsClient(userId);

  (analyticsCommunityClient as typeof CommunityAnalyticsClient).sendEvent(eventType.OPERATIONAL, {
    source,
    action,
    actionSubject,
    attributes,
  });
};

export const sendTrackEvent = async (
  source: string,
  actionSubject: string,
  action: string,
  attributes: Attributes,
  userId?: string,
): Promise<void> => {
  const analyticsCommunityClient = await getCommunityAnalyticsClient(userId);

  (analyticsCommunityClient as typeof CommunityAnalyticsClient).sendEvent(eventType.TRACK, {
    source,
    action,
    actionSubject,
    attributes,
  });
};

export const sendUIEvent = async (
  source: string,
  actionSubject: string,
  action: string,
  actionSubjectId?: string,
  attributes?: Attributes,
  userId?: string,
): Promise<void> => {
  const analyticsCommunityClient = await getCommunityAnalyticsClient(userId);

  (analyticsCommunityClient as typeof CommunityAnalyticsClient).sendEvent(eventType.UI, {
    source,
    action,
    actionSubject,
    actionSubjectId,
    attributes,
  });
};

export const sendMarketingUIEvent = async (payload: UIEventPayload, userId?: string): Promise<void> => {
  const analyticsCommunityClient = await getCommunityAnalyticsClient(userId);
  const extendedPayload = {
    ...payload,
    attributes: {
      ...payload.attributes,
      navigatedFrom: detectPage(location.pathname),
    },
  };

  (analyticsCommunityClient as typeof CommunityAnalyticsClient).sendEvent(eventType.UI, extendedPayload);
};
