import React, { ReactElement } from 'react';
import { css } from '@compiled/react';
import { Link } from 'react-router-dom';
import { token } from '@atlaskit/tokens';

export interface SubNavbarLinkProps {
  isSelected: boolean;
  linkName: string;
  linkPath: string;
}

export const SubNavbarLink = ({ isSelected, linkName, linkPath }: SubNavbarLinkProps): ReactElement => (
  <Link css={[contentLinkStyles, isSelected && selectedLinkStyles]} to={linkPath}>
    {linkName}
  </Link>
);

const contentLinkStyles = css({
  textDecoration: 'none',
  color: token('color.text'),
  cursor: 'pointer',
  fontWeight: token('font.weight.regular'),
  '&:hover': {
    textDecoration: 'none',
    color: token('color.link'),
  },
});

const selectedLinkStyles = css({
  textDecoration: 'none',
  color: token('color.link'),
  cursor: 'pointer',
});
