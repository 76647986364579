import { LoaderFunction } from 'react-router-dom';

import { fetchGraphQL } from 'src/services/contentful/client';
import { GET_ROLE_COLLECTION, GetRoleCollectionResponse, ContentfulRole } from 'src/graphql/queries/GetRoleCollection';
import { GetRoleCollectionQueryVariables } from 'src/graphql/generated/graphql';

export const loader: LoaderFunction = async ({ params, request }): Promise<RoleLoaderProps> => {
  const preview = request.url.includes('preview=true');
  const roleId = params.roleId ?? '';

  const roleData = await getRole(roleId, preview);

  if (!roleData) {
    throw new Error('Role was not found');
  }

  return { roleData };
};

const getRole = async (roleId: string, preview: boolean): Promise<ContentfulRole | undefined> => {
  const response = await fetchGraphQL<GetRoleCollectionResponse, GetRoleCollectionQueryVariables>(GET_ROLE_COLLECTION, {
    slug: roleId,
    preview,
  });

  return response.roleCollection.items[0];
};

export interface RoleLoaderProps {
  roleData: ContentfulRole;
}
