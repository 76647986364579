import { sendUIEvent } from 'src/utils/analytics';

export const handleCardLinkClick = (cardTitle: string, sectionTitle?: string, accountId?: string): void => {
  sendUIEvent(
    'roleBasedCollectionPage',
    'link',
    'clicked',
    'cardLink',
    {
      contentBlockTitle: sectionTitle,
      cardTitle,
    },
    accountId,
  );
};
