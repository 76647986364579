import { css } from '@compiled/react';
import { Image } from '@atlassian/learning-components';

import { sendUIEvent } from 'src/utils/analytics';
import { User } from 'src/types';
import { isStandalone } from 'src/pages/LessonPage/utils/isStandalone';
import { getImageAttrs } from 'src/utils/contentful';
import { AtImage } from 'src/graphql/generated/graphql';

interface ImageInlineProps {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  entry: any;
  courseId: string | undefined;
  lessonId: string | undefined;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  fields: Record<string, any>;
  user: User | null | undefined;
}

export const ImageInline: React.FC<ImageInlineProps> = ({ entry, fields, courseId, lessonId, user }) => {
  const image = getImageAttrs(fields as AtImage);
  const shouldRenderImage = !!image;

  return shouldRenderImage ? (
    <div css={spacingStyles}>
      <Image
        center={!!fields.center}
        caption={fields.caption ?? null}
        src={image.src}
        width={fields.width || null}
        height={fields.height || null}
        alt={image.alt}
        css={css({ maxWidth: '100%' })}
        onClick={() =>
          sendUIEvent(
            'lessonScreen',
            'image',
            'clicked',
            'imageComponent',
            {
              lessonSlug: lessonId,
              courseSlug: courseId,
              imageId: entry.sys.id,
              imageAlt: image.alt,
              isStandaloneLesson: isStandalone(),
            },
            user?.account_id,
          )
        }
      />
    </div>
  ) : null;
};

const spacingStyles = css({
  paddingTop: '40px',
  paddingBottom: '40px',
});
