import { FeatureGateEnvironment, ClientOptions } from '@atlaskit/feature-gate-js-client';

export const TARGET_APP = 'learning-app-fe_web';

export const getClientOptions = (appEnvironment: string): ClientOptions => {
  switch (appEnvironment) {
    case 'production':
      return {
        apiKey: 'e1c1d93e-75ca-47fb-b625-aeef457406b3',
        environment: FeatureGateEnvironment.Production,
        targetApp: TARGET_APP,
      };
    case 'staging':
      return {
        apiKey: 'fbb553ea-90bc-444e-9070-89c7710dbd04',
        environment: FeatureGateEnvironment.Staging,
        targetApp: TARGET_APP,
      };
    default:
      return {
        apiKey: '094d594e-76ef-4798-821a-c8788f5f2390',
        environment: FeatureGateEnvironment.Development,
        targetApp: TARGET_APP,
      };
  }
};
