import React from 'react';
import { css } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { media } from '@atlaskit/primitives/responsive';
import { Heading } from '@atlassian/learning-components';

interface CertificationBenefitProps {
  image?: {
    src: string;
    alt: string;
  };
  title: string;
  subtitle: React.ReactNode;
}

export const CertificationBenefit: React.FC<CertificationBenefitProps> = ({ image, title, subtitle }) => (
  <div css={containerStyles} data-testid="cert-benefit">
    {image && <img css={imageStyles} src={image.src} alt={image.alt} />}
    <div>
      <div css={headingWrapperStyles}>
        <Heading level={4}>{title}</Heading>
      </div>
      <span css={subtitleStyles}>{subtitle}</span>
    </div>
  </div>
);

const containerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'start',
  maxWidth: '340px',
  padding: `0 ${token('space.500')}`,
  borderRight: `1px solid ${token('color.border')}`,

  '&:last-of-type': {
    borderRight: 'none',
  },

  [media.below.md]: {
    padding: `${token('space.300')} 0`,
    maxWidth: 'unset',
    borderRight: 'none',
    flexDirection: 'row',
    textAlign: 'left',
    borderTop: `1px solid ${token('color.border')}`,

    '&:last-of-type': {
      borderBottom: `1px solid ${token('color.border')}`,
    },
  },

  [media.below.sm]: {
    padding: `${token('space.300')} 0`,
    flexDirection: 'column !important',
    textAlign: 'center !important',
  },
});

const imageStyles = css({
  objectFit: 'cover',
  width: '120px',
  height: '120px',
  marginRight: 0,

  [media.below.sm]: {
    marginRight: 0,
    marginBottom: token('space.100'),
  },

  [media.below.md]: {
    marginRight: token('space.300'),
  },
});

const headingWrapperStyles = css({
  margin: `${token('space.100')} 0`,
});

const subtitleStyles = css({
  fontSize: '16px',
  lineHeight: '24px',
});
