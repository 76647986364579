import React, { ReactElement, useState, useEffect } from 'react';
import { css } from '@compiled/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { token } from '@atlaskit/tokens';
import { Button, ButtonAppearance } from '@atlassian/learning-components';

import { SubNavbarLink } from 'src/pages/App/children/SubNavbarLink';
import { useAtlassianUser } from 'src/hooks/useAtlassianUser';
import { ContentHubType, LandingType } from 'src/constants';
import { sendUIEvent } from 'src/utils/analytics';
import { detectPage } from 'src/utils/detectPage';
import { SubNavbarDropdown } from 'src/pages/App/children/SubNavbarDropdown';

export const SubNavbar = (): ReactElement => {
  const navigate = useNavigate();
  const { user } = useAtlassianUser();
  const [scrollProgress, setScrollProgress] = useState(0);

  const location = useLocation();
  const isSelected = (path: string): boolean => location.pathname === path;

  useEffect(() => {
    const handleScroll = (): void => {
      setScrollProgress(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleMyLearningClick = (): void => {
    user &&
      sendUIEvent(
        'learningApp',
        'button',
        'clicked',
        'myLearningButton',
        {
          navigatedFrom: detectPage(location.pathname),
        },
        user.account_id,
      );

    navigate(`/my-learning/in-progress`);
  };

  const handleDropdownItemClick = (): void => {
    sendUIEvent(
      'learningApp',
      'subNavbarDropdown',
      'clicked',
      'subNavbarDropdownItem',
      {
        navigatedFrom: detectPage(location.pathname),
      },
      user?.account_id,
    );
  };

  const dynamicStyles = {
    transform: `translateY(-${Math.min(scrollProgress, 68)}px)`,
  };

  const handleLearningClick = (): void => {
    navigate('/');
  };

  return (
    <nav
      data-testid="subnavbar"
      css={[containerStyles, scrollProgress > 0 && scrolledStyles, disableDynamicStylesOnTable]}
      style={dynamicStyles}
      aria-label="learning"
    >
      <div css={contentStyles}>
        <div css={navigationContainerStyles}>
          <p
            role="button"
            aria-label="home-page-link"
            tabIndex={0}
            css={headingStyles}
            onClick={handleLearningClick}
            onKeyDown={handleLearningClick}
          >
            Learning
          </p>
          <ul css={contentListStyles} aria-label="learning navigation">
            <li css={contentItemStyles}>
              <SubNavbarDropdown onDropdownItemClick={handleDropdownItemClick} />
            </li>
            <li css={contentItemStyles}>
              <SubNavbarLink
                linkName="Live training"
                linkPath={`/landing/${LandingType.LIVE_TRAINING}`}
                isSelected={isSelected(`/landing/${LandingType.LIVE_TRAINING}`)}
              />
            </li>
            <li css={contentItemStyles}>
              <SubNavbarLink
                linkName="Certifications"
                linkPath={`/hub/${ContentHubType.CERTIFICATIONS}`}
                isSelected={isSelected(`/hub/${ContentHubType.CERTIFICATIONS}`)}
              />
            </li>
          </ul>
        </div>
        {user && (
          <div>
            <Button label="My learning" appearance={ButtonAppearance.SECONDARY} onClick={handleMyLearningClick} />
          </div>
        )}
      </div>
    </nav>
  );
};

const disableDynamicStylesOnTable = css({
  '@media (max-width: 1280px)': {
    transform: 'unset !important',
  },
});

const scrolledStyles = css({
  zIndex: 200,
});

const containerStyles = css({
  position: 'fixed',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '68px',
  backgroundColor: token('color.background.input'),
  zIndex: 6,
  top: '68px',
  padding: '0 16px',

  '@media (max-width: 1279px)': {
    display: 'none',
  },
});

const contentStyles = css({
  width: '100%',
  maxWidth: '1232px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  '@media (min-width: 1280px)': {
    width: '1232px',
  },
});

const navigationContainerStyles = css({
  display: 'flex',
  alignItems: 'center',
});

const headingStyles = css({
  textAlign: 'center',
  color: token('color.text'),
  fontFamily: 'Charlie Text, sans-serif',
  fontSize: '20px',
  fontWeight: 600,
  lineHeight: '20px',
  letterSpacing: '0.05px',
  marginTop: '8px !important',

  '&:hover': {
    cursor: 'pointer',
  },
});

const contentListStyles = css({
  display: 'flex',
  alignItems: 'center',
  paddingLeft: 0,
  marginTop: '8px',
  marginLeft: token('space.300'),
  listStyle: 'none',
});

const contentItemStyles = css({
  height: '56px',
  display: 'flex',
  alignItems: 'center',
  padding: `0 ${token('space.150')}`,
  marginTop: '0',
  marginLeft: token('space.100'),
  textAlign: 'center',
  color: token('color.text'),
  fontFamily: 'Charlie Text, sans-serif',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '20px',
  letterSpacing: '-0.084px',
  position: 'relative',

  '&:first-of-type': {
    marginLeft: 0,
  },
});
