import { css } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { Button, ButtonAppearance, ButtonSize, Heading } from '@atlassian/learning-components';
import { media } from '@atlaskit/primitives/responsive';
import { useNavigate } from 'react-router-dom';
import { FC } from 'react';

import { sendUIEvent } from 'src/utils/analytics';
import { useAtlassianUser } from 'src/hooks/useAtlassianUser';
import { teamTraining } from 'src/pages/landings/LiveTrainingPage/utils/utils';

interface ListItemsSectionProps {
  heading: string;
  subheading: string;
  list: { icon: string; paragraph: string }[];
  buttonLabel?: string;
  link?: string;
  analyticsButtonLabel?: string;
}

export const ListItemsSection: FC<ListItemsSectionProps> = (content: ListItemsSectionProps) => {
  const { user } = useAtlassianUser();
  const navigate = useNavigate();

  const handleClick = (buttonLabel?: string, link?: string): void => {
    sendUIEvent('liveTrainingScreen', 'button', 'clicked', buttonLabel, {}, user?.account_id);

    if (content.heading === teamTraining.heading) {
      link && navigate(link);
    } else {
      window.open(link);
    }
  };

  return (
    <div css={containerStyles}>
      <div css={css({ marginBottom: token('space.200') })}>
        <Heading level={2}>{content.heading}</Heading>
      </div>
      <p css={[paragraphStyles, css({ marginBottom: token('space.200') })]}>{content.subheading}</p>
      {content.list.map((item) => (
        <div key={item.paragraph} css={paragraphWithIconContainerStyles}>
          <img css={iconStyles} src={item.icon} alt={item.paragraph} />
          <p css={paragraphStyles}>{item.paragraph}</p>
        </div>
      ))}
      <div css={css({ marginTop: token('space.500') })}>
        {content.buttonLabel && (
          <Button
            appearance={ButtonAppearance.PRIMARY}
            size={ButtonSize.LARGE}
            label={content.buttonLabel}
            isResponsive={true}
            onClick={() => handleClick(content.analyticsButtonLabel, content.link)}
          />
        )}
      </div>
    </div>
  );
};

const containerStyles = css({
  width: '1020px',
  marginTop: token('space.1000'),
  marginBottom: token('space.1000'),

  [media.below.lg]: {
    width: '720px',
  },

  [media.below.sm]: {
    width: '375px',
    marginTop: '56px',
    marginBottom: '56px',
    padding: `0 ${token('space.200')}`,
  },
});

const paragraphWithIconContainerStyles = css({
  display: 'flex',
  alignItems: 'center',
  marginBottom: token('space.200'),

  [media.below.sm]: {
    fontSize: '16px',
    lineHeight: '24px',
  },
});

const paragraphStyles = css({
  color: '#000',
  marginTop: 0,
  fontFamily: 'Inter, sans-serif',
  fontSize: '20px',
  fontWeight: 400,
  lineHeight: '32px',

  [media.below.sm]: {
    fontSize: '16px',
    lineHeight: '24px',
  },
});

const iconStyles = css({
  marginRight: token('space.200'),
});
