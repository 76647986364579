import React, { useEffect } from 'react';
import { css } from '@compiled/react';
import { media } from '@atlaskit/primitives/responsive';
import { Heading } from '@atlassian/learning-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { token } from '@atlaskit/tokens';

import { MyLearningTabs } from 'src/pages/MyLearningPage/children/MyLearningTabs';
import { useAtlassianUser } from 'src/hooks/useAtlassianUser';
import { sendScreenEvent } from 'src/utils/analytics';
import { LiveTrainingNotice } from 'src/pages/MyLearningPage/children/LiveTrainingNotice';

export const PATHS = ['in-progress', 'saved', 'complete'];

export const MyLearningPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, isLoading: isLoadingAtlassianUser, hasError } = useAtlassianUser();

  const activeTabIndex = PATHS.findIndex((path) => location.pathname.includes(path));

  useEffect(() => {
    if (activeTabIndex === -1 || (!isLoadingAtlassianUser && !user)) {
      navigate(`/not-found`);
    }

    if (!isLoadingAtlassianUser && !hasError) {
      sendScreenEvent('myLearningScreen', {}, user?.account_id);
    }
  }, [activeTabIndex, isLoadingAtlassianUser, navigate, user, hasError]);

  return (
    <main data-testid="my-learning-page" css={basePageStyles}>
      <section data-testid="my-learning-section" css={sharedSectionContainerStyles}>
        <Heading level={2}>My Learning</Heading>
        <LiveTrainingNotice />
        <MyLearningTabs activeTabIndex={activeTabIndex} />
      </section>
    </main>
  );
};

const basePageStyles = css({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const sharedSectionContainerStyles = css({
  maxWidth: '1232px',
  width: '100%',
  padding: '176px 0 104px',
  margin: '0 auto',

  '@media (max-width: 1279px)': {
    padding: `${token('space.300')} ${token('space.200')} ${token('space.800')} ${token('space.200')}`,
  },

  [media.below.sm]: {
    padding: `${token('space.300')} ${token('space.200')} ${token('space.800')} ${token('space.200')}`,
  },
  [media.only.sm]: {
    padding: `${token('space.400')} ${token('space.300')} ${token('space.800')} ${token('space.300')}`,
  },
});
