import { media } from '@atlaskit/primitives/responsive';
import { css } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { Divider, FlatContentCard, Heading } from '@atlassian/learning-components';
import React, { ReactElement } from 'react';

import greyShape from 'src/assets/images/collections/grayShape.svg';
import { ContentfulCertPrepLinks } from 'src/graphql/queries/GetCertPrepCollection';
import { AssociatedContent } from 'src/graphql/generated/graphql';
import { ImageAttrs } from 'src/utils/contentful';
import { sendUIEvent } from 'src/utils/analytics';
import { useAtlassianUser } from 'src/hooks/useAtlassianUser';
import { useNavigateByHost } from 'src/hooks/useNavigateByHost';

export interface CertificationSectionProps {
  imageAttrs: ImageAttrs | undefined;
  title: string;
  relatedContent: ContentfulCertPrepLinks;
  backgroundColor?: string;
}

export const CertificatePreparationSection = ({
  imageAttrs,
  title,
  relatedContent,
  backgroundColor,
}: CertificationSectionProps): ReactElement => {
  const { user } = useAtlassianUser();
  const navigateByHost = useNavigateByHost();

  const handleLearnMoreClick = (cardTitle: string, link?: string): void => {
    sendUIEvent(
      'certificationPreparationCollectionScreen',
      'link',
      'clicked',
      'cardLink',
      {
        contentBlockTitle: title,
        cardTitle: cardTitle,
      },
      user?.account_id,
    );

    if (link) {
      navigateByHost(link);
    }
  };

  return (
    <div data-testid="certification section collection">
      {backgroundColor && (
        <div css={topGreyShapeWrapperStyles}>
          <img css={topGreyShapeStyles} src={`${greyShape}`} alt="grey shape image" />
        </div>
      )}
      <div css={backgroundColor && [responsiveHeadingStyles, css({ background: backgroundColor })]}>
        <div css={[containerStyles]}>
          <div css={certificationPreparationContentStyles}>
            {imageAttrs && (
              <div>
                <img css={headingImageStyles} src={imageAttrs.src} alt={imageAttrs.alt} />
              </div>
            )}
            <div css={headingContainerStyles}>
              <Heading level={2}>{title}</Heading>
            </div>
          </div>
          <div css={certificationPreparationContainerStyles}>
            {(relatedContent as unknown as AssociatedContent[]).map((content, index) => (
              <div key={`${content?.sys.id}-${index}`} css={certificationPreparationStyles}>
                <Divider color={token('color.border')} height={1} />
                <FlatContentCard
                  onClick={(title) => handleLearnMoreClick(title, content?.link?.link)}
                  title={content?.titleExternal}
                  text={content?.description}
                  link={content?.link?.link}
                  linkLabel={content?.link?.label}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const topGreyShapeWrapperStyles = css({
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
});

const topGreyShapeStyles = css({
  bottom: 0,
  zIndex: '-1',
  width: '100%',
});

const responsiveHeadingStyles = css({
  paddingTop: token('space.800'),

  [media.below.md]: {
    paddingTop: '56px',
  },
});

const containerStyles = css({
  width: '100%',
  margin: '0 auto',
  maxWidth: '1440px',
  display: 'flex',
  flexDirection: 'column',

  [media.below.md]: {
    padding: `0 ${token('space.300')}`,
  },

  [media.below.sm]: {
    padding: `0 ${token('space.200')}`,
  },
});

const certificationPreparationContentStyles = css({
  maxWidth: '1020px',
  width: '100%',
  margin: '0 auto',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  [media.below.md]: {
    maxWidth: '720px',
  },

  [media.below.sm]: {
    maxWidth: '343px',
  },
});

const headingContainerStyles = css({
  marginTop: token('space.400'),
  marginBottom: token('space.200'),
  maxWidth: '720px',
});

const headingImageStyles = css({
  height: '80px',
  width: 'auto',
  maxWidth: '100%',
  boxSizing: 'border-box',
});

const certificationPreparationContainerStyles = css({
  width: '100%',
  maxWidth: '1020px',
  margin: `${token('space.600')} auto ${token('space.1000')} auto`,
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  columnGap: '56px',

  [media.below.md]: {
    gridTemplateColumns: '1fr',
  },
  [media.below.sm]: {
    marginTop: token('space.300'),
  },
});

const certificationPreparationStyles = css({
  maxWidth: '482px',
  width: '100%',
  margin: '0 auto',

  '&:nth-last-child(-n + 2)': {
    borderBottom: `1px solid ${token('color.border')}`,
  },

  [media.below.md]: {
    maxWidth: 'unset',

    '&:nth-last-child(2)': {
      borderBottom: 'none',
    },
  },
});
