import React from 'react';
import { css } from '@compiled/react';
import { media } from '@atlaskit/primitives/responsive';
import { token } from '@atlaskit/tokens';
import { Button, ButtonAppearance, ButtonSize, Heading, Link, LinkStyle, Text } from '@atlassian/learning-components';
import { useNavigate } from 'react-router-dom';

import { useAtlassianUser } from 'src/hooks/useAtlassianUser';
import { sendUIEvent } from 'src/utils/analytics';
import { getAssetUrl, getUrlPrefix } from 'src/services/http/user';

export const RecommendedContentSection: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAtlassianUser();

  const onButtonClick = (buttonTitle: string, redirectLink: string, type: 'link' | 'button'): void => {
    navigate(redirectLink);
    sendUIEvent('homeScreen', type, 'clicked', buttonTitle, {}, user?.account_id);
  };

  return (
    <section data-testid="recommended-content-section" css={recommendedContentContainerStyles}>
      <img
        data-testid="recommended-content-image"
        css={imageContainerStyles}
        src={getAssetUrl('/images/homePage/homeRecommendedContent.png')}
        alt="recommended resource image"
      />
      <div css={textContentContainerStyles}>
        <span css={headingStyles}>
          <Heading level={2}>Get the most out of Atlassian Intelligence</Heading>
        </span>
        <span css={textStyles}>
          <Text size="large">
            Unlock your team’s full potential by automating repetitive tasks and generating new ideas with Atlassian
            Intelligence.
          </Text>
        </span>
        <span css={buttonStyles}>
          <Button
            label="Start the learning path"
            size={ButtonSize.LARGE}
            isResponsive={true}
            onClick={() =>
              onButtonClick('startTheLearningPathButton', '/path/get-the-most-out-of-atlassian-intelligence', 'button')
            }
            appearance={ButtonAppearance.PRIMARY}
          />
        </span>
        <span css={linkStyles}>
          <Link
            style={LinkStyle.SEMIBOLD}
            label="Browse the full learning catalog"
            link={`${getUrlPrefix()}/catalog`}
            onClick={() => onButtonClick('browseTheFullLearningCatalogLink', '/catalog', 'link')}
            icon="arrow-right"
          />
        </span>
      </div>
    </section>
  );
};

const recommendedContentContainerStyles = css({
  padding: `${token('space.300')} ${token('space.300')} ${token('space.1000')}`,
  maxWidth: '1022px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  [media.below.md]: {
    flexDirection: 'column',
    padding: `${token('space.1000')} ${token('space.300')}`,
  },

  [media.below.sm]: {
    padding: `${token('space.800')} ${token('space.200')} !important`,
  },
});

const imageContainerStyles = css({
  maxWidth: '475px',
  marginBottom: '0',

  [media.below.md]: {
    marginBottom: token('space.600'),
  },

  [media.below.sm]: {
    maxWidth: '343px',
    marginBottom: token('space.400'),
  },
});

const textContentContainerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '499px',
  marginLeft: token('space.600'),

  [media.below.md]: {
    maxWidth: '720px',
    marginLeft: '0',
    flexDirection: 'column',
  },
});

const headingStyles = css({
  marginBottom: token('space.200'),
});

const textStyles = css({
  marginBottom: token('space.500'),
});

const buttonStyles = css({
  marginBottom: token('space.300'),
});

const linkStyles = css({
  a: {
    fontSize: '20px',
    lineHeight: '24px',
    textDecorationSkipInk: 'none',
  },

  [media.below.sm]: {
    a: {
      fontSize: '16px',
      lineHeight: '20px',
    },
  },
});
