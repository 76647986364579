import React from 'react';
import { css } from '@compiled/react';
import { Heading, Link, LinkStyle, Text } from '@atlassian/learning-components';
import { useNavigate } from 'react-router-dom';
import { token } from '@atlaskit/tokens';
import { media } from '@atlaskit/primitives/responsive';

import { ContentHubType } from 'src/constants';
import { sendUIEvent } from 'src/utils/analytics';
import { useAtlassianUser } from 'src/hooks/useAtlassianUser';
import { getAssetUrl, getUrlPrefix } from 'src/services/http/user';

export const CareerSection: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAtlassianUser();

  const onLinkClick = (): void => {
    navigate(`/hub/${ContentHubType.ROLE_BASED}`);
    sendUIEvent('homeScreen', 'link', 'clicked', 'findOutMoreLink', {}, user?.account_id);
  };

  return (
    <section data-testid="career-section" css={sectionContainerStyles}>
      <img
        data-testid="career-section-image"
        css={imageContainerStyles}
        src={getAssetUrl('/images/homePage/careerSectionImage.svg')}
        alt="career resource image"
      />
      <div css={textContentContainerStyles}>
        <span css={headingStyles}>
          <Heading level={2}>Prepare for your next career move</Heading>
        </span>
        <span css={textStyles}>
          <Text size="large">
            Learn essential tools and skills to excel in your job and impress employers. From Developers to Marketing
            Managers, IT Support Agents to Platform Engineers and more, these career hubs will help you advance or
            prepare for your next role.
          </Text>
        </span>
        <span css={linkStyles}>
          <Link
            style={LinkStyle.SEMIBOLD}
            label="Find out more"
            link={`${getUrlPrefix()}/hub/${ContentHubType.ROLE_BASED}`}
            onClick={onLinkClick}
            icon="arrow-right"
          />
        </span>
      </div>
      <div css={sectionBackgroundStyles}></div>
    </section>
  );
};

const sectionContainerStyles = css({
  width: '100%',
  height: '802px',
  position: 'relative',
  borderTop: `1px solid #091E4224`,
  borderBottom: `1px solid #091E4224`,
  padding: `${token('space.1000')}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  [media.below.md]: {
    height: '977px',
    flexDirection: 'column',
  },

  [media.below.sm]: {
    height: '753px !important',
    padding: `${token('space.500')} ${token('space.200')}`,
  },
});

const sectionBackgroundStyles = css({
  position: 'absolute',
  top: token('space.1000'),
  width: '100%',
  height: '100%',
  maxWidth: '1020px',
  maxHeight: '640px',
  margin: '0 auto',
  zIndex: -1,
  backgroundColor: '#F8F8F8',
  clipPath: 'polygon(0% 6%, 36% 6%, 36% 0%, 100% 6%, 100% 94%, 64% 94%, 64% 100%, 0 94%);',

  [media.below.md]: {
    maxHeight: '815px',
  },

  [media.below.sm]: {
    maxHeight: '671px !important',
    top: token('space.500'),
    clipPath: 'polygon(0% 4%, 36% 4%, 36% 0%, 100% 4%, 100% 96%, 64% 96%, 64% 100%, 0 96%);',
  },
});

const imageContainerStyles = css({
  maxWidth: '400px',
  marginBottom: '0',

  [media.below.md]: {
    marginBottom: token('space.300'),
  },

  [media.below.sm]: {
    maxWidth: '240px',
  },
});

const textContentContainerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '413px',
  marginLeft: token('space.600'),

  [media.below.md]: {
    maxWidth: '609px',
    marginLeft: '0',
    flexDirection: 'column',
    justifyContent: 'center',
  },
});

const headingStyles = css({
  marginBottom: token('space.200'),
});

const textStyles = css({
  marginBottom: token('space.500'),

  [media.below.md]: {
    marginBottom: token('space.400'),
  },
});

const linkStyles = css({
  a: {
    fontSize: '20px',
    lineHeight: '24px',
  },

  [media.below.sm]: {
    a: {
      fontSize: '16px',
      lineHeight: '20px',
    },
  },
});
