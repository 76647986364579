import { useEffect, useState } from 'react';

import { ContentfulSmLesson } from 'src/graphql/queries/GetSmLesson';
import { TOP_NAVBAR_HEIGHT } from 'src/pages/LessonPage/children/Sidebar';

export const cleanupObserver = (sections: { id: string }[], seenSectionObserver: IntersectionObserver): void => {
  for (const section of [...sections, { id: 'quiz' }]) {
    const element = document.getElementById(`${section.id}`);

    if (element) {
      seenSectionObserver.unobserve(element);
    }
  }
};

export const useIntersactionObserverForTracking = ({
  lesson,
}: {
  lesson: ContentfulSmLesson;
}): { seenSections: string[] } => {
  const [seenSections, setSeenSections] = useState<string[]>([]);

  useEffect(() => {
    if (!lesson) {
      return;
    }
    const seenSectionObserver: IntersectionObserver = new IntersectionObserver(
      (entries) => {
        if (entries.length === 1) {
          setSeenSections((prev) => {
            if (!prev.includes(entries[0].target.id)) {
              return [...prev, entries[0].target.id];
            }

            return prev;
          });
        }
      },
      { threshold: 1, rootMargin: `${TOP_NAVBAR_HEIGHT}px 0px -75% 0px` },
    );

    const sections = lesson.sectionListCollection.items ?? [];

    for (const section of [...sections, { id: 'quiz' }]) {
      const element = document.getElementById(`${section.id}`);

      if (element) {
        seenSectionObserver.observe(element);
      }
    }

    return () => cleanupObserver(sections, seenSectionObserver);
  }, [lesson, setSeenSections]);

  useEffect(() => {
    setSeenSections([]);
  }, [lesson?.slug]);

  return { seenSections };
};
