import React, { useCallback, useMemo, useState } from 'react';
import { LessonsTabContent, LessonsTabContentSize, useDeviceSize } from '@atlassian/learning-components';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { useParams } from 'react-router-dom';
import { css } from '@compiled/react';

import { getOptions } from 'src/pages/LessonPage/children/utils/getOptions';
import { sendUIEvent } from 'src/utils/analytics';
import { useAtlassianUser } from 'src/hooks/useAtlassianUser';
import { ContentfulLessonTabContent } from 'src/graphql/queries/GetSmLesson';
import { useMediaProgressTracking } from 'src/hooks/useMediaProgressTracking';
import { trackVideoProgress } from 'src/pages/LessonPage/utils/analytics';
import { isStandalone } from 'src/pages/LessonPage/utils/isStandalone';

interface LessonsTabWrapperProps {
  block: ContentfulLessonTabContent;
}

export const LessonsTabWrapperComponent: React.FC<LessonsTabWrapperProps> = ({ block }) => {
  const { user, isLoading, hasError } = useAtlassianUser();
  const { courseId, lessonId } = useParams();
  const lessonsTabComponent = block;
  const [videoDataProgress, setVideoProgress] = useState({ videoProgress: NaN, name: '', videoId: '' });
  const updateVideoProgress = (progress: number, name: string, videoId: string): void =>
    setVideoProgress({ videoProgress: progress, name, videoId });

  const [activeTab, setActiveTab] = useState(0);
  const contentLength = useMemo(() => lessonsTabComponent.contentCollection.items.length, [lessonsTabComponent]);
  const activeContent = useMemo(
    () =>
      activeTab < contentLength
        ? lessonsTabComponent.contentCollection.items[activeTab]
        : lessonsTabComponent.contentCollection.items[contentLength - 1],
    [activeTab, contentLength, lessonsTabComponent.contentCollection.items],
  );
  const width = useDeviceSize();
  const size = lessonsTabComponent.size && lessonsTabComponent.size.toLowerCase();

  const isStandaloneLesson = isStandalone();

  const sendVideoProgressEvent = useCallback(
    (action: string): void => {
      trackVideoProgress({
        action,
        name: videoDataProgress.name,
        videoId: videoDataProgress.videoId,
        videoProgress: videoDataProgress.videoProgress,
        isLoading,
        hasError,
        user,
        courseSlug: courseId!,
        lessonSlug: lessonId!,
      });
    },
    [
      courseId,
      hasError,
      isLoading,
      lessonId,
      user,
      videoDataProgress.name,
      videoDataProgress.videoId,
      videoDataProgress.videoProgress,
    ],
  );

  const onChangeTab = useCallback(
    (index: number) => {
      sendUIEvent(
        'lessonScreen',
        'tab',
        'clicked',
        'lessonTabComponent',
        {
          name: lessonsTabComponent.name,
          lessonSlug: lessonId,
          courseSlug: courseId,
          tabIndex: index,
          totalTabs: lessonsTabComponent.contentCollection.items.length,
          isStandaloneLesson: isStandaloneLesson,
        },
        user?.account_id,
      );

      setActiveTab(index);
    },
    [user, lessonsTabComponent, lessonId, courseId, isStandaloneLesson],
  );

  useMediaProgressTracking(sendVideoProgressEvent, videoDataProgress.videoProgress);

  return (
    <div css={lessonTabContainerStyles}>
      <LessonsTabContent
        activeTab={activeTab}
        tabLinks={lessonsTabComponent.tabsLabel}
        onClickTab={onChangeTab}
        size={size as LessonsTabContentSize}
      >
        <div key={activeTab}>
          {activeContent?.content.json &&
            documentToReactComponents(
              activeContent?.content.json,
              getOptions(activeContent?.content, width, user, updateVideoProgress),
            )}
        </div>
      </LessonsTabContent>
    </div>
  );
};

const lessonTabContainerStyles = css({
  width: '100%',
  margin: '40px 0',
  display: 'flex',
  justifyContent: 'center',
});
