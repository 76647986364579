import Tabs, { TabList, useTab, TabPanel } from '@atlaskit/tabs';
import { css } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { ReactElement } from 'react';
import { media } from '@atlaskit/primitives/responsive';

import { PATHS } from 'src/pages/MyLearningPage/MyLearningPage';
import { MyLearningContentSection } from 'src/pages/MyLearningPage/children/MyLearningContentSection';
import { RecentlyViewedLessonSection } from 'src/pages/MyLearningPage/children/RecentlyViewedLessonSection';
import { MyLearningPageContentSections } from 'src/pages/MyLearningPage/constants';
import { sendUIEvent } from 'src/utils/analytics';
import { useAtlassianUser } from 'src/hooks/useAtlassianUser';
import { MyLearningLoaderProps } from 'src/pages/MyLearningPage';
import { ArchivedContentSection } from 'src/pages/MyLearningPage/children/ArchivedContentSection';

interface MyLearningTabsProps {
  activeTabIndex: number;
}

const CustomTab = ({ label }: { label: string }): ReactElement => {
  const tabAttributes = useTab();

  return (
    <div css={tabStyles} {...tabAttributes}>
      {label}
    </div>
  );
};

export const MyLearningTabs = ({ activeTabIndex }: MyLearningTabsProps): ReactElement => {
  const { recentlyViewedLessonsResources, myLearningResourceData, archivedContentResources } =
    useLoaderData() as MyLearningLoaderProps;
  const navigate = useNavigate();
  const { user } = useAtlassianUser();

  const handleTabChange = (index: number): void => {
    navigate(`/my-learning/${PATHS[index]}`);

    user &&
      sendUIEvent(
        'myLearningScreen',
        'tab',
        'clicked',
        'myLearningTab',
        {
          learningTab: PATHS[index],
        },
        user.account_id,
      );
  };

  return (
    <div css={tabsListContainerStyles} data-testid="my-learning-tabs">
      <Tabs onChange={handleTabChange} id="default" defaultSelected={activeTabIndex} selected={activeTabIndex}>
        <TabList>
          <CustomTab label="In Progress" />
          <CustomTab label="Saved" />
          <CustomTab label="Complete" />
        </TabList>
        {PATHS.map((path) => (
          <TabPanel key={path}>
            <div css={emptyTabContainerStyles} key={path}>
              <MyLearningContentSection
                status={path}
                myLearningResourceData={myLearningResourceData}
                handleTabChange={handleTabChange}
              />
              {path === MyLearningPageContentSections.IN_PROGRESS && recentlyViewedLessonsResources && (
                <RecentlyViewedLessonSection
                  resources={recentlyViewedLessonsResources}
                  handleTabChange={handleTabChange}
                />
              )}
              {path === MyLearningPageContentSections.COMPLETE && archivedContentResources && (
                <ArchivedContentSection resources={archivedContentResources} />
              )}
            </div>
          </TabPanel>
        ))}
      </Tabs>
    </div>
  );
};

const tabsListContainerStyles = css({
  marginTop: '28px',
});

const tabStyles = css({
  fontSize: '20px',
  color: token('color.text.subtle'),
  fontFamily: 'Charlie Display, sans-serif',
  fontWeight: 600,
  lineHeight: '28px',
  textAlign: 'center',
  padding: `${token('space.100')} ${token('space.150')}  ${token('space.150')} !important`,

  '&:first-child': {
    paddingLeft: `${token('space.100')} !important`,
  },

  '&:hover[aria-selected="false"]': {
    color: '#000 !important',
  },

  '&:focus-visible': {
    borderRadius: '4px',
    outline: `2px solid ${token('color.border.focused')}`,
  },

  '&:focus-visible[aria-selected]:first-child': {
    paddingLeft: '0 !important',
  },

  [media.below.sm]: {
    fontSize: '16px',
    lineHeight: '24px',
  },

  '@media (max-width: 1279px)': {
    padding: `0 ${token('space.100')} 10px ${token('space.100')} !important`,
  },
});

const emptyTabContainerStyles = css({
  width: '100%',
  margin: '0 auto',
});
