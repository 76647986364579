export const detectPage = (currentPage: string): string => {
  const pages = currentPage.split('/');

  if (pages.includes('course') && !pages.includes('lesson')) {
    return 'courseScreen';
  }

  if (pages.includes('path')) {
    return 'learningPathScreen';
  }

  if (pages.includes('course') && pages.includes('lesson')) {
    return 'lessonScreen';
  }

  if (pages.includes('catalog')) {
    return 'catalogScreen';
  }

  if (pages.includes('my-learning')) {
    return 'myLearningScreen';
  }

  if (pages.includes('profile')) {
    return 'profileScreen';
  }

  return 'homeScreen';
};
