import { ContentfulSmCourse } from 'src/graphql/queries/GetSmCourse';
import { ContentfulSmLesson } from 'src/graphql/queries/GetSmLesson';

export const getNextLesson = (
  course: ContentfulSmCourse,
  lesson: ContentfulSmLesson,
): ContentfulSmCourse['lessonsCollection']['items'][0] | null => {
  if (!course || !lesson) {
    return null;
  }

  const currentLessonIndex = course.lessonsCollection.items.findIndex((l) => l.sys.id === lesson?.sys.id);
  const isLastLesson = currentLessonIndex === course.lessonsCollection.items.length - 1;
  const nextLesson = isLastLesson
    ? course.lessonsCollection.items[0]
    : course.lessonsCollection.items[currentLessonIndex + 1];

  return nextLesson;
};
