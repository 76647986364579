import React from 'react';
import { Divider, FlatContentCard, Heading, Text } from '@atlassian/learning-components';
import { css } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { media } from '@atlaskit/primitives/responsive';

import { AssociatedContent } from 'src/graphql/queries/GetContentHub';
import { useAtlassianUser } from 'src/hooks/useAtlassianUser';
import { sendUIEvent } from 'src/utils/analytics';
import { getUrlPrefix } from 'src/services/http/user';
import { ContentHubType } from 'src/constants';
import { useNavigateByHost } from 'src/hooks/useNavigateByHost';

export interface ContentBlockProps {
  title: string;
  subtitle: string;
  associatedContent: AssociatedContent;
  imageSrc?: string;
}

export const ContentBlock: React.FC<ContentBlockProps> = ({ title, subtitle, associatedContent, imageSrc }) => {
  const { user } = useAtlassianUser();
  const navigateByHost = useNavigateByHost();
  const onLearnMoreLinkClick = (cardTitle: string, link?: string): void => {
    sendUIEvent(
      'learningPathsHubScreen',
      'link',
      'clicked',
      'cardLink',
      {
        contentBlockTitle: title,
        cardTitle,
      },
      user?.account_id,
    );
    if (link) {
      navigateByHost(link);
    }
  };

  return (
    <div id={`${title.toLowerCase().replaceAll(' ', '-')}`} css={contentBlockStyles} data-testid="content-block">
      {imageSrc && (
        <div
          data-testid="content-block-image"
          css={contentBlockImageStyles}
          style={
            {
              '--content-block-image': `url(${imageSrc})`,
            } as Record<string, string>
          }
        ></div>
      )}
      <div css={headerStyles} data-testid="content-block-title">
        <Heading level={2}>{title}</Heading>
      </div>
      <div css={subtitleStyles} data-testid="content-block-subtitle">
        <Text size="large">{subtitle}</Text>
      </div>
      <div css={associatedContentContainerStyles}>
        {associatedContent?.length > 0 &&
          associatedContent.map(({ sys, titleExternal, link, description }) => {
            let updatedLink = link.link;

            try {
              const url = new URL(link.link);
              const learningPrefix = getUrlPrefix();
              const hubPath = `/hub/${ContentHubType.LEARNING_PATHS}`;

              if (url.pathname.includes(learningPrefix + '/path')) {
                const [, rest] = url.pathname.split(learningPrefix);

                url.pathname = `${learningPrefix}${hubPath}${rest}`;
                updatedLink = url.href;
              }
            } catch (error) {
              /* eslint-disable no-console */
              console.error('Invalid URL:', updatedLink, error);
            }

            return (
              <div css={associatedContentStyles} key={sys.id} data-testid="content-block-associated-card">
                <Divider color={token('color.border')} height={1} />
                <FlatContentCard
                  title={titleExternal}
                  link={updatedLink}
                  text={description}
                  onClick={(title) => onLearnMoreLinkClick(title, updatedLink)}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

const contentBlockStyles = css({
  width: '100%',
  marginBottom: '56px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',

  [media.below.xs]: {
    marginBottom: token('space.300'),
  },
});

const contentBlockImageStyles = css({
  height: '240px',
  width: '100%',
  boxSizing: 'border-box',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  marginBottom: token('space.600'),
  backgroundImage: 'var(--content-block-image)',

  [media.below.md]: {
    height: '276px',
  },

  [media.below.xs]: {
    height: '204px',
    marginBottom: token('space.400'),
  },
});

const headerStyles = css({
  marginBottom: token('space.200'),
});

const subtitleStyles = css({
  marginBottom: token('space.500'),

  [media.below.xs]: {
    marginBottom: token('space.300'),
  },
});

const associatedContentContainerStyles = css({
  width: '100%',
  maxWidth: '1022px',
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  columnGap: '56px',

  [media.below.md]: {
    gridTemplateColumns: '1fr',
  },
});

const associatedContentStyles = css({
  maxWidth: '482px',
  width: '100%',
  margin: '0 auto',

  '&:nth-last-child(-n + 2)': {
    borderBottom: `1px solid ${token('color.border')}`,
  },

  [media.below.md]: {
    maxWidth: 'unset',

    '&:nth-last-child(2)': {
      borderBottom: 'none',
    },
  },
});
