import { getLearningPathPart, getLearningPathsHubPart } from 'src/utils/pathUtils';

export const constructCourseUrl = (courseSlug: string, pathId?: string, pathname?: string): string => {
  const learningPathPart = getLearningPathPart(pathId);
  const allLearningPathsHubPart = getLearningPathsHubPart(pathname);

  return `${allLearningPathsHubPart}${learningPathPart}/course/${courseSlug}`;
};

export const constructLessonUrl = (
  courseSlug: string,
  lessonSlug: string,
  pathId?: string,
  pathname?: string,
): string => {
  const learningPathPart = getLearningPathPart(pathId);
  const allLearningPathsHubPart = getLearningPathsHubPart(pathname);

  return `${allLearningPathsHubPart}${learningPathPart}/course/${courseSlug}/lesson/${lessonSlug}`;
};
